import { memo, useCallback, useState } from 'react';

import Button from '@components/Button';
import SwitchButtons from '@features/Switch/SwitchButtons';
import {
  END_TYPE_OPTIONS_LABEL_MAP,
  getEndTypeOptions,
} from '@pages/shared/realtime-dashboard/model/constants/options';
import { useSelector } from '@xstate/react';
import { dashboardActor } from '@pages/shared/realtime-dashboard/model/xstate/dashboardMachine';

type ConnectSettingsSectionProps = {
  onStartRecording: any;
  onStopRecording: any;
  onConnectConversation: any;
  onDisconnectConversation: any;
  onChangeTurnEndType: any;
  isRecording: boolean;
  canPushToTalk: boolean;
  onFinishSession: () => void;
  // setRecordingStatus: (value: string) => void;
  sessionId: string;
};

const ConnectSettingsSection = ({
  onStartRecording,
  onStopRecording,
  onConnectConversation,
  onDisconnectConversation,
  isRecording,
  onChangeTurnEndType,
  canPushToTalk,
  onFinishSession,
  // setRecordingStatus,
  sessionId,
}: ConnectSettingsSectionProps) => {
  const isConnected = useSelector(dashboardActor, (state) => state.context.isConnected);
  const selectedEndType = useSelector(dashboardActor, (state) => state.context.selectedEndType);
  const selectedEndTypeLabel =
    END_TYPE_OPTIONS_LABEL_MAP[selectedEndType.value as keyof typeof END_TYPE_OPTIONS_LABEL_MAP];
  const [isFinishSessionDisabled, setIsFinishSessionDisabled] = useState(false);

  const handleConnectClick = useCallback(() => {
    if (isConnected) {
      onDisconnectConversation?.();
    } else {
      onConnectConversation?.(sessionId);
    }
  }, [isConnected]);

  return (
    <div className="flex flex-col mt-4 space-y-4">
      <div className="flex justify-between">
        <div className="flex items-center">{selectedEndTypeLabel}</div>
        <SwitchButtons
          containerClasses="!w-[10.25rem] shrink-0 text-[11px]"
          options={getEndTypeOptions()}
          initialOption={selectedEndType}
          onChange={onChangeTurnEndType}
        />
      </div>
      <div className="flex flex-col">
        <div className="flex space-x-4">
          <Button
            className="w-1/2 disabled:cursor-not-allowed"
            text={isRecording ? 'Release to send' : 'Press to talk'}
            disabled={!isConnected || !canPushToTalk}
            onMouseDown={onStartRecording}
            onMouseUp={onStopRecording}
          />
          <Button
            className={`${isConnected ? 'bg-gray-500' : 'bg-blue-500'} w-1/2`}
            text={isConnected ? 'Disconnect' : 'Connect'}
            onClick={handleConnectClick}
          />
          <Button
            disabled={isFinishSessionDisabled}
            className={`${isConnected ? 'bg-gray-500' : 'bg-blue-500'} w-1/2`}
            text="Finish Session"
            onClick={() => {
              setIsFinishSessionDisabled(true);
              onFinishSession();
            }}
          />
        </div>
      </div>
    </div>
  );
};

const MemoizedConnectSettingsSection = memo(ConnectSettingsSection);
MemoizedConnectSettingsSection.displayName = 'ConnectSettingsSection';

export default MemoizedConnectSettingsSection;
