import InfoCard from '@shared/ui/card/InfoCard';
import { ReactNode } from 'react';

const createHintCardFactory = (content: ReactNode) => {
  return (
    <InfoCard cardClasses="!h-[75%] md_d:!h-full !pl-4">
      <div className="flex">
        <span className="flex-shrink-0 w-5 h-5 mr-2 bg-purple-500 rounded-full flex" />
        <p className="text-start text-sm md_d:text-base xl_d:text-lg">{content}</p>
      </div>
    </InfoCard>
  );
};

export const HINT_CARD_MAP = [
  createHintCardFactory(
    <>
      You can now invite a patient to this session or start a session with our AI bot to test how
      the session works.
    </>
  ),
  createHintCardFactory(
    <>
      Adjust camera so that you are in the center of the frame and stay still so that our software
      measures your heart rate.
      <br />
      <br />
      Our software also measures the heart rate of your patient so that you can understand in what
      state they are during the session.
      <br />
      <br />
      Make sure that your lighting is bright enough.
    </>
  ),
  createHintCardFactory(
    <>
      Here you can see the summary of your patients’ previous session.
      <br />
      Right now this is filled with AI data.
      <br />
      <br />
      Before each session, you can review what you discussed with the patient in the previous
      session and prepare for the session.
    </>
  ),
  createHintCardFactory(
    <>
      Here you can see the Self-work that your patient did and their results.
      <br />
      <br />
      Self-work is done with AI so that AI analyzes all the data and gives you the summary result.
    </>
  ),
];
