import Button from '@components/Button';
import { Modal } from './Modal';

export const ReusableModal = ({
  isOpen,
  title,
  onClose,
  content,
}: {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  content: React.ReactNode;
}) => (
  <Modal
    paperClass="!h-[90%] !max-h-[90%] !w-[45rem] md_d:!w-[50rem]"
    title={title}
    isOpen={isOpen}
    onCancel={onClose}
    withTitleDivider={false}
    controls={
      <div className="flex mt-4 justify-end">
        <Button
          text="Continue"
          onClick={onClose}
        />
      </div>
    }
  >
    {content}
  </Modal>
);
