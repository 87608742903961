import { ReactNode } from 'react';
import { MoveRight } from 'lucide-react';

type InfoCardProps = {
  children?: ReactNode;
  title?: string;
  caption?: string;
  buttonLabel?: string;
  customButton?: ReactNode;
  onCardButtonClick?: () => void;
  cardClasses?: string;
  customTitle?: ReactNode;
  captionClasses?: string;
  isClickable?: boolean;
  isClickableHoverClasses?: string;
};

const InfoCard = ({
  children,
  title,
  caption,
  buttonLabel,
  customButton,
  customTitle,
  cardClasses = 'bg-white',
  captionClasses = 'text-text-subtext',
  isClickableHoverClasses = '',
  isClickable = false,
  onCardButtonClick = () => {},
}: InfoCardProps) => {
  return (
    <div
      onClick={isClickable ? onCardButtonClick : undefined}
      className={`w-[18rem] h-[21rem] sm_d:w-[22rem] sm_d:h-[21rem] max-h-[23rem] 
              md_d:max-h-[28rem] md_d:w-[28rem] md_d:h-[28rem] py-7 px-4 md_d:px-6 
              flex flex-col justify-between rounded-2xl shadow-card 
              ${cardClasses} 
              ${isClickable ? `cursor-pointer ${isClickableHoverClasses} ` : ''}`}
    >
      {(title || caption || customTitle) && (
        <div className="w-full overflow-hidden text-ellipsis text-left pb-4">
          {customTitle && customTitle}
          {title && <p className="text-xl sm_d:text-2xl font-semibold">{title}</p>}
          {caption && (
            <span className={`text-sm sm_d:text-base text-ellipsis ${captionClasses}`}>
              {caption}
            </span>
          )}
        </div>
      )}
      {children && (
        <div className="w-full h-full overflow-hidden rounded-lg overflow-y-auto">{children}</div>
      )}
      {customButton && customButton}
      {buttonLabel && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            onCardButtonClick();
          }}
          className={`flex cursor-pointer pt-6 self-end gap-x-2 `}
        >
          <p className="text-sm sm_d:text-base">{buttonLabel}</p> <MoveRight />
        </div>
      )}
    </div>
  );
};

export default InfoCard;
