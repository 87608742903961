import { shallowEqual, useSelector } from '@xstate/react';
import { dashboardActor } from '@pages/shared/realtime-dashboard/model/xstate/dashboardMachine';
import PSDPlot from '@features/Plot/PSDPlot';
import PowerColumn from '../PowerColumn';

const PSDGraph = ({ isRealtime = true, data }: { isRealtime?: boolean; data?: any }) => {
  const frequencyDomainMetricsCustom = useSelector(
    dashboardActor,
    (state) => state.context.sdkData?.frequencyDomainMetricsCustom,
    shallowEqual
  );

  let latestElement: any = {};
  if (isRealtime) {
    latestElement = frequencyDomainMetricsCustom;
  } else {
    const metrics = data?.processedData?.frequencyDomainMetricsCustom;

    latestElement =
      Array.isArray(metrics) && metrics.length > 0
        ? {
            ...metrics[0],
            startTime: metrics[0]?.startTime ?? null,
            endTime: metrics[0]?.endTime ?? null,
            frequencies: metrics[0]?.frequencies ?? [],
            power: metrics[0]?.power ?? [],
          }
        : null;
  }

  return (
    <div>
      <div className="flex gap-5 w-full items-center">
        <div className="flex flex-col items-center">
          <h2 className="text-sm font-bold">
            Window (Start: {latestElement?.startTime?.toFixed(2) || 0}s, End:
            {latestElement?.endTime?.toFixed(2) || 0}s)
          </h2>
          <PSDPlot
            frequencies={latestElement?.frequencies || []}
            powerValues={latestElement?.power || []}
          />
        </div>
        <div className="flex gap-7 items-end h-48 w-fit mb-14">
          <PowerColumn
            label="VLF"
            percentage={latestElement?.vlfPercentage || 0}
            color="red"
          />
          <PowerColumn
            label="LF"
            percentage={latestElement?.lfPercentage || 0}
            color="green"
          />
          <PowerColumn
            label="HF"
            percentage={latestElement?.hfPercentage || 0}
            color="#9333EA"
          />
        </div>
      </div>
    </div>
  );
};

export default PSDGraph;
