import { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import { getVideoSessionSummaryContent, getVideoSessionNotes } from '@api/userAPI';
import { useSelector } from '@xstate/react';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { Loader } from '@shared/ui/loader/Loader';
import { findPatientById } from '@utils/patient';
import { getLastSession } from '@utils/sessions';
import Accordion from '@shared/ui/accordion/Accordion';
import { ShamefulAny } from '@interfaces/index';
import 'react-quill/dist/quill.snow.css'; // Import the default Quill theme styles

const SummaryWaitingRoom = () => {
  const currentPatientId = useSelector(
    refaelaTherapistActor,
    (state) => state.context.currentPatientId
  );
  const currentUser = useSelector(refaelaTherapistActor, (state) => state.context.currentUser);
  const patientList = useSelector(
    refaelaTherapistActor,
    (state) => state.context.patientSegment.patientList
  );

  const [summary, setSummary] = useState('Generating a summary, please wait...');
  const [note, setNote] = useState('');
  const [isSummaryLoading] = useState(false);

  const therapistId = currentUser?.therapistId;

  useEffect(() => {
    if (!patientList.length || !currentPatientId) return;

    const currentPatient = findPatientById(patientList, currentPatientId);
    if (!currentPatient) return;

    const lastSession = getLastSession(currentPatient.sessions);

    const noSessionsMessage =
      'There are no sessions yet. The last session summary will be shown here when you complete your first session with this patient.';

    if (!lastSession) {
      setSummary(noSessionsMessage);
      setNote(noSessionsMessage);
      return;
    }

    if (!therapistId || !lastSession.sessionId) return;

    const fetchData = async () => {
      try {
        const [fetchedSummary, fetchedNotes] = await Promise.all([
          getVideoSessionSummaryContent(currentPatientId, therapistId, lastSession.sessionId),
          getVideoSessionNotes(currentPatientId, therapistId, lastSession.sessionId),
        ]);

        setSummary(fetchedSummary || 'No summary available');
        setNote(fetchedNotes || 'No notes available');
      } catch (error: ShamefulAny) {
        console.error('Error fetching summary or notes:', error.message, error.details);
        setSummary(`Error loading summary: ${error.message}`);
        setNote(`Error loading notes: ${error.message}`);
      }
    };

    fetchData();
  }, [currentPatientId, therapistId]);

  // Sanitize the data before rendering
  const sanitizedSummary = DOMPurify.sanitize(summary);
  const sanitizedNote = DOMPurify.sanitize(note);

  return (
    <div className="w-full h-full overflow-hidden flex justify-center gap-20 min-h-[700px]">
      <div className="flex w-full flex-col">
        {/* maxHeight={400}   looks too much*/}
        <Accordion
          titleClasses="text-sm"
          title="Last session summary"
          maxHeight={240}
        >
          <div className="relative">
            <div
              className="text-left ql-editor"
              dangerouslySetInnerHTML={{ __html: sanitizedSummary }}
            />

            {isSummaryLoading && (
              <div className="absolute inset-0 bg-white bg-opacity-70 flex items-center justify-center">
                <Loader
                  className="m-0 justify-between w-fit "
                  spinnerClasses={'ml-0'}
                />
              </div>
            )}
          </div>
        </Accordion>
        <Accordion
          titleClasses="text-sm"
          title="Notes"
          maxHeight={240}
        >
          <div
            className="text-left ql-editor"
            dangerouslySetInnerHTML={{ __html: sanitizedNote }}
          />
        </Accordion>
      </div>
    </div>
  );
};

export default SummaryWaitingRoom;
