import { EventLogType } from '@shared/constants/events/eventLogs';
import { EventLogSource } from '@shared/constants/events/eventSource';

export function getEventSourceByType(eventType: EventLogType) {
  switch (eventType) {
    case EventLogType.HEART_RATE_10S:
    case EventLogType.SHENAI_DATA:
      return EventLogSource.MXLABS;
    case EventLogType.VF_TRANSCRIPTS:
      return EventLogSource.VF;
    case EventLogType.USER_FACE_EMOTIONS:
      return EventLogSource.HUME;
    case EventLogType.FEEDBACK:
      return EventLogSource.FEEDBACK;
    case EventLogType.FOR_LIVE:
      return EventLogSource.FOR_LIVE;
    default:
      return EventLogSource.CUSTOM;
  }
}
