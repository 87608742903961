import { useCallback, useRef } from 'react';
import { throttle } from 'lodash';
import { dashboardActor } from '../xstate/dashboardMachine';

const useRealtimeBuffers = () => {
  const agentStateBuffer = useRef<any[]>([]);
  const realtimeEventsBuffer = useRef<any[]>([]);

  const throttleUpdateAgentState = useCallback(
    throttle((setAgentState?: (state: any) => void) => {
      const prev = [...agentStateBuffer.current];
      agentStateBuffer.current = [];
      setAgentState?.(prev[prev.length - 1]);
    }, 2000),
    []
  );

  const throttleUpdateRealtimeEvents = useCallback(
    throttle((wavStreamPlayer: any) => {
      const prev = [...realtimeEventsBuffer.current];
      realtimeEventsBuffer.current = [];
      dashboardActor.send({
        type: 'REALTIME_EVENTS_UPDATE',
        data: prev.map((event) => ({
          ...event,
          role: wavStreamPlayer.stream ? 'assistant' : null,
        })),
      });
    }, 2000),
    []
  );

  return {
    agentStateBuffer,
    realtimeEventsBuffer,
    throttleUpdateAgentState,
    throttleUpdateRealtimeEvents,
  };
};

export default useRealtimeBuffers;
