import { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { RedirectToSignIn, useSignIn, useUser } from '@clerk/clerk-react';
import { DailyProvider } from '@daily-co/daily-react';
import { AppProvider } from './contextApp';
import { getTokenFromURL } from '@utils/getTokenFromURL';
import { getFullToken } from '@api/user/me';
import { WebSocketProvider } from './websocket';
import App from './App';
import { Loader } from '@shared/ui/loader/Loader';

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <App />,
    },
  ],
  {
    future: {
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_relativeSplatPath: true,
      v7_skipActionErrorRevalidation: true,
    },
  }
);

const AppWrapper = () => {
  const [isSigningIn, setIsSigningIn] = useState(true);
  const [isExecuted, setIsExecuted] = useState(false);
  const { signIn } = useSignIn();
  const { isSignedIn } = useUser(); // Fetches the user's sign-in status from Clerk

  useEffect(() => {
    const processToken = async () => {
      try {
        if (signIn && !isExecuted) {
          const tokenFromUrl = getTokenFromURL(window.location.href);
          let fetchedFullToken = tokenFromUrl;

          // Check if a token was found in the URL, otherwise fetch one using the shortId.
          if (!fetchedFullToken) {
            const shortId = new URLSearchParams(window.location.search).get('shortId');
            if (shortId) {
              fetchedFullToken = await getFullToken(shortId);
            }
          }

          // If a token is found, create the sign-in session.
          if (fetchedFullToken) {
            setIsExecuted(true);
            const signInResult = await signIn.create({
              strategy: 'ticket',
              ticket: fetchedFullToken,
            });
            console.log('Sign-in response:', JSON.stringify(signInResult, null, 2));
          }
        }

        // Wait a moment before switching to false to ensure everything completes.
        setTimeout(() => {
          setIsSigningIn(false);
        }, 500);
      } catch (err: any) {
        err.sentryHandled = true;
        console.error('Error during token handling:', JSON.stringify(err));
        setIsSigningIn(false);
      }
    };

    processToken();
  }, [signIn]);

  // Show the loader while signing in
  if (isSigningIn) {
    return <Loader />;
  }

  // Check the sign-in status and render accordingly
  return isSignedIn ? (
    <AppProvider>
      <WebSocketProvider>
        <DailyProvider>
          <RouterProvider
            router={router}
            future={{ v7_startTransition: true }}
          />
        </DailyProvider>
      </WebSocketProvider>
    </AppProvider>
  ) : (
    <RedirectToSignIn />
  );
};

export default AppWrapper;
