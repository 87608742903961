import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from 'lucide-react';
import isBoolean from 'lodash/isBoolean';
import { Loader } from '@shared/ui/loader/Loader';

interface Tab {
  label: string;
  content: React.ReactNode;
}

interface ToggleableProps {
  tabs: Tab[];
  initialTab?: number;
  isShown?: boolean;
  isLoading?: boolean;
  onToggle?: () => void;
  titleClasses?: string;
  titleFont?: string;
  additionalData?: number | string;
  withCardStyle?: boolean;
}

const ToggleableTabs = ({
  tabs,
  initialTab = 0,
  isShown,
  onToggle,
  isLoading,
  titleClasses = '',
  titleFont,
  additionalData,
  withCardStyle,
}: ToggleableProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const [activeTab, setActiveTab] = useState(initialTab);

  const handleToggle = () => {
    if (isLoading) return;
    if (onToggle && isShown !== undefined) {
      onToggle();
    } else {
      setIsOpen((prev) => !prev);
    }
  };

  const handleTabChange = (e: any, index: number) => {
    e.preventDefault();
    if (tabs.length > 1) e.stopPropagation();
    setActiveTab(index);
  };

  const isContentShown = isBoolean(isShown) ? isShown : isOpen;

  const cardStyles = withCardStyle
    ? `${isContentShown ? 'py-3' : 'py-0'} px-5 bg-white rounded-2xl shadow-card`
    : '';

  return (
    <div
      className={`${cardStyles} flex transition-padding duration-300 ease-in-out flex-col items-center w-full`}
    >
      <div
        className={`flex gap-3 cursor-pointer w-full justify-between items-center ${titleClasses}`}
        onClick={handleToggle}
      >
        <div
          className={`${titleFont ? titleFont : 'text-sm font-semibold '} py-1 flex gap-x-2 text-center text-black font-['Inter'] leading-normal tracking-tight`}
        >
          {tabs.map((tab, index) => (
            <button
              key={`${tab}-${index}`}
              className={`py-1 pr-2 ${
                tabs.length > 1 && activeTab === index
                  ? 'px-2 rounded-br-30 bg-purple-600 text-white'
                  : 'text-gray-500'
              }`}
              onClick={(e) => handleTabChange(e, index)}
            >
              <span className="font-bold text-nowrap">{tab.label}</span>
            </button>
          ))}
        </div>
        <div className="flex gap-2 items-center">
          {additionalData && <div className="text-xs text-nowrap">{additionalData}</div>}
          {!isLoading && (isContentShown ? <ChevronUpIcon /> : <ChevronDownIcon />)}
          {isLoading && <Loader />}
        </div>
      </div>
      <div
        className={`w-full transition-max-height duration-300 ease-in-out overflow-hidden ${
          isContentShown ? 'opacity-100 max-h-screen' : 'opacity-0 max-h-0'
        }`}
      >
        {tabs.map((tab, index) => (
          <div
            key={`${tab}-${index}`}
            style={{
              visibility: index === activeTab ? 'visible' : 'hidden',
              height: index === activeTab ? 'auto' : '0',
              overflow: 'hidden',
            }}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ToggleableTabs;
