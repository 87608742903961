import { apiClient } from '..';

export async function generateAISessionSummary(userId: string, sessionId: string): Promise<string> {
  try {
    const requestBody = {
      userId,
      sessionId,
    };

    const { data } = await apiClient.post('/ai/ai_summary_generate', requestBody);

    return data.aiSummary;
  } catch (e: any) {
    console.error('Error generating AI session summary:', e.message, e.details);

    return `${e.message}: ${e.details}`;
  }
}
