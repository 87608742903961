import Checkbox from '@shared/ui/input/checkbox/Checkbox';
import { useState, useMemo } from 'react';
import { mergeTranscriptsBySource } from './utils';

export type KeyMomentsProps = {
  keyMoment: any;
  handleCheckboxChange: (id: string, question: string) => void;
  handleQuestionChange: (id: string, question: string) => void;
};

export const KeyMoment = ({
  keyMoment,
  handleCheckboxChange,
  handleQuestionChange,
}: KeyMomentsProps) => {
  const [whatToAskAbout, setWhatToAskAbout] = useState(keyMoment.questionToAsk || '');

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newQuestion = e.target.value;
    setWhatToAskAbout(newQuestion);
    handleQuestionChange(keyMoment.id, newQuestion);
  };

  const mergedTranscripts = useMemo(
    () => mergeTranscriptsBySource(keyMoment.transcripts),
    [keyMoment.transcripts]
  );

  return (
    <div className="flex gap-4 mb-4">
      <div className="flex items-center">
        <Checkbox
          checked={keyMoment.isApproved}
          onChange={() => handleCheckboxChange(keyMoment.id, whatToAskAbout)}
        />
      </div>
      <div className="p-4 bg-white rounded-2xl shadow-lg w-full max-w-md">
        <div className="space-y-4">
          {mergedTranscripts.map((transcript: any, i: number) => (
            <div
              className="flex flex-col"
              key={i}
            >
              <span className="text-purple-500 font-bold">{transcript.source}</span>
              <p className="text-gray-700">{transcript.message}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-6 w-full">
        <label
          htmlFor="question"
          className="block text-gray-700 font-bold mb-2"
        >
          What to ask about it
        </label>
        <textarea
          className="w-[65%] placeholder-gray-400 border border-gray-200 rounded-lg h-32 resize-none"
          value={whatToAskAbout}
          onChange={handleTextareaChange}
          placeholder="What to ask"
        />
      </div>
    </div>
  );
};
