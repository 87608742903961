export const startFrameDropCounter = (
  onFrameDrop: (missedFrames: number, timestamp: number) => void
) => {
  const expectedFPS = 30; // Target FPS
  const frameDuration = 1000 / expectedFPS;

  let lastFrameTime = performance.now();
  let droppedFrames = 0;

  const monitorFrames = () => {
    const now = performance.now();
    const delta = now - lastFrameTime;

    // Calculate if any frames were dropped
    if (delta > frameDuration) {
      const missedFrames = Math.floor(delta / frameDuration) - 1;
      droppedFrames += missedFrames;

      // Call the callback with dropped frames and timestamp
      if (missedFrames > 0) {
        onFrameDrop(missedFrames, Date.now());
      }
    }

    lastFrameTime = now;
    requestAnimationFrame(monitorFrames);
  };

  requestAnimationFrame(monitorFrames);

  return () => {
    // Stop monitoring when cleanup is required
    console.log(`Stopped monitoring. Total dropped frames: ${droppedFrames}`);
  };
};
