import { useCallback } from 'react';
import { getAiSessionFrames } from '@api/ai/aiFrame';

import { useFetch } from '@hooks/useFetch';

export const useFrames = (userId: string, sessionId: string) => {
  const fetchFrames = useCallback(async () => {
    const response = await getAiSessionFrames(userId, sessionId);

    if (response.error) {
      throw new Error(`Failed to fetch frames: ${response.message}`);
    }
    return response.data.frames || [];
  }, [userId, sessionId]);

  const { data, isLoading, error, refresh } = useFetch({
    fetcher: fetchFrames,
  });

  return {
    frames: data,
    isLoading,
    error,
    refresh,
  };
};
