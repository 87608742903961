import { useEffect, useRef } from 'react';
import { useSelector, shallowEqual } from '@xstate/react';
import { dashboardActor } from '@pages/shared/realtime-dashboard/model/xstate/dashboardMachine';

type RealtimeChecksProps = {
  isConnected: boolean;
};

export const useRealtimeChecks = ({ isConnected }: RealtimeChecksProps) => {
  const { isShowCanvas, isShowWarning } = useSelector(
    dashboardActor,
    (state) => state.context.simplifiedSegment,
    shallowEqual
  );

  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (!isConnected) return;
    const tick = () => {
      dashboardActor.send({ type: 'START_REALTIME_CHECK' });
    };

    timerRef.current = setInterval(tick, 1000);
    tick();

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [isConnected]);

  useEffect(() => {
    if (!isShowCanvas) return;
    let timeoutId: NodeJS.Timeout;
    timeoutId = setTimeout(() => {
      dashboardActor.send({ type: 'RESET_SHOW_CANVAS' });
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isShowCanvas]);

  return { isShowWarning, isShowCanvas };
};
