import { PostgrestClient } from '@supabase/postgrest-js';
import { PostAIEventLogsPayload, PostEventLogsPayload } from './eventLog';
import { USER_ROLES } from '@interfaces/user';

const REST_URL = `${import.meta.env.VITE_VF_SITE_BE_URL}/api/rest`;

export const db = new PostgrestClient(REST_URL, {
  // fetch: (...args) => {
  //   console.log("Args:", args);
  //   return fetch(...args);
  // },
  schema: 'public',
});

declare interface IUser {
  id: string;
}

declare interface IPatient extends IUser {
  id: string;
}

declare interface ITherapist extends IUser {
  id: string;
}

export function createPatient(patient: IPatient) {
  return db.from('users').upsert({ id: patient.id, role: USER_ROLES.PATIENT });
}

export function createTherapist(therapist: ITherapist) {
  return db.from('users').upsert({ id: therapist.id, role: USER_ROLES.THERAPIST });
}

export async function postEventLogToDb(payload: PostEventLogsPayload) {
  const data = payload.logs.map((log) => {
    return {
      session_id: payload.sessionId,
      user_id: payload.patientId,
      type: log.source,
      data: log.data,
    };
  });

  return db.from('measurements').insert(data);
}

export async function postAIEventLogToDb(payload: PostAIEventLogsPayload) {
  const data = payload.logs.map((log) => {
    return {
      session_id: payload.sessionId,
      user_id: payload.userId,
      type: log.source,
      data: log.data,
    };
  });

  return db.from('measurements').insert(data);
}

interface CreateSessionPayload {
  id: string;
  therapistId?: string;
  patientId: string;
  state: string;
  scheduledAt?: Date;
  type: string; // 'standard' | 'ai'
}

export function createSession(payload: CreateSessionPayload) {
  return db.from('sessions').insert({
    id: payload.id,
    therapist_id: payload.type == 'ai' ? null : payload.therapistId,
    patient_id: payload.patientId,
    type: payload.type,
    state: payload.state,
    scheduled_at: payload.scheduledAt?.toISOString(),
  });
}
