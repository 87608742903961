import { FullHeartIcon } from '@assets/icons';
import { EllipsisVerticalIcon, MergeIcon, PencilIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { KeyMoment, KeyMomentItemProps } from './types';
import { findClosestKeyMoment, formatTime } from '@utils/keyMoment';
import Button from '@components/Button';

const TranscriptItem = ({
  item,
  recordingStartTime,
  handleTimestampClick,
  removeKeyMoment,
  keyMoments,
  mergeWithKeyMoment,
  isAdjacentToKeyMoment,
  isInKeyMoment,
  isBeforeKeyMoment,
  isAfterKeyMoment,
  createNewKeyMoment,
  updateTranscriptMessage,
}: KeyMomentItemProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredKeyMoment, setHoveredKeyMoment] = useState<KeyMoment | null>(null);
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedMessage, setEditedMessage] = useState(item.message);

  useEffect(() => {
    if (isAdjacentToKeyMoment) {
      const closestKeyMoment = findClosestKeyMoment(item.timestamp, keyMoments);
      setHoveredKeyMoment(closestKeyMoment);
    } else if (isInKeyMoment) {
      const keyMomentForItem = keyMoments.find((km) =>
        km.transcripts.some((t) => t.id === item.id)
      );
      setHoveredKeyMoment(keyMomentForItem || null);
    }
  }, [isAdjacentToKeyMoment, isInKeyMoment, keyMoments, item.timestamp, item.id]);

  const handleMenuClick = () => {
    setIsMenuClicked(!isMenuClicked);
  };

  const handleUnmergeClick = () => {
    if (hoveredKeyMoment) {
      mergeWithKeyMoment(item, hoveredKeyMoment, true);
    } else {
      console.error('No hoveredKeyMoment found for unmerge');
    }
  };

  const handleRemoveKeyMomentClick = () => {
    const keyMomentToRemove = keyMoments.find((km) => km.transcripts.some((t) => t.id === item.id));

    if (!removeKeyMoment) {
      console.error('removeKeyMoment function is not defined');
      return;
    }

    if (keyMomentToRemove) {
      removeKeyMoment(keyMomentToRemove);
    } else {
      console.error('KeyMoment not found for removal');
    }
  };

  const handleSaveEdit = () => {
    if (typeof updateTranscriptMessage !== 'function') {
      console.error('updateTranscriptMessage is not defined');
      return;
    }

    updateTranscriptMessage(item.id, editedMessage);
    setIsEditing(false);
  };

  useEffect(() => {
    if (!isHovered) setIsMenuClicked(false);
  }, [isHovered]);

  return (
    <div
      className="flex flex-col"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isAfterKeyMoment && isAdjacentToKeyMoment && isHovered && hoveredKeyMoment && (
        <div
          className="cursor-pointer text-purple-400 ml-2 w-full flex justify-center items-center"
          onClick={() => mergeWithKeyMoment(item, hoveredKeyMoment)}
        >
          <div className="h-[2px] bg-purple-400 w-[8rem] "></div>
          <MergeIcon />
          <div className="h-[2px] bg-purple-400 w-[8rem]"></div>
        </div>
      )}
      <div className="flex">
        <div className="flex flex-col p-4 items-start">
          <div className="flex gap-2 items-center">
            <div className="flex gap-[7px] items-center">
              <FullHeartIcon />
              <div className="text-gray-800 text-[13px] font-semibold font-['Inter'] leading-tight">
                __
              </div>
              {recordingStartTime && (
                <div
                  className="cursor-pointer text-gray-500 text-[13px] font-normal font-['Inter'] leading-tight"
                  onClick={() => handleTimestampClick(item.timestamp - recordingStartTime)}
                >
                  {formatTime(item.timestamp)}
                </div>
              )}
              <div className="text-gray-500 text-xs font-bold font-['Inter'] leading-[18px]">
                {item.source.replace(/^./, item.source[0].toUpperCase())}
              </div>
            </div>

            {!isAdjacentToKeyMoment && isHovered && (
              <div
                className="text-gray-600 cursor-pointer relative"
                onClick={handleMenuClick}
              >
                <EllipsisVerticalIcon />
                {isMenuClicked && (
                  <div className=" absolute z-20 w-[172px] h-14 bg-white rounded-xl shadow left-[-155px] flex items-center justify-center">
                    <div className="text-black text-sm font-normal font-['Inter'] leading-[21px] gap-2 text-black">
                      {isInKeyMoment ? (
                        <div className="flex flex-col p-4 items-start">
                          <span
                            className="hover:text-gray-500"
                            onClick={handleUnmergeClick}
                          >
                            Unmerge
                          </span>
                          <span
                            className="hover:text-gray-500"
                            onClick={handleRemoveKeyMomentClick}
                          >
                            Remove Key moment
                          </span>
                        </div>
                      ) : (
                        <span
                          className="hover:text-gray-500"
                          onClick={() => createNewKeyMoment(item)}
                        >
                          Create Key moment
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {/* <div className="text-sm">{item.message}</div> */}
          {isEditing ? (
            <div className="flex items-center gap-2">
              <input
                type="text"
                className="border p-1 text-sm rounded"
                value={editedMessage}
                onChange={(e) => setEditedMessage(e.target.value)}
              />
              <Button
                variant="plain"
                onClick={handleSaveEdit}
                text="Save"
              />

              <Button
                variant="plainSecondary"
                onClick={() => setIsEditing(false)}
                text="Cancel"
              />
            </div>
          ) : (
            <div
              className="text-sm relative group flex items-center gap-1 text-left"
              onDoubleClick={() => {
                if (isInKeyMoment && !isMenuClicked) {
                  setIsEditing(true);
                }
              }}
            >
              {item.message}
              {/* Tooltip */}
              {isInKeyMoment && !isMenuClicked && (
                <div className="absolute flex gap-1 -bottom-5 text-nowrap bg-purple-100 text-gray-600 text-xs px-2 py-1 rounded opacity-0 group-hover:opacity-60 transition-opacity ">
                  <PencilIcon
                    width={'1rem'}
                    height={'1rem'}
                  />
                  <span>Double click to edit</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {isBeforeKeyMoment && isAdjacentToKeyMoment && isHovered && hoveredKeyMoment && (
        <div
          className="cursor-pointer text-purple-400 rotate-180 ml-2 w-full flex justify-center items-center"
          onClick={() => mergeWithKeyMoment(item, hoveredKeyMoment)}
        >
          <div className="h-[2px] bg-purple-400 w-[8rem] "></div>
          <MergeIcon />
          <div className="h-[2px] bg-purple-400 w-[8rem]"></div>
        </div>
      )}
    </div>
  );
};
export default TranscriptItem;
