import React, { useEffect, useState, useRef } from 'react';
import { debounce } from 'lodash';
import { CheckIcon, CloseIcon, FullHeartIcon } from '@assets/icons';
import Button from '@components/Button';

import {
  KeyMoment,
  KeyMomentsData,
  MergedData,
  MKeyMoment,
  MTranscript,
  Transcript,
} from './types';
import TranscriptItem from './TranscriptItem';
import { formatTime } from '@utils/keyMoment';
import { Loader } from '@shared/ui/loader/Loader';

import { ShamefulAny } from '@interfaces/index';
import VideoPlayerComponent from '../RecordingPlayer/VideoPlayerComponent';
import {
  checkAudioTranscription,
  requestAudioTranscription,
  saveApprovedTranscription,
} from '@api/userAPI';
import { SelectOption, SimpleSelect } from '@shared/ui/input/select/SimpleSelect';
import { azureSupportedLanguages } from '@interfaces/constants';
import { useWebSocket } from '../../../websocket';

type KeyMomentsProps = {
  // setVideoDuration: (newDuration: number) => void;
  onTimeUpdate: (currentTimeMillis: number) => void;
  sessionId?: string;
  keyMomentsData: KeyMomentsData;
  videoRef: React.RefObject<HTMLVideoElement>;
  currentVideoTime: number;
  updatedKeyMoments: KeyMomentsData;
  setUpdatedKeymoments: React.Dispatch<React.SetStateAction<KeyMomentsData | undefined>>;
  keyMomentsWrapperClasses?: string;
  recordingState?: Record<string, ShamefulAny>;
  therapistRecordingState?: Record<string, ShamefulAny>;
  transcriptsState?: Record<string, ShamefulAny>;
  generalState?: Record<string, ShamefulAny>;
  framesState?: Record<string, ShamefulAny>;
  therapistFramesState?: Record<string, ShamefulAny>;
  framesData: {
    timestamp: number;
    link: string;
  }[];
  therapistFramesData?: {
    timestamp: number;
    link: string;
  }[];
  recordingTimeFrame: {
    recordingStartTime: number | undefined;
    recordingStopTime: number | undefined;
  };
  therapistVideoRef?: React.RefObject<HTMLVideoElement>;
  videoDifference?: {
    recordingStartsFirst: 'therapistRecording' | 'patientRecording';
    differenceBetweenStarts: number;
  } | null;
  setIsLoadingState?: React.Dispatch<React.SetStateAction<Record<string, any>>>;
};

const KeyMoments = ({
  onTimeUpdate,
  videoRef,
  keyMomentsData,
  recordingState,
  therapistRecordingState,
  framesState,
  therapistFramesState,
  generalState,
  transcriptsState,
  currentVideoTime,
  setUpdatedKeymoments,
  updatedKeyMoments,
  keyMomentsWrapperClasses = '',
  framesData,
  therapistFramesData,
  recordingTimeFrame,
  therapistVideoRef,
  videoDifference,
  sessionId,
  setIsLoadingState,
}: KeyMomentsProps) => {
  const [currentTimestamp, setCurrentTimestamp] = useState(0);
  const [mergedData, setMergedData] = useState<MergedData>([]);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [recordingStartTime, setRecordingStartTime] = useState<null | number>(null);
  const [isInitialLoaderEnabled, setIsInitialLoaderEnabled] = useState<boolean>(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showHebrewTranslation, setShowHebrewTranslation] = useState(false);
  const [transcriptionRequestStatus, setTranscriptionRequestStatus] =
    useState('No pending requests');
  const [audioTranscription, setAudioTranscription] = useState<any[]>([]);
  const [showFrames, setShowFrames] = useState(true);
  const intervalId = useRef<NodeJS.Timeout | null>(null);
  const prevTime = useRef<number>(currentVideoTime);

  const { socket } = useWebSocket();

  socket?.on('transcriptionReady', ({ transcription, locale }) => {
    setTranscriptionRequestStatus(`${locale} transcription is ready`);
    setAudioTranscription(transcription);
  });

  socket?.on('transcriptionStatus', (status: any) => {
    setTranscriptionRequestStatus(status);
  });

  const { data, isLoading, isFailed, error } = recordingState || {
    isLoading: false,
    isFailed: null,
    error: null,
    data: {},
  };

  const videoSrc = data?.recording;

  const {
    data: therapistRecordingData,
    isLoading: therapistRecordingIsLoading,
    isFailed: therapistRecordingIsFailed,
    error: therapistRecordingError,
  } = therapistRecordingState || {
    isLoading: false,
    isFailed: null,
    error: null,
    data: {},
  };

  const therapistVideoSrc = therapistRecordingData?.therapistRecording;

  const [selectedOption, setSelectedOption] = useState<{ value: string; label: string }>({
    value: 'he-IL',
    label: 'Hebrew (Israel)',
  });

  const handleLanguageChange = (selected: { value: string; label: string }) => {
    setSelectedOption(selected);
  };

  useEffect(() => {
    if (sessionId) {
      socket?.emit('joinMeetingRoom', sessionId);
      const checkTranscription = async (sessionId: string) => {
        try {
          setTranscriptionRequestStatus('Checking...');
          checkAudioTranscription(sessionId);
        } catch (error) {
          setTranscriptionRequestStatus('Something went wrong, try to send a request again');
          console.error(error);
        }
      };
      if (sessionId) {
        checkTranscription(sessionId);
      }
    }
  }, [sessionId]);

  const requestTranscription = async () => {
    try {
      setTranscriptionRequestStatus('Sending request...');
      await requestAudioTranscription(sessionId as string, selectedOption.value);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!recordingTimeFrame) return;

    const recordingStart = recordingTimeFrame.recordingStartTime;

    recordingStart && setRecordingStartTime(new Date(recordingStart).getTime());
  }, [recordingTimeFrame]);

  useEffect(() => {
    const currentVideoRef = videoRef?.current;
    const currentTherapistVideoRef = therapistVideoRef?.current;

    if (videoDifference) {
      const { recordingStartsFirst } = videoDifference;

      const handleTimeUpdate = debounce(() => {
        if (currentVideoRef) {
          onTimeUpdate(currentVideoRef.currentTime * 1000);
        }
      }, 100);

      const handleTimeUpdateTherapist = debounce(() => {
        if (currentTherapistVideoRef) {
          onTimeUpdate(currentTherapistVideoRef.currentTime * 1000);
        }
      }, 100);

      if (recordingStartsFirst === 'therapistRecording') {
        currentVideoRef?.addEventListener('timeupdate', handleTimeUpdate);
      } else {
        currentTherapistVideoRef?.addEventListener('timeupdate', handleTimeUpdateTherapist);
      }

      return () => {
        currentVideoRef?.removeEventListener('timeupdate', handleTimeUpdate);
        currentTherapistVideoRef?.removeEventListener('timeupdate', handleTimeUpdateTherapist);
      };
    }
  }, [videoSrc, onTimeUpdate, videoDifference, showFrames]);

  useEffect(() => {
    if (videoRef && videoRef.current && isFinite(currentTimestamp)) {
      const targetTimeInSeconds = currentTimestamp;

      if (videoDifference) {
        const { recordingStartsFirst, differenceBetweenStarts } = videoDifference;
        const adjustedDifference = isNaN(differenceBetweenStarts)
          ? 0
          : differenceBetweenStarts / 1000;

        // Only update currentTime if the difference is significant
        if (Math.abs(videoRef.current.currentTime - targetTimeInSeconds) > 0.3) {
          if (recordingStartsFirst === 'therapistRecording') {
            if (therapistVideoRef && therapistVideoRef.current) {
              therapistVideoRef.current.currentTime = targetTimeInSeconds + adjustedDifference;
            }
            if (videoRef.current) {
              videoRef.current.currentTime = targetTimeInSeconds;
            }
          } else if (recordingStartsFirst === 'patientRecording') {
            if (videoRef.current) {
              videoRef.current.currentTime = targetTimeInSeconds + adjustedDifference;
            }
            if (therapistVideoRef && therapistVideoRef.current) {
              therapistVideoRef.current.currentTime = targetTimeInSeconds;
            }
          }
        }
      } else {
        // Handle case where videoDifference is null
        if (Math.abs(videoRef.current.currentTime - targetTimeInSeconds) > 0.3) {
          videoRef.current.currentTime = targetTimeInSeconds;
          if (therapistVideoRef && therapistVideoRef.current) {
            therapistVideoRef.current.currentTime = targetTimeInSeconds;
          }
        }
      }
    } else if (showFrames) {
      // Trigger the onTimeUpdate callback if frames are shown

      onTimeUpdate(currentTimestamp * 1000);
    }
  }, [currentTimestamp, videoRef, showFrames, therapistVideoRef, videoDifference]);

  useEffect(() => {
    if (!transcriptsState?.data) return;

    // Use updatedKeyMoments if available; otherwise, fallback to keyMomentsData
    const keyMoments = updatedKeyMoments?.keyMoments || keyMomentsData?.keyMoments || [];

    const keyMomentTranscriptIds = new Set(
      keyMoments.flatMap((km) => km.transcripts.map((t) => t.id))
    );

    const filteredSessionTranscripts: MTranscript[] = transcriptsState.data.transcript
      .filter((t: Transcript) => !keyMomentTranscriptIds.has(t.id))
      .map((t: Transcript) => ({
        type: 'transcript',
        id: t.id,
        timestamp: t.timestamp,
        message: t.message,
        source: t.source,
      }));

    const keyMomentsDataMapped: MKeyMoment[] = keyMoments.map((km) => ({
      type: 'keyMoment',
      timestamp: new Date(km.timestamp).getTime(),
      heartBeat: km.heartBeat,
      timeInMillis: km.timeInMillis,
      approvalStatus: km.approvalStatus,
      transcripts: km.transcripts.map((t) => ({
        type: 'transcript',
        id: t.id,
        timestamp: t.timestamp,
        message: t.message,
        source: t.source,
      })),
    }));

    const mergedData: MergedData = [...filteredSessionTranscripts, ...keyMomentsDataMapped].sort(
      (a, b) => a.timestamp - b.timestamp
    );

    setMergedData(mergedData);
  }, [transcriptsState?.data, updatedKeyMoments]);

  useEffect(() => {
    if (mergedData.length === 0 || recordingStartTime === null) return;
    const currentTimeMillis = currentVideoTime;
    const closestIndex = mergedData.findIndex((item) => {
      return Math.abs(item.timestamp - recordingStartTime - currentTimeMillis) <= 1000;
    });

    const scrollToFrame = (index: number) => {
      if (index !== -1 && itemRefs.current[index]) {
        const ref = itemRefs.current[index];
        if (ref) {
          ref.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        } else {
          console.error('Ref is undefined for index:', index);
        }
      }
    };

    if (showFrames) {
      scrollToFrame(closestIndex);
      return;
    }

    if (videoRef && videoRef.current) {
      scrollToFrame(closestIndex);
    }
  }, [showFrames, mergedData, currentVideoTime, recordingStartTime, videoRef, therapistVideoRef]);

  useEffect(() => {
    const handleSpacebarPress = (event: KeyboardEvent) => {
      if (
        event.code === 'Space' &&
        videoRef.current &&
        (event.target as HTMLElement)?.tagName !== 'INPUT' &&
        (event.target as HTMLElement)?.tagName !== 'TEXTAREA' &&
        !(event.target as HTMLElement).closest('.ql-editor') // Check if the target is inside the Quill editor(our text editor)
      ) {
        event.preventDefault();

        if (videoRef.current.paused) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      }
    };

    document.addEventListener('keydown', handleSpacebarPress);

    return () => {
      document.removeEventListener('keydown', handleSpacebarPress);
    };
  }, [videoRef, showFrames]);
  const checkVideoPlayable = (
    videoRef: React.RefObject<HTMLVideoElement> | undefined,
    videoSrc: string
  ) => {
    return new Promise((resolve) => {
      if (!videoRef || !videoSrc) {
        resolve(false);
        return;
      }

      const testVideo = document.createElement('video');
      testVideo.src = videoSrc;

      testVideo.oncanplay = () => resolve(true);
      testVideo.onerror = () => resolve(false);
    });
  };
  const [showVideo, setShowVideo] = useState(false);
  // if both videos are valid switch to video if not stay with frames
  useEffect(() => {
    if (!isLoading && !therapistRecordingIsLoading && videoSrc && therapistVideoSrc) {
      // Function to check the validity of both videos
      const validateAndSwitch = async () => {
        const therapistVideoPlayable = await checkVideoPlayable(
          therapistVideoRef,
          therapistVideoSrc
        );
        const videoPlayable = await checkVideoPlayable(videoRef, videoSrc);

        if (therapistVideoPlayable && videoPlayable) {
          setShowVideo(true);

          if (intervalId.current !== null) {
            clearInterval(intervalId.current);
            intervalId.current = null;
          }

          setIsPlaying(false);
          setShowFrames(false);
        } else {
          console.error('One or both video sources are invalid or unplayable');
          setShowFrames(true);
        }
      };

      validateAndSwitch();
    }
  }, [isLoading, therapistRecordingIsLoading, videoSrc, therapistVideoSrc]);

  const startOrPauseTimeUpdate = () => {
    if (showFrames) {
      if (isPlaying) {
        if (intervalId.current !== null) {
          clearInterval(intervalId.current);
          intervalId.current = null; // Clear and reset the interval reference
        }
        setIsPlaying(false);
      } else {
        intervalId.current = setInterval(() => {
          prevTime.current += 500; // We post two frames per second, hence 500ms

          if (
            typeof recordingTimeFrame.recordingStopTime !== 'undefined' &&
            typeof recordingTimeFrame.recordingStartTime !== 'undefined' &&
            prevTime.current <=
              recordingTimeFrame.recordingStopTime - recordingTimeFrame.recordingStartTime
          ) {
            onTimeUpdate(prevTime.current);
          } else {
            if (intervalId.current !== null) {
              clearInterval(intervalId.current);
              intervalId.current = null; // Ensure reference is reset
            }
            setIsPlaying(false);
          }
        }, 500);
        setIsPlaying(true);
      }
    } else {
      const video = videoRef?.current;
      const therapistVideo = therapistVideoRef?.current;

      if (video && therapistVideo) {
        // Handle synchronization when both videos are present
        if (video.paused || therapistVideo.paused) {
          video.play().catch(console.error);
          therapistVideo.play().catch(console.error);
          setIsPlaying(true);
        } else {
          video.pause();
          therapistVideo.pause();
          setIsPlaying(false);
        }
      } else if (video) {
        // Handle only primary video
        if (video.paused) {
          video.play().catch(console.error);
          setIsPlaying(true);
        } else {
          video.pause();
          setIsPlaying(false);
        }
      } else if (therapistVideo) {
        // Handle only therapist video
        if (therapistVideo.paused) {
          therapistVideo.play().catch(console.error);
          setIsPlaying(true);
        } else {
          therapistVideo.pause();
          setIsPlaying(false);
        }
      }
    }
  };

  // Cleanup interval on component unmount
  useEffect(() => {
    return () => {
      if (intervalId.current) clearInterval(intervalId.current);
      socket?.emit('leaveMeetingRoom', sessionId);
    };
  }, []);

  useEffect(() => {
    if (!showFrames && videoRef?.current && isFinite(currentVideoTime)) {
      const currentTimeInSeconds = currentVideoTime / 1000;

      if (videoDifference) {
        const { recordingStartsFirst, differenceBetweenStarts } = videoDifference;
        const adjustedDifference = isNaN(differenceBetweenStarts)
          ? 0
          : differenceBetweenStarts / 1000;

        const adjustVideoTime = debounce(() => {
          if (
            videoRef?.current &&
            Math.abs(videoRef.current.currentTime - currentTimeInSeconds) > 0.1
          ) {
            if (recordingStartsFirst === 'therapistRecording' && therapistVideoRef?.current) {
              if (videoRef.current.paused) {
                videoRef.current.currentTime = currentTimeInSeconds;
              }

              if (therapistVideoRef?.current && therapistVideoRef.current.paused) {
                therapistVideoRef.current.currentTime = currentTimeInSeconds + adjustedDifference;
              }
            } else if (recordingStartsFirst === 'patientRecording') {
              // Only adjust if the video is NOT playing
              if (videoRef.current.paused) {
                videoRef.current.currentTime = currentTimeInSeconds + adjustedDifference;
              }
              if (therapistVideoRef?.current && therapistVideoRef.current.paused) {
                therapistVideoRef.current.currentTime = currentTimeInSeconds;
              }
            }
          }
        }, 100);

        adjustVideoTime();
      } else {
        if (
          videoRef?.current &&
          Math.abs(videoRef.current.currentTime - currentTimeInSeconds) > 0.1
        ) {
          if (videoRef.current.paused) {
            videoRef.current.currentTime = currentTimeInSeconds;
          }
          if (therapistVideoRef?.current && therapistVideoRef.current.paused) {
            therapistVideoRef.current.currentTime = currentTimeInSeconds;
          }
        }
      }
    }
  }, [showFrames, currentVideoTime, videoDifference]);

  const handleTimestampClick = (timeInMillis: number) => {
    const seconds = timeInMillis / 1000;
    if (isFinite(seconds)) {
      setCurrentTimestamp(seconds);
    } else {
      console.error('Invalid seconds value:', seconds);
    }
  };

  const mergeWithKeyMoment = (
    transcript: Transcript,
    keyMoment: KeyMoment,
    isUnmerge: boolean = false
  ) => {
    if (!updatedKeyMoments || !keyMoment) return;

    const mappedKeyMoments = updatedKeyMoments.keyMoments.map((km) => {
      if (km.timestamp === keyMoment.timestamp) {
        const newTranscripts = isUnmerge
          ? km.transcripts.filter((t) => t.id !== transcript.id)
          : [...km.transcripts, transcript];

        return {
          ...km,
          transcripts: newTranscripts,
        };
      }
      return km;
    });

    const newUpdatedKeyMoments = {
      ...updatedKeyMoments,
      keyMoments: mappedKeyMoments.map((keyMoment) => {
        if (keyMoment.transcripts) {
          keyMoment.transcripts.sort((a, b) => a.timestamp - b.timestamp);
        }

        return {
          ...keyMoment,
          keyMomentRange:
            !keyMoment.transcripts || keyMoment.transcripts.length === 0
              ? { startTime: null, endTime: null }
              : {
                  startTime: keyMoment.transcripts[0]?.timestamp ?? null,
                  endTime: keyMoment.transcripts.at(-1)?.timestamp ?? null,
                },
        };
      }),
    };

    setUpdatedKeymoments(newUpdatedKeyMoments);

    const updatedMergedData = mergedData
      .filter((item) => !(item.type === 'transcript' && item.id === transcript.id))
      .map((item) => {
        if (
          isUnmerge &&
          item.type === 'keyMoment' &&
          item.timestamp === new Date(keyMoment.timestamp).getTime()
        ) {
          return {
            ...item,
            transcripts: item.transcripts.filter((t) => t.id !== transcript.id),
          };
        }

        if (
          !isUnmerge &&
          item.type === 'keyMoment' &&
          item.timestamp === new Date(keyMoment.timestamp).getTime()
        ) {
          const updatedTranscripts = [
            ...item.transcripts,
            {
              id: transcript.id,
              timestamp: transcript.timestamp,
              message: transcript.message,
              source: transcript.source,
            },
          ].sort((a, b) => a.timestamp - b.timestamp);

          return {
            ...item,
            transcripts: updatedTranscripts,
          };
        }

        return item;
      });

    if (isUnmerge) {
      updatedMergedData.push({
        type: 'transcript',
        ...transcript,
      });
    }

    setMergedData(updatedMergedData.sort((a, b) => a.timestamp - b.timestamp));
  };

  const createNewKeyMoment = (transcript: Transcript) => {
    if (!updatedKeyMoments) {
      return;
    }

    const newKeyMoment: KeyMoment = {
      approvalStatus: 'none',
      heartBeat: 0,
      timeInMillis: recordingStartTime ? transcript.timestamp - recordingStartTime : 0,
      timestamp: transcript.timestamp,
      transcripts: [transcript],
    };

    const newUpdatedKeyMoments: KeyMomentsData = {
      ...updatedKeyMoments,
      keyMoments: [...updatedKeyMoments.keyMoments, newKeyMoment],
    };
    newUpdatedKeyMoments.keyMoments.forEach((keyMoment) => {
      if (keyMoment.transcripts) {
        keyMoment.transcripts.sort((a, b) => a.timestamp - b.timestamp);
      }
    });
    const newMergedData: MergedData = [
      ...mergedData.filter((el) => !(el.type === 'transcript' && el.id === transcript.id)),
      {
        type: 'keyMoment',
        ...newKeyMoment,
      } as MKeyMoment,
    ];

    setUpdatedKeymoments(newUpdatedKeyMoments);
    setMergedData(newMergedData.sort((a, b) => a.timestamp - b.timestamp));
  };

  const removeKeyMoment = (keyMoment: KeyMoment) => {
    if (!keyMoment) return;

    setUpdatedKeymoments((prevKeyMomentsData) => {
      if (!prevKeyMomentsData) return prevKeyMomentsData;

      const updatedKeyMomentsList = prevKeyMomentsData.keyMoments.filter((km) => {
        return new Date(km.timestamp).getTime() !== new Date(keyMoment.timestamp).getTime();
      });

      return { ...prevKeyMomentsData, keyMoments: updatedKeyMomentsList };
    });

    setMergedData((prevMergedData) => {
      if (!prevMergedData) return prevMergedData;

      const updatedMergedData = prevMergedData.filter(
        (item) =>
          !(
            item.type === 'keyMoment' &&
            new Date(item.timestamp).getTime() === new Date(keyMoment.timestamp).getTime()
          )
      );

      const transcriptsAsOrdinaryItems = keyMoment.transcripts.map((transcript) => ({
        type: 'transcript' as const,
        ...transcript,
      }));

      const newMergedData: MergedData = [...updatedMergedData, ...transcriptsAsOrdinaryItems].sort(
        (a, b) => a.timestamp - b.timestamp
      );

      return newMergedData;
    });
  };

  const updateKeyMomentStatus = (
    keyMoment: KeyMoment,
    newStatus: 'none' | 'approved' | 'disapproved',
    setUpdatedKeyMoments: React.Dispatch<React.SetStateAction<KeyMomentsData | undefined>>,
    setMergedData: React.Dispatch<React.SetStateAction<MergedData>>
  ) => {
    if (!keyMoment) return;
    setUpdatedKeyMoments((prevKeyMomentsData) => {
      if (!prevKeyMomentsData) return prevKeyMomentsData;

      const updatedKeyMomentsList = prevKeyMomentsData.keyMoments.map((km) => {
        const kmTimestampNumeric = new Date(km.timestamp).getTime();

        if (kmTimestampNumeric === keyMoment.timestamp) {
          return { ...km, approvalStatus: newStatus };
        }
        return km;
      });

      return { ...prevKeyMomentsData, keyMoments: updatedKeyMomentsList };
    });

    setMergedData((prevMergedData) => {
      const updatedMergedData = prevMergedData.map((item) => {
        const itemTimestampNumeric = new Date(item.timestamp).getTime();

        return item.type === 'keyMoment' && itemTimestampNumeric === keyMoment.timestamp
          ? { ...item, approvalStatus: newStatus }
          : item;
      });

      return updatedMergedData.sort(
        (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      );
    });
  };
  const updateTranscriptMessage = (transcriptId: string, newMessage: string) => {
    if (!setUpdatedKeymoments) return;
    if (!updatedKeyMoments) return;

    // Update KeyMoments
    const updatedKeyMomentsList = updatedKeyMoments.keyMoments.map((km) => {
      const updatedTranscripts = km.transcripts.map((t) =>
        t.id === transcriptId ? { ...t, message: newMessage } : t
      );

      return {
        ...km,
        transcripts: updatedTranscripts,
      };
    });

    setUpdatedKeymoments({ ...updatedKeyMoments, keyMoments: updatedKeyMomentsList });

    // Update MergedData
    const updatedMergedData = mergedData.map((item) => {
      if (item.type === 'transcript' && item.id === transcriptId) {
        return { ...item, message: newMessage };
      }

      if (item.type === 'keyMoment') {
        const updatedKeyMomentTranscripts = item.transcripts.map((t) =>
          t.id === transcriptId ? { ...t, message: newMessage } : t
        );

        return { ...item, transcripts: updatedKeyMomentTranscripts };
      }

      return item;
    });

    setMergedData(updatedMergedData);
  };

  const handleKeyMomentApproved = (keyMoment: KeyMoment) => {
    updateKeyMomentStatus(keyMoment, 'approved', setUpdatedKeymoments, setMergedData);
  };

  const handleKeyMomentReject = (keyMoment: KeyMoment) => {
    removeKeyMoment(keyMoment);
  };

  if (
    (transcriptsState?.data && isInitialLoaderEnabled) ||
    (framesState?.data && isInitialLoaderEnabled)
  ) {
    setIsInitialLoaderEnabled(false);
  }

  if (isInitialLoaderEnabled) {
    return (
      <Loader
        flexDirection="flex-col"
        label="Preparing session data..."
        className="w-full h-full self-center items-center justify-center"
      />
    );
  }

  const processSessionData = (sessionData: any) => {
    return sessionData.map((item: any, index: any) => {
      if (item.type === 'transcript') {
        const isAdjacentToKeyMoment =
          (sessionData[index - 1] && sessionData[index - 1].type === 'keyMoment') ||
          (sessionData[index + 1] && sessionData[index + 1].type === 'keyMoment');

        const isAfterKeyMoment =
          sessionData[index - 1] && sessionData[index - 1].type === 'keyMoment';

        const isBeforeKeyMoment =
          sessionData[index + 1] && sessionData[index + 1].type === 'keyMoment';

        return (
          <div>
            <div
              key={`general-transcript-${index}`}
              ref={(el) => (itemRefs.current[index] = el)}
            >
              <TranscriptItem
                index={index}
                item={item}
                recordingStartTime={recordingStartTime}
                handleTimestampClick={handleTimestampClick}
                itemRefs={itemRefs}
                keyMoments={updatedKeyMoments.keyMoments}
                mergeWithKeyMoment={mergeWithKeyMoment}
                isAdjacentToKeyMoment={isAdjacentToKeyMoment}
                isInKeyMoment={false}
                isBeforeKeyMoment={isBeforeKeyMoment}
                isAfterKeyMoment={isAfterKeyMoment}
                createNewKeyMoment={createNewKeyMoment}
                showHebrewTranslation={showHebrewTranslation}
              />
            </div>
          </div>
        );
      } else if (item.type === 'keyMoment') {
        return (
          <div>
            <div
              key={`key-${item.timestamp}-item-${index}`}
              ref={(el) => (itemRefs.current[index] = el)}
              className="bg-white rounded-xl shadow flex p-4 gap-5 justify-between"
            >
              <div className="flex flex-col">
                <div className="flex gap-2 items-center">
                  <div className="flex gap-[7px]">
                    <FullHeartIcon />
                    <div className="text-gray-800 text-[13px] font-semibold font-['Inter'] leading-tight">
                      {item.heartBeat}
                    </div>
                    <div
                      className="cursor-pointer text-gray-500 text-[13px] font-normal font-['Inter'] leading-tight"
                      onClick={() => handleTimestampClick(item.timeInMillis)}
                    >
                      {formatTime(item.timestamp)}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-start">
                  {item.transcripts?.map((el: any, i: number) => (
                    <div key={`${el.id}-${i}`}>
                      <TranscriptItem
                        index={i}
                        item={el}
                        recordingStartTime={recordingStartTime}
                        handleTimestampClick={handleTimestampClick}
                        itemRefs={itemRefs}
                        keyMoments={updatedKeyMoments.keyMoments}
                        mergeWithKeyMoment={mergeWithKeyMoment}
                        isAdjacentToKeyMoment={false}
                        isInKeyMoment={true}
                        isBeforeKeyMoment={false}
                        isAfterKeyMoment={false}
                        createNewKeyMoment={createNewKeyMoment}
                        removeKeyMoment={removeKeyMoment}
                        showHebrewTranslation={showHebrewTranslation}
                        updateTranscriptMessage={updateTranscriptMessage}
                      />
                    </div>
                  ))}
                </div>
              </div>

              {item.approvalStatus == 'none' && (
                <div className="flex flex-col gap-4">
                  <Button
                    variant="iconPurple"
                    size="sm"
                    className="text-white"
                    icon={<CheckIcon />}
                    onClick={() => handleKeyMomentApproved(item)}
                  />
                  <Button
                    variant="iconWhite"
                    size="sm"
                    icon={<CloseIcon />}
                    onClick={() => handleKeyMomentReject(item)}
                  />
                </div>
              )}
            </div>
          </div>
        );
      }
      return null;
    });
  };

  const saveTranscript = async () => {
    setShowHebrewTranslation((prev) => !prev);

    const response = await saveApprovedTranscription(audioTranscription, sessionId!);

    if (response?.updatedKeyMoments) {
      const newUpdatedKeyMoments = response.updatedKeyMoments;

      setUpdatedKeymoments(newUpdatedKeyMoments);

      // Collect transcript IDs that are already in key moments
      const keyMomentTranscriptIds = new Set(
        newUpdatedKeyMoments.keyMoments.flatMap((km: KeyMoment) => km.transcripts.map((t) => t.id))
      );

      // Filter out transcripts that are already included in key moments
      const filteredTranscripts = audioTranscription.filter(
        (t) => !keyMomentTranscriptIds.has(t.id)
      );

      // Merge new key moments and remaining transcripts into mergedData
      const newMergedData = [
        ...mergedData.filter((el) => el.type !== 'keyMoment' && el.type !== 'transcript'),
        ...newUpdatedKeyMoments.keyMoments.map((km: KeyMoment) => ({ type: 'keyMoment', ...km })),
        ...filteredTranscripts.map((t) => ({ type: 'transcript', ...t })),
      ].sort((a, b) => a.timestamp - b.timestamp);

      setMergedData(newMergedData);
    }

    setIsLoadingState?.((prev) => ({
      ...prev,
      transcripts: {
        isLoading: false,
        isFailed: false,
        error: null,
        data: { transcript: audioTranscription },
      },
    }));

    setTranscriptionRequestStatus(response.message);
    setTimeout(() => {
      setTranscriptionRequestStatus('No pending transcription requests');
    }, 3000);
  };

  return (
    <div className="flex flex-col gap-7 w-full h-full">
      <div className="flex justify-between max-w-[1800px]">
        <p className="text-gray-800 text-sm font-normal font-['Inter'] leading-tight tracking-tight max-w-[830px] w-full text-left">
          Watch the video session and explore the transcription to follow along or catch up on
          details. Key moments are highlighted for quick navigation and easy access to important
          parts.
        </p>
      </div>
      <div className="flex justify-between gap-4 w-full h-full overflow-auto max-w-[1800px]">
        {/* TODO: optimize conditional rendering */}
        <div className="w-[50%] flex gap-4">
          {/* first video */}
          {videoDifference?.recordingStartsFirst === 'therapistRecording' ? (
            <VideoPlayerComponent
              showFrames={showFrames}
              showVideo={showVideo}
              framesState={therapistFramesState}
              framesData={therapistFramesData || []}
              recordingTimeFrame={recordingTimeFrame}
              currentVideoTime={currentVideoTime}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              startOrPauseTimeUpdate={startOrPauseTimeUpdate}
              intervalId={intervalId}
              isLoading={therapistRecordingIsLoading}
              videoSrc={therapistVideoSrc}
              isFailed={therapistRecordingIsFailed}
              error={therapistRecordingError}
              videoRef={
                therapistVideoRef ?? ({ current: null } as React.RefObject<HTMLVideoElement>)
              }
              videoDifference={videoDifference}
            />
          ) : (
            <VideoPlayerComponent
              showFrames={showFrames}
              showVideo={showVideo}
              framesState={framesState}
              framesData={framesData}
              recordingTimeFrame={recordingTimeFrame}
              currentVideoTime={currentVideoTime}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              startOrPauseTimeUpdate={startOrPauseTimeUpdate}
              intervalId={intervalId}
              isLoading={isLoading}
              videoSrc={videoSrc}
              isFailed={isFailed}
              error={error}
              videoRef={videoRef}
              videoDifference={videoDifference}
            />
          )}
          {/* second video */}
          {videoDifference?.recordingStartsFirst === 'therapistRecording' ? (
            <VideoPlayerComponent
              showFrames={showFrames}
              showVideo={showVideo}
              framesState={framesState}
              framesData={framesData}
              recordingTimeFrame={recordingTimeFrame}
              currentVideoTime={currentVideoTime}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              startOrPauseTimeUpdate={startOrPauseTimeUpdate}
              intervalId={intervalId}
              isLoading={isLoading}
              videoSrc={videoSrc}
              isFailed={isFailed}
              error={error}
              videoRef={videoRef}
              videoDifference={null}
            />
          ) : (
            <VideoPlayerComponent
              showFrames={showFrames}
              showVideo={showVideo}
              framesState={therapistFramesState}
              framesData={therapistFramesData || []}
              recordingTimeFrame={recordingTimeFrame}
              currentVideoTime={currentVideoTime}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              startOrPauseTimeUpdate={startOrPauseTimeUpdate}
              intervalId={intervalId}
              isLoading={therapistRecordingIsLoading}
              videoSrc={therapistVideoSrc}
              isFailed={therapistRecordingIsFailed}
              error={therapistRecordingError}
              videoRef={
                therapistVideoRef ?? ({ current: null } as React.RefObject<HTMLVideoElement>)
              }
              videoDifference={null}
            />
          )}
        </div>

        <div className="flex flex-col w-[50%] gap-4">
          {mergedData && updatedKeyMoments && mergedData.length > 0 && (
            <div className="flex justify-between gap-4">
              <div className="flex gap-4 w-[55%]">
                <SimpleSelect
                  withSearch={false}
                  selectClass=""
                  selectDropdownClass="w-full overflow-y-auto max-h-[20rem] !top-16 !left-0"
                  selectedOption={selectedOption}
                  placeholder="Select language"
                  onChange={handleLanguageChange}
                >
                  {azureSupportedLanguages.map((startState, index) => (
                    <SelectOption
                      key={index}
                      value={startState.code}
                    >
                      {startState.name}
                    </SelectOption>
                  ))}
                </SimpleSelect>
                <Button
                  text="Transcribe"
                  onClick={requestTranscription}
                  size="sm"
                />
                <p className="mt-auto mb-auto text-xs">{transcriptionRequestStatus}</p>
              </div>

              <div className="flex w-[35%]">
                {transcriptionRequestStatus.includes('ready') && (
                  <div className="flex justify-end gap-4">
                    <Button
                      variant="plainSecondary"
                      className="h-8"
                      text={showHebrewTranslation ? 'Show original text' : 'Show the result'}
                      onClick={() => setShowHebrewTranslation((prev: any) => !prev)}
                      size="sm"
                    />
                    {/* <div className='w-[50%'> */}
                    {showHebrewTranslation && (
                      <Button
                        className="h-8 text-sm"
                        text={'Approve transcription'}
                        onClick={saveTranscript}
                        size="sm"
                      />
                    )}
                    {/* </div> */}
                  </div>
                )}
              </div>
            </div>
          )}
          <div
            className={`flex flex-col my-auto overflow-y-auto p-4 rounded-md ${keyMomentsWrapperClasses}`}
          >
            {generalState?.isLoading && (
              <Loader
                label="Key moments are loading..."
                flexDirection="flex-col"
                className="w-full h-full self-center items-center justify-center"
              />
            )}
            {!transcriptsState?.data &&
              !transcriptsState?.isLoading &&
              transcriptsState?.isFailed && (
                <div className="w-full h-full rounded-md">
                  <div className="flex flex-col self-center items-center justify-center">
                    No key moments captured.
                  </div>
                </div>
              )}

            {mergedData &&
              updatedKeyMoments &&
              mergedData.length > 0 &&
              processSessionData(showHebrewTranslation ? audioTranscription : mergedData)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyMoments;
