import {
  assignAISession,
  createAISession,
  generateSessionLink,
  getUserData,
  getVideoSessionSummaryContent,
  saveUpdatedKeyMoments,
} from '@api/userAPI';
import Button from '@components/Button';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { useSelector } from '@xstate/react';
import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import InvitePatient from '../InvitePatientStep';
import { AppContext } from '../../../contextApp';
import {
  AI_SESSION_SUBTYPES_BY_TYPE,
  AI_SESSION_SUBTYPES_DESCRIPTION,
  FOLLOW_UP_SUBTYPES,
  NormalisedAISessionTypes,
  SPEAKER_STYLES,
} from '@interfaces/constants';
import {
  FOLLOW_UP_SUBTYPES_DESCRIPTION_TYPE,
  SessionPromptsBySessionType,
} from '@interfaces/types';
import { SessionInstruction, SessionType } from './types';

import { SelectOption, SimpleSelect } from '@shared/ui/input/select/SimpleSelect';
import { KeyMoment } from './KeyMoment';
import {
  getLastSessionWithTherapist,
  getSessionWithTherapistDataById,
} from '@api/session/lastSessionWithTherapist';
import { ShamefulAny } from '@interfaces/index';
import { SessionSettings } from '@shared/types/dashboard/sessionSettings';
import SelectedPatientOption from '../InvitePatientStep/ui/SelectedPatientOption';
import axios from 'axios';
import Checkbox from '@shared/ui/input/checkbox/Checkbox';
import { LanguageSelector } from './ui/LanguageSelector';
import CopyButton from '../InvitePatientStep/ui/CopyButton';
import { formatDate } from './utils';
import { createBaseKeyMomentsPromt } from './utils/createPromt';
import { defaultSessionSettings } from '@shared/constants/sessionSettings';
import { Loader } from '@shared/ui/loader/Loader';

export type AssignAISessionProps = {
  setIsBlockedToFinishSessionWrapUp: Dispatch<SetStateAction<boolean>>;
};

const AssignAISession = ({ setIsBlockedToFinishSessionWrapUp }: AssignAISessionProps) => {
  const snapshot: any = useSelector(refaelaTherapistActor, (state) => state);

  const keyMomentsRef = useRef<any[] | null>(null);

  const patientId = snapshot.context.currentPatientId;
  const therapistId = snapshot.context.currentUser.therapistId;
  const sessionId = snapshot.context.currentSessionId;
  const therapistName = snapshot.context.currentUser.name;
  const { patientToMeetId, setPatientToMeetId } = useContext(AppContext);

  const [generatedLink, setGeneratedLink] = useState('');
  const [sessionsCount, setSessionsCount] = useState(1);
  const [notificationDays, setNotificationDays] = useState(1);
  const [isAssignSuccessfull, setIsAssignSuccessfull] = useState(false);
  const [isAssignInProgress, setIsAssignInProgress] = useState(false);
  const [keyMomentsToReflect, setKeyMomentsToReflect] = useState<any[]>([]);

  const [isIncludeSummary, setIsIncludeSummary] = useState(true);
  const [lastSessionWithTherapist, setLastSessionWithTherapist] = useState<ShamefulAny>({});
  const [songArtist, setSongArtist] = useState('');
  const [songTitle, setSongTitle] = useState('');
  const [speakerStyle, setSpeakerStyle] = useState('');
  const [followUpSummary, setFollowUpSummary] = useState('');
  const [songLyrics, setSongLyrics] = useState('');
  const [finalPrompt, setFinalPrompt] = useState('');
  const [songReflectionDiscussion, setSongReflectionDiscussion] = useState('');
  const [songReflectionIntroduction, setSongReflectionIntroduction] = useState('');
  const [openEndedChatThingsToNote, setOpenEndedChatThingsToNote] = useState('');
  const [keyMomentsOpeningQuestion, setKeyMomentsOpeningQuestion] = useState(
    `How was your session with ${therapistName}?`
  );
  const [keyMomentsfollowUpQuestion, setKeyMomentsFollowUpQuestion] = useState(
    'What was most meaningful to you from that session?'
  );
  const [openEndedChatOpeningQuestion, setOpenEndedChatOpeningQuestion] = useState(
    `How was your session with ${therapistName}?`
  );
  const [openEndedChatWhatToFocusOn, setOpenEndedChatWhatToFocusOn] = useState(
    'What was most meaningful to you from that session?'
  );
  const [songReflectionOpeningQuestion, setSongReflectionOpeningQuestion] = useState(
    `How was your session with ${therapistName}?`
  );
  const [userData, setUserData] = useState<ShamefulAny>(null);

  const [selectedSessionPrompt, setSelectedSessionPrompt] = useState('');

  const [sessionType, setSessionType] = useState<SessionType>('follow_up_conversation');

  const [selectedLanguage, setSelectedLanguage] = useState('Hebrew');

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLanguage(event.target.value);
  };

  const [sessionSettings] = useState<SessionSettings>(defaultSessionSettings);

  useEffect(() => {
    if (!lastSessionWithTherapist) return;

    if (lastSessionWithTherapist?.keyMomentsData?.keyMoments) {
      setKeyMomentsToReflect(
        lastSessionWithTherapist.keyMomentsData.keyMoments.map((el: any) => ({
          ...el,
          id: el.timestamp,
          isApproved: el.approvalStatus !== 'none',
          questionToAsk: el.questionToAsk ?? '',
        }))
      );
    }
  }, [lastSessionWithTherapist, lastSessionWithTherapist?.keyMomentsData?.keyMoments]);

  useEffect(() => {
    const fetchSummary = async () => {
      setIsIncludeSummary((prev) => prev!);
      try {
        if (patientId && therapistId && sessionId && isIncludeSummary) {
          const fetchedSummary = await getVideoSessionSummaryContent(
            patientId,
            therapistId,
            sessionId
          );

          // Remove HTML tags
          const plainText =
            new DOMParser().parseFromString(fetchedSummary || 'No summary available', 'text/html')
              .body.textContent || 'No summary available';

          setFollowUpSummary(plainText);
        }
      } catch (error: ShamefulAny) {
        const { message, details } = error;
        console.error('Error fetching summary:', message, details);
      }
    };

    fetchSummary();
  }, [isIncludeSummary]);

  const handleCheckboxChange = (id: any, question: string) => {
    setKeyMomentsToReflect((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, isApproved: !item.isApproved, questionToAsk: question } : item
      )
    );
  };
  const handleQuestionChange = (id: any, question: string) => {
    setKeyMomentsToReflect((prevItems) =>
      prevItems.map((item) => (item.id === id ? { ...item, questionToAsk: question } : item))
    );
  };

  const handleAssignHomeworkSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsAssignInProgress(true);

    try {
      const patientToSendId = snapshot.value === 'AssignAISession' ? patientToMeetId : patientId;
      const shortUrl = await generateSessionLink(patientToSendId, sessionType, sessionsCount);

      // Create and assign each session one by one
      for (let i = 0; i < sessionsCount; i++) {
        const sessionResponse = await createAISession(
          `${patientToSendId}_P`,
          sessionType,
          sessionSettings,
          finalPrompt
        );
        const sessionId = sessionResponse?.sessionId; // Ensure sessionId exists

        if (!sessionId) {
          throw new Error(`Failed to get sessionId for session ${i + 1}`);
        }

        // Assign the created session
        await assignAISession(patientToSendId, sessionType, 1, shortUrl, sessionId);
      }

      setIsAssignSuccessfull(true);
      setIsAssignInProgress(false);
      if (snapshot.value !== 'AssignAISession') setGeneratedLink(shortUrl);
      setIsBlockedToFinishSessionWrapUp(false);

      setTimeout(() => {
        setIsAssignSuccessfull(false);
      }, 3000);
    } catch (error) {
      console.error('Error during AI session assign or creation: ', JSON.stringify(error));
      setIsAssignInProgress(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // patientToMeetId for assign session page, patientId for Session Wrap-up assign step
        const data = await getUserData(patientToMeetId ? patientToMeetId : patientId);

        if (patientId && sessionId && snapshot.value != 'AssignAISession') {
          const res = await getSessionWithTherapistDataById(patientId, therapistId, sessionId);

          setLastSessionWithTherapist(res);
        }
        if (snapshot.value == 'AssignAISession') {
          const { lastSession } = await getLastSessionWithTherapist(patientToMeetId, therapistId);

          setLastSessionWithTherapist(lastSession);
        }
        setUserData(data);
      } catch (e) {
        console.error('Error fetching user data: ', JSON.stringify(e));
      }
    };

    if (patientId || patientToMeetId) {
      fetchData();
    }
  }, [patientId, patientToMeetId]);

  useEffect(() => {
    if (!userData || !lastSessionWithTherapist) return;
    const today = new Date();
    const lastSessionDate = formatDate(lastSessionWithTherapist.timestamp);
    const patientName = userData.firstName;

    const keyMomentsReflectionPrompt = createBaseKeyMomentsPromt(
      selectedLanguage,
      'Israel',
      therapistName,
      patientName,
      'male',
      formatDate(today),
      lastSessionDate,
      followUpSummary,
      keyMomentsOpeningQuestion,
      keyMomentsfollowUpQuestion,
      keyMomentsToReflect
    );

    if (selectedSessionPrompt === FOLLOW_UP_SUBTYPES.KEY_MOMENTS_REFLECTION) {
      setFinalPrompt(keyMomentsReflectionPrompt);
    } else {
      setFinalPrompt(
        `${sessionType && `You are an empathetic and skilled therapist conducting a session with a patient named ${userData.firstName}. Your role is to listen actively, validate the patient’s feelings, and provide thoughtful guidance without judgment. The session will be based on ${NormalisedAISessionTypes[sessionType]}.`}
        SESSION LANGUAGE IS ${selectedLanguage}. SPEAK ${selectedLanguage}
  ${selectedSessionPrompt && `Next session main goal will be a ${selectedSessionPrompt}.`}
  ${selectedSessionPrompt == FOLLOW_UP_SUBTYPES.SONG_REFLECTION && songReflectionOpeningQuestion ? `Opening question for the session: ${songReflectionOpeningQuestion}` : ''}
  ${selectedSessionPrompt == FOLLOW_UP_SUBTYPES.SONG_REFLECTION && songReflectionIntroduction ? `Song reflection introduction is: ${songReflectionIntroduction}` : ''}
  ${selectedSessionPrompt == FOLLOW_UP_SUBTYPES.SONG_REFLECTION && songArtist ? `Song artist: ${songArtist}` : ''}
  ${selectedSessionPrompt == FOLLOW_UP_SUBTYPES.SONG_REFLECTION && songTitle ? `Song title: ${songTitle}` : ''}
  ${selectedSessionPrompt == FOLLOW_UP_SUBTYPES.SONG_REFLECTION && songLyrics ? `Song lyrics: \n${songLyrics}` : ''}
  ${selectedSessionPrompt == FOLLOW_UP_SUBTYPES.SONG_REFLECTION && songReflectionDiscussion ? `Song reflection discussion: ${songReflectionDiscussion}` : ''}
  ${selectedSessionPrompt == FOLLOW_UP_SUBTYPES.OPEN_ENDED_CHAT && speakerStyle ? `Speaker style: ${speakerStyle}` : ''}
  ${selectedSessionPrompt == FOLLOW_UP_SUBTYPES.OPEN_ENDED_CHAT && openEndedChatOpeningQuestion ? `Opening question: ${openEndedChatOpeningQuestion}` : ''}
  ${selectedSessionPrompt == FOLLOW_UP_SUBTYPES.OPEN_ENDED_CHAT && openEndedChatWhatToFocusOn ? `You need to focus on: ${openEndedChatWhatToFocusOn}` : ''}
  ${selectedSessionPrompt == FOLLOW_UP_SUBTYPES.OPEN_ENDED_CHAT && openEndedChatThingsToNote ? `Things to note are: ${openEndedChatThingsToNote}` : ''}`.trim()
      );
    }
  }, [
    sessionType,
    selectedSessionPrompt,
    userData,
    keyMomentsOpeningQuestion,
    keyMomentsfollowUpQuestion,
    songReflectionIntroduction,
    songReflectionDiscussion,
    songArtist,
    songTitle,
    songLyrics,
    openEndedChatOpeningQuestion,
    openEndedChatWhatToFocusOn,
    openEndedChatThingsToNote,
    speakerStyle,
    followUpSummary,
    isIncludeSummary,
    keyMomentsToReflect,
    selectedLanguage,
  ]);

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    adjustHeight();
  }, [finalPrompt]);

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };
  useEffect(() => {
    if (sessionType === 'follow_up_conversation') {
      setSelectedSessionPrompt('Key Moments Reflection');
    } else {
      setSelectedSessionPrompt('');
    }
  }, [sessionType]);

  // to clean patientToMeetId on unmount
  useEffect(() => {
    if (patientToMeetId) {
      return () => {
        setPatientToMeetId('');
      };
    }
  }, [patientToMeetId, setPatientToMeetId]);

  const isReadyForSave = useRef(false);

  useEffect(() => {
    keyMomentsRef.current = keyMomentsToReflect;

    if (keyMomentsToReflect && keyMomentsToReflect.length > 0) {
      isReadyForSave.current = true;
    }
  }, [keyMomentsToReflect]);

  useEffect(() => {
    if (!lastSessionWithTherapist) return;

    // Trigger saving when component unmounts and when data is ready
    return () => {
      if (
        !keyMomentsRef.current ||
        !Array.isArray(keyMomentsRef.current) ||
        !isReadyForSave.current
      )
        return;

      saveUpdatedKeyMoments(
        {
          ...lastSessionWithTherapist.keyMomentsData,
          keyMoments: keyMomentsRef.current,
        },
        patientId,
        therapistId,
        sessionId
      );
    };
  }, [lastSessionWithTherapist]);

  return (
    <div className="w-full h-full">
      <p className="text-left mb-8 ">
        Here are the types of AI session you can assign to your patient so that they would progress
        in therapy between sessions.
      </p>
      {snapshot.value == 'AssignAISession' && (
        <InvitePatient
          setSessionId={() => {}}
          setRoomUrl={() => {}}
          onBlockStep={() => {}}
          sessionsCount={sessionsCount}
        />
      )}
      {!userData || !(patientToMeetId || patientId) ? (
        <div className=" transition-all animate-fadeIn  flex justify-center w-full h-full mt-60">
          <Loader />
        </div>
      ) : (
        <div className="animate-fadeIn transition-all">
          <form onSubmit={handleAssignHomeworkSubmit}>
            <div className="flex gap-4 text-left justify-between">
              <div className="w-[70%]">
                <div className="flex flex-col gap-4 mb-4">
                  <div className="flex gap-4">
                    <p className="font-medium leading-[44px]">Select session type</p>
                    {sessionType && (
                      <Button
                        type="button"
                        variant="primary"
                        text="Clear all settings"
                        onClick={() => {
                          setSessionType('');
                          setSelectedSessionPrompt('');
                          setFollowUpSummary('');
                          setSpeakerStyle('');

                          snapshot.value !== 'AssignAISession' &&
                            setIsBlockedToFinishSessionWrapUp(false);
                        }}
                      />
                    )}
                  </div>
                  <SimpleSelect
                    placeholder="Select session type"
                    isOpen={false}
                    selectClass="w-[40%]"
                    selectOptionClass={`h-6 ${sessionType ? '' : 'text-text-subtext'}`}
                    selectDropdownClass=""
                    withSearch={false}
                    selectedOption={{ label: '', value: '' }}
                    renderValue={(placeholder) => (
                      <SelectedPatientOption
                        label={NormalisedAISessionTypes[sessionType]}
                        placeholder={placeholder}
                      />
                    )}
                    onChange={({ value }) => {
                      setSessionType(value as keyof SessionInstruction);
                      setSelectedSessionPrompt('');
                      snapshot.value !== 'AssignAISession' &&
                        setIsBlockedToFinishSessionWrapUp(true);
                    }}
                  >
                    {Object.entries(NormalisedAISessionTypes).map((type, index) => (
                      <SelectOption
                        key={index}
                        value={type[0]}
                      >
                        {type[1]}
                      </SelectOption>
                    ))}
                  </SimpleSelect>
                  {sessionType && (
                    <div className="flex flex-col gap-4">
                      <p className="font-medium">Select prompt</p>
                      <SimpleSelect
                        placeholder="Choose session prompt"
                        isOpen={false}
                        withSearch={false}
                        selectClass="w-[40%]"
                        selectOptionClass={`h-6 ${selectedSessionPrompt ? '' : 'text-text-subtext'}`}
                        selectDropdownClass=""
                        selectedOption={{ label: '', value: '' }}
                        renderValue={(placeholder) => (
                          <SelectedPatientOption
                            label={selectedSessionPrompt}
                            placeholder={placeholder}
                          />
                        )}
                        onChange={({ value }) => {
                          setSelectedSessionPrompt(value);
                        }}
                      >
                        {sessionType &&
                          AI_SESSION_SUBTYPES_BY_TYPE[
                            sessionType as keyof SessionPromptsBySessionType
                          ].map((type, index) => {
                            return (
                              <SelectOption
                                key={index}
                                value={type}
                              >
                                {type}
                              </SelectOption>
                            );
                          })}
                      </SimpleSelect>
                      {selectedSessionPrompt && (
                        <p className="text-sm ml-4">
                          {
                            AI_SESSION_SUBTYPES_DESCRIPTION[
                              selectedSessionPrompt as keyof FOLLOW_UP_SUBTYPES_DESCRIPTION_TYPE
                            ]
                          }
                        </p>
                      )}
                    </div>
                  )}
                </div>
                {lastSessionWithTherapist ? (
                  <div className="my-4 ">
                    {sessionType === 'follow_up_conversation' &&
                      selectedSessionPrompt == FOLLOW_UP_SUBTYPES.KEY_MOMENTS_REFLECTION && (
                        <div className="my-4 animate-fadeIn transition-all">
                          <div className="flex flex-col my-4 gap-2">
                            <div className="flex flex-col my-4 gap-2">
                              <p className="font-bold">Opening question</p>
                              <textarea
                                className="w-[40%] placeholder-gray-400 border border-gray-200 rounded-lg h-24 resize-none"
                                value={keyMomentsOpeningQuestion}
                                onChange={(e) => setKeyMomentsOpeningQuestion(e.target.value)}
                              />
                            </div>
                            <div className="flex flex-col my-4 gap-2">
                              <p className="font-bold">Follow-up question</p>
                              <textarea
                                className="w-[40%] placeholder-gray-400 border border-gray-200 rounded-lg h-24 resize-none"
                                value={keyMomentsfollowUpQuestion}
                                onChange={(e) => setKeyMomentsFollowUpQuestion(e.target.value)}
                                placeholder="What was most meaningful to you from that session?"
                              />
                            </div>
                            {lastSessionWithTherapist?.keyMomentsData?.keyMoments && (
                              <div className="flex flex-col gap-4">
                                <p className="font-bold">Review Key Moments</p>
                                {keyMomentsToReflect.map((keyMoment: any) => (
                                  <KeyMoment
                                    keyMoment={keyMoment}
                                    key={keyMoment.timestamp}
                                    handleCheckboxChange={handleCheckboxChange}
                                    handleQuestionChange={handleQuestionChange}
                                  />
                                ))}
                              </div>
                            )}
                            <div className="flex flex-col my-4 gap-2">
                              <label>
                                <div className="flex gap-4">
                                  <Checkbox
                                    checked={isIncludeSummary}
                                    onChange={() => setIsIncludeSummary((prev) => !prev)}
                                  />
                                  <p className="font-bold">Include Summary</p>
                                </div>
                              </label>
                              {isIncludeSummary && (
                                <textarea
                                  className="placeholder-gray-400 border border-gray-200 rounded-lg w-[40%] h-28 resize-none"
                                  value={followUpSummary}
                                  onChange={(e) => setFollowUpSummary(e.target.value)}
                                  placeholder="Summary here"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    {sessionType === 'follow_up_conversation' &&
                      selectedSessionPrompt == FOLLOW_UP_SUBTYPES.OPEN_ENDED_CHAT && (
                        <div className="my-4 animate-fadeIn transition-all">
                          <div className="flex flex-col my-4 gap-2">
                            <p className="font-bold">Select speaker style</p>
                            <SimpleSelect
                              placeholder="Select Style"
                              isOpen={false}
                              withSearch={false}
                              selectClass="w-[40%]"
                              selectOptionClass="h-6 text-text-subtext"
                              selectDropdownClass=""
                              selectedOption={{ label: '', value: '' }}
                              renderValue={(placeholder) => (
                                <SelectedPatientOption
                                  label={speakerStyle}
                                  placeholder={placeholder}
                                />
                              )}
                              onChange={({ value }) => {
                                setSpeakerStyle(value);
                              }}
                            >
                              {SPEAKER_STYLES.map((type, index) => (
                                <SelectOption
                                  key={index}
                                  value={type}
                                >
                                  {type}
                                </SelectOption>
                              ))}
                            </SimpleSelect>
                            <div className="flex flex-col my-4 gap-2">
                              <p className="font-bold">Opening question</p>
                              <textarea
                                className="w-[40%] placeholder-gray-400 border border-gray-200 rounded-lg h-24 resize-none"
                                value={openEndedChatOpeningQuestion}
                                onChange={(e) => setOpenEndedChatOpeningQuestion(e.target.value)}
                              />
                            </div>
                            <div className="flex flex-col my-4 gap-2">
                              <p className="font-bold">What to focus on</p>
                              <textarea
                                className="w-[40%] placeholder-gray-400 border border-gray-200 rounded-lg h-24 resize-none"
                                value={openEndedChatWhatToFocusOn}
                                onChange={(e) => setOpenEndedChatWhatToFocusOn(e.target.value)}
                                placeholder="What was most meaningful to you from that session?"
                              />
                            </div>
                            <div className="flex flex-col my-4 gap-2">
                              <p className="font-bold">Things to note</p>
                              <textarea
                                className="w-[40%] placeholder-gray-400 border border-gray-200 rounded-lg h-24 resize-none"
                                value={openEndedChatThingsToNote}
                                onChange={(e) => setOpenEndedChatThingsToNote(e.target.value)}
                                placeholder="No things to note yet..."
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    {sessionType == 'follow_up_conversation' &&
                      selectedSessionPrompt == FOLLOW_UP_SUBTYPES.SONG_REFLECTION && (
                        <div className="flex flex-col gap-4 mb-4">
                          <div>
                            <div className="flex flex-col my-4 gap-2">
                              <p className="font-bold">Opening question</p>
                              <textarea
                                className="w-[40%] placeholder-gray-400 border border-gray-200 rounded-lg h-24 resize-none"
                                value={songReflectionOpeningQuestion}
                                onChange={(e) => setSongReflectionOpeningQuestion(e.target.value)}
                              />
                            </div>
                            <div className="flex flex-col my-4 gap-2">
                              <p className="font-bold">Song Reflection Introduction</p>
                              <textarea
                                className="w-[40%] placeholder-gray-400 border border-gray-200 rounded-lg h-24 resize-none"
                                value={songReflectionIntroduction}
                                onChange={(e) => setSongReflectionIntroduction(e.target.value)}
                                placeholder="No introduction yet..."
                              />
                            </div>
                          </div>
                          <p className="font-bold">Play song</p>

                          <input
                            className="w-[40%] placeholder-gray-400 border border-gray-200 rounded-lg"
                            type="text"
                            value={songArtist}
                            placeholder="Song Artist..."
                            onChange={(event) => setSongArtist(event.target.value)}
                          />
                          <input
                            className="w-[40%] placeholder-gray-400 border border-gray-200 rounded-lg"
                            type="text"
                            value={songTitle}
                            placeholder="Song Title..."
                            onChange={(event) => setSongTitle(event.target.value)}
                          />
                          {songArtist && songTitle && (
                            <Button
                              type="button"
                              className="w-[40%]"
                              variant="primary"
                              text="Get Song Lyrics"
                              onClick={async () => {
                                const { data: song } = await axios.get(
                                  `https://api.lyrics.ovh/v1/${songArtist}/${songTitle}`
                                );
                                const formattedLyrics = song.lyrics.replace(
                                  /(\r?\n\s*){2,}/g,
                                  '\n'
                                );
                                setSongLyrics(formattedLyrics);
                              }}
                            />
                          )}
                          <div className="my-4">
                            <div className="flex flex-col my-4 gap-2">
                              <p className="font-bold">Song Lyrics</p>
                              <textarea
                                className="w-[40%] placeholder-gray-400 border border-gray-200 rounded-lg h-24 resize-none"
                                value={songLyrics}
                                placeholder={'No lyrics yet...'}
                                onChange={(e) => setSongLyrics(e.target.value)}
                              />
                            </div>
                            <div className="flex flex-col my-4 gap-2">
                              <p className="font-bold">Reflection Discussion</p>
                              <textarea
                                className="w-[40%] placeholder-gray-400 border border-gray-200 rounded-lg h-24 resize-none"
                                value={songReflectionDiscussion}
                                placeholder={'No discussion yet...'}
                                onChange={(e) => setSongReflectionDiscussion(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                ) : (
                  <div className="text-rose-400">No sessions available for reflection.</div>
                )}
              </div>
              <div className="flex flex-col gap-4 w-[30%] mr-16">
                <div className="flex flex-col my-4 gap-2">
                  <p className="font-bold">Final Prompt</p>
                  <textarea
                    ref={textareaRef}
                    className="w-full placeholder-gray-400 border border-gray-200 rounded-lg h-56 resize-none max-h-[500px]"
                    value={finalPrompt}
                    placeholder={'Final prompt here'}
                    onChange={(e) => setFinalPrompt(e.target.value)}
                  />
                </div>
                <div>
                  <div className="font-bold">Number of sessions</div>
                  <div>
                    <select
                      className="border border-gray-200 rounded-lg"
                      id="sessionCountDropdown"
                      value={sessionsCount}
                      onChange={(e) => setSessionsCount(Number(e.target.value))}
                    >
                      {[1, 2, 3, 4, 5].map((num) => (
                        <option
                          key={num}
                          value={num}
                        >
                          {num}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <LanguageSelector
                  selectedLanguage={selectedLanguage}
                  onChange={handleOptionChange}
                />
                <div>
                  <div className="font-bold">Notify about Self-work every day</div>
                  <div>
                    <span>For next</span>
                    <select
                      className="border border-gray-200 rounded-lg mx-2"
                      id="notificationDaysDropdown"
                      value={notificationDays}
                      onChange={(e) => setNotificationDays(Number(e.target.value))}
                    >
                      {[1, 2, 3, 4, 5].map((num) => (
                        <option
                          key={num}
                          value={num}
                        >
                          {num}
                        </option>
                      ))}
                    </select>
                    <span>days</span>
                  </div>
                </div>
                <Button
                  className="w-[60%]"
                  type="submit"
                  text={isAssignInProgress ? 'Assigning Self-work...' : 'Assign Self-work'}
                  disabled={!(patientToMeetId || patientId) || isAssignInProgress}
                />
                {isAssignSuccessfull && (
                  <p className="overflow-auto">Session are assigned and created successfully</p>
                )}
                {generatedLink && (
                  <div className="flex flex-col">
                    <CopyButton
                      label={'Copy link to session'}
                      value={generatedLink}
                    />
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default AssignAISession;
