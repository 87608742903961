import { memo, useCallback } from 'react';
import { shallowEqual, useSelector } from '@xstate/react';
// xState
import { dashboardActor } from '@pages/shared/realtime-dashboard/model/xstate/dashboardMachine';

import { Tool } from '@entities/shared/RealtimeDashboard/model/types/tools';

import MemoMeasurementGroupSection from '@components/AISession/ui/MeasurementSection/MeasurementGroupSection';

import MicrophoneSelector from '@features/Select/Microphone/MicrophoneSelector';
import CameraSelector from '@features/Select/Camera/CameraSelector';
import { appActor } from '@machines/shared/appMachine';
import { WavRecorder, WavStreamPlayer } from '@shared/lib/wavtools/index';
import ConversationItems from '@components/AISession/ui/ConversationItems';

import AISimplifiedGraphsTool from '@components/AISession/ui/AITools/AISimplifiedGraphsTool';
import { StartSessionButton } from '@components/AISession/ui/StartSessionButton';
import { useRealtimeChecks } from '../../../hooks/useRealtimeChecks';
import AudioVisualizer from '@entities/shared/RealtimeDashboard/ui/SimplifiedView/ui/AudioVisualizer/AudioVisualizer';

import { CameraView } from './CameraView';

interface RealtimeAIProps {
  startTimeRef?: React.MutableRefObject<number | undefined>;
  wavRecorder?: WavRecorder; //React.MutableRefObject<WavRenderer | null>;
  wavStreamPlayer?: WavStreamPlayer; //React.MutableRefObject<WavRenderer | null>;
  isSDKInitialized: boolean;
  shenaiSDK: any;
  onSetInitializationSettings: any;
  onConnectConversation: any;
  isConnected: boolean;
  sessionId: string;
  realtimeClientInstance: any;
}

const AISimplifiedRealtimeAI = ({
  wavRecorder,
  wavStreamPlayer,
  isSDKInitialized,
  shenaiSDK,
  sessionId,
  onSetInitializationSettings,
  onConnectConversation,
  isConnected,
  realtimeClientInstance,
}: RealtimeAIProps) => {
  const tools: Tool[] = useSelector(dashboardActor, (state) => state.context.tools, shallowEqual);
  const showTranscription = tools.find((tool) => tool.label === 'Captions')?.flag.isInUse || false;
  const showGraphs = tools.find((tool) => tool.label === 'Graphs')?.flag.isInUse || false;

  const { microphoneId, cameraId } = useSelector(appActor, (state) => state.context);

  const { isShowCanvas, isShowWarning } = useRealtimeChecks({ isConnected });

  const handleStartSessionClick = useCallback(() => {
    onConnectConversation?.(sessionId);
    document.documentElement.requestFullscreen().catch((err) => {
      console.error(`Error attempting to enable full-screen mode: ${err.message}`);
    });
  }, [sessionId]);

  const handleSetCameraId = useCallback((id: string) => {
    appActor.send({ type: 'CAMERA_ID_UPDATE', id });
  }, []);

  const handleSetMicrophoneId = useCallback((id: string) => {
    appActor.send({ type: 'MICROPHONE_ID_UPDATE', id });
  }, []);

  return (
    <div
      data-component="SimplifiedAIPage"
      className="w-full relative h-full"
      style={{ height: `calc(100% - 180px + 48px)` }}
    >
      {/* 180px - is the height of topbar ; 48px - is the height of app padding top(24px) and marging top realtime dashboard */}

      <div className="flex z-10 flex-col w-full h-full items-center ">
        <div
          className={`w-full flex ${isConnected ? 'justify-start mb-8' : 'justify-center h-full'} flex-col items-center`}
        >
          <CameraView
            isShowCanvas={isShowCanvas}
            isConnected={isConnected}
            isSDKInitialized={isSDKInitialized}
          />
          <div className="w-[105px] h-[105px] mt-6">
            <AudioVisualizer
              wavRecorder={wavRecorder}
              wavStreamPlayer={wavStreamPlayer}
              isShowWarning={isShowWarning}
            />
          </div>

          {!showGraphs && (
            <div className="relative flex items-end justify-center w-full mt-4 mb-7">
              <MemoMeasurementGroupSection />
            </div>
          )}

          {!isConnected && (
            <>
              <StartSessionButton onClick={handleStartSessionClick} />

              <div className="flex  w-full h-full items-end justify-center relative">
                <div className="flex gap-5  w-fit h-fit relative overflow-visible">
                  <MicrophoneSelector
                    key="simple-ai-1"
                    setMicrophone={handleSetMicrophoneId}
                    manualDropdownPosition
                    microphone={microphoneId}
                    selectClass="w-full max-w-[240px] relative"
                    selectDropdownClass="top-unset left-0 bottom-[60px]"
                  />

                  <CameraSelector
                    key="simple-a-2"
                    selectClass="w-full max-w-[240px] relative"
                    selectDropdownClass="top-unset left-0 bottom-[60px]"
                    manualDropdownPosition
                    setShenaiInitSettings={onSetInitializationSettings}
                    setCamera={handleSetCameraId}
                    camera={cameraId}
                    updateShenai={shenaiSDK}
                  />
                </div>
              </div>
            </>
          )}
        </div>

        {isConnected && showTranscription && (
          <ConversationItems realtimeClientInstance={realtimeClientInstance} />
        )}
        {showGraphs && <AISimplifiedGraphsTool />}
      </div>
    </div>
  );
};

const MemoAISimplifiedRealtimeAI = memo(AISimplifiedRealtimeAI);
MemoAISimplifiedRealtimeAI.displayName = 'AISimplifiedRealtimeAI';
export { MemoAISimplifiedRealtimeAI };
