import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from '@xstate/react';
// xState
import { dashboardActor } from './model/xstate/dashboardMachine';
// API
import { endAISession } from '@api/userAPI';
// Hooks
import useSocketIOLogging from '@hooks/useSocketIOLogging';
import { useWebSocket } from '../../../websocket';
import useRealtimeClient from './model/hooks/useRealtimeClient';
import useRealtimeAISession from './model/hooks/useRealtimeSession';
import useEventHandlers from './model/hooks/useEventHandlers';
import useRealtimeConnection from './model/hooks/useRealtimeConnection';
import { useSentryForPage } from '@hooks/sentry/useSentryForPage';
// Types
import { Patient } from '@interfaces/patient';
import { refaelaPatientActor } from '@components/xState/machines/refaelaPatientMachine';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { TherapistUser } from '@interfaces/index';
// Utils
import { isUserTherapist } from '@utils/helpers';
import { WavRecorder, WavStreamPlayer } from '@shared/lib/wavtools';
import { fetchCurrentUserDetails } from '@api/user/me';
import { PATIENT_MACHINE_EVENTS } from '@machines/patient/constants';
import AISession from '@modules/Patient/AISession';
import RealtimeDashboard from '@entities/shared/RealtimeDashboard/RealtimeDashboard';
import { generateAISessionSummary } from '@api/ai/summary';

const RealtimeDashboardPage = ({
  actor,
}: {
  actor: typeof refaelaPatientActor | typeof refaelaTherapistActor;
}) => {
  useSentryForPage('RealtimeDashboardPage');

  const currentUser = useSelector(actor, (state) => state.context.currentUser);

  const isConnected = useSelector(dashboardActor, (state) => state.context.isConnected);
  const sessionSettings = useSelector(dashboardActor, (state) => state.context.sessionSettings);

  const isTherapist = isUserTherapist(currentUser?.role!);
  const userId = isTherapist
    ? (currentUser as TherapistUser).therapistId!
    : (currentUser as Patient).patientId;

  const wavRecorderRef = useRef<WavRecorder>(new WavRecorder({ sampleRate: 24000 }));
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(new WavStreamPlayer({ sampleRate: 24000 }));

  //#region hooks

  const { roomUrl, sessionId, meetingToken } = useRealtimeAISession(
    userId,
    isTherapist,
    currentUser?.incomingSessionType // || currentUser?.assignedHomeworks?.find(hw => hw.count > 0)?.type
  );

  const { socket } = useWebSocket();

  useSocketIOLogging(sessionId, userId, socket, 'logs-ai');

  const { voiceBotInstance, handleUpdateConfig, handleClientReady, handleConnectionChange } =
    useRealtimeClient();

  const { handleSetEventHandlers } = useEventHandlers(
    voiceBotInstance,
    wavStreamPlayerRef,
    userId,
    sessionId,
    socket
  );

  const { handleConnectConversation, handleDisconnectConversation } = useRealtimeConnection({
    voiceBotInstance,
    wavRecorderRef,
    wavStreamPlayerRef,
    handleSetEventHandlers,
    handleConnectionChange,
    sessionId,
    userId,
  });
  //#endregion hooks

  //#region handlers
  const saveAISession = useCallback(async () => {
    try {
      await handleDisconnectConversation();
    } catch (error) {
      console.error('Error disconnecting conversation:', error);
    }

    if (currentUser && userId) {
      try {
        await endAISession(userId, roomUrl, currentUser.role!, sessionSettings);
      } catch (error) {
        console.error('Error ending AI session:', error);
      }
    }
  }, [currentUser, userId, roomUrl, sessionSettings]);

  const handleFinishSession = useCallback(async () => {
    if (isConnected) {
      try {
        window.removeEventListener('beforeunload', saveAISession);
        await saveAISession();
      } catch (error) {
        console.error('Error saving AI session:', error);
      }
    }

    if (!currentUser || !userId) return;
    if (sessionId && isConnected) {
      try {
        await generateAISessionSummary(userId, sessionId);
      } catch (error) {
        console.error('Error saving AI session summary:', error);
      }
    }

    let userData;
    try {
      userData = await fetchCurrentUserDetails(userId);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }

    try {
      const isTherapist = isUserTherapist(currentUser.role!);

      if (isTherapist) {
        userData &&
          refaelaTherapistActor.send({
            type: 'therapistUser.update',
            currentUser: userData as TherapistUser,
          });
        refaelaTherapistActor.send({ type: 'goToTherapistOffice' });
      } else {
        userData &&
          refaelaPatientActor.send({
            type: 'UPDATE_PATIENT_USER',
            currentUser: userData as Patient,
          });
        refaelaPatientActor.send({ type: PATIENT_MACHINE_EVENTS.END_SESSION });
      }

      dashboardActor.send({ type: 'SDK_DATA_UPDATE', data: null });
    } catch (error) {
      console.error('Error handling session completion:', error);
    }
  }, [isConnected, currentUser, userId, refaelaTherapistActor, refaelaPatientActor]);

  //#endregion handlers

  useEffect(() => {
    handleClientReady?.(voiceBotInstance);
    // Auto-connect if enabled
    if (false) {
      handleConnectConversation();
    }
    return () => {
      voiceBotInstance?.reset();
    };
  }, []);

  useEffect(() => {
    if (roomUrl) {
      window.addEventListener('beforeunload', saveAISession);
    }

    return () => {
      if (roomUrl) {
        window.removeEventListener('beforeunload', saveAISession);
      }
    };
  }, [roomUrl]);

  return (
    <>
      <div className="flex flex-col w-full h-full mt-6">
        <RealtimeDashboard
          actor={actor}
          meetingToken={meetingToken}
          roomUrl={roomUrl}
          userId={userId}
          sessionId={sessionId}
          onConnect={handleConnectConversation}
          onDisconnect={handleDisconnectConversation}
          voiceBotInstance={voiceBotInstance}
          wavRecorder={wavRecorderRef.current}
          wavStreamPlayer={wavStreamPlayerRef.current}
          onUpdateConfig={handleUpdateConfig}
          onFinishSession={handleFinishSession}
        />
      </div>
      {roomUrl && (
        <div className="top-0 left-0 w-0 h-0 absolute">
          <AISession
            actor={isTherapist ? refaelaTherapistActor : refaelaPatientActor}
            roomUrl={roomUrl}
            meetingToken={meetingToken}
          />
        </div>
      )}
    </>
  );
};

export default RealtimeDashboardPage;
