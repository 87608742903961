import { createAISession, getAISession, startAISession } from '@api/userAPI';
import { SESSION_TYPES } from '@interfaces/constants';
import { useSelector } from '@xstate/react';
import { useState, useEffect } from 'react';
import { dashboardActor } from '../xstate/dashboardMachine';
import { clearAuthToken } from '../api/auth';

const useRealtimeAISession = (
  userId: string,
  isTherapist: boolean,
  sessionTypeFallback?: string
) => {
  const sessionType = useSelector(dashboardActor, (state) => state.context.sessionType);

  const sessionPrompt = useSelector(dashboardActor, (state) => state.context.sessionPrompt);
  const sessionSettings = useSelector(dashboardActor, (state) => state.context.sessionSettings);

  const [roomUrl, setRoomUrl] = useState('');
  const [meetingToken, setMeetingToken] = useState('');

  const sessionId = roomUrl ? roomUrl.split('/').pop()! : '';

  const handleInstructionsUpdate = (value: string) => {
    dashboardActor.send({
      type: 'SESSION_SETTINGS_UPDATE',
      data: { key: 'instructions', value },
    });
  };

  const createAndStartAISession = async () => {
    handleInstructionsUpdate(sessionPrompt);
    try {
      const type = sessionType || sessionTypeFallback || SESSION_TYPES.AI_BIO_FEEDBACK;
      const { roomUrl, token } = await createAISession(
        userId,
        type,
        sessionSettings,
        sessionPrompt
      );

      setRoomUrl(roomUrl);
      setMeetingToken(token);
      if (roomUrl) {
        await startAISession(roomUrl, userId, type);
      }
    } catch (e) {
      (e as any).sentryHandled = true;
      console.error('Error during session creation and start: ', JSON.stringify(e));
    }
  };

  const fetchAndStartAISession = async () => {
    try {
      const { roomUrl, token, prompt } = await getAISession(userId);
      const type = sessionType || sessionTypeFallback || SESSION_TYPES.AI_BIO_FEEDBACK;
      setRoomUrl(roomUrl);
      setMeetingToken(token);
      handleInstructionsUpdate(prompt);
      if (roomUrl) {
        await startAISession(roomUrl, userId, type);
      }
    } catch (e) {
      console.error('Error during session fetch and start: ', e);
    }
  };

  useEffect(() => {
    clearAuthToken(userId);
    if (!userId) return;
    if (isTherapist) {
      createAndStartAISession();
    } else {
      fetchAndStartAISession();
    }
  }, []);

  return { roomUrl, sessionId, meetingToken, sessionSettings };
};

export default useRealtimeAISession;
