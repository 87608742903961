import { useState, useCallback, useRef, useContext, useEffect } from 'react';
import debounce from 'lodash/debounce';
//xState
import { dashboardActor } from '@pages/shared/realtime-dashboard/model/xstate/dashboardMachine';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { refaelaPatientActor } from '@components/xState/machines/refaelaPatientMachine';
// Hooks
import { useShenai } from '@hooks/shenAI/useShenai';
// Constants
import { END_TYPE_OPTIONS } from '@pages/shared/realtime-dashboard/model/constants/options';
// Types
import { ClientConfig } from './model/types/types';
import { ShenaiSdkDataI } from '@shared/types/shenai/sdk';
import { ShamefulAny } from '@interfaces/index';
// Utils
import {
  SessionInfo,
  ConversationalModelProperties,
  MemoVisionAIModel,
} from './ui/AdvancedView/ui';
import RealtimeMeasurementGraphs from './ui/AdvancedView/ui/Graphs/RealtimeMeasurementGraphs';
import { MemoRealtimeAi } from '@modules/Patient/AISession/RealtimeAISession';
import { RealtimeClient } from '@openai/realtime-api-beta/dist/lib/client';

import InfoRow from '@shared/ui/caption/InfoRow';
import MemoizedConnectSettingsSection from './ui/AdvancedView/ui/ConnectSettingsBlock';
import { AppContext } from '../../../contextApp';
import SpeechCharacteristicData from './ui/AdvancedView/ui/SpeechCharacteristicData';
import SummaryData from './ui/AdvancedView/ui/SummaryData';
import SuggestionData from './ui/AdvancedView/ui/SuggestionData';
import CharacteristicAnalysisData from './ui/AdvancedView/ui/CharacteristicAnalysisData';
import ConversationAnalysisData from './ui/AdvancedView/ui/ConversationAnalysisData';
import FrequentKeywordsData from './ui/AdvancedView/ui/FrequentKeywordsData';
import SpeakerActivationPlot from '@features/Plot/SpeakerActivationPlot';
import Button from '@components/Button';
import MemoAISessionSimplified from './ui/SimplifiedView/AISessionSimplified';
import { WavRecorder, WavStreamPlayer } from '@shared/lib/wavtools/index';

type RealtimeDashboardProps = {
  actor: typeof refaelaPatientActor | typeof refaelaTherapistActor;
  roomUrl: string;
  userId: string;
  sessionId: string;
  meetingToken: string;
  onUpdateConfig: (config: Partial<ClientConfig>) => void;
  onFinishSession: () => Promise<void>;
  onConnect: () => Promise<void>;
  onDisconnect: () => void;
  voiceBotInstance: RealtimeClient;
  wavStreamPlayer: WavStreamPlayer;
  wavRecorder: WavRecorder;
};

const RealtimeDashboard = ({
  actor,
  onUpdateConfig,
  onFinishSession,
  onConnect,
  onDisconnect,
  userId,
  sessionId,
  voiceBotInstance,
  wavStreamPlayer,
  wavRecorder,
}: RealtimeDashboardProps) => {
  const [isRecording, setIsRecording] = useState(false);
  const [canPushToTalk, setCanPushToTalk] = useState(true);

  const [isSimplifiedSessionOpened, setSimplifiedSessionOpenned] = useState(true);

  const startTimeRef = useRef<string>(new Date().toISOString());
  const { isLogsSending } = useContext(AppContext);

  const {
    isSDKInitialized,
    initializationSettings,
    setInitializationSettings,
    initializeShenAiSdk,
    deinitializeShenAiSdk,
    shenaiSDK,
    subscribeToSdkData,
  } = useShenai('ai_session', isLogsSending, userId, sessionId);

  // @ts-ignore
  const handleTranscript = useCallback((transcript: string) => {
    // console.log('New transcript:', transcript);
  }, []);

  // @ts-ignore
  const handleAudio = useCallback((audioBlob: Blob) => {
    // console.log('Received audio blob:', audioBlob);
  }, []);

  const handleError = useCallback((error: any) => {
    error.sentryHandled = true;
    console.error('Realtime recording error:', JSON.stringify(error));
  }, []);

  const handleDeinitializeShenAISDK = useCallback(() => {
    deinitializeShenAiSdk();
  }, []);

  const handleInitializeShenAISDK = useCallback((settings: ShamefulAny) => {
    initializeShenAiSdk(settings, undefined);
  }, []);

  const handleSetInitializationSettings = useCallback((settings: ShamefulAny) => {
    setInitializationSettings(settings);
  }, []);

  const sendSessionUpdate = debounce((key: string, value: any) => {
    if (voiceBotInstance?.isConnected()) {
      voiceBotInstance.realtime.send('session.update', {
        session: { [key]: value },
      });
    }
  }, 1000);

  const handleSubscribtion = useCallback((data: ShenaiSdkDataI) => {
    dashboardActor.send({
      type: 'SDK_DATA_UPDATE',
      data,
    });
  }, []);

  useEffect(() => {
    const unsubscribe = subscribeToSdkData(handleSubscribtion);
    return () => {
      unsubscribe();
      dashboardActor.send({
        type: 'SDK_DATA_UPDATE',
        data: null,
      });
    };
  }, []);

  // temperature, maxTokens
  const handleSettingsUpdate = useCallback(
    ({ key, value }: any) => {
      onUpdateConfig({ [key]: value });
      sendSessionUpdate(key, value);
    },
    [voiceBotInstance, onUpdateConfig]
  );

  //#region connection
  const handleStartRecording = useCallback(async () => {
    try {
      setIsRecording(true);

      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        voiceBotInstance?.cancelResponse(trackId, offset);
      }

      await wavRecorder.record((data: any) => voiceBotInstance?.appendInputAudio(data.mono));
    } catch (error: ShamefulAny) {
      error.sentryHandled = true;
      handleError?.(error);
      setIsRecording(false);
    }
  }, []);

  const handleStopRecording = useCallback(async () => {
    try {
      setIsRecording(false);

      await wavRecorder.pause();
      voiceBotInstance?.createResponse();
    } catch (error: ShamefulAny) {
      error.sentryHandled = true;
      handleError?.(error);
    }
  }, []);

  const handleChangeTurnEndType = useCallback(
    async ({ label, value }: { label: string; value: string }) => {
      dashboardActor.send({ type: 'END_TYPE_UPDATE', option: { label, value } });
      if (voiceBotInstance?.realtime.isConnected()) {
        if (value === END_TYPE_OPTIONS.MANUAL && wavRecorder.getStatus() === 'recording') {
          await wavRecorder.pause();
        }

        (voiceBotInstance.sessionConfig as any).turn_detection =
          value === END_TYPE_OPTIONS.MANUAL
            ? null
            : { ...voiceBotInstance.defaultServerVadConfig, type: END_TYPE_OPTIONS.VAD };
        // Update turn detection
        voiceBotInstance.realtime.send('session.update', {
          session: {
            turn_detection:
              value === END_TYPE_OPTIONS.MANUAL
                ? null
                : { ...voiceBotInstance.defaultServerVadConfig, type: END_TYPE_OPTIONS.VAD },
          },
        });

        if (value === END_TYPE_OPTIONS.VAD) {
          await wavRecorder.record((data: any) => voiceBotInstance.appendInputAudio(data.mono));
        }
      } else {
        if (voiceBotInstance) {
          voiceBotInstance.defaultSessionConfig.turn_detection =
            value === END_TYPE_OPTIONS.MANUAL
              ? null
              : { ...voiceBotInstance?.defaultServerVadConfig, type: END_TYPE_OPTIONS.VAD };
        }
      }

      setCanPushToTalk(value === END_TYPE_OPTIONS.MANUAL);
    },
    []
  );
  //#endregion connection

  const handleSectionToggleByKey = useCallback((key: string) => {
    dashboardActor.send({ type: 'TOGGLE_SECTION', key });
  }, []);

  const handleSimplifiedSession = useCallback(() => {
    setSimplifiedSessionOpenned((prev) => !prev);
  }, []);

  return (
    <>
      <div
        className={`absolute w-full h-full bg-transparent transition-opacity duration-300 top-[-48px] ${
          isSimplifiedSessionOpened ? 'opacity-100 relative' : 'opacity-0 pointer-events-none'
        }`}
      >
        <MemoAISessionSimplified
          isSimplifiedSessionOpened={isSimplifiedSessionOpened}
          onConnectConversation={onConnect}
          sessionId={sessionId}
          startTimeRef={startTimeRef}
          wavRecorder={wavRecorder}
          wavStreamPlayer={wavStreamPlayer}
          isSDKInitialized={isSDKInitialized}
          onSetInitializationSettings={handleSetInitializationSettings}
          onFinishSession={onFinishSession}
          shenaiSDK={shenaiSDK}
          onPauseSession={handleChangeTurnEndType}
          onOpenAdvancedMode={setSimplifiedSessionOpenned}
          realtimeClientInstance={voiceBotInstance}
        />
      </div>
      <div
        className={`absolute w-full h-full transition-opacity duration-300 ${
          !isSimplifiedSessionOpened ? 'opacity-100 relative' : 'opacity-0 pointer-events-none'
        }`}
      >
        <Button
          variant="outlinedPrimary"
          text={isSimplifiedSessionOpened ? 'Close simplified session' : 'Open simplified session'}
          className="max-w-fit z-20"
          onClick={handleSimplifiedSession}
        />
        <div className="grid grid-cols-[25%_50%_25%] gap-4 p-6 w-full text-left">
          <div className="bg-transparent rounded-lg">
            <MemoVisionAIModel
              key="vision-ai-model"
              shenaiSDK={shenaiSDK}
              isSDKInitialized={isSDKInitialized}
              initializationSettings={initializationSettings}
              onSectionToggleByKey={handleSectionToggleByKey}
              onSetInitializationSettings={handleSetInitializationSettings}
              onInitializeShenAiSdk={handleInitializeShenAISDK}
              onDeinitializeShenAiSdk={handleDeinitializeShenAISDK}
            />
          </div>
          <div className="bg-transparent p-4 rounded-lg col-span-1.5">
            <SessionInfo
              key="session-info-shenai-window"
              isSDKInitialized={isSDKInitialized}
            />
            <MemoRealtimeAi
              key="realtime-ai"
              onSectionToggleByKey={handleSectionToggleByKey}
              onInstructionsUpdate={handleSettingsUpdate}
              startTimeRef={startTimeRef}
              wavRecorder={wavRecorder}
              wavStreamPlayer={wavStreamPlayer}
              voiceBotInstance={voiceBotInstance}
              // agentState={agentState}
              hideUI={false}
            />
            <RealtimeMeasurementGraphs onSectionToggleByKey={handleSectionToggleByKey} />
            {voiceBotInstance && (
              <div>
                <SummaryData
                  key="summaryAgent"
                  actor={actor}
                  sessionId={sessionId}
                  realtime={voiceBotInstance?.realtime}
                  onSectionToggleByKey={handleSectionToggleByKey}
                />
                <SuggestionData
                  key="suggestionAgent"
                  actor={actor}
                  sessionId={sessionId}
                  realtime={voiceBotInstance?.realtime}
                  onSectionToggleByKey={handleSectionToggleByKey}
                />
              </div>
            )}
          </div>
          <div className="bg-transparent p-4 rounded-lg">
            <ConversationalModelProperties
              key="conversational-model-properties"
              onSectionToggleByKey={handleSectionToggleByKey}
              onSettingsUpdate={handleSettingsUpdate}
              allowVoiceChange={!voiceBotInstance?.isConnected()}
            />
            {sessionId && (
              <MemoizedConnectSettingsSection
                key="connect-settings-section"
                onStartRecording={handleStartRecording}
                onStopRecording={handleStopRecording}
                onConnectConversation={onConnect}
                onDisconnectConversation={onDisconnect}
                onChangeTurnEndType={handleChangeTurnEndType}
                onFinishSession={onFinishSession}
                isRecording={isRecording}
                canPushToTalk={canPushToTalk}
                sessionId={sessionId}
              />
            )}
            <div className="flex flex-col space-y-4 w-full">
              <InfoRow
                key="Speaker-activation-state"
                label={'Speaker activation'}
                value={''}
              />
              <SpeakerActivationPlot
                key="speaker"
                voiceBotInstance={voiceBotInstance}
                sessionId={sessionId}
                userId={userId}
              />
            </div>
            {voiceBotInstance && (
              <div className="flex flex-col gap-4">
                <FrequentKeywordsData
                  sessionId={sessionId}
                  actor={actor}
                  realtime={voiceBotInstance?.realtime}
                  onSectionToggleByKey={handleSectionToggleByKey}
                />
                <SpeechCharacteristicData
                  sessionId={sessionId}
                  actor={actor}
                  key="topicDetectionAgent"
                  realtime={voiceBotInstance?.realtime}
                  onSectionToggleByKey={handleSectionToggleByKey}
                />
                <ConversationAnalysisData
                  sessionId={sessionId}
                  actor={actor}
                  key="ConversationAnalysisData"
                  realtime={voiceBotInstance?.realtime}
                  onSectionToggleByKey={handleSectionToggleByKey}
                />
                <CharacteristicAnalysisData
                  sessionId={sessionId}
                  actor={actor}
                  key="CharacteristicData"
                  realtime={voiceBotInstance?.realtime}
                  onSectionToggleByKey={handleSectionToggleByKey}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RealtimeDashboard;
