import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { debounce } from 'lodash';
import Button from '@components/Button';
import { ShamefulAny } from '@interfaces/index';
import { formatTime } from '@utils/keyMoment';
import { PauseIcon, PlayIcon } from 'lucide-react';

interface SelfReviewTimelineProps {
  startTime: number;
  endTime: number;
  setCurrentSelfReviewTime: ShamefulAny;
  currentSelfReviewTime: number;
  videoRef?: React.RefObject<HTMLVideoElement>; // Optional for frame-based simulation
}

const SelfReviewTimeline: React.FC<SelfReviewTimelineProps> = ({
  startTime,
  endTime,
  setCurrentSelfReviewTime,
  currentSelfReviewTime,
  videoRef,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const hasVideo = !!videoRef?.current;
  const totalTimelineDuration = endTime - startTime;

  // Memoize mapTimelineToVideoTime using useCallback
  const mapTimelineToVideoTime = useCallback(
    (timelineTime: number) => {
      const videoDuration = videoRef?.current?.duration || 0;

      if (!Number.isFinite(videoDuration) || videoDuration <= 0) {
        console.warn('Video duration is not valid:', videoDuration);
        return 0;
      }

      return ((timelineTime - startTime) / totalTimelineDuration) * videoDuration;
    },
    [startTime, totalTimelineDuration, videoRef]
  );

  const mapVideoTimeToTimeline = useCallback(
    (videoTime: number) =>
      startTime + (videoTime / (videoRef?.current?.duration || 1)) * totalTimelineDuration,
    [startTime, totalTimelineDuration, videoRef]
  );

  const debouncedSetCurrentSelfReviewTime = useMemo(
    () =>
      debounce((newTime: number) => {
        setCurrentSelfReviewTime(Math.floor(newTime));
      }, 100),
    [setCurrentSelfReviewTime]
  );
  useEffect(() => {
    if (hasVideo) {
      // Clear interval when switching to video
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    }
  }, [hasVideo]);

  useEffect(() => {
    if (!isDragging && hasVideo && videoRef?.current) {
      const videoDuration = videoRef.current.duration;

      if (Number.isFinite(videoDuration)) {
        const videoTime = mapTimelineToVideoTime(currentSelfReviewTime);

        // Only update video currentTime if it is significantly different
        if (
          Number.isFinite(videoTime) &&
          Math.abs(videoRef.current.currentTime - videoTime) > 0.2
        ) {
          videoRef.current.currentTime = videoTime;
        }
      }
    }
  }, [currentSelfReviewTime, isDragging, hasVideo, videoRef, mapTimelineToVideoTime]);

  useEffect(() => {
    if (hasVideo && videoRef.current) {
      const handleTimeUpdate = () => {
        if (!isDragging) {
          const videoTime = videoRef.current?.currentTime;
          if (!videoTime) return;
          const timelineTime = mapVideoTimeToTimeline(videoTime);

          debouncedSetCurrentSelfReviewTime(timelineTime);
        }
      };

      videoRef.current.addEventListener('timeupdate', handleTimeUpdate);
      return () => videoRef.current?.removeEventListener('timeupdate', handleTimeUpdate);
    }
  }, [hasVideo, isDragging, videoRef, mapVideoTimeToTimeline, debouncedSetCurrentSelfReviewTime]);

  const calculateNewTime = (clientX: number, rect: DOMRect) => {
    const clickX = clientX - rect.left;
    return startTime + (clickX / rect.width) * totalTimelineDuration;
  };

  const handleTimelineClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const newTime = Math.floor(calculateNewTime(e.clientX, rect));
    if (hasVideo && videoRef?.current) {
      const newVideoTime = mapTimelineToVideoTime(newTime);
      videoRef.current.currentTime = newVideoTime;
    }
    setCurrentSelfReviewTime(newTime);
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsDragging(true);
    const rect = e.currentTarget.getBoundingClientRect();
    const newTime = Math.floor(calculateNewTime(e.clientX, rect));
    setCurrentSelfReviewTime(newTime);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isDragging) {
      const rect = e.currentTarget.getBoundingClientRect();
      const newTime = Math.floor(calculateNewTime(e.clientX, rect));
      setCurrentSelfReviewTime(newTime);
    }
  };

  const handleMouseUp = () => setIsDragging(false);

  const handlePlayPauseToggle = () => {
    if (hasVideo && videoRef?.current) {
      // Ensure simulation interval is cleared when using video
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }

      if (!isPlaying) {
        const playPromise = videoRef.current.play();

        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              setIsPlaying(true);
            })
            .catch((error) => {
              console.error('Failed to play the video:', error);
            });
        }
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    } else {
      // Handle frame-based playback simulation
      if (!isPlaying) {
        intervalRef.current = setInterval(() => {
          setCurrentSelfReviewTime((prevTime: number) => {
            const nextTime = prevTime + 1000;
            if (nextTime >= endTime) {
              clearInterval(intervalRef.current!);
              setIsPlaying(false);
              return endTime;
            }
            return nextTime;
          });
        }, 1000);
        setIsPlaying(true);
      } else {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
        setIsPlaying(false);
      }
    }
  };

  const progressPercentage = Math.min(
    100,
    Math.max(0, ((currentSelfReviewTime - startTime) / totalTimelineDuration) * 100)
  );

  return (
    <div>
      <div
        className="w-full h-[5px] bg-purple-700 relative cursor-pointer"
        onClick={handleTimelineClick}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={() => setIsDragging(false)}
      >
        {/* Progress Bar */}
        <div
          className="absolute h-full bg-purple-200"
          style={{ width: `${progressPercentage}%` }}
        />

        {/* Current Time Indicator */}
        <div
          className="absolute top-[-50%] w-[10px] h-[10px] bg-purple-700 rounded-full transform -translate-x-1/2"
          style={{ left: `${progressPercentage}%` }}
        />
      </div>
      <div className="flex justify-between mt-2">
        <Button
          onClick={handlePlayPauseToggle}
          variant="iconWhite"
          icon={isPlaying ? <PauseIcon /> : <PlayIcon />}
        />
        <div>
          {formatTime(currentSelfReviewTime)} /{' '}
          {endTime ? (
            formatTime(endTime)
          ) : (
            <span className="text-rose-400 text-sm">dropped connection</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelfReviewTimeline;
