import { useEffect, useRef, useState } from 'react';
import { useWebSocket } from '../websocket';
import { isUserTherapist } from '@utils/helpers';

type Patient = {
  patientId: string;
  sessionId: string;
  therapistId: string;
  hr4s: number;
};

export function useCalibratedPatients(currentUser: { role: string; therapistId: string } | null) {
  const { socket } = useWebSocket();
  const [calibratedPatients, setCalibratedPatients] = useState<Record<string, Patient>>({});
  const hasJoinedRoom = useRef(false);

  useEffect(() => {
    if (
      socket &&
      socket.connected &&
      currentUser &&
      isUserTherapist(currentUser.role) &&
      currentUser.therapistId &&
      !hasJoinedRoom.current
    ) {
      const therapistId = currentUser.therapistId;
      socket.emit('therapist-join', { therapistId });
      hasJoinedRoom.current = true;

      const handleNewCalibratedPatient = (patient: Patient) => {
        setCalibratedPatients((prev) => ({ ...prev, [patient.patientId]: patient }));
      };

      const handlePatientDisconnected = ({ patientId }: { patientId: string }) => {
        setCalibratedPatients((prev) => {
          const updated = { ...prev };
          delete updated[patientId];
          return updated;
        });
      };

      socket.on('new-calibrated-patient', handleNewCalibratedPatient);
      socket.on('patient-disconnected', handlePatientDisconnected);

      return () => {
        socket.off('new-calibrated-patient', handleNewCalibratedPatient);
        socket.off('patient-disconnected', handlePatientDisconnected);
      };
    }
  }, [socket?.connected]);

  return { calibratedPatients };
}
