import { useCallback } from 'react';

import { getAiSessionRealtimeData } from '@api/ai/aiRealtimeData';
import { useFetch } from '@hooks/useFetch';

export const useRealtimeData = (userId: string, sessionId: string) => {
  const fetchRealtimeData = useCallback(async () => {
    const { events, transcripts, agents } = await getAiSessionRealtimeData(userId, sessionId);
    return { events, transcripts, agents };
  }, [userId, sessionId]);

  const { data, isLoading, error, refresh } = useFetch({ fetcher: fetchRealtimeData });

  return {
    events: data?.events || [],
    transcripts: data?.transcripts || [],
    agents: data?.agents || [],
    isLoading,
    error,
    refresh,
  };
};
