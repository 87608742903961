import { useState } from 'react';

import ToggleableTabs from '@features/Tab/ToggleableTabs/ToggleableTabs';
import { ShamefulAny } from '@interfaces/index';
import { getDataForPlot } from '../utils';
import ValueTimeAISessionPlot from '@features/Plot/ValueTimeAISessionPlot';
import { TachogramPlot } from '@features/Plot';
import PSDGraph from '@entities/shared/RealtimeDashboard/ui/AdvancedView/ui/Graphs/PSDGraph';

const initialPlotShowState = {
  tachogram: true,
  hr10: true,
  hr4: true,
  cardiacStress: true,
  hrvSDNN: true,
};

const SelfReviewMeasurementGraphs = ({
  filteredDataFromMxlabs,
  currentDataPoint,
  currentSelfReviewTime,
}: {
  filteredDataFromMxlabs: ShamefulAny;
  currentDataPoint: ShamefulAny | null;
  currentSelfReviewTime: number;
}) => {
  const [isPlotShown, setPlotShown] = useState(initialPlotShowState);

  const togglePlotVisibility = (plotName: keyof typeof initialPlotShowState) => {
    setPlotShown((prevState) => ({
      ...prevState,
      [plotName]: !prevState[plotName],
    }));
  };

  return (
    <div className="mb-4 w-full">
      <div className="flex items-center mb-2 justify-between">
        <div className="w-56 h-6 text-black  text-[15px] font-bold font-['Inter'] leading-normal">
          Realtime measurement graphs
        </div>
      </div>

      <div className="flex flex-col items-center gap-3 w-full">
        <div>
          <PSDGraph
            isRealtime={false}
            data={currentDataPoint}
          />
        </div>

        <ToggleableTabs
          withCardStyle
          tabs={[
            {
              label: 'HR10',
              content: (
                <ValueTimeAISessionPlot
                  key="hr10"
                  dataForPlot={getDataForPlot(filteredDataFromMxlabs, 'hr10s')}
                  label="hr10"
                  currentSelfReviewTime={currentSelfReviewTime}
                />
              ),
            },
            {
              label: 'HR4',
              content: (
                <ValueTimeAISessionPlot
                  key="hr4"
                  dataForPlot={getDataForPlot(filteredDataFromMxlabs, 'hr4s')}
                  label="hr4"
                  currentSelfReviewTime={currentSelfReviewTime}
                />
              ),
            },
          ]}
          titleClasses="max-w-[190px]"
          isShown={isPlotShown.hr10}
          additionalData={currentDataPoint?.hr10s && `${currentDataPoint?.hr10s} bpm`}
          onToggle={() => togglePlotVisibility('hr10')}
        />

        <ToggleableTabs
          withCardStyle
          titleClasses="max-w-[190px]"
          tabs={[
            {
              label: 'SDNN',
              content: (
                <ValueTimeAISessionPlot
                  key="hrv-plot"
                  dataForPlot={getDataForPlot(filteredDataFromMxlabs, 'hrvSDNN')}
                  currentSelfReviewTime={currentSelfReviewTime}
                />
              ),
            },
          ]}
          isShown={isPlotShown.hrvSDNN}
          additionalData={currentDataPoint?.hrvSDNN && `${currentDataPoint?.hrvSDNN} ms`}
          onToggle={() => togglePlotVisibility('hrvSDNN')}
        />

        <ToggleableTabs
          withCardStyle
          tabs={[
            {
              label: 'Cardiac stress',
              content: (
                <ValueTimeAISessionPlot
                  key="cardiacStress-plot"
                  dataForPlot={getDataForPlot(filteredDataFromMxlabs, 'cardiacStress')}
                  currentSelfReviewTime={currentSelfReviewTime}
                />
              ),
            },
          ]}
          titleClasses="max-w-[190px]"
          isShown={isPlotShown.cardiacStress}
          additionalData={currentDataPoint?.cardiacStress}
          onToggle={() => togglePlotVisibility('cardiacStress')}
        />
        <ToggleableTabs
          withCardStyle
          tabs={[
            {
              label: 'Tachogram',
              content: (
                <TachogramPlot
                  key="tachogram-plot"
                  realtimeBeats={currentDataPoint?.realtimeHeartbeats}
                  finalBeats={currentDataPoint?.results?.heartbeats}
                />
              ),
            },
          ]}
          titleClasses="max-w-[190px]"
          isShown={isPlotShown.tachogram}
          onToggle={() => togglePlotVisibility('tachogram')}
        />
      </div>
    </div>
  );
};

export default SelfReviewMeasurementGraphs;
