interface RadioOption {
  label: string;
  value: string;
}

interface CustomRadioProps {
  options: RadioOption[];
  selected: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CustomRadio = ({ options, selected, onChange }: CustomRadioProps) => {
  return (
    <div className="flex flex-col space-y-2">
      {options.map((option) => (
        <label
          key={option.value}
          className="flex items-center space-x-3 cursor-pointer"
        >
          <input
            type="radio"
            name="language"
            value={option.value}
            checked={selected === option.value}
            onChange={onChange}
            className="hidden"
          />
          <div
            className={`w-5 h-5 rounded-full border-2 ${
              selected === option.value ? 'border-purple-500' : 'border-gray-400'
            } flex items-center justify-center transition-all`}
          >
            {selected === option.value && (
              <div className="w-3 h-3 bg-purple-500 rounded-full"></div>
            )}
          </div>
          <span className="text-gray-800">{option.label}</span>
        </label>
      ))}
    </div>
  );
};
