import { useEffect, useRef, useState, memo } from 'react';
import useResizeObserver from 'use-resize-observer';
import * as Plot from '@observablehq/plot';
import * as d3 from 'd3';
import { ChevronDown, Search, ZoomOutIcon } from 'lucide-react';
// Constants
import { SIGNALS_LIST } from './model/constants/signals';
import { PLOT_CATEGORY } from './model/constants/categories';
// Types
import { ITimelineRange } from './types';
import { IPlotPayload, PlotSizeMap } from './types';
import { AvailableRoleSignalsT } from './model/types/signals';
// Utils
import { getPlotVisualisationElements } from './helpers';
import { getPlotColorRange } from './model/utils/getPlotColorRange';
// Components
import SelectableList from './ui/SelectableList';
import useClickOutside from '@hooks/useClickOutside';
import { USER_ROLES } from '@interfaces/user';
import { ValueOf } from '@utils/types';
import { PLOT_SIGNALS } from '@shared/constants/plot/signals';
import { emptyDataPlaceHolder } from './model/constants/graph';
import Switch from '@shared/ui/switch/Switch';
import { KeyMomentsData, KeyMomentWithRange } from '@components/SessionComponents/KeyMoments/types';
import Button from '@components/Button';
import ReactDOM from 'react-dom/client';

type ConfigurablePlotGraphProps = {
  mainYPayload: IPlotPayload | null;
  secondaryYPayload: IPlotPayload | null;
  timelineRange: ITimelineRange;
  plotType?: string;
  plotSize?: string;
  isHrFilter: boolean;
  configuredSignals: Record<ValueOf<typeof USER_ROLES>, ValueOf<typeof PLOT_SIGNALS>[]>;
  availableRoleSignals: Record<AvailableRoleSignalsT, boolean>;
  onPlotClick?: (value: number) => void;
  onSignalChange: (role: ValueOf<typeof USER_ROLES>, value: ValueOf<typeof PLOT_SIGNALS>[]) => void;
  onSwitchAvailableRoleSignalChange: (role: AvailableRoleSignalsT, value: boolean) => void;

  keyMoments?: KeyMomentsData | null;
};

export const ConfigurablePlotGraph = memo(
  ({
    mainYPayload,
    secondaryYPayload,
    timelineRange,
    plotSize,
    plotType,
    isHrFilter,
    onSignalChange,
    onSwitchAvailableRoleSignalChange,
    configuredSignals,
    availableRoleSignals,

    keyMoments,
  }: ConfigurablePlotGraphProps) => {
    const [isSignalPlotMenuByRoleOpen, setSignalPlotMenuByRoleIsOpen] = useState<
      Record<ValueOf<typeof USER_ROLES>, boolean>
    >({
      [USER_ROLES.PATIENT]: false,
      [USER_ROLES.THERAPIST]: false,
      [USER_ROLES.ADMIN]: false,
    });
    const [zoomRange, setZoomRange] = useState<{ start: number; end: number } | null>(null);

    const patientMenuRef = useRef(null);
    const therapistMenuRef = useRef(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const plotContainerRef = useRef<HTMLDivElement | null>(null);

    const height = PlotSizeMap[plotSize as keyof typeof PlotSizeMap];
    const { data, label } = mainYPayload || {};
    const secondaryData = secondaryYPayload?.data;

    // add to trigger re-render when resize container
    const { width } = useResizeObserver({ ref: containerRef });

    const handlePlotMenuClose = (role: ValueOf<typeof USER_ROLES>) => (flag: boolean) => {
      setSignalPlotMenuByRoleIsOpen((prev) => ({ ...prev, [role]: flag }));
    };

    const plotMarginLeft = 27;
    const plotMarginRight = 27;
    const [plotWidth, setPlotWidth] = useState(0);

    useEffect(() => {
      if (containerRef.current) {
        const width = containerRef.current.clientWidth - plotMarginLeft - plotMarginRight;
        setPlotWidth(Math.max(width, 0)); // Ensure non-negative width
      }
    }, [containerRef.current]);

    const allYValues = [
      ...(data?.map((d) => d.value) || []),
      ...(secondaryData?.map((d) => d.value) || []),
    ];

    const yMin = allYValues.length ? Math.min(...allYValues) : 0;
    const yMax = allYValues.length ? Math.max(...allYValues) : 100; // Default range
    const borderHeight = yMax * 1.1;
    const keyMomentHighlights =
      keyMoments?.keyMoments
        .filter(
          (km: KeyMomentWithRange) =>
            km.keyMomentRange?.startTime != null && km.keyMomentRange?.endTime != null
        )
        .flatMap((km: KeyMomentWithRange, index) => {
          const startTime = km.keyMomentRange?.startTime ?? 0;
          const endTime = km.keyMomentRange?.endTime ?? startTime + 1000;
          const highlightEnd = Math.max(startTime + 1000, endTime);

          return [
            // Highlight area
            Plot.rectY(
              [
                {
                  x1: startTime,
                  x2: highlightEnd,
                  y1: yMin,
                  y2: yMax,
                  index,
                },
              ],
              {
                x1: 'x1',
                x2: 'x2',
                y1: 'y1',
                y2: 'y2',
                fill: '#9333ea',
                opacity: 0.15,
                className: `keymoment-highlight-${index}`,
              }
            ),
            // Left border (vertical line at startTime)
            Plot.line(
              [
                { x: startTime, y: yMin },
                { x: startTime, y: borderHeight },
              ],
              {
                x: 'x',
                y: 'y',
                stroke: '#9333ea',
                strokeWidth: 1,
                opacity: 1,
                className: `keymoment-border-left-${index}`,
              }
            ),
            // Right border (vertical line at highlightEnd)
            Plot.line(
              [
                { x: highlightEnd, y: yMin },
                { x: highlightEnd, y: borderHeight },
              ],
              {
                x: 'x',
                y: 'y',
                stroke: '#9333ea',
                strokeWidth: 1,
                opacity: 1,
                className: `keymoment-border-right-${index}`,
              }
            ),
          ];
        }) || [];

    useEffect(() => {
      if (!keyMoments) return;
      const container = plotContainerRef.current;
      if (!container) return;

      setTimeout(() => {
        const svg = container.querySelector('svg');
        if (!svg) {
          console.warn('SVG not found!');
          return;
        }

        d3.select(svg)
          .selectAll('[class^="keymoment-highlight-"]')
          .style('cursor', 'pointer')
          .each(function (_, index) {
            d3.select(this).attr('data-index', index.toString());
          })
          .on('mouseenter', function () {
            const element = this as unknown as SVGGraphicsElement;
            const bbox = element.getBBox();

            d3.select(this).raise().attr('stroke-width', 3);

            const index = Number(d3.select(this).attr('data-index'));
            const km = keyMoments?.keyMoments[index] as KeyMomentWithRange;
            if (!km) return;

            const startTime = km.keyMomentRange?.startTime ?? 0;
            const endTime = km.keyMomentRange?.endTime ?? startTime + 1000;
            const durationSec = Math.round((endTime - startTime) / 1000);
            const formattedDuration =
              durationSec >= 60 ? `${Math.floor(durationSec / 60)} min` : `${durationSec} sec`;

            // Create foreignObject for search icon
            const searchIconFO = d3
              .select(this)
              .append('foreignObject')
              .attr('x', bbox.x + bbox.width / 2 - 12)
              .attr('y', bbox.y + 4)
              .attr('width', 14)
              .attr('height', 14)
              .style('pointer-events', 'none')
              .append('xhtml:div')
              .style('width', '14px')
              .style('height', '14px')
              .style('display', 'flex')
              .style('align-items', 'center')
              .style('justify-content', 'center');

            const searchIconNode = searchIconFO.node() as HTMLElement | null;
            if (searchIconNode) {
              const root = ReactDOM.createRoot(searchIconNode);
              root.render(
                <Search
                  size={14}
                  color="#9333ea"
                />
              );
            }

            // Create foreignObject for duration label
            const durationFO = d3
              .select(this)
              .append('foreignObject')
              .attr('x', bbox.x + bbox.width / 2 - 18)
              .attr('y', bbox.y - 20)
              .attr('width', 35)
              .attr('height', 14)
              .style('pointer-events', 'none');

            const durationNode = durationFO.node() as HTMLElement | null;
            if (durationNode) {
              const root = ReactDOM.createRoot(durationNode);
              root.render(
                <div
                  style={{
                    background: '#efe0fc',
                    color: '#9333ea',
                    fontSize: '8px',
                    borderRadius: '4px',
                    padding: '1px 4px',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  {formattedDuration}
                </div>
              );
            }
          })
          .on('mouseleave', function () {
            d3.select(this).attr('stroke', 'none').attr('opacity', 1);

            // Remove both search icon and duration label on mouse leave
            d3.select(this).selectAll('foreignObject').remove();
          });
      }, 100);
    }, [keyMomentHighlights, plotContainerRef.current]);

    const handleKeyMomentClick = (event: MouseEvent) => {
      if (!keyMoments?.keyMoments || !containerRef.current) return;

      const plotWidth = containerRef.current.clientWidth - plotMarginLeft - plotMarginRight;

      // Ensure click is within plot area
      const adjustedX = event.offsetX - plotMarginLeft;
      if (adjustedX < 0 || adjustedX > plotWidth) return;

      // Convert adjustedX to time within the domain
      const clickedTime =
        timelineRange.startTimestamp +
        (adjustedX / plotWidth) * (timelineRange.endTimestamp - timelineRange.startTimestamp);

      // Find all key moments containing the clicked time
      const selectedMoments = keyMoments.keyMoments.filter((km: KeyMomentWithRange) => {
        const startTime = km.keyMomentRange?.startTime ?? null;
        const endTime = km.keyMomentRange?.endTime ?? null;
        return (
          startTime !== null &&
          endTime !== null &&
          startTime <= clickedTime &&
          endTime >= clickedTime
        );
      });

      if (selectedMoments.length > 0) {
        // Compute zoom range based on all overlapping key moments
        const newStart = Math.min(
          ...selectedMoments.map((km: KeyMomentWithRange) => km.keyMomentRange!.startTime!)
        );
        const newEnd = Math.max(
          ...selectedMoments.map((km: KeyMomentWithRange) => km.keyMomentRange!.endTime!)
        );

        setZoomRange({ start: newStart, end: newEnd });
      }
    };

    useEffect(() => {
      const container = containerRef.current;
      if (!container) return;

      container.addEventListener('click', handleKeyMomentClick);

      return () => {
        container?.removeEventListener('click', handleKeyMomentClick);
      };
    }, [keyMoments]);

    const handlePlotMenuOpen = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      role: ValueOf<typeof USER_ROLES>
    ) => {
      e.preventDefault();
      switch (role) {
        case USER_ROLES.THERAPIST: {
          setSignalPlotMenuByRoleIsOpen((prev) => ({ ...prev, [role]: !prev[role] }));
          handlePlotMenuClose(USER_ROLES.PATIENT)(false);
          break;
        }
        case USER_ROLES.PATIENT: {
          setSignalPlotMenuByRoleIsOpen((prev) => ({ ...prev, [role]: !prev[role] }));
          handlePlotMenuClose(USER_ROLES.THERAPIST)(false);
          break;
        }
        default: {
          setSignalPlotMenuByRoleIsOpen((prev) => ({ ...prev, [role]: !prev[role] }));
          break;
        }
      }
    };

    const handleSignalChange =
      (role: ValueOf<typeof USER_ROLES>, secondaryRole: ValueOf<typeof USER_ROLES>) =>
      (value: ValueOf<typeof PLOT_SIGNALS>[]) => {
        const secondaryConfiguredSignals = configuredSignals[secondaryRole] || [];
        // handle edge case when all item were unselected
        if (secondaryConfiguredSignals.length === 1 && value.length === 1) {
          onSignalChange?.(role, value);
          return;
        }
        // only two plots at a time for both roles
        // TODO think about crowded plots

        // if (secondaryConfiguredSignals.length >= 1 && value.length >= 1) {
        //   secondaryConfiguredSignals.shift();
        //   onSignalChange?.(secondaryRole, secondaryConfiguredSignals.filter(Boolean));
        // }

        onSignalChange?.(role, value);
      };

    const handleOutsideClick = (flag: boolean) => {
      if (isSignalPlotMenuByRoleOpen[USER_ROLES.THERAPIST]) {
        handlePlotMenuClose(USER_ROLES.THERAPIST)(flag);
      }
      if (isSignalPlotMenuByRoleOpen[USER_ROLES.PATIENT]) {
        handlePlotMenuClose(USER_ROLES.PATIENT)(flag);
      }
    };

    const handleRoleSwitch = (checked: boolean, role: AvailableRoleSignalsT) => {
      onSwitchAvailableRoleSignalChange?.(role, checked);
    };

    useClickOutside([therapistMenuRef, patientMenuRef], handleOutsideClick);

    const MIN_ZOOM_INTERVAL = 30000; // Ensure at least 30 seconds

    const adjustedDomain = (() => {
      if (zoomRange?.start && zoomRange?.end && zoomRange.start !== zoomRange.end) {
        const zoomWidth = zoomRange.end - zoomRange.start;

        if (zoomWidth < MIN_ZOOM_INTERVAL) {
          const midpoint = (zoomRange.start + zoomRange.end) / 2;
          let newStart = midpoint - MIN_ZOOM_INTERVAL / 2;
          let newEnd = midpoint + MIN_ZOOM_INTERVAL / 2;

          // Ensure the adjusted domain does not overflow the timeline range
          if (newStart < timelineRange.startTimestamp) {
            newStart = timelineRange.startTimestamp;
            newEnd = newStart + MIN_ZOOM_INTERVAL;
          }

          if (newEnd > timelineRange.endTimestamp) {
            newEnd = timelineRange.endTimestamp;
            newStart = newEnd - MIN_ZOOM_INTERVAL;
          }

          return [newStart, newEnd];
        }

        return [zoomRange.start, zoomRange.end];
      }

      return [timelineRange.startTimestamp, timelineRange.endTimestamp];
    })();

    useEffect(() => {
      if (data === null && secondaryData == null) return;
      if (!containerRef?.current) return; // Ensure containerRef is valid
      if (!plotContainerRef.current) return; // Ensure plotContainerRef is valid
      let primary = data;
      let secondary = secondaryData;
      if (!primary && secondary) {
        [primary, secondary] = [secondary, primary];
      }

      const { leftYaxisPlot, rightYaxisPlot, pointerLineLeftAxixData, y2, y2toy1Scale } =
        getPlotVisualisationElements(primary, secondary, plotType, height, isHrFilter, {
          startTimestamp: adjustedDomain[0],
          endTimestamp: adjustedDomain[1],
        });

      const plot = Plot.plot({
        width: (plotContainerRef?.current as any).clientWidth,
        height,
        marginTop: 25,
        marginBottom: 25,
        marginRight: plotMarginRight,
        marginLeft: plotMarginLeft,
        color: {
          legend: false,
          domain: [
            ...Array.from(new Set(data?.map((d) => `${d.role}-${d.key}`))),
            ...Array.from(new Set(secondaryData?.map((d) => `${d.role}-${d.key}`))),
          ],
          range: [...getPlotColorRange(data || []), ...getPlotColorRange(secondaryData || [])],
        },
        marks:
          data || secondaryData
            ? [
                ...leftYaxisPlot.flat(),
                pointerLineLeftAxixData,
                ...keyMomentHighlights,
                ...(secondaryYPayload && y2 && y2toy1Scale ? [rightYaxisPlot] : []),
              ]
            : [...emptyDataPlaceHolder],

        x: {
          label: 'Time',
          type: 'time',
          transform: (d) => new Date(d),
          tickFormat: '%H:%M:%S',
          axis: null,
          domain: adjustedDomain,
        },
        y: {
          grid: true,
          domain: allYValues.length ? [yMin, yMax] : undefined,
        },
      });
      // Clear previous plots before appending new one
      plotContainerRef.current.innerHTML = '';
      plotContainerRef.current.appendChild(plot);

      return () => {
        plot.remove();
      };
    }, [
      data,
      secondaryData,
      height,
      label,
      plotType,
      timelineRange,
      width,
      zoomRange,
      plotWidth,
      adjustedDomain,
    ]);

    return (
      <div
        ref={containerRef}
        className="w-full mt-12 relative h-full"
      >
        <div
          ref={plotContainerRef}
          className="relative w-full h-full"
        ></div>

        <div className="absolute left-[2rem] top-[-2rem] flex">
          <div className="flex items-center text-sm gap-2">
            <div
              ref={therapistMenuRef}
              className="flex gap-2 items-center"
            >
              <Switch
                checked={availableRoleSignals[USER_ROLES.THERAPIST]}
                onChange={(checked) => handleRoleSwitch(checked, USER_ROLES.THERAPIST)}
                label=""
              />
              <div>
                <div
                  onClick={(e) => handlePlotMenuOpen(e, USER_ROLES.THERAPIST)}
                  className="flex cursor-pointer items-center"
                >
                  <span>Therapist</span>
                  <ChevronDown
                    className={`ml-2 w-5 transition-transform duration-100 ${isSignalPlotMenuByRoleOpen[USER_ROLES.THERAPIST] ? 'rotate-180' : ''}`}
                  />
                </div>
                {isSignalPlotMenuByRoleOpen[USER_ROLES.THERAPIST] && (
                  <div className="absolute top-5 bg-white overflow-y-auto h-[150px] w-[160px] shadow-md p-2">
                    <SelectableList
                      list={configuredSignals[USER_ROLES.THERAPIST]}
                      disabled={!availableRoleSignals[USER_ROLES.THERAPIST]}
                      onChange={(value: ValueOf<typeof PLOT_SIGNALS>[]) =>
                        handleSignalChange(USER_ROLES.THERAPIST, USER_ROLES.PATIENT)(value)
                      }
                      options={SIGNALS_LIST}
                      category={PLOT_CATEGORY.SIGNALS}
                    />
                  </div>
                )}
              </div>
            </div>
            <div
              ref={patientMenuRef}
              className="flex gap-2 items-center"
            >
              <Switch
                switchColor="bg-blue-600"
                checked={availableRoleSignals[USER_ROLES.PATIENT]}
                onChange={(checked) => handleRoleSwitch(checked, USER_ROLES.PATIENT)}
                label=""
              />
              <div>
                <div
                  onClick={(e) => handlePlotMenuOpen(e, USER_ROLES.PATIENT)}
                  className="flex cursor-pointer items-center"
                >
                  <span>Patient</span>
                  <ChevronDown
                    className={`ml-2 w-5 transition-transform duration-100 ${isSignalPlotMenuByRoleOpen[USER_ROLES.PATIENT] ? 'rotate-180' : ''}`}
                  />
                </div>
                {isSignalPlotMenuByRoleOpen[USER_ROLES.PATIENT] && (
                  <div className="absolute top-5 bg-white overflow-y-auto h-[150px] w-[160px] shadow-md p-2">
                    <SelectableList<ValueOf<typeof PLOT_SIGNALS>>
                      list={configuredSignals[USER_ROLES.PATIENT]}
                      disabled={!availableRoleSignals[USER_ROLES.PATIENT]}
                      onChange={(value: ValueOf<typeof PLOT_SIGNALS>[]) =>
                        handleSignalChange(USER_ROLES.PATIENT, USER_ROLES.THERAPIST)(value)
                      }
                      options={SIGNALS_LIST}
                      category={PLOT_CATEGORY.SIGNALS}
                    />
                  </div>
                )}
              </div>
            </div>
            <div>
              {zoomRange && (
                <Button
                  variant="iconWhite"
                  onClick={() => setZoomRange(null)}
                  className="w-[108px] h-6 text-xs font-normal"
                  icon={
                    <ZoomOutIcon
                      width={16}
                      height={16}
                    />
                  }
                  text="Full graph"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
