import { useState } from 'react';

interface Tab {
  name: string;
  content: JSX.Element;
}

interface SidebarTabsProps {
  tabs: Tab[];
}

const SidebarTabs = ({ tabs }: SidebarTabsProps) => {
  const [activeTab, setActiveTab] = useState(tabs[0].name);

  return (
    <div className="flex h-full w-full">
      <div className="w-1/4 p-4 ">
        {tabs.map((tab) => (
          <div
            key={tab.name}
            className={` cursor-pointer w-32 h-8 tracking-tight text-base${
              activeTab === tab.name
                ? 'font-semibold border-r-4 border-r-purple-600'
                : ' font-normal'
            }`}
            style={{ color: activeTab === tab.name ? '#9333ea' : '#111827' }}
            onClick={() => setActiveTab(tab.name)}
          >
            {tab.name}
          </div>
        ))}
      </div>
      <div className="w-3/4 p-6 ">
        <div className="mt-2 text-gray-800">
          {tabs.find((tab) => tab.name === activeTab)?.content}
        </div>
      </div>
    </div>
  );
};
export default SidebarTabs;
