import { useState, useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import { useSelector } from '@xstate/react';
// Utils
import { useMXLabsLogsAISessionReview } from '@components/Plot/utils';
import { flattenDataArray } from './utils';
import { getIdWithoutPrefix } from '@utils/prefixes';
// Types
import { ShamefulAny } from '@interfaces/index';

import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { getLatestItemByTimestamp } from '@shared/utils/shenai/getLatestItemByTimestamp';
import { processSavedData } from '@entities/shared/RealtimeDashboard/model/utils/proccessSavedBeforeHeartbeatData';
import {
  SelfReviewVisionAIModel,
  SelfReviewMediaDisplayBlock,
  SelfReviewTimeline,
  SelfReviewConversationData,
  SelfReviewMeasurementGraphs,
  SelfReviewSummaryData,
  SelfReviewSuggestionData,
  SelfReviewConversationalModelProperties,
  SelfReviewSpeakerActivationData,
  SelfReviewFrequentKeywordsData,
  SelfReviewTopicDetectionAgent,
  SelfReviewConversationAnalysisData,
  SelfReviewCharacteristicData,
} from './ui';
import { FrequentKeywordsLibrary } from './ui/SelfReviewFrequentKeywordsData';
import { useReviewSessionData } from './model/hooks/useReviewSessionData';
import { useRealtimeData } from './model/hooks/useRealtimeData';
import { useRecording } from './model/hooks/useRecording';
import { useFrames } from './model/hooks/useFrames';

const SelfWorkReview = () => {
  const { aiReviewUserId, aiReviewSessionId } = useSelector(
    refaelaTherapistActor,
    (state: ShamefulAny) => state.context
  );

  const [currentSelfReviewTime, setCurrentSelfReviewTime] = useState<number>(0);

  const [currentDataPoint, setCurrentDataPoint] = useState<ShamefulAny | null>(null);

  const [activeTopic, setActiveTopic] = useState<string>('');
  const [speakerActivationData, setSpeakerActivationData] = useState<ShamefulAny[]>([]);

  const [summaryData, setSummaryData] = useState({
    mainTopic: 'Waiting for data...',
    mostMeaningfulStatement: 'Waiting for data...',
    summary: 'Waiting for data...',
  });

  const [suggestionData, setSuggestionData] = useState({
    topicsToExplore: ['Waiting for data...'],
    topicsToExpand: ['Waiting for data...'],
    deepeningQuestions: ['Waiting for data...'],
  });

  const [speechCharacteristics, setSpeechCharacteristics] = useState({
    language: 'Waiting for data...',
    gender: 'Waiting for data...',
    tone: 'Waiting for data...',
  });

  const [characteristicData, setCharacteristicData] = useState({
    openness: 0,
    introspection: 0,
    depthOfSharing: 0,
  });

  const [filteredFrames, setFilteredFrames] = useState<ShamefulAny[]>([]);
  const [frequentKeywords, setFrequentKeywords] = useState<FrequentKeywordsLibrary>({});
  const [filteredMxlabsData, setFilteredMxlabsData] = useState<ShamefulAny[]>([]); // filtered according start and stop time from session.json

  const [currentFrame, setCurrentFrame] = useState<ShamefulAny | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const { logs: mxlabs } = useMXLabsLogsAISessionReview(aiReviewUserId, aiReviewSessionId);

  const sessionReviewUrl = `${window.location.origin}/?userId=${getIdWithoutPrefix(aiReviewUserId)}&sessionId=${aiReviewSessionId}`;

  const { sessionData, sessionSettings, onUpdateSessionData } =
    useReviewSessionData(aiReviewSessionId);

  const {
    events,
    transcripts,
    agents,
    isLoading: realtimeDataIsLoading,
  } = useRealtimeData(aiReviewUserId, aiReviewSessionId);

  const {
    recording,
    // isLoading: isRecordingLoading,
    // error,
    // refresh
  } = useRecording(aiReviewUserId, aiReviewSessionId);
  const {
    frames,
    // isLoading: areFramesLoading,
    // error,
  } = useFrames(aiReviewUserId, aiReviewSessionId);

  useEffect(() => {
    if (sessionData && mxlabs) {
      const { sessionStartTime, sessionStopTime } = sessionData;

      // If sessionStopTime is 0 and sessionStartTime > 0, update sessionStopTime

      let updatedSessionStopTime = sessionStopTime;
      if (sessionStopTime === 0 && sessionStartTime > 0 && mxlabs.length > 0) {
        updatedSessionStopTime = mxlabs[mxlabs.length - 1].timestamp;

        onUpdateSessionData((prevState: any) => {
          return prevState ? { ...prevState, sessionStopTime: updatedSessionStopTime } : null;
        });
      }

      setCurrentSelfReviewTime(sessionStartTime || 0);

      // Filter mxlabs based on sessionStartTime and updatedSessionStopTime
      // process before filtering
      const processedMxlabs = processSavedData(mxlabs);

      const filteredMxlabs = processedMxlabs.filter(
        (data: ShamefulAny) =>
          data.timestamp >= sessionStartTime && data.timestamp <= updatedSessionStopTime
      );

      setFilteredMxlabsData(filteredMxlabs);
    }
  }, [sessionData, mxlabs]);

  // Filter frames based on sessionData
  // TODO: test how it works when frames are availiable
  useEffect(() => {
    if (sessionData && frames) {
      const { sessionStartTime, sessionStopTime } = sessionData;

      const filteredFramesData = frames.filter(
        (frame: ShamefulAny) =>
          frame.timestamp >= sessionStartTime && frame.timestamp <= sessionStopTime
      );
      setFilteredFrames(filteredFramesData);
    }
  }, [sessionData, frames]);

  useEffect(() => {
    if (sessionData) {
      const { sessionStartTime } = sessionData;

      // filter agents by the timeline
      const filteredRealtimeAgentsData: any[] = agents?.filter((realtimeAgent: ShamefulAny) => {
        return (
          realtimeAgent.timestamp >= sessionStartTime &&
          realtimeAgent.timestamp <= currentSelfReviewTime
        );
      });

      // filter agents by type
      const filteredSpeakerActivationAgentData = filteredRealtimeAgentsData.filter(
        (item: any) => item.agentType == 'speaker-activation-ai-agent'
      );

      const filteredSpeakerActivationData = flattenDataArray(filteredSpeakerActivationAgentData);

      const filteredSpeechCharacteristicData = filteredRealtimeAgentsData.filter(
        (item: any) => item.agentType == 'speech-characteristics-ai-agent'
      );
      const filteredCharacteristicData = filteredRealtimeAgentsData.filter(
        (item: any) => item.agentType == 'characteristic-ai-agent'
      );
      const filteredConversationAnalysisData = filteredRealtimeAgentsData.filter(
        (item: any) => item.agentType == 'conversation-analysis-ai-agent'
      );
      const filteredFrequentKeywordsData = filteredRealtimeAgentsData.filter(
        (item: any) => item.agentType == 'frequent-keywords-ai-agent'
      );

      const filteredSummaryData = filteredRealtimeAgentsData.filter(
        (item) => item.agentType == 'history-ai-agent'
      );
      const filteredSuggestionData = filteredRealtimeAgentsData.filter(
        (item) => item.agentType == 'suggestion-ai-agent'
      );

      // find the closest timestamp item to display
      const latestSummaryData = getLatestItemByTimestamp(filteredSummaryData);
      const latestSuggestionData = getLatestItemByTimestamp(filteredSuggestionData);
      const latestCharacteristicData = getLatestItemByTimestamp(filteredCharacteristicData);
      const latestSpeechCharacteristicData = getLatestItemByTimestamp(
        filteredSpeechCharacteristicData
      );
      const latestConversationAnalysisData = getLatestItemByTimestamp(
        filteredConversationAnalysisData
      );

      if (!isEqual(filteredSpeakerActivationData, speakerActivationData)) {
        setSpeakerActivationData(filteredSpeakerActivationData || speakerActivationData);
      }

      if (!isEqual(latestSummaryData?.data, summaryData)) {
        setSummaryData(latestSummaryData?.data || summaryData);
      }

      if (!isEqual(latestSuggestionData?.data, suggestionData)) {
        setSuggestionData(latestSuggestionData?.data || suggestionData);
      }

      if (!isEqual(latestCharacteristicData?.data, characteristicData)) {
        setCharacteristicData(latestCharacteristicData?.data || characteristicData);
      }

      if (!isEqual(latestSpeechCharacteristicData?.data, speechCharacteristics)) {
        setSpeechCharacteristics(latestSpeechCharacteristicData?.data || speechCharacteristics);
      }

      if (!(latestConversationAnalysisData?.data?.activeTopic == activeTopic)) {
        setActiveTopic(latestConversationAnalysisData?.data?.activeTopic || '');
      }

      let keywordsData: FrequentKeywordsLibrary = {};
      filteredFrequentKeywordsData.length > 0 &&
        filteredFrequentKeywordsData.forEach((item) => {
          if (item.data.keyword == 'N/A') return;

          const isKeywordPresent = keywordsData[item.data.keyword];

          if (isKeywordPresent) {
            keywordsData = {
              ...keywordsData,
              [item.data.keyword]: frequentKeywords[item.data.keyword] + 1,
            };
          } else {
            keywordsData = { ...keywordsData, [item.data.keyword]: 1 };
          }
        });

      if (!isEqual(keywordsData, frequentKeywords)) {
        setFrequentKeywords(keywordsData);
      }
    }
  }, [sessionData, agents, currentSelfReviewTime]);

  // Find the closest data point and corresponding frame
  useEffect(() => {
    const closestData =
      filteredMxlabsData.find((el: ShamefulAny) => el.timestamp >= currentSelfReviewTime) || null;
    setCurrentDataPoint(closestData);

    if (filteredFrames) {
      const closestFrame =
        filteredFrames.find((frame: ShamefulAny) => frame.timestamp >= currentSelfReviewTime) ||
        null;
      setCurrentFrame(closestFrame);
    }
  }, [currentSelfReviewTime, filteredMxlabsData, filteredFrames]);

  return (
    <div className="grid grid-cols-[25%_50%_25%] gap-4 p-6 w-full text-left">
      <div className="bg-transparent rounded-lg">
        <SelfReviewVisionAIModel
          key="review-vision-ai-model"
          currentDataPoint={currentDataPoint}
          currentSelfReviewTime={currentSelfReviewTime}
          dataFromMxlabs={filteredMxlabsData}
        />
      </div>
      <div className="bg-transparent p-4 rounded-lg col-span-1.5">
        <SelfReviewMediaDisplayBlock
          frame={currentFrame}
          recording={recording}
          ref={videoRef}
          sessionReviewLink={sessionReviewUrl}
        />
        <SelfReviewTimeline
          startTime={sessionData?.sessionStartTime || 0}
          endTime={sessionData?.sessionStopTime || 0}
          videoRef={videoRef}
          setCurrentSelfReviewTime={setCurrentSelfReviewTime}
          currentSelfReviewTime={currentSelfReviewTime}
        />
        <SelfReviewConversationData
          isLoading={realtimeDataIsLoading}
          startTime={sessionData?.sessionStartTime.toString() || '0'}
          transcripts={transcripts}
          events={events}
          prompt={sessionSettings.instructions}
        />
        <SelfReviewMeasurementGraphs
          filteredDataFromMxlabs={filteredMxlabsData}
          currentDataPoint={currentDataPoint}
          currentSelfReviewTime={currentSelfReviewTime}
        />
        <div>
          <SelfReviewSummaryData summaryData={summaryData} />
          <SelfReviewSuggestionData suggestionData={suggestionData} />
        </div>
      </div>
      <div>
        <div className="flex flex-col gap-4">
          <SelfReviewConversationalModelProperties
            initialMaxTokens={sessionSettings.maxTokens}
            initialPrefixPadding={sessionSettings.prefixPadding}
            initialSilenceDuration={sessionSettings.silenceDuration}
            initialTemperature={sessionSettings.temperature}
            initialThreshold={sessionSettings.threshold}
            initialVoice={sessionSettings.voice}
          />
          <SelfReviewSpeakerActivationData speakerActivationData={speakerActivationData} />
          <SelfReviewFrequentKeywordsData frequentKeywords={frequentKeywords} />
          <SelfReviewTopicDetectionAgent speechCharacteristics={speechCharacteristics} />
          <SelfReviewConversationAnalysisData activeTopic={activeTopic} />
          <SelfReviewCharacteristicData characteristicData={characteristicData} />
        </div>
      </div>
    </div>
  );
};

export default SelfWorkReview;
