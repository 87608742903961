export const EventLogSource = {
  VF: 'VF',
  MXLABS: 'MXLABS',
  HUME: 'HUME',
  FEEDBACK: 'FEEDBACK',
  CUSTOM: 'CUSTOM',
  FOR_LIVE: 'FOR_LIVE',
} as const;

export type EventLogSource = (typeof EventLogSource)[keyof typeof EventLogSource];
