import SidebarTabs from '@features/SidebarTabs/SidebarTabs';

import { PrivacyPolicy } from './PrivacyPolicy';
import { CookiePolicy } from './CookiePolicy';
import { TermsOfUse } from './TermsOfUse';

interface Tab {
  name: string;
  content: JSX.Element;
}

export const Policies = () => {
  const tabs: Tab[] = [
    { name: 'Terms of Use', content: <TermsOfUse /> },
    { name: 'Privacy Policy', content: <PrivacyPolicy /> },
    { name: 'Cookie Policy', content: <CookiePolicy /> },
  ];

  return (
    <div className="w-full h-full">
      <SidebarTabs tabs={tabs} />
    </div>
  );
};
