import { useState, useEffect } from 'react';

type PermissionName = 'camera' | 'microphone';

export const useMediaDevicePermissionGranted = (permission: PermissionName): boolean => {
  const [permissionGranted, setPermissionGranted] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true;

    const checkPermission = async () => {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: permission as PermissionDescriptor['name'],
        });

        if (isMounted) {
          setPermissionGranted(permissionStatus.state === 'granted');
        }

        permissionStatus.onchange = () => {
          if (isMounted) {
            setPermissionGranted(permissionStatus.state === 'granted');
          }
        };
      } catch (error) {
        console.error('Error checking permission:', JSON.stringify(error));
      }
    };

    checkPermission();

    return () => {
      isMounted = false;
    };
  }, [permission]);

  return permissionGranted;
};
