import ActionSlot from '@modules/Therapist/Home/ui/SessionListItem/ActionSlot';
import { Notebook, EllipsisVertical } from 'lucide-react';

export const createActionSlot = ({ ...props }) => {
  return (
    <>
      {props.shortSummary && (
        <ActionSlot
          title={props.shortSummary}
          className="text-purple-600"
          icon={<Notebook />}
          onClick={() => {}}
        />
      )}
      <ActionSlot
        icon={<EllipsisVertical />}
        onClick={() => {}}
      />
    </>
  );
};
