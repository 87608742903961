import { PRIVACY_POLICY_DATA_USES, SECTION_LINKS } from './constants/privacyPolicies';
import { PrivacyDataUsesTable } from './ui/PrivacyDataUsesTable';

export const PrivacyPolicy = () => {
  const smoothScroll = (e: React.MouseEvent<HTMLAnchorElement>, targetId: string) => {
    e.preventDefault();
    const targetElement = document.querySelector(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <div className="flex max-w-[1160px] w-11/12">
      <div className="flex flex-col gap-8 items-start font-['Inter'] tracking-tight self-start text-gray-800">
        <div className="text-4xl font-bold mt-[44px]">Privacy Policy</div>
        <div className="flex flex-col gap-6 w-full text-start mb-[88px] text-sm font-normal">
          <section className="flex flex-col gap-6 w-full">
            <h2 className="text-lg font-bold">General</h2>
            <p>
              Better You Technologies Ltd., including our subsidiaries and affiliates (collectively{' '}
              <span className="font-bold">“BYT”</span>, <span className="font-bold">“we”</span>,{' '}
              <span className="font-bold">“our”</span> or <span className="font-bold">“us”</span>),
              develops and operates a therapy platform for the AI era, built to enhance the
              effectiveness of psychological therapy (the “Platform”).
            </p>

            <p>
              This Privacy Policy describes how we collect, store, use and disclose data from the
              following identified or identifiable individuals ({' '}
              <span className="font-bold">“personal data”</span>):
            </p>

            <ul className="list-disc list-inside space-y-2">
              <li>
                <strong>Therapists</strong>, mental health professionals who access and interact
                with the Platform in their professional capacity.
              </li>
              <li>
                <strong>Patients</strong> are individuals who use the Platform in its Alpha stage,
                to engage in tailored therapy sessions and interactive activities, enhancing their
                understanding of their mental health and wellbeing.
              </li>
              <li>
                <strong>Prospects</strong> who visit our website at{' '}
                <a
                  href="https://www.betteryou.tech"
                  className="text-purple-600 underline underline-offset-4"
                >
                  https://www.betteryou.tech
                </a>{' '}
                (the “Website”), interact with our online ads and content, surveys, emails or other
                communications under our control (collectively with the Platform, the “Services”) or
                any other prospective Patient or Therapist (collectively – “you” or “your”).
              </li>
            </ul>
            <p>
              Privacy is important to us, and we are strongly committed to transparency and fairness
              in our data processing activities. Please read this Privacy Policy carefully and make
              sure that you fully understand and agree to our practices.
            </p>
            <p>Specifically, this Privacy Policy describes our practices regarding -</p>
            <ul className="list-decimal list-inside space-y-2">
              {SECTION_LINKS.map(({ id, text }) => (
                <li key={id}>
                  <a
                    href={id}
                    className="underline underline-offset-4"
                    onClick={(e) => smoothScroll(e, id)}
                  >
                    {text}
                  </a>
                </li>
              ))}
            </ul>
          </section>
          <section
            className="flex flex-col gap-6 w-full"
            id="section-1"
          >
            <h2 className="text-lg font-bold">1. Data Collection</h2>
            <p>
              Under some data protection laws, BYT assumes the role of data controller. You are not
              legally required to provide us with any Personal Data, and may do so (or avoid doing
              so) at your own free will.
            </p>
            <p>
              If you do not wish to provide us with your Personal Data, or to have it processed by
              us, please avoid any interaction with us or with our Services, or submit a request to
              exercise your rights as further explained in{' '}
              <a
                href="#section-9"
                className="text-purple-600 underline underline-offset-4"
                onClick={(e) => smoothScroll(e, '#section-9')}
              >
                Section 9
              </a>{' '}
              below but please keep in mind that limiting our processing may also result in us not
              being able to provide you the ability to use our Services, or with the best user
              experience when using our Services.
            </p>
            <br />
            <p>We may collect or generate the following types of personal data about you:</p>
            <ul className="list-disc list-inside space-y-2">
              <li>
                <strong>Basic Personal Data</strong>: Name, contact details (phone number, email).
              </li>
              <li>
                <strong>Technical Data</strong>: IP address, device identifier or other similar
                identifiers, browser history, information on interaction with the Services, cookies,
                pixels, and activity logs.
              </li>
              <li>
                <strong>Interest and Preferences</strong>: Expressed, presumed, or identified needs,
                preferences, attributes, and insights relevant to potential or existing engagement.
              </li>
            </ul>

            <h3 className="text-base font-bold">Data About Therapists</h3>
            <ul className="list-disc list-inside space-y-2">
              <li>
                <strong>Interaction Data</strong>: Session recordings; video and audio data
                (including facial expressions, gestures, environment, and voice tone).
              </li>
              <li>
                <strong>Biological Data</strong>: Age and heart rate.
              </li>
              <li>
                <strong>Professional Data</strong>: Employment details at clinics, hospitals,
                private practices, or educational institutions; professional experience in previous
                roles and settings; educational background covering degrees, universities attended,
                and specialized training; licenses and certifications held; memberships and
                affiliations with professional associations; specializations in specific fields
                within psychology.
              </li>
              <li>
                <strong>Contractual Data</strong>: Information about your contracts and agreements
                with us.
              </li>
              <li>
                <strong>Content Data</strong>: Documentation uploaded through the use of the
                Platform (e.g., session notes); Personal Data provided during interaction with the
                Services; information provided by Patients.{' '}
              </li>
            </ul>

            <h3 className="text-base font-bold">Data About Patients</h3>
            <ul className="list-disc list-inside space-y-2">
              <li>
                <strong>Interaction Data</strong>: Session recordings; video and audio data
                (including facial expressions, gestures, environment, and voice tone).
              </li>
              <li>
                {' '}
                <strong>Biological Data</strong>: Age; heart rate.
              </li>
              <li>
                <strong>Content Data</strong>:{' '}
                <span>
                  Documentation uploaded through the use of the Platform (e.g., personal photos);
                  Personal Data provided during interaction with the Platform (e.g., text entered
                  into chat); interpretation and analysis of interactions with the Platform;
                  information provided by Therapists (e.g., session notes).
                </span>
                <br />
                <span>
                  Depending on the laws that apply to you, a portion of this data may be considered
                  biometric data, which we only use to facilitate your use of the Platform and
                  improve our Services.
                </span>
              </li>
            </ul>

            <p>
              We may obtain the categories of personal information listed above from the following
              categories of sources:
            </p>

            <ul className="list-disc list-outside pl-4 space-y-2">
              <li>
                <strong>Directly from you</strong>: You may provide us with Personal Data when you
                choose to use our Services, request information from us or contact us for any other
                reason, including via email or through your interactions with the Platform.
                <br />
                When you first sign in to the Platform, you will be asked whether you permit the
                Platform access to your device's camera and microphone. If you grant access to the
                camera and microphone, we will collect and process information on the
                characteristics of the video and audio being recorded, such as the environment, the
                appearance, and the expression of the person that appears in the video, as well as
                his or her tone of voice.
                <br />
                Please note that the use of video and audio footage is integral to the core
                functionality of the Platform. By analyzing this footage, we gain essential insights
                into our Patients' mental states, heart rates, and other vital parameters. This
                enables us to understand our Patients better and provide you with tailored support
                and enhanced care.
              </li>
              <li>
                <strong>Data received from Therapists or Patients</strong>: If you have been
                referred to the Platform by a mental health specialist (referred to in this Privacy
                Policy as a “Therapist”), we may receive Personal Data about your interactions with
                the Therapist. This Personal Data may include session notes and therapy guidelines.
                Similarly, we may receive Personal Data about Therapists from our Patients,
                including information about past and future sessions, feedback and initiation or
                termination of engagement with a Therapist.
              </li>
              <li>
                <strong>Data automatically collected or generated</strong>: When you interact with
                or use our Services, we may collect certain technical data about your device (such
                as your operating system, IP address, device identifier, browsing history, etc.) We
                collect or generate such data either independently or with the help of our Service
                Providers (as detailed in{' '}
                <a
                  href="#section-5"
                  className="text-purple-600 underline underline-offset-4"
                  onClick={(e) => smoothScroll(e, '#section-5')}
                >
                  Section 5
                </a>{' '}
                below), including through the use of "cookies" and other tracking technologies (as
                further detailed in{' '}
                <a
                  href="#section-6"
                  className="text-purple-600 underline underline-offset-4"
                  onClick={(e) => smoothScroll(e, '#section-6')}
                >
                  Section 6
                </a>{' '}
                below).
              </li>
              <li>
                <strong>Data received from third party services</strong>: Our Services may be used
                in connection with third party services, sites, and mobile applications. If you use
                our Services with or through such third parties, we may receive Personal Data about
                you from such third parties. Please note that when you directly engage with such
                third-party services, sites and mobile applications, any aspect of that engagement
                that is not directly related to the Services and directed by BYT is beyond the scope
                of BYT's Terms and Conditions and this Privacy Policy, and their own terms and
                privacy policies will govern your use of those services.
              </li>
            </ul>
          </section>
          <section
            className="flex flex-col gap-6 w-full"
            id="section-2"
          >
            <h2 className="text-lg font-bold">2. Data Uses</h2>

            <p>
              We use your Personal Data as described in{' '}
              <a
                href="#section-1"
                className="text-purple-600 underline underline-offset-4"
                onClick={(e) => smoothScroll(e, '#section-1')}
              >
                Section 1
              </a>{' '}
              as necessary for the performance of our Services (“Performance of a Contract”); to
              comply with our legal and contractual obligations (“Legal Obligations”); and to
              support our legitimate interests in maintaining and improving our Services
              (“Legitimate Interests”).
              <br />
              If you reside or are using the Services in a territory governed by privacy laws which
              determine that “consent” is the only or most appropriate legal basis for the
              processing of Personal Data as described in this Privacy Policy (either in general,
              based on the types of Personal Data you expect or elect to process or have processed
              by us or via the Service, or due to the nature of such processing) (“Consent”), your
              acceptance of our <span className="underline underline-offset-4">Terms of use </span>
              and of this Privacy Policy will be deemed as your consent to the processing of your
              Personal Data for all purposes detailed in this Privacy Policy, unless the applicable
              law requires a different form of consent. If you wish to withdraw such consent, please
              contact us at{' '}
              <a
                href="mailto:privacy@betteryou.tech"
                className="text-purple-600"
              >
                privacy@betteryou.tech
              </a>
              .
              <br />
              Specifically, we collect and use Personal Data for the following purposes (and in
              reliance on the legal bases for processing noted next to them, as appropriate):
            </p>
            <PrivacyDataUsesTable data={PRIVACY_POLICY_DATA_USES} />
          </section>
          <section
            className="flex flex-col gap-6 w-full"
            id="section-3"
          >
            <h2 className="text-lg font-bold">3. Data Location </h2>
            <p>
              We and our authorized Service Providers (defined below) maintain, store and process
              Personal Data in the United States, Israel, European Union, and other locations as
              reasonably necessary for the proper performance and delivery of our Services, or as
              may be required by law.
              <br />
              BYT is headquartered in Israel, a jurisdiction which is considered by the European
              Commission, the UK Information Commissioner's Office (ICO), and the Swiss Federal Data
              Protection and Information Commissioner, to be offering an adequate level of
              protection for Personal Data of individuals residing in EU Member States, the UK and
              Switzerland, respectively. We transfer data from the EEA, the UK, and Switzerland to
              Israel on this basis.
              <br />
              While privacy laws may vary between jurisdictions, BYT and its affiliates and Service
              Providers are each committed to protecting Personal Data in accordance with this
              Privacy Policy, customary industry standards, and such appropriate lawful mechanisms
              and contractual terms requiring adequate data protection, regardless of any lesser
              legal requirements that may apply in the jurisdiction from which such data originated.
              For data transfers from the EU, the UK, or Switzerland to countries that are not
              considered to be offering an adequate level of data protection by the relevant data
              protection authority, we and the relevant data exporters and importers enter into
              Standard Contractual Clauses (“SCC”), you can obtain a copy by contacting us as
              indicated in{' '}
              <a
                href="#section-11"
                className="text-purple-600 underline underline-offset-4"
                onClick={(e) => smoothScroll(e, '#section-11')}
              >
                Section 11
              </a>{' '}
              below.
            </p>
          </section>
          <section
            className="flex flex-col gap-6 w-full"
            id="section-4"
          >
            <h2 className="text-lg font-bold">4. Data Retention </h2>
            <p>
              We may retain your Personal Data for as long as it is reasonably necessary to provide
              you with the ability to use our Services and offerings and to maintain and expand our
              relationship; to comply with our legal and contractual obligations; or to protect
              ourselves from any potential disputes (e.g. as required by laws applicable to
              log-keeping, records and bookkeeping, and in order to have proof and evidence
              concerning our relationship, should any legal issues arise following your
              discontinuance of use), all in accordance with applicable laws and regulations.
              <br />
              When using the Platform - recordings of video and audio footage will be retained until
              your account has been terminated, either by us or by you.
              <br />
              You may ask us to delete any Personal Data, including video and audio footage to the
              Platform, by sending us an email as stated in{' '}
              <a
                href="#section-9"
                className="text-purple-600 underline underline-offset-4"
                onClick={(e) => smoothScroll(e, '#section-9')}
              >
                Section 9
              </a>{' '}
              below. We may delete any data or video and audio footage from our systems, without
              notice to you, including once we deem it no longer necessary for the purposes set
              forth in this Privacy Policy. We may also retain your data in an anonymized form.
              <br />
              Please note that unless required by applicable law, we will not be obligated to retain
              your data for any particular period, and are free to securely delete it for any reason
              and at any time, with or without notice to you.
              <br />
              Please also note that we do not have access to your payment information, it is
              transferred directly to the relevant Service Provider. We only retain a token of such
              payment information (which cannot be used to reidentify it) and the chosen payment
              method, as it is required for your continuous payment for our Services.
              <br />
              If you have any questions about our data retention policy, please contact us at:{' '}
              <a
                href="mailto:privacy@betteryou.tech"
                className="text-purple-600"
              >
                privacy@betteryou.tech
              </a>
              .
            </p>
          </section>
          <section
            className="flex flex-col gap-6 w-full"
            id="section-5"
          >
            <h2 className="text-lg font-bold">5. Data Disclosure</h2>

            <p>We may disclose your Personal Data in the following instances:</p>
            <ul className="flex flex-col gap-4">
              <li>
                <strong>Service Providers</strong>: we may engage selected business partners,
                third-party companies and individuals, affiliates, subcontractor and sub-processors
                (collectively <strong>“Service Providers”</strong>) to perform services
                complementary to our own (e.g., payment processing, IT and system administration
                services, data backup, security and storage services, data analytics, marketing
                etc.). Some of the Service Providers we engage with are external AI and machine
                learning tools that help us enhance our Services. These tools help us deliver a more
                efficient and effective user experience by leveraging the latest in technological
                advancements. Please note that the use of some Personal Data by AI Service Providers
                may be governed by their own privacy policies. Patients and Therapists can obtain
                our list of AI Service Providers by contacting us as indicated in Section 10 below.
                Our Service Providers may have access to some or all of your Personal Data processed
                by us, depending on each of their specific roles and purposes in facilitating and
                enhancing our Services, and may only use it for such purposes.
              </li>
              <li>
                <strong>Sharing your Feedback or Recommendations</strong>: If you submit a public
                review or feedback, note that we may (at our discretion) store and present your
                review to other users of our Services. If you wish to remove your public review,
                please contact us at{' '}
                <a
                  href="mailto:privacy@betteryou.tech"
                  className="text-purple-600"
                >
                  privacy@betteryou.tech
                </a>
                .
              </li>
              <li>
                <strong>Protecting Rights and Safety</strong>: we may share your Personal Data with
                others if we believe in good faith that this will help protect the rights, property,
                or personal safety of BYT, any of our Patients, Therapists, or Prospects, or any
                members of the general public.
              </li>
              <li>
                <strong>Third-Party Websites and Services</strong>: Our services may include links
                to third-party websites and integrations with third-party services. Such websites
                and third-party services, and any information you process, submit, transmit, or
                otherwise use with such websites and third-party services, are beyond the scope of
                BYT's Terms of use and Privacy Policy and will therefore be governed by such
                third-party's terms and privacy practices and policies, and not by this Privacy
                Policy. We encourage you to carefully read the terms and privacy policies of such
                websites and third-party services.
              </li>
              <li>
                <strong> Legal Compliance</strong>: in exceptional circumstances, we may disclose or
                allow government and law enforcement officials access to your Personal Data, in
                response to a subpoena, search warrant or court order (or similar requirement), or
                in compliance with applicable laws and regulations, with or without notice to you.
                Such disclosure or access may occur if we believe in good faith that: (a) we are
                legally compelled to do so; (b) disclosure is appropriate in connection with efforts
                to investigate, prevent, or take action regarding actual or suspected illegal
                activity, fraud, or other wrongdoing; or (c) such disclosure is required to protect
                our legitimate business interests, including the security or integrity of our
                products and Services.
              </li>
              <li>
                <strong> BYT Subsidiaries and Affiliated Companies; Change of Control</strong>: We
                share Personal Data internally within our group companies, for the purposes
                described in this Privacy Policy. In addition, should BYT or any of its affiliates
                undergo any change in control or ownership, including by means of merger,
                acquisition, or purchase of its assets, or will be found eligible for a governmental
                grant, your Personal Data may be shared (to the extent necessary and customary) with
                the parties involved in such an event. If we believe that such an event might
                materially affect your Personal Data then stored with us, we will notify you of this
                event and the choices you may have via e-mail or prominent notice on our Services.
              </li>
              <li>
                <strong> Additional Sharing</strong>: we may share your Personal Data in additional
                manners, pursuant to your request or explicit approval, or if we are legally
                obligated to do so, or if we have successfully rendered such data non-personal,
                non-identifiable and anonymous. We may transfer, share or otherwise use non-personal
                and non-identifiable data at our sole discretion and without the need for further
                approval.
                <br /> When we disclose Personal Data for a purpose listed above, we enter into a
                contract that describes the purpose and requires the recipient to both keep that
                Personal Data confidential and not use it for any purpose except performing the
                contract.
              </li>
            </ul>
          </section>
          <section
            className="flex flex-col gap-6 w-full"
            id="section-6"
          >
            <h2 className="text-lg font-bold">6. Cookies and Tracking Technologies</h2>
            <p>
              Our Services utilize “cookies”, anonymous identifiers, pixels, container tags and
              other technologies in order to provide and monitor our Services, to ensure that they
              perform properly, to analyze our performance and marketing activities, and to
              personalize your experience. Such cookies and similar files or tags may also be
              temporarily placed on your device. Certain cookies and other technologies serve to
              recall Personal Data, such as an IP address, as indicated by you.
              <br />
              Please note that we do not change our practices in response to a “Do Not Track” signal
              in the HTTP header from a browser or mobile application, however, most browsers allow
              you to control cookies, including whether or not to accept them and how to remove
              them. You may set most browsers to notify you if you receive a cookie, or to block or
              remove cookies altogether.
            </p>
            <p>
              To learn more about our practices concerning cookies and online tracking, please visit
              our <span className="underline underline-offset-4">Cookie Policy</span>.
            </p>
          </section>
          <section
            className="flex flex-col gap-6 w-full "
            id="section-7"
          >
            <h2 className="text-lg font-bold">7. Communications</h2>

            <p>
              We engage in service and promotional communications, through social media, e-mail,
              phone, surveys, SMS and notifications.
            </p>
            <ul className="flex flex-col gap-4">
              <li>
                <strong>Service Communications:</strong> we may contact you with important
                information regarding our Services. For example, we may send you notifications to
                inform you of changes or updates to our Services, billing issues, etc. You can
                control your communications and notifications in accordance with the instructions
                included in the communications sent to you. Please note that you will not be able to
                opt-out of receiving certain service communications which are integral to your use
                of the Services.
              </li>
              <li>
                <strong>Notifications and Promotional Communications:</strong> we may send you
                messages and notifications about new features, surveys, offerings, events and
                special opportunities, and any other information we think you will find valuable. We
                may provide such notices through any of the contact means available to us (e.g.,
                SMS, mobile notifications or e-mail), through the Services, or through our marketing
                campaigns on any other sites or platforms. Furthermore, we may send you promotions
                and marketing materials about our other services and products.
              </li>
            </ul>
            <p>
              If you wish not to receive such promotional communications via SMS, notifications or
              e-mail, you may notify BYT at any time by e-mailing us at{' '}
              <a
                href="mailto:privacy@betteryou.tech"
                className="text-purple-600 underline underline-offset-4"
              >
                privacy@betteryou.tech
              </a>{' '}
              or by following the "unsubscribe", "stop" or "change e-mail preferences" instructions
              contained in the promotional communications you receive.
            </p>
          </section>
          <section
            className="flex flex-col gap-6 w-full"
            id="section-8"
          >
            <h2 className="text-lg font-bold">8. Data Security</h2>
            <p>
              In order to protect your Personal Data held with us and our Service Providers, We and
              our Service Providers implement systems, applications and procedures to secure your
              Personal Data, to minimize the risks of theft, damage, loss of information, or
              unauthorized access or use of information. These measures provide sound
              industry-standard security, however, please be aware that regardless of any security
              measures used, we cannot and do not guarantee the absolute protection and security of
              any Personal Data stored with us or with any third parties.
            </p>
          </section>
          <section
            className="flex flex-col gap-6 w-full"
            id="section-9"
          >
            <h2 className="text-lg font-bold">9. Data Subject Rights</h2>
            <p>
              Under certain laws, including the EU or UK General Data Protection Regulation (GDPR)
              and the Israeli Protection of Privacy Law, individuals have rights regarding their
              Personal Data. These rights include – each to the extent applicable to you – the right
              to request information about or request access to your Personal Data, or to request
              its correction, portability or erasure. You may also have the right to restrict or
              object to the processing of your Personal Data. Under some regulatory frameworks you
              may also have the right to lodge a complaint with the relevant supervisory authority.
              <br />
              We will not charge a fee to process or respond to your verifiable privacy request
              unless it is excessive, repetitive, or manifestly unfounded. If we determine that the
              request warrants a fee, we will tell you why we made that decision and provide you
              with a cost estimate before completing your request. Alternatively, we may refuse to
              comply with your request in such circumstances. To the extent applicable to you, you
              may also designate an authorized agent, in writing or through a power of attorney, to
              request to exercise your privacy rights on your behalf. The authorized agent may
              submit a request to exercise these rights by emailing us.
              <br />
              Please note that when you or an authorized agent ask us to exercise any of your rights
              under this policy or applicable law, we may need to ask you to provide us with certain
              credentials to make sure that you are who you claim you are, to avoid disclosure to
              you of Personal Data related to others and to ask you to provide further information
              to better understand the nature and scope of data regarding which you request to
              exercise your rights. Such additional data may be then retained by us for legal,
              compliance and auditing purposes (e.g., as proof of the identity of the person
              submitting the request or proof of request fulfillment). We will not fulfill your
              request unless you have provided sufficient information that enables us to reasonably
              verify that you are the individual about whom we collected the Personal Data.
              <br />
              We may redact from the data that we will make available to the requesting data subject
              any Personal Data related to others. Please also note that if you request deletion of
              your Personal Data, we may deny your request or may retain certain elements of your
              Personal Data if it is necessary for us or our Service Providers. We will provide
              details of our reasoning to you in our correspondence on the matter.
              <br />
              Each right may be executed to the extent available to you under the laws which apply
              to you and is subject to various exclusions and exceptions under applicable laws.
              <br />
              Please contact us by e-mail at:{' '}
              <a
                href="mailto:privacy@betteryou.tech"
                className="text-purple-600 underline underline-offset-4"
              >
                privacy@betteryou.tech
              </a>{' '}
              if you wish to exercise your privacy rights.
            </p>
          </section>
          <section
            className="flex flex-col gap-6 w-full"
            id="section-10"
          >
            <h2 className="text-lg font-bold">10. Additional Information and Contact Details</h2>
            <ul className="flex flex-col gap-4">
              <li>
                <strong>Updates and amendments</strong>: we may update and amend this Privacy Policy
                from time to time by posting an amended version on our Services. The amended version
                will be effective as of the date it is published. We will provide prior notice if we
                believe any substantial changes are involved via any of the communication means
                available to us or via Services. After such notice period, all amendments shall be
                deemed accepted by you.
              </li>
              <li>
                <strong> External links</strong>: while our Services may contain links to other
                websites or services, we are not responsible for their privacy practices, and
                encourage you to pay attention and to read the privacy policies of each and every
                website and service you visit. This Privacy Policy applies only to our Services.
              </li>
              <li>
                <strong>
                  Our Services are not designed to attract children under the age of 16
                </strong>
                : we do not knowingly collect Personal Data from children and do not wish to do so.
                If we learn that a child is using the Services, we will attempt to prohibit and
                block such use and to promptly delete any Personal Data stored with us which we deem
                to relate to such a child. If you believe that we might have any such data, please
                contact us at{' '}
                <a
                  href="mailto:privacy@betteryou.tech"
                  className="text-purple-600 underline underline-offset-4"
                >
                  privacy@betteryou.tech
                </a>{' '}
                .
              </li>
              <li>
                <strong> Questions, Concerns or Complaints</strong>: if you have any comments or
                questions regarding our Privacy Policy, or if you have any concerns regarding your
                Personal Data held with us, or if you wish to make a complaint about how your
                Personal Data is being processed by BYT, you can contact us at{' '}
                <a
                  href="mailto:privacy@betteryou.tech"
                  className="text-purple-600 underline underline-offset-4"
                >
                  privacy@betteryou.tech
                </a>{' '}
                .
              </li>
            </ul>
          </section>
          <p>Last updated: November 2024</p>
        </div>
      </div>
    </div>
  );
};
