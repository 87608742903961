import FFT from 'fft.js';

type Heartbeat = {
  start_location_sec: number;
  end_location_sec: number;
  duration_ms: number;
};

const nextPowerOfTwo = (n: number) => Math.pow(2, Math.ceil(Math.log2(n)));

export const calculateFrequencyDomainMetrics = (
  heartbeatData: Heartbeat[],
  windowSec: number = 30,
  stepSec: number = 1, // Step size for sliding window
  resampleRate: number = 4
) => {
  if (heartbeatData.length < 2) return null;

  const ibiTimes = heartbeatData.map((hb) => hb.start_location_sec);
  const ibiDurations = ibiTimes.slice(1).map((time, idx) => time - ibiTimes[idx]);

  const results = [];

  // Define the start and end times for the sliding window
  let startTime = ibiTimes[0];
  const endTime = ibiTimes[ibiTimes.length - 1];

  while (startTime + windowSec <= endTime) {
    const endWindowTime = startTime + windowSec;

    // Extract the IBIs within the current window
    const windowIndices = ibiTimes
      .map((time, idx) => ({ time, idx }))
      .filter(({ time }) => time >= startTime && time < endWindowTime)
      .map(({ idx }) => idx);

    const windowIBIs = windowIndices.map((idx) => ibiDurations[idx]);

    const afterInterpolation = interpolateRR(windowIBIs, resampleRate);

    const mean =
      afterInterpolation.tachogram.reduce((sum, val) => sum + val, 0) /
      afterInterpolation.tachogram.length;
    const detrendedIBI = afterInterpolation.tachogram.map((val) => val - mean);

    const { frequencies, power } = welch(detrendedIBI, resampleRate);

    const vlfBand = (freq: number) => freq >= 0.003 && freq < 0.04;
    const lfBand = (freq: number) => freq >= 0.04 && freq < 0.15;
    const hfBand = (freq: number) => freq >= 0.15 && freq <= 0.4;

    const vlfPower = calculateBandPower(frequencies, power, vlfBand);
    const lfPower = calculateBandPower(frequencies, power, lfBand);
    const hfPower = calculateBandPower(frequencies, power, hfBand);

    const totalPower = vlfPower + lfPower + hfPower;

    const vlfPercentage = totalPower > 0 ? (vlfPower / totalPower) * 100 : 0;
    const lfPercentage = totalPower > 0 ? (lfPower / totalPower) * 100 : 0;
    const hfPercentage = totalPower > 0 ? (hfPower / totalPower) * 100 : 0;

    const lfHfRatio = hfPower > 0 ? lfPower / hfPower : NaN;

    results.push({
      startTime: startTime,
      endTime: endWindowTime,
      vlfPower: vlfPower,
      lfPower: lfPower,
      hfPower: hfPower,
      totalPower: totalPower,
      vlfPercentage: vlfPercentage,
      lfPercentage: lfPercentage,
      hfPercentage: hfPercentage,
      lfhfRatio: lfHfRatio,
      frequencies,
      power,
    });

    startTime += stepSec;
  }

  return results;
};

function interpolateRR(rrIntervals: any, resampleRate = 4) {
  if (
    !Array.isArray(rrIntervals) ||
    rrIntervals.length === 0 ||
    rrIntervals.some((v) => v <= 0 || isNaN(v))
  ) {
    console.warn('Invalid rrIntervals: returning empty tachogram.');
    return { tachogram: new Float32Array(0), sampleRate: resampleRate };
  }

  const t = new Array(rrIntervals.length + 1);
  t[0] = 0;
  for (let i = 1; i < t.length; i++) {
    t[i] = t[i - 1] + rrIntervals[i - 1];
  }

  const totalTime = t[t.length - 1] ?? 0;
  if (totalTime <= 0 || isNaN(totalTime)) {
    console.warn(`Invalid totalTime (${totalTime}): returning empty tachogram.`);
    return { tachogram: new Float32Array(0), sampleRate: resampleRate };
  }

  const dt = 1 / resampleRate;
  const N = Math.max(0, Math.floor(totalTime * resampleRate));

  if (N === 0) {
    console.warn('Computed N is 0: returning empty tachogram.');
    return { tachogram: new Float32Array(0), sampleRate: resampleRate };
  }

  const uniformT = new Float32Array(N);
  for (let i = 0; i < N; i++) {
    uniformT[i] = i * dt;
  }

  const tachogram = new Float32Array(N);
  let idx = 0;

  for (let i = 0; i < N; i++) {
    const currentTime = uniformT[i];

    while (idx < t.length - 1 && !(currentTime >= t[idx] && currentTime < t[idx + 1])) {
      idx++;
    }

    if (idx >= t.length - 1) {
      tachogram[i] = rrIntervals[rrIntervals.length - 1];
      continue;
    }

    const rr1 = rrIntervals[idx];
    const rr2 = rrIntervals[idx + 1] ? rrIntervals[idx + 1] : rr1;

    const t1 = t[idx];
    const t2 = t[idx + 1];
    const ratio = t2 - t1 > 0 ? (currentTime - t1) / (t2 - t1) : 0;

    tachogram[i] = rr1 + ratio * (rr2 - rr1);
  }

  return { tachogram, sampleRate: resampleRate };
}

// Welch's method implementation
function welch(data: any, fs: number, fftSize?: number) {
  fftSize = fftSize ?? nextPowerOfTwo(Math.max(data.length, 512));

  if (data.length === 0 || data.every((v: number) => isNaN(v))) {
    // console.error('Welch method received empty or invalid data.');
    return { frequencies: [], power: [] };
  }

  const fft = new FFT(fftSize);
  const input = new Array(fftSize).fill(0);
  const output = new Array(fftSize).fill(0);

  // // Apply Hann window
  const hannWindow = Array.from(
    { length: fftSize },
    (_, i) => 0.5 - 0.5 * Math.cos((2 * Math.PI * i) / (fftSize - 1))
  );
  const windowedData = data.map((value: number, index: number) => value * (hannWindow[index] || 0));

  if (windowedData.every((v: number) => v === 0 || isNaN(v))) {
    // console.error('All windowed data values are zero or NaN. Skipping FFT.');
    return { frequencies: [], power: [] };
  }

  input.splice(0, windowedData.length, ...windowedData);

  fft.realTransform(output, input);
  fft.completeSpectrum(output);

  const psd = output.slice(0, fftSize / 2).map((_val, idx) => {
    const real = output[idx * 2];
    const imag = output[idx * 2 + 1];
    return (real * real + imag * imag) / (fs * fftSize); // Normalize by sample rate & FFT size
  });

  const frequencies = Array.from({ length: fftSize / 2 }, (_, i) => i * (fs / fftSize));

  return { frequencies, power: psd };
}

// Band Power Calculation
function calculateBandPower(
  frequencies: number[],
  power: number[],
  bandFilter: (freq: number) => boolean
): number {
  let totalPower = 0;
  for (let i = 0; i < frequencies.length; i++) {
    if (bandFilter(frequencies[i])) {
      totalPower += power[i];
    }
  }
  return totalPower;
}

/// For testing:

function generateTestHeartbeatData(
  durationSec: number = 600,
  baseHR: number = 60,
  lfFreq: number = 0.1, // LF frequency modulation (0.1 Hz)
  hfFreq: number = 0.25, // HF frequency modulation (0.25 Hz)
  resampleRate: number = 4
): Heartbeat[] {
  const ibiBase = 60 / baseHR; // Convert BPM to IBI (sec)

  const sampleRate = resampleRate; // Sampling rate (Hz)
  const timeSteps = Array.from({ length: durationSec * sampleRate }, (_, i) => i / sampleRate);

  // Generate sinusoidal IBI variations
  const ibiValues = timeSteps.map(
    (t) =>
      ibiBase +
      0.1 * Math.sin(2 * Math.PI * lfFreq * t) + // LF modulation (0.1 Hz)
      0.05 * Math.sin(2 * Math.PI * hfFreq * t) // HF modulation (0.25 Hz)
  );

  // Convert to Heartbeat objects with all required properties
  const heartbeats: Heartbeat[] = ibiValues.map((ibi, index) => {
    const start_location_sec = index === 0 ? 0 : timeSteps[index - 1] + ibi;
    const end_location_sec = timeSteps[index] + ibi;
    const duration_ms = end_location_sec - start_location_sec; // in sec

    return {
      start_location_sec,
      end_location_sec,
      duration_ms,
    };
  });

  return heartbeats;
}

export const testHeartbeatData = generateTestHeartbeatData();
