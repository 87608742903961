import { memo, useState } from 'react';

import { MeasurementBlock } from '.';
import { cardiacStressThreshold } from '@shared/utils/shenai/cardiacThreshold';
import { CARDIAC_STRESS_BG_COLOR_MAP } from '@components/AISession/model/constants/cardiacStressColorMap';
import PulseValue from './PulseValue';
import { dashboardActor } from '@pages/shared/realtime-dashboard/model/xstate/dashboardMachine';
import { useSelector, shallowEqual } from '@xstate/react';
import { Eye, EyeOff } from 'lucide-react';

type AISimplifiedPlotBlockProps = {};

const MeasurementGroupSection = ({}: AISimplifiedPlotBlockProps) => {
  const [isHRCompleted, setIsHRCompleted] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [isHidden, setIsHidden] = useState(false);
  const sdkData = useSelector(dashboardActor, (state) => state.context.sdkData, shallowEqual);
  const cardiacStressLevelIndex = cardiacStressThreshold(sdkData?.cardiacStressRT);

  const conditionalColorSet = cardiacStressLevelIndex
    ? {
        bgColor:
          CARDIAC_STRESS_BG_COLOR_MAP[
            cardiacStressLevelIndex as keyof typeof CARDIAC_STRESS_BG_COLOR_MAP
          ] || '',
        textColor: '#fafafa',
        plotColor: '#fafafa',
      }
    : undefined;

  const handleToggleVisibility = () => {
    setIsHidden((prev) => !prev);
  };

  return (
    <div className="flex ml-12">
      <div className={`flex min-w-[288px] max-w-[400px]`}>
        <div className={`${isHidden ? 'hidden' : 'flex bg-bg-gray rounded-lg gap-4 p-2'}`}>
          <MeasurementBlock
            plotKey="hr10-ai"
            label="PULSE"
            value={<PulseValue sdkData={sdkData} />}
            timeInSeconds={10}
            isCompleted={isHRCompleted}
            setCompleted={setIsHRCompleted}
            sdkData={sdkData}
            measurementValue={sdkData?.hr10s}
          />
          <MeasurementBlock
            plotKey="hrv-ai"
            label="HRV"
            value={sdkData?.hrvSDNN && <span className="text-nowrap">{sdkData.hrvSDNN} ms</span>}
            timeInSeconds={40}
            isCompleted={isCompleted}
            setCompleted={setIsCompleted}
            sdkData={sdkData}
            measurementValue={sdkData?.hrvSDNN}
          />
          <MeasurementBlock
            plotKey="cardiac-stress-ai"
            label="CARDIAC STRESS"
            value={sdkData?.cardiacStressRT ?? null}
            timeInSeconds={40}
            isCompleted={isCompleted}
            setCompleted={setIsCompleted}
            sdkData={sdkData}
            conditionalColorSet={conditionalColorSet}
            measurementValue={sdkData?.cardiacStressRT}
          />
        </div>
      </div>
      <div
        onClick={handleToggleVisibility}
        className="w-auto text-purple-600 ml-2 cursor-pointer"
      >
        {isHidden ? <Eye /> : <EyeOff />}
      </div>
    </div>
  );
};

const MemoMeasurementGroupSection = memo(MeasurementGroupSection);
MemoMeasurementGroupSection.displayName = 'MeasurementGroupSection';

export default MemoMeasurementGroupSection;
