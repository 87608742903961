import { useState, useEffect } from 'react';

interface SwitchProps {
  label: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  containerClasses?: string;
  labelClasses?: string;
  disabled?: boolean;
  switchColor?: string;
}

const Switch = ({
  label,
  checked = false,
  onChange,
  containerClasses,
  labelClasses,
  disabled = false,
  switchColor = 'bg-purple-600',
}: SwitchProps) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleToggle = () => {
    if (disabled) return;

    const newChecked = !isChecked;
    setIsChecked(newChecked);
    if (onChange) {
      onChange(newChecked);
    }
  };

  return (
    <div
      className={`flex items-center ${containerClasses} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
    >
      <label className={`mr-2 text-gray-700 ${labelClasses}`}>{label}</label>
      <div
        className={`relative w-9 h-5 flex items-center rounded-full p-1 cursor-pointer transition-colors ${
          isChecked ? switchColor : 'bg-gray-300'
        } ${disabled ? 'pointer-events-none' : ''}`}
        onClick={handleToggle}
      >
        <div
          className={`bg-white w-4 h-4 rounded-full shadow-md transform transition-transform ${
            isChecked ? 'translate-x-3' : 'translate-x-0'
          }`}
        />
      </div>
    </div>
  );
};

export default Switch;
