import Button from '@components/Button';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { useSelector } from '@xstate/react';

export const TherapistGreetingPage = () => {
  const snapshot = useSelector(refaelaTherapistActor, (state) => state);

  const userName = snapshot?.context?.currentUser?.name;

  const patientId =
    (snapshot?.context?.patientSegment?.patientList &&
      snapshot?.context?.patientSegment?.patientList[0]?.patientId) ||
    'user_2kYbZRnGSfAp0bRNAZWwwlfRCsw';

  return (
    <div className="h-[80%] flex justify-center flex-col gap-4">
      <p className="text-4xl font-bold">{`Hello, ${userName ? userName : 'Anonymous'}!`}</p>
      <p>Let's set up your first session on the platform</p>
      <div className="space-y-4">
        <Button
          className="w-fit mx-auto"
          text="Start Your First Session"
          onClick={() =>
            refaelaTherapistActor.send({
              type: 'goToTherapistPretalkWaitingRoom',
              patientId: patientId,
            })
          }
        />
        <Button
          onClick={() =>
            refaelaTherapistActor.send({
              type: 'skipFirstSession',
              isFirstSessionSkipped: true,
            })
          }
          className="w-fit mx-auto"
          variant="plain"
          text="Skip for now"
        />
      </div>
    </div>
  );
};
