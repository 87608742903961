import { ShamefulAny } from '@interfaces/index';
import { apiClient } from '.';

import { KeyMomentsData, Transcript } from '@interfaces/keyMoments';

export async function updateUserStatus(id: string, status: string): Promise<any> {
  try {
    const { data } = await apiClient.patch('/patient/updateUserStatus', {
      id: id,
      status: status,
    });

    return data;
  } catch (e: any) {
    return e.message;
  }
}

export async function getUserMetadata(id: string): Promise<any> {
  try {
    const { data } = await apiClient.get(`/user/get_user_metadata/${id}`);
    return data;
  } catch (e: any) {
    console.error(`Error fetching user metadata for ID: ${id}`, e.message, e.details);

    return e.message;
  }
}

export async function getUserData(id: string): Promise<any> {
  try {
    const { data } = await apiClient.get(`/user/${id}`);

    return data;
  } catch (e: any) {
    console.error(`Error fetching user data for ID: ${id}`, e.message, e.details);

    return e.message;
  }
}

export async function createDailySession(therapistId: string, patientId: string): Promise<any> {
  try {
    const { data } = await apiClient.post('/daily/createSession', {
      therapistId: therapistId,
      patientId: patientId,
    });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function createAISession(
  userId: string,
  sessionType: string,
  sessionSettings: any,
  sessionPrompt: string
): Promise<any> {
  try {
    const { data } = await apiClient.post('/daily/createAISession', {
      userId,
      sessionType,
      sessionSettings,
      sessionPrompt,
    });

    const roomUrl = data.roomUrl;
    const token = data.meetingToken;
    const sessionId = data.sessionId;

    return { roomUrl, token, sessionId };
  } catch (e) {
    (e as any).sentryHandled = true;
    throw e;
  }
}

export async function getAISession(userId: string): Promise<any> {
  try {
    const { data } = await apiClient.get('/daily/getAISession', {
      params: {
        userId,
      },
    });

    const roomUrl = data.roomUrl;
    const token = data.meetingToken;
    const prompt = data.prompt;

    return { roomUrl, token, prompt };
  } catch (e) {
    throw e;
  }
}

export async function startDailySession(roomUrl: string): Promise<string> {
  try {
    const { data } = await apiClient.post('/daily/startSession', {
      roomUrl: roomUrl,
    });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function startAISession(
  roomUrl: string,
  userId: string,
  assignAISessionType?: string
): Promise<string> {
  console.log('assignAISessionType  userAPI', assignAISessionType);

  try {
    const { data } = await apiClient.post('/daily/startAISession', {
      roomUrl,
      userId,
      assignAISessionType,
    });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function getDailySession(roomUrl: string): Promise<string> {
  try {
    const { data } = await apiClient.post(`/daily/sessions/${roomUrl}`, {
      roomUrl: roomUrl,
    });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function endDailySession(
  patientId: string,
  therapistId: string,
  roomUrl: string,
  keyMoments: any
): Promise<string> {
  try {
    const { data } = await apiClient.post('/daily/endSession', {
      roomUrl: roomUrl,
      patientId: patientId,
      therapistId: therapistId,
      keyMoments: keyMoments,
    });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function endAISession(
  userId: string,
  roomUrl: string,
  userRole: string,
  sessionSettings: any
): Promise<string> {
  try {
    const { data } = await apiClient.post('/daily/endAISession', {
      userId,
      roomUrl,
      userRole,
      sessionSettings,
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      console.error(`API Error: ${error.response.status} - ${error.response.data.message}`);
    } else {
      console.error(`Request Failed: ${error.message}`);
    }

    throw error;
  }
}

export async function saveTranscriptionId(
  transcriptionId: string,
  sessionId: string
): Promise<string> {
  try {
    const { data } = await apiClient.post('/daily/saveTranscriptionId', {
      transcriptionId: transcriptionId,
      sessionId: sessionId,
    });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function sendUserInput(
  userId: string,
  userInput: string,
  sessionId: string,
  transcriptionId: string
): Promise<string> {
  try {
    const { data } = await apiClient.post('/daily/sendUserInput', {
      userId,
      userInput,
      sessionId,
      transcriptionId,
    });

    return data;
  } catch (e: any) {
    throw e;
  }
}

export async function assignAISession(
  patientId: string,
  type: string,
  count: number,
  sessionUrl: string | null = null,
  sessionId: string
): Promise<string> {
  try {
    const { data } = await apiClient.post('/daily/assignAISession', {
      patientId,
      type,
      count,
      sessionUrl,
      sessionId,
    });
    return data;
  } catch (e: any) {
    throw e;
  }
}

export async function saveMeetingRecording(
  sessionId: string,
  patientId: string,
  therapistId: string,
  downloadLink: string,
  role: string
): Promise<string> {
  try {
    const { data } = await apiClient.post('/video_session/saveRecording', {
      sessionId: sessionId,
      link: downloadLink,
      patientId: patientId,
      therapistId: therapistId,
      role: role,
    });

    return data;
  } catch (e: any) {
    throw e;
  }
}

export async function getMeetingRecordingLink(
  sessionId: string,
  patientId: string,
  therapistId: string,
  role: string
): Promise<any> {
  try {
    const { data } = await apiClient.get('/video_session/get_recording_link', {
      params: {
        sessionId,
        patientId,
        therapistId,
        role,
      },
    });

    return data.recording;
  } catch (error: any) {
    console.error('Error retrieving meeting recording link:', error.message, error.details);
  }
}

export async function getVideoSessionSummary(
  patientId: string,
  therapistId: string,
  sessionId: string,
  updatedKeyMomentsData: KeyMomentsData,
  sessionTranscripts: Transcript[],
  setIsLoading: (loading: boolean) => void
): Promise<string> {
  try {
    setIsLoading(true);

    const requestBody = {
      patientId,
      therapistId,
      sessionId,
      updatedKeyMomentsData,
      sessionTranscripts,
    };

    const { data } = await apiClient.post('/video_session/summary_generate', requestBody);

    return data.summary;
  } catch (e: any) {
    console.error('Error generating video session summary:', e.message, e.details);

    return `${e.message}: ${e.details}`;
  } finally {
    setIsLoading(false);
  }
}

export async function saveVideoSessionNotes(
  patientId: string,
  therapistId: string,
  sessionId: string,
  sessionNotes: string
): Promise<string> {
  try {
    const requestBody = {
      patientId,
      therapistId,
      sessionId,
      sessionNotes,
    };

    const { data } = await apiClient.post('/video_session/notes', requestBody);

    return data.summary;
  } catch (e: any) {
    return e.response?.data?.message || 'An error occurred';
  }
}

export async function saveVideoSessionSummary(
  patientId: string,
  therapistId: string,
  sessionId: string,
  sessionSummary: string
): Promise<string> {
  try {
    const requestBody = {
      patientId,
      therapistId,
      sessionId,
      sessionSummary,
    };

    const { data } = await apiClient.post('/video_session/summary_save', requestBody);

    return data.summary;
  } catch (e: any) {
    return e.response?.data?.message || 'An error occurred';
  }
}

export async function getUpdatedKeyMoments(
  patientId: string,
  therapistId: string,
  sessionId: string
): Promise<KeyMomentsData> {
  try {
    const { data } = await apiClient.get('/key_moments/get_updated_key_moments', {
      params: { patientId, therapistId, sessionId },
    });

    return data;
  } catch (e: any) {
    console.error('Error fetching updated key moments:', {
      message: e.message,
      response: e.response?.data || e,
      status: e.response?.status,
    });
    console.error('Error fetching updated key moments:', e.message, e.details);
    return {
      keyMoments: [],
      recordingStartTime: '',
      recordingStopTime: '',
      error: 'An error occurred while fetching updated key moments',
    };
  }
}

export async function getVideoSessionSummaryContent(
  patientId: string,
  therapistId: string,
  sessionId: string
): Promise<string> {
  try {
    const { data } = await apiClient.get('/video_session/get_summary', {
      params: { patientId, therapistId, sessionId },
    });

    return data.summary;
  } catch (e: any) {
    const { message, details } = e;

    console.error('Error fetching video session summary:', message, details);

    return message || 'An error occurred while fetching the video session summary.';
  }
}

export async function getVideoSessionNotes(
  patientId: string,
  therapistId: string,
  sessionId: string
): Promise<string> {
  try {
    const { data } = await apiClient.get('/video_session/get_notes', {
      params: { patientId, therapistId, sessionId },
    });

    return data.notes;
  } catch (e: any) {
    const { message, details } = e;

    console.error('Error fetching video session notes:', message, details);

    return message || 'An error occurred while fetching the video session notes.';
  }
}

export async function generateSessionLink(
  user_id: string,
  sessionType: string,
  sessionsCount: number
): Promise<any> {
  try {
    const { data } = await apiClient.post('/patient/generateSessionLink', {
      user_id,
      sessionType,
      sessionsCount,
    });

    return data.sessionLink;
  } catch (e: any) {
    return e.message;
  }
}

export async function getAuthToken(id: string): Promise<any> {
  try {
    const { data } = await apiClient.post('/patient/getAuthToken', {
      user_id: id,
    });

    return data.message;
  } catch (e: any) {
    return e.message;
  }
}

export async function cleanAuthToken(id: string): Promise<any> {
  try {
    const { data } = await apiClient.post('/patient/cleanAuthToken', {
      user_id: id,
    });

    return data.message;
  } catch (e: any) {
    return e.message;
  }
}

export const saveKeyMoments = async (
  patientId: string,
  therapistId: string,
  sessionId: string,
  keyMoments: any[],
  recordingStartTime: number,
  recordingStopTime: number
) => {
  try {
    const response = await apiClient.post('/key_moments/save', {
      patientId: patientId,
      therapistId: therapistId,
      sessionId: sessionId,
      keyMoments: [keyMoments],
      recordingStartTime,
      recordingStopTime,
    });

    console.log('Key moments saved successfully:', response.data);
  } catch (error: any) {
    console.error(
      'Error saving key moments:',
      error.response ? error.response.data : error.message
    );
  }
};

export const saveUpdatedKeyMoments = async (
  updatedKeyMomentsData: KeyMomentsData,
  patientId: string,
  therapistId: string,
  sessionId: string
) => {
  try {
    const response = await apiClient.put('/key_moments/update', {
      patientId,
      therapistId,
      sessionId,
      updatedKeyMomentsData,
    });

    console.log('Updated Key moments saved successfully:', response.data);
  } catch (error: any) {
    console.error(
      'Error saving updated key moments:',
      error.response ? error.response.data : error.message
    );
  }
};

export const getKeyMoments = async (patientId: string, sessionId: string) => {
  try {
    const { data } = await apiClient.get('/key_moments/get_key_moments', {
      params: {
        patientId: patientId,
        sessionId: sessionId,
      },
    });
    return data;
  } catch (error: any) {
    console.error(
      'Error fetching key moments:',
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const generateShortUrl = async (originalUrl: string): Promise<string> => {
  try {
    const shortLinkResponse = await apiClient.post('/shorten', { originalUrl });

    const { shortUrl } = shortLinkResponse.data;
    return shortUrl;
  } catch (error: ShamefulAny) {
    console.error('Error generating short URL:', error.message);
    throw error;
  }
};

export async function fetchFileContent(
  sessionId: string,
  patientId: string,
  therapistId: string,
  fileName: string,
  userRole: string
): Promise<any> {
  try {
    const data = await apiClient.get('/files/file-content', {
      params: {
        sessionId,
        patientId,
        therapistId,
        fileName,
        userRole,
      },
    });

    return data;
  } catch (e: any) {
    e.sentryHandled = true;
    const { message, details } = e;
    console.error('Error fetching file content:', message, details);

    return message || 'An error occurred while fetching file content.';
  }
}

export async function fetchAiSessionSettings(sessionId: string): Promise<ShamefulAny> {
  try {
    const response = await apiClient.post('/daily/get-ai-session-settings', {
      sessionId,
    });
    return response.data;
  } catch (e: ShamefulAny) {
    e.sentryHandled = true;
    console.error('Error fetching file content:', e.message || e);
  }
}

export async function fetchAiSessionFileContent(
  sessionId: string,
  userId: string,
  fileName: string
): Promise<ShamefulAny> {
  try {
    const { data: sessionSettings } = await apiClient.get('/files/file-content/ai-file', {
      params: { sessionId, userId, fileName },
    });
    return sessionSettings;
  } catch (e: ShamefulAny) {
    e.sentryHandled = true;
    console.error('Error fetching file content:', e.message || e);
  }
}

export const askChatGPT = async (
  userId: string,
  sessionId: string,
  data: any,
  agentName: string
): Promise<any> => {
  try {
    const { data: gptResponse } = await apiClient.post('/llm', {
      userId,
      sessionId,
      data,
      agentName,
    });

    const result = gptResponse.result;

    return { result };
  } catch (e: ShamefulAny) {
    e.sentryHandled = true;
    console.error('Error asking llm:', e.message || JSON.stringify(e));
  }
};

export const requestAudioTranscription = async (
  sessionId: string,
  locale: string
): Promise<any> => {
  try {
    await apiClient.post('/daily/request-audio-transcription', {
      sessionId,
      locale,
    });
  } catch (e: ShamefulAny) {
    e.sentryHandled = true;
    console.error('Error asking llm:', e.message || JSON.stringify(e));
  }
};

export const checkAudioTranscription = async (sessionId: string): Promise<any> => {
  try {
    await apiClient.post('/daily/check-audio-transcription', {
      sessionId,
    });
  } catch (e: ShamefulAny) {
    e.sentryHandled = true;
    console.error('Error asking llm:', e.message || JSON.stringify(e));
  }
};

export const saveApprovedTranscription = async (data: any, sessionId: string): Promise<any> => {
  try {
    const { data: result } = await apiClient.post('/daily/save-approved-transcription', {
      data,
      sessionId,
    });

    return result;
  } catch (e: ShamefulAny) {
    e.sentryHandled = true;
    console.error('Error asking llm:', e.message || JSON.stringify(e));
  }
};
