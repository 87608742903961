import { useCallback, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import { Socket } from 'socket.io-client';
import { dashboardActor } from '../xstate/dashboardMachine';
// Types
import { SessionSettings } from '@shared/types/dashboard/sessionSettings';
import { ShamefulAny } from '@interfaces/index';
import { RealtimeEventState } from '@shared/types/events/realtimeEvents';
// Utils
import { transformAudioData } from '../utils/audio';

let chunkTimeout: NodeJS.Timeout;
let completedAudioTimeout: NodeJS.Timeout;

export const useVoiceBotEvents = (
  voiceBotInstance: any,
  wavStreamPlayer: any,
  handleStateChanged: any,
  agentStateBuffer: any,
  throttleUpdateAgentState: any,
  realtimeEventsBuffer: any,
  throttleUpdateRealtimeEvents: any,
  userId: string,
  sessionId: string,
  socket: Socket | null
) => {
  const worker = useRef(
    new Worker(new URL('../workers/audioTransform.ts', import.meta.url), { type: 'module' })
  );

  const handleUpdateSessionSettings = useCallback(
    ({ key, value }: { key: keyof SessionSettings; value: string | number }) => {
      dashboardActor.send({ type: 'SESSION_SETTINGS_UPDATE', data: { key, value } });
    },
    []
  );

  const handleError = useCallback((error: any) => {
    error.sentryHandled = true;
    console.error('VoiceBot instance error:', JSON.stringify(error));
  }, []);

  const handleRealtimeEvent = useCallback(
    debounce((realtimeEvent: RealtimeEventState) => {
      if (
        realtimeEvent.event.type === 'session.updated' ||
        realtimeEvent.event.type === 'session.created'
      ) {
        const { temperature, voice, maxTokens, instructions } = realtimeEvent.event.session || {};
        if (instructions) handleUpdateSessionSettings({ key: 'instructions', value: instructions });
        if (temperature) handleUpdateSessionSettings({ key: 'temperature', value: temperature });
        if (voice) handleUpdateSessionSettings({ key: 'voice', value: voice });
        if (maxTokens) handleUpdateSessionSettings({ key: 'maxTokens', value: maxTokens });
      }

      if (realtimeEvent.event?.type === 'state.updated') {
        agentStateBuffer.current.push(realtimeEvent.event?.data);
        // TODO: update agent state
        throttleUpdateAgentState();
        handleStateChanged(realtimeEvent);
      }

      const lastEvent = realtimeEventsBuffer.current[realtimeEventsBuffer.current.length - 1];
      if (lastEvent?.event.type === realtimeEvent.event.type) {
        lastEvent.count = (lastEvent.count || 0) + 1;
        realtimeEventsBuffer.current = [...realtimeEventsBuffer.current.slice(0, -1), lastEvent];
      } else {
        realtimeEventsBuffer.current.push({
          ...realtimeEvent,
          role: wavStreamPlayer.stream ? 'assistant' : null,
        });
      }
      throttleUpdateRealtimeEvents(wavStreamPlayer);
    }, 1000),
    [handleStateChanged, throttleUpdateAgentState, throttleUpdateRealtimeEvents]
  );

  const handleConversationInterrupted = useCallback(async () => {
    const trackSampleOffset = await wavStreamPlayer.interrupt();
    if (trackSampleOffset?.trackId) {
      voiceBotInstance.cancelResponse(trackSampleOffset.trackId, trackSampleOffset.offset);
    }
  }, [wavStreamPlayer]);

  const handleConversationUpdated = useCallback(
    async ({ item, delta }: any) => {
      if (!socket) {
        console.info('WebSocket is not connected');
        return;
      }

      // // this triggered only when role === assistant
      if (delta?.audio) {
        wavStreamPlayer.add16BitPCM(delta.audio, item.id);

        chunkTimeout = setTimeout(async () => {
          const metadata = {
            userId,
            sessionId,
            itemId: item.id,
            type: item.type,
            role: 'assistant',
            audioLength: delta.audio.byteLength,
            sampleRate: 24000,
          };

          const transformedAudioChunk = await transformAudioData(worker.current, delta.audio);

          socket?.emit('save-ai-session-transcript-chunk', {
            metadata,
            audioChunk: transformedAudioChunk,
          });
        }, 0);
      }
      // // we null audio for assistant role because we sent it via chunks
      if (
        item.status === 'completed' &&
        item.formatted.audio?.length &&
        item.formatted.transcript
      ) {
        completedAudioTimeout = setTimeout(async () => {
          const transformedAudio = await transformAudioData(worker.current, item.formatted.audio);
          const formattedData =
            item.role === 'user'
              ? {
                  ...item.formatted,
                  audio: transformedAudio,
                }
              : {
                  audio: null,
                  text: item.formatted.text,
                  transcript: item.formatted.transcript,
                };
          socket?.emit('save-ai-session-transcript', {
            userId,
            sessionId,
            conversationItem: {
              ...item,
              formatted: formattedData,
            },
          });
        }, 0);
      }
    },
    [userId, sessionId, socket]
  );

  const handleSetEventHandlers = useCallback(() => {
    if (!voiceBotInstance) return;
    voiceBotInstance.on('realtime.event', handleRealtimeEvent);
    voiceBotInstance.on('error', handleError);
    voiceBotInstance.on('conversation.interrupted', handleConversationInterrupted);
    voiceBotInstance.on('conversation.updated', handleConversationUpdated);
  }, [handleRealtimeEvent, handleConversationUpdated, voiceBotInstance, wavStreamPlayer]);

  useEffect(() => {
    return () => {
      worker.current.terminate();
      clearTimeout(chunkTimeout);
      clearTimeout(completedAudioTimeout);
      if ('realtime.event' in voiceBotInstance.eventHandlers) {
        try {
          voiceBotInstance.off('realtime.event', handleRealtimeEvent);
        } catch (e: ShamefulAny) {
          e.sentryHandled = true;
          console.error('realtime event error:', JSON.stringify(e));
        }
      }
    };
  }, []);

  return { handleSetEventHandlers };
};

export default useVoiceBotEvents;
