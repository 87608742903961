import { createUserDb } from '@services/db/users/createUserDb';
import { apiClient } from '..';
import { USER_ROLES } from '@interfaces/user';
import { ValueOf } from '@utils/types';

type PatientPayload = {
  therapistId: string;
  therapistScheduleUrl: string;
  patientEmail: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  needsInvitation: boolean;
  role: ValueOf<typeof USER_ROLES>;
};

export async function updatePatientsList(payload: PatientPayload): Promise<any> {
  const {
    therapistId,
    therapistScheduleUrl,
    patientEmail,
    phoneNumber,
    firstName,
    lastName,
    needsInvitation,
  } = payload;
  try {
    const { data } = await apiClient.patch('/patient/updatePatientsList', {
      therapistId: therapistId,
      therapistScheduleUrl: therapistScheduleUrl,
      patientEmail: patientEmail,
      phoneNumber: phoneNumber,
      firstName: firstName,
      lastName: lastName,
      needsInvitation: needsInvitation,
    });

    return data;
  } catch (e: any) {
    console.error('Error in updating patient list', e.message, e.details.errors[0]);
    throw e.details.errors[0];
  }
}

export const updatePatientsListAndUsers = async (payload: PatientPayload) => {
  try {
    const data = await updatePatientsList(payload);
    const patientId = data.patientId;

    await createUserDb(patientId);

    return data;
  } catch (e) {
    (e as any).sentryHandled = true;
  }
};
