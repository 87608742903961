export const CookiePolicy = () => {
  return (
    <div className="flex max-w-[1160px] w-11/12">
      <div className="flex flex-col gap-8 items-start font-['Inter'] tracking-tight self-start text-gray-800">
        <div className="text-4xl font-bold mt-[44px]">Cookie Policy</div>
        <div className="flex flex-col gap-6 w-full text-start mb-[88px]">
          <p>
            <strong> Questions, Concerns or Complaints</strong>: if you have any comments or
            questions regarding our Privacy Policy, or if you have any concerns regarding your
            Personal Data held with us, or if you wish to make a complaint about how your Personal
            Data is being processed by BYT, you can contact us at{' '}
            <a
              href="mailto:privacy@betteryou.tech"
              className="text-purple-600 underline underline-offset-4"
            >
              privacy@betteryou.tech
            </a>{' '}
            .
          </p>
        </div>
      </div>
    </div>
  );
};
