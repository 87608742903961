import MemoAISimplifiedShenaiCanvas from './AISimplifiedShenaiCanvas';
import { useState } from 'react';

type CameraViewProps = {
  isShowCanvas: boolean;
  isConnected: boolean;
  isSDKInitialized: boolean;
};

export const CameraView = ({ isShowCanvas, isConnected, isSDKInitialized }: CameraViewProps) => {
  const [isPinned, setIsPinned] = useState(false);
  const handlePinCameraView = () => {
    setIsPinned((prev) => !prev);
  };

  const shoudHideCanvas = isConnected && !isShowCanvas && !isPinned;

  return (
    <div
      className={`flex transition-all duration-500 ease-in-out ${
        shoudHideCanvas ? 'opacity-0 pointer-events-none' : 'opacity-100'
      } ${isConnected ? 'absolute bottom-0 right-0' : 'w-full h-full justify-center items-center'}`}
      style={{
        transform: isConnected
          ? isShowCanvas || isPinned
            ? 'translateY(0)'
            : 'translateY(20px)'
          : '',
        maxHeight: isConnected ? (isShowCanvas || isPinned ? '300px' : '0') : '100%',
        overflow: shoudHideCanvas ? 'hidden' : 'visible',
      }}
    >
      <MemoAISimplifiedShenaiCanvas
        isConnected={isConnected}
        onPin={handlePinCameraView}
        isPinned={isPinned}
        key="AI-simplified-shenai-canvas"
        isSDKInitialized={isSDKInitialized}
      />
      {!isConnected && (
        <div className="text-center text-gray-800 text-base absolute w-[183px] top-[30%] h-[79px] left-[130%]">
          Center your face, try not to move and look at the camera until HRV finish is loading
        </div>
      )}
    </div>
  );
};
