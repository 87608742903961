import { memo, useEffect, useState } from 'react';
import { formatSessionDate } from '../model/utils/formatters';
import SessionListItem from './SessionListItem/SessionListItem';

import { CreateAssignSessionInfoSlot } from '../model/utils/slots/infoSlot';
import { Loader } from '@shared/ui/loader/Loader';

type onGoToAISessionReview = {
  assignedSessions: any;
  isPatientsDataLoading: boolean;
};

const AssignedSessionsTab = memo(
  ({ assignedSessions, isPatientsDataLoading }: onGoToAISessionReview) => {
    const [sessionList, setSessionList] = useState(assignedSessions);

    useEffect(() => {
      setSessionList(assignedSessions);
    }, [assignedSessions]);

    const handleSessionCleared = (clearedType: string, clearedPatientId: string) => {
      setSessionList((prevSessions: any[]) =>
        prevSessions
          .map((session) =>
            session.patientId === clearedPatientId
              ? {
                  ...session,
                  assignedSessionsByType: session.assignedSessionsByType.filter(
                    (s: { type: string }) => s.type !== clearedType
                  ),
                }
              : session
          )
          .filter((session) => session.assignedSessionsByType.length > 0)
      );
    };
    return (
      <div className="space-y-1 h-full pl-2">
        {sessionList.length > 0 ? (
          sessionList.map((assignedSession: any, index: number) => {
            const {
              type,
              count,
              patientName: name,
              patientSurname: surname,
              image,
              patientId,
              assignedSessionsByType,
            } = assignedSession;

            if (Array.isArray(assignedSessionsByType) && assignedSessionsByType.length > 0) {
              const lastAssignedSessionForType = assignedSessionsByType.sort(
                (a: { date: number }, b: { date: number }) => b.date - a.date
              )[0];

              const { date, time } = formatSessionDate(lastAssignedSessionForType.date);

              return (
                <SessionListItem
                  key={`${patientId}-${surname}-${index}`}
                  img={image}
                  subTitle={type}
                  time={time}
                  fullName={`${name} ${surname}`}
                  aiSessionType={type}
                  infoSlot={
                    <CreateAssignSessionInfoSlot
                      count={count}
                      date={date}
                      sessionUrl={lastAssignedSessionForType.sessionUrl}
                      userId={patientId}
                      type={type}
                      onSessionCleared={() => handleSessionCleared(type, patientId)}
                    />
                  }
                />
              );
            } else {
              return (
                <div key={index}>
                  <p>
                    No valid sessions found for {name} {surname}
                  </p>
                </div>
              );
            }
          })
        ) : isPatientsDataLoading ? (
          <Loader className="flex h-full w-full justify-center items-center" />
        ) : (
          <div className="flex h-full items-center justify-center text-center text-gray-500">
            No assigned sessions available
          </div>
        )}
      </div>
    );
  }
);

AssignedSessionsTab.displayName = 'AssignedSessionsTab';

export default AssignedSessionsTab;
