import ToggleableTabs from '@features/Tab/ToggleableTabs/ToggleableTabs';
import TachogramPlot from '@features/Plot/TachogramPlot';
import { dashboardActor } from '@pages/shared/realtime-dashboard/model/xstate/dashboardMachine';
import { useSelector } from '@xstate/react';

type TachogramGraphProps = {
  isShown: boolean;
  onToggle: () => void;
  isCleared: boolean;
};

const TachogramGraph = ({ isShown, onToggle, isCleared }: TachogramGraphProps) => {
  const sdkData = useSelector(dashboardActor, (state) => state.context.sdkData);
  return (
    <ToggleableTabs
      isShown={isShown}
      onToggle={onToggle}
      withCardStyle
      tabs={[
        {
          label: 'Tachogram',
          content: (
            <TachogramPlot
              key="tachogram-plot"
              isCleared={isCleared}
              realtimeBeats={sdkData?.realtimeHeartbeats}
              finalBeats={sdkData?.results?.heartbeats}
            />
          ),
        },
      ]}
      titleClasses="max-w-[190px]"
    />
  );
};

export default TachogramGraph;
