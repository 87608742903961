import { BreadcrumbItem } from '@shared/types/breadcrumbs/breadcrumb';

type BreadcrumbsProps = {
  prevState: BreadcrumbItem | null;
  currentState: BreadcrumbItem;
  onPrevRouteClick: () => void;
};

const Breadcrumbs = ({ prevState, currentState, onPrevRouteClick }: BreadcrumbsProps) => {
  const handlePrevRouteClick = () => {
    onPrevRouteClick && onPrevRouteClick();
  };

  return (
    <div className="h-5 justify-start items-center gap-4 inline-flex ">
      {prevState && (
        <>
          <div className="rounded-md justify-start items-center gap-1.5 flex">
            {prevState.icon}
            <div
              onClick={handlePrevRouteClick}
              className="text-center text-gray-600 text-sm font-medium font-['Inter'] leading-tight tracking-tight cursor-pointer"
            >
              {prevState.nameToDisplay}
            </div>
          </div>
          <svg
            className="flex-shrink-0 size-5 text-gray-400 dark:text-neutral-600 mx-2"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path
              d="M6 13L10 3"
              stroke="currentColor"
              strokeLinecap="round"
            ></path>
          </svg>
        </>
      )}
      <div className="flex items-center gap-1.5">
        {currentState?.forceIcon && currentState?.icon}
        <div className="text-center text-gray-800 text-sm font-semibold font-['Inter'] leading-tight tracking-tight">
          {currentState.nameToDisplay}
        </div>
      </div>
    </div>
  );
};

export default Breadcrumbs;
