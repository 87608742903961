import InfoCard from '@shared/ui/card/InfoCard';
import { PropsWithChildren, ReactNode, useMemo, useState } from 'react';

type Tab = { label: string; content: any };

type TabCard = {
  cardClasses: string;
  tabClasses: string;
  tabLabelClasses: string;
  tabsContainerClasses: string;
  tabs: Tab[];
  sharedBetweenTabsCustomButton?: ReactNode;
};

const TabCard = ({
  tabs,
  cardClasses,
  tabClasses,
  tabLabelClasses,
  tabsContainerClasses,
  sharedBetweenTabsCustomButton,
}: PropsWithChildren<any>) => {
  const [activeTab, setActiveTab] = useState(0);

  const getTabs = useMemo(() => {
    return (
      <nav
        className={`${tabsContainerClasses} flex border-b border-coolGray justify-between`}
        aria-label="Tabs"
        role="tablist"
        aria-orientation="horizontal"
      >
        {tabs.map((t: Tab, i: number) => {
          const key = `${t.label.split(' ').join('-')}-${i}`;
          return (
            <button
              id={key}
              key={key}
              role="tab"
              type="button"
              onClick={() => setActiveTab(i)}
              className={`${tabClasses} justify-center w-full overflow-hidden hs-tab-active:border-purple-600 hs-tab-active:text-purple-600 hs-tab-active:font-bold py-2 inline-flex items-center gap-x-1 border-b-4 border-transparent whitespace-nowrap text-gray-500 focus:outline-none disabled:opacity-50 disabled:pointer-events-none ${activeTab === i ? 'active' : ''}`}
              data-hs-tab={`#${key}`}
              aria-controls={`${key}`}
              aria-selected={activeTab === i}
            >
              <span className={`${tabLabelClasses} text-nowrap overflow-hidden text-ellipsis`}>
                {t.label}
              </span>
            </button>
          );
        })}
      </nav>
    );
  }, [tabs, activeTab]);
  return (
    <InfoCard
      cardClasses={cardClasses}
      customTitle={getTabs}
      customButton={sharedBetweenTabsCustomButton}
    >
      {tabs[activeTab].content}
    </InfoCard>
  );
};

export default TabCard;
