import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { InitializationSettings } from '../../../public/shenai-sdk/index'; // import from local
import { useShenaiSdk } from '@hooks/useShenaiSdk';
import { delay } from '@utils/helpers';
import { AppContext } from '../../contextApp';
import { EventLogType } from '@shared/constants/events/eventLogs';
import { rateFacePosition } from '@entities/shared/RealtimeDashboard/agents/utils';
import { ShamefulAny } from '@interfaces/index';
import { useWebSocket } from '../../websocket';
import { getCameraFrame } from '@services/media';
import { blobToBase64 } from '@utils/blob';
import { startFrameDropCounter } from '@utils/framesDropCounter';
import { getIsFaceOk } from '@shared/utils/shenai/getFaceIsOk';
import { useMediaDevicePermissionGranted } from '@hooks/useMediaDevicePermissionGranted';
import { calculateFrequencyDomainMetrics } from '@entities/shared/RealtimeDashboard/model/utils/frequencyDomainCalculation';

const SHENAI_API_KEY = import.meta.env.VITE_VF_SHENAI_SDK_API_KEY;

const getCameraFrameData = async () => {
  const blob = await getCameraFrame({ video: true });
  const base64 = await blobToBase64(blob as Blob);
  const timestamp = Date.now();
  return { base64, timestamp };
};

export const useShenai = (
  componentName: string,
  isLogsSending?: boolean,
  userId?: string,
  sessionId?: string
) => {
  const { addEventLog } = useContext(AppContext);

  const shenaiSDKRef = useRef<any | null>(null);
  const sdkDataRef = useRef<any | null>(null);
  const frameDropEventsRef = useRef<
    { frameDropInMoment: number; timestamp: number; totalFrameLoss: number }[]
  >([]);

  const lastFrameDropSentRef = useRef<{
    frameDropInMoment: number;
    timestamp: number;
    totalFrameLoss: number;
  } | null>(null);

  const sdkSubscribersRef = useRef<any[]>([]);

  const [cameraId, setCameraId] = useState('');
  const [isSDKInitialized, setIsSDKInitialized] = useState(false);
  const [initializationSettings, setInitializationSettings] = useState<InitializationSettings>();

  const shenaiSDK = useShenaiSdk();
  const { socket } = useWebSocket();
  const permissionGranted = useMediaDevicePermissionGranted('camera');

  shenaiSDKRef.current = shenaiSDK;

  const lastHeartbeatLengthRef = useRef(0);
  const lastHeartbeatsRef = useRef<number[] | null>(null);

  const fetchData = async () => {
    if (isSDKInitialized && shenaiSDKRef.current) {
      const sdk = shenaiSDKRef.current;
      const timestamp = Date.now();

      const realtimeHeartbeats = sdk.getRealtimeHeartbeats(35) || [];

      let newHeartbeats: number[] | null;

      // Detect if the heartbeats array was reset
      if (realtimeHeartbeats.length === 0) {
        newHeartbeats = null; // Data reset
        lastHeartbeatLengthRef.current = 0;
      } else {
        newHeartbeats = realtimeHeartbeats.slice(lastHeartbeatLengthRef.current);

        // Ensure it's an array before checking length
        if (Array.isArray(newHeartbeats) && newHeartbeats.length === 0) {
          newHeartbeats = [];
        }

        lastHeartbeatLengthRef.current = realtimeHeartbeats.length;
      }

      // Save last non-null state for comparison
      lastHeartbeatsRef.current = realtimeHeartbeats.length > 0 ? realtimeHeartbeats : null;

      // Get the last recorded frame drop event
      const latestFrameDrop = frameDropEventsRef.current.at(-1);

      let frameDropToSend;
      if (
        latestFrameDrop &&
        (!lastFrameDropSentRef.current ||
          latestFrameDrop.timestamp !== lastFrameDropSentRef.current.timestamp)
      ) {
        frameDropToSend = latestFrameDrop; // Send only if it's new
        lastFrameDropSentRef.current = latestFrameDrop; // Update last sent
      } else {
        frameDropToSend = lastFrameDropSentRef.current; // Send the freshest if no new data
      }

      const newData = {
        heartRate: sdk.getRealtimeHeartRate(),
        signalQuality: sdk.getCurrentSignalQualityMetric(),
        measurementProgress: sdk.getMeasurementProgressPercentage(),
        isInitialized: sdk.isInitialized(),
        faceState: sdk.getFaceState(),
        hr4s: sdk.getHeartRate4s(),
        hr10s: sdk.getHeartRate10s(),
        cardiacStressRT: sdk.getRealtimeCardiacStress(),
        hrvSDNN: sdk.getRealtimeHrvSdnn(),
        bbox: sdk.getNormalizedFaceBbox(),
        measurementState: sdk.getMeasurementState(),
        badSignal: sdk.getTotalBadSignalSeconds(),
        facePose: sdk.getFacePose(),
        results: sdk.getMeasurementResults(),
        realtimeMetrics: sdk.getRealtimeMetrics(35),
        realtimeHeartbeats: newHeartbeats,
        visibilityState: document.visibilityState === 'visible' ? 1 : 0,
        movementRate: rateFacePosition(sdk.getNormalizedFaceBbox()),
      };
      const dataForCalculation = sdk?.getRealtimeHeartbeats(35); // 30 seconds not enough for calculation
      //Todo newHeartbeats is  not enough for calculation so dataForcalculation used and also tachogram in calibration needs full heartbeats data

      const frequencyDomainMetricsCustom = calculateFrequencyDomainMetrics(dataForCalculation);
      //TODO: discuss should we save all windows or only last is enough
      // Extract only the last element's required fields
      const lastMetrics =
        Array.isArray(frequencyDomainMetricsCustom) && frequencyDomainMetricsCustom.length > 0
          ? frequencyDomainMetricsCustom.at(-1)
          : null;

      const filteredMetrics = lastMetrics
        ? {
            startWindow: lastMetrics.startTime,
            endWindow: lastMetrics.endTime,
            vlfPower: lastMetrics.vlfPower,
            lfPower: lastMetrics.lfPower,
            hfPower: lastMetrics.hfPower,
            vlfPercentage: lastMetrics.vlfPercentage,
            lfPercentage: lastMetrics.lfPercentage,
            hfPercentage: lastMetrics.hfPercentage,
            lfhfRatio: lastMetrics.lfhfRatio,
          }
        : null;

      sdkDataRef.current = {
        ...newData,
        realtimeHeartbeats: dataForCalculation,
        frameDropInfo: frameDropToSend,
        frequencyDomainMetricsCustom: lastMetrics,
      };

      emtiUpdatesForSubscribers();

      addEventLog([
        {
          type: EventLogType.SHENAI_DATA,
          timestamp,
          data: {
            ...newData,
            faceState: newData.faceState?.value,
            measurementState: newData.measurementState?.value,
            frameDropInfo: frameDropToSend,
            frequencyDomainMetricsCustom: filteredMetrics,
          },
        },
      ]);
    }
  };

  const updateCanvas = () => {
    const oldCanvas = document.getElementById('mxcanvas1') as HTMLCanvasElement;
    const aiSimplifiedCanvas = document.getElementById(
      'mxcanvas-ai-simplified'
    ) as HTMLCanvasElement;

    if (oldCanvas) {
      const newCanvas = document.createElement('canvas');
      newCanvas.width = oldCanvas.width;
      newCanvas.height = oldCanvas.height;

      newCanvas.style.width = '100%';
      newCanvas.style.height = '100%';
      newCanvas.style.maxWidth = '600px';
      newCanvas.style.maxHeight = '300px';
      newCanvas.style.aspectRatio = '5 / 4';

      newCanvas.id = 'mxcanvas1';

      // Using requestAnimationFrame for smooth replacement
      requestAnimationFrame(() => {
        oldCanvas.replaceWith(newCanvas);
        console.log('Canvas updated after deinitialization');
      });
    }
    if (aiSimplifiedCanvas) {
      const aiCanvas = document.createElement('canvas');
      aiCanvas.width = aiSimplifiedCanvas.width;
      aiCanvas.height = aiSimplifiedCanvas.height;

      aiCanvas.style.width = '100%';
      aiCanvas.style.height = '100%';
      aiCanvas.id = 'mxcanvas-ai-simplified';
    }
  };

  const initializeShenAiSdk = useCallback(
    (settings: InitializationSettings, onSuccess?: () => void) => {
      if (!shenaiSDKRef.current || isSDKInitialized) return;

      shenaiSDKRef.current.initialize(SHENAI_API_KEY, '', settings, (res: ShamefulAny) => {
        if (res === shenaiSDKRef.current.InitializationResult.OK) {
          console.log('Shen.AI License result: ', res);
          shenaiSDKRef.current.setRecordingEnabled(false);

          if (componentName === 'ai_session') {
            //attach to both canvases on initialisation
            shenaiSDKRef.current.attachToCanvas('#mxcanvas-ai-simplified', false);
            shenaiSDKRef.current.attachToCanvas('#mxcanvas1', false);
          } else shenaiSDKRef.current.attachToCanvas('#mxcanvas1');

          console.log('initialised successfully with version', shenaiSDKRef.current.getVersion());
          setIsSDKInitialized(true);

          shenaiSDKRef.current?.selectCameraByDeviceId(cameraId, true);

          shenaiSDKRef.current?.setShowSignalTile(false); // remove signal quality(stars) and signal plot from user interface

          onSuccess?.();
        } else {
          console.error('Shen.AI SDK initialization failed or canvas not found.');
        }
      });
    },
    [shenaiSDKRef.current, isSDKInitialized]
  );

  const deinitializeShenAiSdk = useCallback(async () => {
    if (isSDKInitialized && shenaiSDKRef.current) {
      try {
        await shenaiSDKRef.current.deinitialize();
        setIsSDKInitialized(false);
        console.log('Shen.AI SDK deinitialized successfully');

        // Wait before updating the canvas
        await delay(500);

        updateCanvas();

        // Add delay to stabilize the process
        await delay(1000);
      } catch (error) {
        console.error('Error during Shen.AI SDK deinitialization:', JSON.stringify(error));
      }
    }
  }, [shenaiSDKRef.current, isSDKInitialized]);

  const emtiUpdatesForSubscribers = useCallback(() => {
    const sdkData = sdkDataRef.current;
    sdkSubscribersRef.current?.forEach((cb: any) => cb(sdkData));
  }, [sdkDataRef.current, sdkSubscribersRef.current]);

  const subscribeToSdkData = useCallback((callback: (data: any) => void) => {
    sdkSubscribersRef.current?.push(callback);
    // return unsubscribe fn
    return () => {
      sdkSubscribersRef.current = sdkSubscribersRef.current?.filter((cb: any) => cb !== callback);
    };
  }, []);

  useEffect(() => {
    const stopFrameDropCounter = startFrameDropCounter((missedFrames, timestamp) => {
      const prevEvents = frameDropEventsRef.current;
      const totalFrameLoss =
        prevEvents.reduce((sum, event) => sum + event.frameDropInMoment, 0) + missedFrames;

      const updatedEvents = [
        ...prevEvents,
        { frameDropInMoment: missedFrames, timestamp, totalFrameLoss },
      ];

      frameDropEventsRef.current = updatedEvents;
    });

    return () => {
      frameDropEventsRef.current = [];
      stopFrameDropCounter();
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    let pollingTimeoutId: NodeJS.Timeout;

    const startSdkDataPolling = async () => {
      try {
        if (isMounted && isSDKInitialized) {
          await fetchData();
        }
      } catch (e) {
        console.error('Error fetching shenai data', JSON.stringify(e));
      } finally {
        if (isMounted && isSDKInitialized) {
          pollingTimeoutId = setTimeout(startSdkDataPolling, 1000);
        }
      }
    };

    startSdkDataPolling();

    return () => {
      isMounted = false;
      clearTimeout(pollingTimeoutId);

      let oldCanvas = document.getElementById('mxcanvas1') as HTMLCanvasElement;
      let aiSimplifiedCanvas = document.getElementById(
        'mxcanvas-ai-simplified'
      ) as HTMLCanvasElement;

      // If canvases are missing, create new ones and mark them as temporary
      if (!oldCanvas && isSDKInitialized && shenaiSDKRef.current) {
        oldCanvas = document.createElement('canvas');
        oldCanvas.id = 'mxcanvas1';
        oldCanvas.style.display = 'none';
        oldCanvas.setAttribute('data-temp', 'true'); // Mark as temporary
        document.body.appendChild(oldCanvas);
      }

      if (
        !aiSimplifiedCanvas &&
        componentName === 'ai_session' &&
        isSDKInitialized &&
        shenaiSDKRef.current
      ) {
        aiSimplifiedCanvas = document.createElement('canvas');
        aiSimplifiedCanvas.id = 'mxcanvas-ai-simplified';
        aiSimplifiedCanvas.style.display = 'none';
        aiSimplifiedCanvas.setAttribute('data-temp', 'true'); // Mark as temporary
        document.body.appendChild(aiSimplifiedCanvas);
      }

      if (isSDKInitialized && shenaiSDKRef.current) {
        console.log('Deinitializing SDK...');
        shenaiSDKRef.current.deinitialize();

        setTimeout(() => {
          sdkDataRef.current = null;
          setIsSDKInitialized(false);

          const oldCanvas = document.getElementById('mxcanvas1');
          const aiSimplifiedCanvas = document.getElementById('mxcanvas-ai-simplified');

          if (oldCanvas) {
            oldCanvas.remove();
          }
          if (aiSimplifiedCanvas) {
            aiSimplifiedCanvas.remove();
          }
        }, 500);
      } else {
        console.log('SDK not deinitialized');
      }
    };
  }, [shenaiSDK?.isInitialized()]);

  const getDeviceId = async (retryInterval = 1000, maxRetries = 10) => {
    let retries = 0;

    while (retries < maxRetries) {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((device) => device.kind === 'videoinput');

      if (videoDevices.length > 0 && videoDevices[0].deviceId != '') {
        localStorage.setItem('cameraId', `${videoDevices[0].deviceId}`);
        return setCameraId(videoDevices[0].deviceId); // Return the first available video device ID
      }

      retries++;
      await new Promise((resolve) => setTimeout(resolve, retryInterval));
    }

    throw new Error('No media devices found after retries.');
  };

  useEffect(() => {
    if (!permissionGranted) return;
    const initializeCamera = async () => {
      try {
        await getDeviceId();
      } catch (error: any) {
        console.error('Error getting device ID:', error.message);
      }

      if (cameraId && shenaiSDKRef.current) {
        const settings: InitializationSettings = {
          precisionMode: shenaiSDKRef.current.PrecisionMode.RELAXED,
          operatingMode: shenaiSDKRef.current.OperatingMode.MEASURE,
          measurementPreset: shenaiSDKRef.current.MeasurementPreset.INFINITE_METRICS,
          cameraMode: shenaiSDKRef.current.CameraMode.DEVICE_ID,
          onboardingMode: shenaiSDKRef.current.OnboardingMode.HIDDEN,
          showUserInterface: false,

          showFacePositioningOverlay: true,
          showVisualWarnings: false,
          enableCameraSwap: false,
          showFaceMask: true,
          showBloodFlow: true,
          hideShenaiLogo: true,
          enableStartAfterSuccess: true,
          enableSummaryScreen: false,
          enableHealthRisks: false,
          showOutOfRangeResultIndicators: true,
          showTrialMetricLabels: false,
          enableFullFrameProcessing: false,
        };
        setInitializationSettings(settings);
        initializeShenAiSdk(settings);
      }
    };

    initializeCamera();
  }, [shenaiSDKRef.current, cameraId, permissionGranted]);

  useEffect(() => {
    if (socket?.connected) {
      if (!sessionId || !getIsFaceOk(sdkDataRef.current, shenaiSDKRef.current) || !isLogsSending) {
        return;
      }
      const sendCameraFrameInterval = setInterval(async () => {
        const { base64, timestamp } = await getCameraFrameData();
        socket.emit('send-camera-frame', { userId, sessionId, base64, timestamp });
      }, 500);

      return () => {
        clearInterval(sendCameraFrameInterval);
      };
    }
  }, [sdkDataRef.current?.faceState, socket?.connected, userId, isLogsSending, sessionId]);

  return {
    subscribeToSdkData,
    sdkData: sdkDataRef.current,
    isSDKInitialized,
    initializationSettings,
    setInitializationSettings,
    initializeShenAiSdk,
    deinitializeShenAiSdk,
    shenaiSDK: shenaiSDKRef.current,
  };
};
