import { ShenaiSdkDataI } from '@shared/types/shenai/sdk';
import { ShenaiSDK } from '../../../../public/shenai-sdk';

export const getIsFaceOk = (saState?: ShenaiSdkDataI | null, saSDK?: ShenaiSDK | null): boolean => {
  if (!saState || !saSDK) {
    return false;
  }

  switch (saState.faceState) {
    case saSDK.FaceState.OK:
    case saSDK.FaceState.TOO_CLOSE:
    case saSDK.FaceState.TOO_FAR:
    case saSDK.FaceState.NOT_CENTERED:
      return true;
    default:
      return false;
  }
};
