import { memo, useEffect, useState } from 'react';
import { useSelector } from '@xstate/react';

import { WavRecorder, WavStreamPlayer } from '@shared/lib/wavtools/index';
import { MemoAISimplifiedRealtimeAI } from './ui/AISimplifiedRealtimeAI';
import TopBar from '../../../../../components/AISession/ui/TopBar';
import { appActor } from '@machines/shared/appMachine'; // ??
import { dashboardActor } from '@pages/shared/realtime-dashboard/model/xstate/dashboardMachine';

type AISessionSimplifiedProps = {
  onConnectConversation: any;
  onOpenAdvancedMode: (x: boolean) => void;
  sessionId: string;
  isSDKInitialized: boolean;
  startTimeRef: any;
  wavStreamPlayer: WavStreamPlayer;
  wavRecorder: WavRecorder;
  shenaiSDK: any;
  onPauseSession: any;
  isSimplifiedSessionOpened: any;
  onFinishSession: () => Promise<void>;
  onSetInitializationSettings: any;
  realtimeClientInstance: any;
};

const AISessionSimplified = ({
  onConnectConversation,
  onFinishSession,
  sessionId,
  isSDKInitialized,
  startTimeRef,
  wavRecorder,
  wavStreamPlayer,
  shenaiSDK,
  onPauseSession,
  isSimplifiedSessionOpened,
  onSetInitializationSettings,
  onOpenAdvancedMode,
  realtimeClientInstance,
}: AISessionSimplifiedProps) => {
  const isConnected = useSelector(dashboardActor, (state) => state.context.isConnected);

  const [isWatingSessionEnd, setIsWaitingsSessionEnd] = useState(false);

  const handleRestartSession = () => {
    console.log('restart session clicked');
  };

  const handleFinishSession = async () => {
    try {
      setIsWaitingsSessionEnd(true);
      await onFinishSession();
    } catch (e) {
      (e as any).sentryHandled = true;
      console.error('Finish session error', JSON.stringify(e));
    } finally {
      setIsWaitingsSessionEnd(false);
    }
  };

  useEffect(() => {
    if (isSimplifiedSessionOpened) {
      appActor.send({ type: 'HEADER_HIDDEN_UPDATE', isHeaderHidden: true });
      shenaiSDK?.setShowUserInterface(false);
    } else {
      appActor.send({ type: 'HEADER_HIDDEN_UPDATE', isHeaderHidden: false });
      shenaiSDK?.setShowUserInterface(true);
    }
  }, [isSimplifiedSessionOpened]);

  useEffect(() => {
    // Cleanup function to handle disconnecting the conversation
    return () => {
      appActor.send({ type: 'HEADER_HIDDEN_UPDATE', isHeaderHidden: false });
    };
  }, []);

  return (
    <div className="relative w-full h-full">
      <TopBar
        onRestartSession={handleRestartSession}
        onFinishSession={handleFinishSession}
        onPauseAISession={onPauseSession}
        onOpenAdvancedMode={onOpenAdvancedMode}
        isWatingSessionEnd={isWatingSessionEnd}
      />

      <MemoAISimplifiedRealtimeAI
        key="AI-simplified-realtime-ai"
        sessionId={sessionId}
        onConnectConversation={onConnectConversation}
        isConnected={isConnected || isWatingSessionEnd}
        startTimeRef={startTimeRef}
        onSetInitializationSettings={onSetInitializationSettings}
        wavRecorder={wavRecorder}
        wavStreamPlayer={wavStreamPlayer}
        isSDKInitialized={isSDKInitialized}
        shenaiSDK={shenaiSDK}
        realtimeClientInstance={realtimeClientInstance}
      />
    </div>
  );
};

const MemoAISessionSimplified = memo(AISessionSimplified);
MemoAISessionSimplified.displayName = 'AISessionSimplified';

export default MemoAISessionSimplified;
