import { memo } from 'react';
import ToggleableTabs from '@features/Tab/ToggleableTabs/ToggleableTabs';
import { TOGGLEABLE_SECTIONS } from '@entities/shared/RealtimeDashboard/model/constants/togglableSections';
import { useSelector } from '@xstate/react';

import { dashboardActor } from '@pages/shared/realtime-dashboard/model/xstate/dashboardMachine';

type SuggestionDataProps = {
  activeTopic: string;
};

const SelfReviewConversationAnalysisData = ({ activeTopic }: SuggestionDataProps) => {
  const sections = useSelector(dashboardActor, (state) => state.context.sections);

  const handleSectionToggle = (key: string) => {
    dashboardActor.send({ type: 'TOGGLE_SECTION', key });
  };

  return (
    <div className="mb-4 space-y-4">
      <div className="flex flex-col gap-4 w-full">
        <ToggleableTabs
          isShown={sections[TOGGLEABLE_SECTIONS.CONVERSATION_ANALYSIS]}
          onToggle={() => handleSectionToggle(TOGGLEABLE_SECTIONS.CONVERSATION_ANALYSIS)}
          tabs={[
            {
              label: 'Conversation analysis',
              content: (
                <div className="flex gap-16">
                  <div>
                    <p className="font-bold">Active Topic:</p>
                    <p>{activeTopic}</p>
                  </div>
                  <div>
                    <p className="font-bold">Target Topic:</p>
                    <p>Breathing exercise</p>
                  </div>
                </div>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default memo(SelfReviewConversationAnalysisData);
