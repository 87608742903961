import { useSelector } from '@xstate/react';
import { dashboardActor } from '@pages/shared/realtime-dashboard/model/xstate/dashboardMachine';
import ToggleableTabs from '@features/Tab/ToggleableTabs/ToggleableTabs';
import ValueTimePlot from '@features/Plot/ValueTimePlot';

type CardiacStressGraphProps = {
  isShown: boolean;
  onToggle: () => void;
  isCleared: boolean;
};

const CardiacStressGraph = ({ isShown, onToggle, isCleared }: CardiacStressGraphProps) => {
  const sdkData = useSelector(dashboardActor, (state) => state.context.sdkData);
  return (
    <ToggleableTabs
      isShown={isShown}
      onToggle={onToggle}
      withCardStyle
      tabs={[
        {
          label: 'Cardiac stress',
          content: (
            <ValueTimePlot
              key="cardiacStress-plot"
              sdkData={sdkData}
              valueForPlot={sdkData?.cardiacStressRT}
              isCleared={isCleared}
            />
          ),
        },
      ]}
      titleClasses="max-w-[190px]"
      additionalData={sdkData?.cardiacStressRT}
    />
  );
};

export default CardiacStressGraph;
