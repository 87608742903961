import { useState, memo, useCallback } from 'react';
import AIConversationVoiceSelect from './AIConversationVoiceSelect';
import InputRange from '@shared/ui/input/range/InputRange';
import ToggleableTabs from '@features/Tab/ToggleableTabs/ToggleableTabs';
import { useSelector } from '@xstate/react';
import { dashboardActor } from '../../../../../../pages/shared/realtime-dashboard/model/xstate/dashboardMachine';
import { TOGGLEABLE_SECTIONS } from '../../../model/constants/togglableSections';
import { SessionSettings } from '@shared/types/dashboard/sessionSettings';

interface ConversationalAIModelProps {
  onSectionToggleByKey: (key: string) => void;
  onSettingsUpdate: ({ key, value }: any) => void;
  allowVoiceChange?: boolean;
}

const ConversationalModelProperties = ({
  onSettingsUpdate,
  onSectionToggleByKey,
  allowVoiceChange = true,
}: ConversationalAIModelProps) => {
  const sections = useSelector(dashboardActor, (state) => state.context.sections);
  const sessionSettings = useSelector(dashboardActor, (state) => state.context.sessionSettings);

  const [settings, setSettings] = useState<SessionSettings>({
    voice: sessionSettings?.voice,
    temperature: sessionSettings?.temperature,
    maxTokens: sessionSettings?.maxTokens,
    threshold: sessionSettings?.threshold,
    prefixPadding: sessionSettings?.prefixPadding,
    silenceDuration: sessionSettings?.silenceDuration,
  });

  const handleUpdateSessionSettings = useCallback(
    (key: keyof SessionSettings) => (value: string | number) => {
      onSettingsUpdate?.({ key, value });
      setSettings((prev) => ({ ...prev, [key]: value })); // local
      dashboardActor.send({ type: 'SESSION_SETTINGS_UPDATE', data: { key, value } }); // external
    },
    []
  );

  return (
    <div className="w-full">
      <h2 className="text-xl font-bold mb-4">Conversational AI Model</h2>
      <ToggleableTabs
        onToggle={() => onSectionToggleByKey(TOGGLEABLE_SECTIONS.VOICEAI_SETTINGS)}
        isShown={sections[TOGGLEABLE_SECTIONS.VOICEAI_SETTINGS]}
        tabs={[
          {
            label: 'Voice settings',
            content: (
              <>
                <div className="mb-4 w-full relative">
                  <span className="text-black text-[13px] font-bold font-['Inter'] leading-normal">
                    Voice
                  </span>
                  <AIConversationVoiceSelect
                    initialValue={settings.voice}
                    onChange={handleUpdateSessionSettings('voice')}
                    disabled={!allowVoiceChange}
                  />
                </div>
                <InputRange
                  label="Threshold"
                  min={0}
                  max={1}
                  step={0.01}
                  defaultValue={Number(settings.threshold)}
                  onChange={handleUpdateSessionSettings('threshold')}
                />
                <InputRange
                  label="Prefix Padding"
                  min={0}
                  max={1000}
                  step={1}
                  defaultValue={Number(settings.prefixPadding)}
                  onChange={handleUpdateSessionSettings('prefixPadding')}
                />
                <InputRange
                  label="Silence Duration"
                  min={0}
                  max={5000}
                  step={10}
                  defaultValue={Number(settings.silenceDuration)}
                  onChange={handleUpdateSessionSettings('silenceDuration')}
                />
                <InputRange
                  label="Model Configuration (Temperature)"
                  min={0.6}
                  max={1}
                  step={0.01}
                  defaultValue={Number(settings.temperature)}
                  onChange={handleUpdateSessionSettings('temperature')}
                />
                <InputRange
                  label="Max Tokens"
                  min={50}
                  max={1000}
                  step={10}
                  defaultValue={Number(settings.maxTokens) || 150}
                  onChange={handleUpdateSessionSettings('maxTokens')}
                />
              </>
            ),
          },
        ]}
      />
    </div>
  );
};

export default memo(ConversationalModelProperties);
