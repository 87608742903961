import { useCallback } from 'react';
import { getAiSessionRecording } from '@api/ai/aiRecording';
import { useFetch } from '@hooks/useFetch';

export const useRecording = (userId: string, sessionId: string) => {
  const fetchRecording = useCallback(async () => {
    const response = await getAiSessionRecording(userId, sessionId);

    if (response.error) {
      throw new Error(`Failed to fetch recording: ${response.message}`);
    }

    return response.data.recording || null;
  }, [userId, sessionId]);

  const { data, error, isLoading, refresh } = useFetch({
    fetcher: fetchRecording,
  });

  return {
    recording: data,
    isLoading,
    error,
    refresh,
  };
};
