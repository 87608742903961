import Logo from '@assets/betteryou-logo.svg?react';
import { useClerk } from '@clerk/clerk-react';

export const EndSessionPage = () => {
  const { signOut } = useClerk();

  setTimeout(() => {
    signOut({ redirectUrl: '/' });
  }, 4000);

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <Logo className="absolute top-6 left-[4.5rem] w-36 h-10" />
      <p className="text-4xl font-semibold">The session has ended.</p>
      <div className="mt-6 w-1/4">
        <p className="text-base">Your therapist will be in touch with you about your next steps.</p>
      </div>

      <div className="max-w-[486px] py-6 px-5 w-full h-fit bg-[#e1d8fc]/60 rounded-[37px] mt-20 flex items-center justify-center">
        <q className="text-center text-[#3f0079] text-lg font-light font-['Inter'] leading-normal tracking-tight">
          There will always be someone who doesn't see you, don't let that someone be you
        </q>
      </div>
    </div>
  );
};
