import { useSelector } from '@xstate/react';

import ToggleableTabs from '@features/Tab/ToggleableTabs/ToggleableTabs';
import { dashboardActor } from '@pages/shared/realtime-dashboard/model/xstate/dashboardMachine';
import { TOGGLEABLE_SECTIONS } from '@entities/shared/RealtimeDashboard/model/constants/togglableSections';
import SelfReviewRealtimeTranscripts from './SelfReviewRealtimeTranscripts';
import SelfReviewConversationModelEvents from './SelfReviewConversationModelEvents';
import { memo } from 'react';

type ConversationDataProps = {
  items?: any;
  events: any[];
  transcripts: any[];
  isConnected?: boolean;
  isLoading?: boolean;
  realtimeClientInstance?: any;
  startTime: string;
  prompt?: string;
};

const SelfReviewConversationData = ({
  events,
  transcripts,
  startTime,
  isLoading = false,
  prompt,
}: ConversationDataProps) => {
  const sections = useSelector(dashboardActor, (state) => state.context.sections);

  const handleSectionToggle = (key: string) => {
    dashboardActor.send({ type: 'TOGGLE_SECTION', key });
  };

  return (
    <div className="my-4 space-y-4">
      <ToggleableTabs
        isShown={sections[TOGGLEABLE_SECTIONS.SYSTEM_INSTRUCTIONS]}
        onToggle={() => handleSectionToggle(TOGGLEABLE_SECTIONS.SYSTEM_INSTRUCTIONS)}
        withCardStyle
        tabs={[
          {
            label: 'System instructions',
            content: (
              <textarea
                className="w-full border border-gray-300 rounded p-2 resize-none"
                rows={4}
                value={prompt}
                onChange={() => {}}
              />
            ),
          },
        ]}
        key="rtPrompt"
      />
      <ToggleableTabs
        isLoading={isLoading}
        isShown={sections[TOGGLEABLE_SECTIONS.CONVERSATION_MODEL_EVENTS]}
        onToggle={() => handleSectionToggle(TOGGLEABLE_SECTIONS.CONVERSATION_MODEL_EVENTS)}
        withCardStyle
        tabs={[
          {
            label: 'Conversation model events',
            content: (
              <SelfReviewConversationModelEvents
                isLoading={isLoading}
                startTime={startTime}
                events={events}
              />
            ),
          },
        ]}
        key="rtConversationalModelEvents"
      />
      <ToggleableTabs
        isLoading={isLoading}
        isShown={sections[TOGGLEABLE_SECTIONS.REALTIME_TRANSCRIPTS]}
        onToggle={() => handleSectionToggle(TOGGLEABLE_SECTIONS.REALTIME_TRANSCRIPTS)}
        withCardStyle
        tabs={[
          {
            label: 'Realtime transcript',
            content: (
              <SelfReviewRealtimeTranscripts
                transcripts={transcripts}
                isLoading={isLoading}
              />
            ),
          },
        ]}
        key="rtTranscript"
      />
    </div>
  );
};

export default memo(SelfReviewConversationData);
