import { RealtimeClient } from '@openai/realtime-api-beta';
import { useCallback } from 'react';
import { END_TYPE_OPTIONS } from '../constants/options';
import { dashboardActor } from '../xstate/dashboardMachine';
import { useSelector } from '@xstate/react';

type UseRealtimeConnectionProps = {
  voiceBotInstance: RealtimeClient;
  wavRecorderRef: React.MutableRefObject<any>;
  wavStreamPlayerRef: React.MutableRefObject<any>;
  handleSetEventHandlers: () => void;
  handleConnectionChange: (connected: boolean) => void;
  sessionId: string;
  userId: string;
};

const useRealtimeConnection = ({
  voiceBotInstance,
  wavRecorderRef,
  wavStreamPlayerRef,
  handleSetEventHandlers,
  handleConnectionChange,
  sessionId,
  userId,
}: UseRealtimeConnectionProps) => {
  //move to global
  const selectedEndType = useSelector(dashboardActor, (state) => state.context.selectedEndType);
  const isConnected = useSelector(dashboardActor, (state) => state.context.isConnected);

  const handleError = useCallback((error: any) => {
    error.sentryHandled = true;
    console.error('Realtime connection error:', JSON.stringify(error));
  }, []);

  const handleSetConnectionState = useCallback((isConnected: boolean) => {
    dashboardActor.send({ type: 'SET_IS_CONNECTED', isConnected });
  }, []);

  const handleDisconnectConversation = useCallback(async () => {
    try {
      handleSetConnectionState(false);

      voiceBotInstance?.disconnect();

      await wavRecorderRef.current.end();
      await wavStreamPlayerRef.current.interrupt();

      handleConnectionChange(false);
    } catch (error: any) {
      error.sentryHandled = true;
      console.error(JSON.stringify(error));

      handleError(error);
    }
  }, [voiceBotInstance, wavRecorderRef, wavStreamPlayerRef, handleConnectionChange, handleError]);

  const handleConnectConversation = useCallback(async () => {
    try {
      if (!isConnected) {
        const wavRecorder = wavRecorderRef.current;
        const wavStreamPlayer = wavStreamPlayerRef.current;

        handleSetConnectionState(true);

        voiceBotInstance?.reset();
        handleSetEventHandlers();

        await wavStreamPlayer.connect();
        await voiceBotInstance?.connect();
        await wavRecorder.begin();

        voiceBotInstance?.realtime.send('conversation.item.create', {
          item: {
            type: 'message',
            role: 'user',
            content: [{ type: 'input_text', text: sessionId }],
          },
        });

        voiceBotInstance?.realtime.send('conversation.item.create', {
          item: {
            type: 'message',
            role: 'user',
            content: [{ type: 'input_text', text: userId }],
          },
        });

        if (voiceBotInstance?.getTurnDetectionType() === END_TYPE_OPTIONS.VAD) {
          await wavRecorder.record((data: any) => voiceBotInstance.appendInputAudio(data.mono));
        }

        //   setCanPushToTalk(selectedEndType.value === END_TYPE_OPTIONS.MANUAL);
        handleConnectionChange?.(true);
      } else {
        console.info('Realtime client failed to connect');
      }
    } catch (error: any) {
      error.sentryHandled = true;
      console.error(JSON.stringify(error));
      handleError(error);
      handleSetConnectionState(false);
      handleConnectionChange?.(false);
    }
  }, [
    voiceBotInstance,
    wavRecorderRef,
    wavStreamPlayerRef,
    handleSetEventHandlers,
    handleConnectionChange,
    handleError,
    sessionId,
    userId,
    selectedEndType.value,
  ]);

  return {
    handleSetConnectionState,
    handleConnectConversation,
    handleDisconnectConversation,
  };
};

export default useRealtimeConnection;
