import { PLOT_SIGNALS } from '@shared/constants/plot/signals';

export const SIGNALS_LIST = Object.values(PLOT_SIGNALS);

export const AUXILIARY_SIGNAL_LIST = [
  PLOT_SIGNALS.CARDIAC_STRESS,
  PLOT_SIGNALS.FACE_STATE,
  PLOT_SIGNALS.HRV_LN_RMSSD,
  PLOT_SIGNALS.HRV_SDNN,
  PLOT_SIGNALS.MEASUREMENT_STATE,
  PLOT_SIGNALS.SIGNAL_QUALITY,
  PLOT_SIGNALS.LF_HF_RATIO,
  PLOT_SIGNALS.LF_POWER_PERCENTAGE,
  PLOT_SIGNALS.HF_POWER_PERCENTAGE,
  PLOT_SIGNALS.VLF_POWER_PERCENTAGE,
] as const;
