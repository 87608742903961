import { useState, useEffect, useCallback } from 'react';

import { getSessionInfo } from '@api/session/sessionInfo';
import { fetchAiSessionSettings } from '@api/userAPI';
import { SessionSettings } from '@shared/types/dashboard/sessionSettings';
import { defaultSessionSettings } from '@shared/constants/sessionSettings';
import { AISession } from '@interfaces/index';
import { useFetch } from '@hooks/useFetch';

export const useReviewSessionData = (sessionId: string) => {
  const [sessionData, setSessionData] = useState<AISession | null>(null);
  const [sessionSettings, setSessionSettings] = useState<SessionSettings>(defaultSessionSettings);

  const fetchReviewSessionData = useCallback(async () => {
    const [sessionRes, settingsRes] = await Promise.all([
      getSessionInfo(sessionId),
      fetchAiSessionSettings(sessionId),
    ]);

    if (sessionRes.error) throw new Error(sessionRes.message);
    if (settingsRes.error) throw new Error(settingsRes.message);
    return { sessionData: sessionRes, settingsData: settingsRes.data };
  }, [sessionId]);

  const { data, isLoading, error } = useFetch({
    fetcher: fetchReviewSessionData,
  });

  useEffect(() => {
    if (data) {
      setSessionData(data?.sessionData);
      setSessionSettings(data?.settingsData);
    }
  }, [data?.sessionData, data?.settingsData]);

  return {
    sessionData: sessionData,
    sessionSettings: sessionSettings,
    isLoading,
    error,
    onUpdateSettings: setSessionSettings,
    onUpdateSessionData: setSessionData,
  };
};
