import { apiClient } from '..';

export async function deleteAssignedSessionsByType(
  assignedSessionType: string,
  userId: string
): Promise<string> {
  try {
    const requestBody = {
      assignedSessionType,
      userId,
    };

    await apiClient.post('/ai/clear_assigned_sessions', requestBody);
    return 'Success';
  } catch (e: any) {
    console.error('Error deleting assigned sessions:', e.message, e.details);
    return `${e.message}: ${e.details}`;
  }
}
