export const mergeTranscriptsBySource = (transcripts: any[]) => {
  if (!transcripts || transcripts.length === 0) return [];

  const mergedTranscripts = [];
  let currentGroup = { source: transcripts[0].source, message: transcripts[0].message };

  for (let i = 1; i < transcripts.length; i++) {
    const prev = transcripts[i - 1];
    const curr = transcripts[i];

    if (curr.source === prev.source) {
      // Merge messages if the source is the same
      currentGroup.message += ' ' + curr.message;
    } else {
      // Push completed group and start a new one
      mergedTranscripts.push(currentGroup);
      currentGroup = { source: curr.source, message: curr.message };
    }
  }

  // Push the last group
  mergedTranscripts.push(currentGroup);

  return mergedTranscripts;
};

// Output: "17/03/2025"
export const formatDate = (date: string | number | Date) => {
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, '0');
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const year = d.getFullYear();
  return `${day}/${month}/${year}`;
};
