import * as ReactDOM from 'react-dom/client';
import { useSentry } from '@hooks/useSentry';
import { ClerkProvider } from '@clerk/clerk-react';
import AppWrapper from './AppWrapper';
import './index.css';

useSentry();

console.log(`Build  #${RUN_ID}(${RUN_NUMBER}) built at ${BUILD_DATE}`); // eslint-disable-line no-console

ReactDOM.createRoot(document.getElementById('root')!).render(
  <ClerkProvider publishableKey={import.meta.env.VITE_VF_CLERK_PUBLISHABLE_KEY}>
    <AppWrapper />
  </ClerkProvider>
);
