import { memo } from 'react';
import { useSelector } from '@xstate/react';
import ToggleableTabs from '@features/Tab/ToggleableTabs/ToggleableTabs';
import { dashboardActor } from '@pages/shared/realtime-dashboard/model/xstate/dashboardMachine';
import { TOGGLEABLE_SECTIONS } from '@entities/shared/RealtimeDashboard/model/constants/togglableSections';

type SuggestionDataProps = {
  suggestionData: {
    topicsToExplore: string[];
    topicsToExpand: string[];
    deepeningQuestions: string[];
  };
};

const SelfReviewSuggestionData = ({ suggestionData }: SuggestionDataProps) => {
  const sections = useSelector(dashboardActor, (state) => state.context.sections);

  const handleSectionToggle = (key: string) => {
    dashboardActor.send({ type: 'TOGGLE_SECTION', key });
  };

  return (
    <div className="mb-4 space-y-4">
      <ToggleableTabs
        key="rtSuggestion"
        isShown={sections[TOGGLEABLE_SECTIONS.SUGGESTIONS]}
        onToggle={() => handleSectionToggle(TOGGLEABLE_SECTIONS.SUGGESTIONS)}
        withCardStyle
        tabs={[
          {
            label: 'Suggestion Agent: ',
            content: (
              <div className="flex flex-col">
                <div className="mb-4">
                  <span className="text-black text-[13px] font-bold font-['Inter'] leading-normal">
                    Topics to Explore
                  </span>

                  <span>
                    {suggestionData.topicsToExplore.map((el: any, i: number) => (
                      <p key={`${el}+${i}`}>{el}</p>
                    ))}
                  </span>
                </div>
                <div className="mb-4">
                  <span className="text-black text-[13px] font-bold font-['Inter'] leading-normal">
                    Topics to Expand
                  </span>

                  <span>
                    {suggestionData.topicsToExpand.map((el: any, i: number) => (
                      <p key={`${el}+${i}`}>{el}</p>
                    ))}
                  </span>
                </div>
                <div className="mb-4">
                  <span className="text-black text-[13px] font-bold font-['Inter'] leading-normal">
                    Deepening Questions
                  </span>

                  <span>
                    {suggestionData.deepeningQuestions.map((el: any, i: number) => (
                      <p key={`${el}+${i}`}>{el}</p>
                    ))}
                  </span>
                </div>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default memo(SelfReviewSuggestionData);
