import { ShamefulAny } from '@interfaces/index';

interface DataEntry {
  timestamp: number;
  [key: string]: any;
}

export const getDataForPlot = (filteredData: ShamefulAny[], parameter: string) => {
  return filteredData?.map((item) => {
    return {
      parameter: item[parameter],
      timestamp: item.timestamp,
      measurementState: item.measurementState,
    };
  });
};

export const getDataForFrameDropPlot = (filteredData: ShamefulAny[], parameter: string) => {
  return filteredData?.map((item) => {
    return {
      parameter: item[parameter].totalFrameLoss,
      timestamp: item.timestamp,
      measurementState: item.measurementState,
    };
  });
};

export const flattenDataArray = (objectsArray: any[]) => {
  return objectsArray.reduce((flatArray, obj) => {
    if (Array.isArray(obj.data)) {
      return flatArray.concat(obj.data); // Merge the 'data' array into flatArray
    }
    return flatArray; // Skip if 'data' is not an array
  }, []);
};

// example of use: const percentage = calculatePercentage(data, 'measurementState', 3);

export const calculatePercentage = (data: DataEntry[], parameter: string, value: any): number => {
  if (!data || data.length === 0) return 0;

  data.sort((a, b) => a.timestamp - b.timestamp);

  const totalDuration = data[data.length - 1].timestamp - data[0].timestamp;

  // Calculate the duration of the specified value
  let targetDuration = 0;

  for (let i = 0; i < data.length - 1; i++) {
    const currentParamValue = data[i][parameter];
    const nextTimestamp = data[i + 1].timestamp;

    if (currentParamValue === value) {
      targetDuration += nextTimestamp - data[i].timestamp; // Add duration where parameter equals value
    }
  }

  // Calculate and return percentage
  return parseFloat(((targetDuration / totalDuration) * 100).toFixed(2));
};
