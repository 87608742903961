import { Fragment } from 'react';

export const PrivacyDataUsesTable = ({
  data,
}: {
  data: {
    purpose: string;
    legalBasis: string[];
  }[];
}) => {
  return (
    <div className=" w-full bg-transparent">
      <div className="max-w-5xl mx-auto   ">
        <div className="grid grid-cols-[60%_40%]">
          <div className="font-bold text-sm text-center bg-[#f7f7f7] pt-4">Purpose</div>
          <div className="font-bold text-sm text-center bg-[#f7f0fd] pt-4">
            Legal Basis for Processing
          </div>

          {data.map((item, index) => (
            <Fragment key={index}>
              <div className="p-4 bg-[#f7f7f7] ">{item.purpose}</div>
              <div className="p-4 bg-[#f7f0fd] w-full">
                <ul className="list-disc pl-5">
                  {item.legalBasis.map((basis, i) => (
                    <li key={i}>{basis}</li>
                  ))}
                </ul>
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
