import { calculateFrequencyDomainMetrics } from './frequencyDomainCalculation';
// for the old sessions when we stored full realtimeHeartbeat

export const processLogs = (mxlabs: any) => {
  //  we mutate original mxlabs
  function processEntries(logs: any[]) {
    return logs.map((log) => {
      if (!Object.prototype.hasOwnProperty.call(log, 'frequencyDomainMetricsCustom')) {
        // If missing, compute it using realtimeHeartbeats
        if (Array.isArray(log.realtimeHeartbeats) && log.realtimeHeartbeats.length > 0) {
          log.frequencyDomainMetricsCustom = calculateFrequencyDomainMetrics(
            log.realtimeHeartbeats
          );
        } else {
          log.frequencyDomainMetricsCustom = null;
        }
      }

      // Extract the last available metrics (because there can be a lot windows)
      const lastMetrics =
        Array.isArray(log.frequencyDomainMetricsCustom) &&
        log.frequencyDomainMetricsCustom.length > 0
          ? log.frequencyDomainMetricsCustom.at(-1)
          : null;

      log.frequencyDomainMetricsCustom = lastMetrics
        ? {
            startWindow: lastMetrics.startTime,
            endWindow: lastMetrics.endTime,
            vlfPower: lastMetrics.vlfPower,
            lfPower: lastMetrics.lfPower,
            hfPower: lastMetrics.hfPower,
            vlfPercentage: lastMetrics.vlfPercentage,
            lfPercentage: lastMetrics.lfPercentage,
            hfPercentage: lastMetrics.hfPercentage,
            lfhfRatio: lastMetrics.lfhfRatio,
          }
        : null;

      return log;
    });
  }

  // Process both patient and therapist logs
  return {
    ['org:patient']: processEntries(mxlabs['org:patient']),
    ['org:therapist']: processEntries(mxlabs['org:therapist']),
  };
};
