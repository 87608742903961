import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import { shallowEqual, useSelector } from '@xstate/react';

import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { AppContext } from '../../contextApp';
import * as Sentry from '@sentry/react';
// API
import { getUpdatedKeyMoments, saveUpdatedKeyMoments } from '@api/userAPI';
import { fetchPatientRecordingData, fetchTherapistRecordingData } from '@api/session/recordingData';
import { fetchPatientTranscriptsData } from '@api/session/transcriptsData';
// Types
import { ITimelineRange, PlotSizeEnum } from '@components/Plot/types';
import { ProcessedMXLabsData } from '@shared/types/mxlabs/logs';
import { KeyMomentsData, KeyMomentWithRange } from '@components/SessionComponents/KeyMoments/types';
// Utils

import { trimByVideoDuration } from '@utils/HRGraph/trimByVideoDuration';
import { useMXLabsLogs } from '@components/Plot/utils';
// Components

import Plot from '@components/Plot';
import { Loader } from '@shared/ui/loader/Loader';
import TherapistWrapUpStepper from '@modules/Therapist/SessionWrapup/TherapistWrapUpStepper';

import { ShamefulAny } from '@interfaces/index';
import { USER_ROLES } from '@interfaces/user';
import { PageTitle } from '@shared/ui/caption/PageTitle';
import useGetTherapistPatientFrames from '@hooks/useGetTherapistPatientFrames';
import { getSessionInfo } from '@api/session/sessionInfo';
import { getTherapistRecordingInstances, getPatientRecordingInstances } from '@utils/helpers';
import { RecordingInstance } from '@utils/types';
import { processLogs } from '@entities/shared/RealtimeDashboard/model/utils/processLogsWithoutFrequencyDomainCalc';
import Button from '@components/Button';
import { SessionReviewTimeline } from './ui/SessionReviewTimeline';

//TODO: delete all commented functionality if there's no usecases for it
const SessionReviewByTherapist: React.FC = () => {
  const stateContext = useSelector(refaelaTherapistActor, (state) => state.context, shallowEqual);

  const sessionId = stateContext.currentSessionId!;
  const patientId = stateContext.currentPatientId!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-extra-non-null-assertion
  const therapistId = stateContext.currentUser?.therapistId!!;

  const [keyMomentsData, setKeyMomentsData] = useState<KeyMomentsData | null>(null);
  const [error, setError] = useState<string | null>(null);

  const [videoDuration, setVideoDuration] = useState<number | null>(null);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [trimmedMxLabs, setTrimmedMxLabs] = useState<Record<string, ProcessedMXLabsData[]> | null>({
    [USER_ROLES.THERAPIST]: [] as ProcessedMXLabsData[],
    [USER_ROLES.PATIENT]: [] as ProcessedMXLabsData[],
  });

  const [sessionDuration, setSessionDuration] = useState<number | null>(null); // for case when there is not video only frames
  const [loadingState, setIsLoadingState] = useState<Record<string, ShamefulAny>>({
    general: { isLoading: false, isFailed: false, error: null, info: null, data: null },
    recording: { isLoading: false, isFailed: false, error: null, info: null, data: null },
    therapistRecording: { isLoading: false, isFailed: false, error: null, info: null, data: null },
    transcripts: { isLoading: false, isFailed: false, error: null, info: null, data: null },
    frames: { isLoading: false, isFailed: false, error: null, data: null },
    therapistFrames: { isLoading: false, isFailed: false, error: null, data: null },
  });
  const { fetchFrames } = useGetTherapistPatientFrames(
    patientId,
    therapistId,
    sessionId,

    setIsLoadingState
  );

  const [isFrequencyCalculated, setIsFrequencyCalculated] = useState(true);
  const [videoDifference, setVideoDiff] = useState<{
    recordingStartsFirst: 'therapistRecording' | 'patientRecording';
    differenceBetweenStarts: number;
  } | null>(null);

  const fetchSessionData = useCallback(async () => {
    try {
      const sessionResponse = await getSessionInfo(sessionId);

      if (sessionResponse.error) {
        throw new Error(sessionResponse.message);
      }

      const therapistInstances = getTherapistRecordingInstances(
        sessionResponse
      ) as RecordingInstance;
      const patientInstances = getPatientRecordingInstances(sessionResponse) as RecordingInstance;

      // Check if both start and stop times exist in both instances
      if (
        therapistInstances.recordingStartTime &&
        therapistInstances.recordingStopTime &&
        patientInstances.recordingStartTime &&
        patientInstances.recordingStopTime
      ) {
        const therapistStartTime = therapistInstances.recordingStartTime;
        const patientStartTime = patientInstances.recordingStartTime;

        const startTimeToUse = Math.max(therapistStartTime, patientStartTime);
        const stopTimeToUse = Math.min(
          therapistInstances.recordingStopTime,
          patientInstances.recordingStopTime
        );

        const differenceBetweenStarts = Math.abs(therapistStartTime - patientStartTime);
        const recordingStartsFirst =
          therapistStartTime < patientStartTime ? 'therapistRecording' : 'patientRecording';

        setRecordingTimeFrame({
          recordingStartTime: new Date(startTimeToUse).getTime(),
          recordingStopTime: new Date(stopTimeToUse).getTime(),
        });

        setVideoDiff({ recordingStartsFirst, differenceBetweenStarts });

        const videoDurationToUse = stopTimeToUse - startTimeToUse; // in ms

        setVideoDuration(videoDurationToUse);
      } else {
        console.warn('Missing recordingStartTime or recordingStopTime in instances');
      }
    } catch (error: ShamefulAny) {
      console.error('Error fetching session data:', JSON.stringify(error));
    }
  }, [sessionId]);

  useEffect(() => {
    fetchSessionData();
  }, [fetchSessionData]);

  const videoRef = useRef<HTMLVideoElement>(null);
  const therapistVideoRef = useRef<HTMLVideoElement>(null);

  const [recordingTimeFrame, setRecordingTimeFrame] = useState<{
    recordingStartTime: number | undefined;
    recordingStopTime: number | undefined;
  }>({
    recordingStartTime: undefined,
    recordingStopTime: undefined,
  });
  const [updatedKeyMoments, setUpdatedKeymoments] = useState<KeyMomentsData | null>(null);

  const { step, setStep } = useContext(AppContext);

  const {
    general: generalState,
    recording: recordingState,
    therapistRecording: therapistRecordingState,
    transcripts: transcriptsState,
    frames: framesState,
    therapistFrames: therapistFramesState,
  } = loadingState;

  useEffect(() => {
    setStep(0);
  }, []);

  useEffect(() => {
    const { recordingStartTime, recordingStopTime } = recordingTimeFrame;
    if (!recordingStopTime || !recordingStartTime) return;

    setSessionDuration(recordingStopTime - recordingStartTime);
  }, [recordingTimeFrame]);

  const fetchData = useCallback(async () => {
    setIsLoadingState((prev) => ({ ...prev, general: { isLoading: true } }));
    try {
      if (patientId && sessionId && therapistId) {
        fetchPatientRecordingData(
          patientId,
          sessionId,
          therapistId,
          undefined,
          undefined,
          true,
          setIsLoadingState
        );
        fetchTherapistRecordingData(
          patientId,
          sessionId,
          therapistId,
          undefined,
          undefined,
          true,
          setIsLoadingState
        );
        await fetchPatientTranscriptsData(patientId, sessionId, therapistId, setIsLoadingState);

        const patientIdPostfixed = patientId.includes('_P') ? patientId : `${patientId}_P`;

        const keyMomentsUpdated = await getUpdatedKeyMoments(
          patientIdPostfixed,
          therapistId,
          sessionId
        );

        keyMomentsUpdated.keyMoments.forEach((keyMoment) => {
          if (keyMoment.transcripts) {
            keyMoment.transcripts.sort((a, b) => a.timestamp - b.timestamp);
          }
        });

        setKeyMomentsData(keyMomentsUpdated);
        setUpdatedKeymoments(keyMomentsUpdated);

        await fetchFrames();
      }
    } catch (error) {
      setError(`Error fetching session data: ${JSON.stringify(error)}`);
      console.error('Error fetching session data:', JSON.stringify(error));
    } finally {
      setIsLoadingState((prev) => ({ ...prev, general: { isLoading: false } }));
    }
  }, [setIsLoadingState, patientId, therapistId, sessionId]);

  useEffect(() => {
    fetchData();
  }, [patientId]);

  useEffect(() => {
    if (!updatedKeyMoments) return;

    // Create a new object to avoid mutation
    const newUpdatedKeyMoments = {
      ...updatedKeyMoments,
      keyMoments: updatedKeyMoments.keyMoments.map((moment: KeyMomentWithRange) => ({
        ...moment,
        keyMomentRange:
          !moment.transcripts || moment.transcripts.length === 0
            ? { startTime: null, endTime: null }
            : {
                startTime: moment.transcripts[0]?.timestamp ?? null,
                endTime: moment.transcripts.at(-1)?.timestamp ?? null,
              },
      })),
    };

    saveUpdatedKeyMoments(newUpdatedKeyMoments, patientId, therapistId, sessionId);
  }, [updatedKeyMoments]);

  const handleTimeUpdate = useCallback((currentTimeMillis: number) => {
    setCurrentTime(currentTimeMillis);
  }, []); // Empty dependency array to keep the function constant across renders

  const handleTimelineClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const durationToUse = videoDuration || sessionDuration;

      if (durationToUse) {
        const timelineRect = e.currentTarget.getBoundingClientRect();
        const clickPositionX = e.clientX - timelineRect.left;
        const clickPositionPercent = clickPositionX / timelineRect.width;
        const newTimeInSeconds = clickPositionPercent * (durationToUse / 1000);

        if (videoDifference) {
          const { recordingStartsFirst, differenceBetweenStarts } = videoDifference;

          const safeDifference = isNaN(differenceBetweenStarts)
            ? 0
            : differenceBetweenStarts / 1000;

          if (recordingStartsFirst === 'therapistRecording' && therapistVideoRef.current) {
            therapistVideoRef.current.currentTime = newTimeInSeconds + safeDifference;
          } else if (recordingStartsFirst === 'patientRecording' && videoRef.current) {
            videoRef.current.currentTime = newTimeInSeconds + safeDifference;
          }
        } else {
          if (videoRef.current) {
            videoRef.current.currentTime = newTimeInSeconds;
          }
          if (therapistVideoRef.current) {
            therapistVideoRef.current.currentTime = newTimeInSeconds;
          }
        }
        handleTimeUpdate(newTimeInSeconds * 1000);
      }
    },
    [
      videoDuration,
      sessionDuration,
      videoDifference,
      videoRef,
      therapistVideoRef,
      handleTimeUpdate,
      currentTime,
    ]
  );

  // INFO: following should get mxlabs file data from patient and therapist session folder
  const { logs: mxlabs, loading: mxlabsloading } = useMXLabsLogs(
    therapistId!,
    patientId!,
    sessionId!
  );

  const [timelineRange, setTimelineRange] = useState<ITimelineRange>({
    startTimestamp: 0,
    endTimestamp: 0,
  });
  const onFrequencyCalculateClick = () => {
    //  processLogs mutate mxlabs(add frequencyDomainMetricsCustom to it)
    processLogs(mxlabs);
    setIsFrequencyCalculated(true);
  };
  useEffect(() => {
    const areMxLabsReceived =
      mxlabs && Object.values(mxlabs).every((logs: ProcessedMXLabsData[]) => logs.length);

    if (
      mxlabs &&
      (!mxlabs['org:patient']?.some((obj) =>
        Object.prototype.hasOwnProperty.call(obj, 'frequencyDomainMetricsCustom')
      ) ||
        !mxlabs['org:therapist']?.some((obj) =>
          Object.prototype.hasOwnProperty.call(obj, 'frequencyDomainMetricsCustom')
        ))
    ) {
      setIsFrequencyCalculated(false);
    }

    if (
      areMxLabsReceived &&
      recordingTimeFrame.recordingStartTime &&
      recordingTimeFrame.recordingStopTime
    ) {
      const {
        startTimestamp,
        endTimestamp,
        [USER_ROLES.THERAPIST]: therapistTrimmedData,
        [USER_ROLES.PATIENT]: patientTrimmedData,
      } = Object.keys(mxlabs).reduce(
        (trimmedAcc: Record<string, ProcessedMXLabsData[] | number>, role: string) => {
          const { startTimestamp, endTimestamp, trimmedData } = trimByVideoDuration(
            mxlabs[role],
            recordingTimeFrame.recordingStartTime,
            recordingTimeFrame.recordingStopTime
          );
          if (!trimmedAcc.startTimestamp || !trimmedAcc.endTimestamp) {
            trimmedAcc = {
              startTimestamp,
              endTimestamp,
            };
          }
          trimmedAcc[role] = trimmedData;

          return trimmedAcc;
        },
        {}
      );

      setTrimmedMxLabs({
        [USER_ROLES.THERAPIST]: therapistTrimmedData as ProcessedMXLabsData[],
        [USER_ROLES.PATIENT]: patientTrimmedData as ProcessedMXLabsData[],
      });

      setTimelineRange({
        startTimestamp: startTimestamp as number,
        endTimestamp: endTimestamp as number,
      });

      if (!recordingTimeFrame) {
        setRecordingTimeFrame({
          recordingStartTime: startTimestamp as number,
          recordingStopTime: endTimestamp as number,
        });
      }
    } else {
      // if there is no recording data - use mxlabs timestamps to show a plot
      // handle cases when therapist or patient mxlabs data is missing
      if (mxlabs && !recordingTimeFrame.recordingStartTime) {
        const patientData = mxlabs[USER_ROLES.PATIENT] || [];
        const therapistData = mxlabs[USER_ROLES.THERAPIST] || [];

        const hasPatientData = patientData.length > 0;
        const hasTherapistData = therapistData.length > 0;

        if (hasPatientData || hasTherapistData) {
          const patientStartTime = hasPatientData ? patientData[0].timestamp : null;
          const patientEndTime = hasPatientData ? patientData.at(-1)?.timestamp : null;
          const therapistStartTime = hasTherapistData ? therapistData[0].timestamp : null;
          const therapistEndTime = hasTherapistData ? therapistData.at(-1)?.timestamp : null;

          setTimelineRange({
            startTimestamp: Math.min(patientStartTime ?? Infinity, therapistStartTime ?? Infinity),
            endTimestamp: Math.max(patientEndTime ?? -Infinity, therapistEndTime ?? -Infinity),
          });

          setRecordingTimeFrame({
            recordingStartTime: Math.min(
              patientStartTime ?? Infinity,
              therapistStartTime ?? Infinity
            ),
            recordingStopTime: Math.max(patientEndTime ?? -Infinity, therapistEndTime ?? -Infinity),
          });

          setTrimmedMxLabs({
            [USER_ROLES.THERAPIST]: therapistData,
            [USER_ROLES.PATIENT]: patientData,
          });
        }
      }
    }
  }, [mxlabs, sessionId, recordingTimeFrame]);

  if (mxlabsloading) {
    return generalState.isLoading || mxlabsloading ? (
      <div className="flex flex-col items-center justify-center w-full h-full">
        <div>
          <Loader
            label="Data processing..."
            className="ml-4"
          />
        </div>
      </div>
    ) : (
      <div className="flex items-center justify-center w-full h-full">
        Please try again later. {error}
      </div>
    );
  }
  const { recordingStartTime, recordingStopTime } = recordingTimeFrame;

  return (
    <div className="h-full w-full flex flex-col gap-4 md_d:gap-10 mt-[20px] relative">
      <div className="flex items-center justify-between">
        <PageTitle>Review, Reflect, and Assign</PageTitle>
      </div>
      <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
        <div className="flex h-full  justify-between">
          {/* {framesState.isLoading && <p>Loading frames...</p>} - do we still need this*/}
          {error && <p>Error: {error}</p>}
          {keyMomentsData && updatedKeyMoments && (
            <div className="w-full h-full overflow-y-auto ">
              <TherapistWrapUpStepper
                recordingState={recordingState}
                therapistRecordingState={therapistRecordingState}
                framesState={framesState}
                therapistFramesState={therapistFramesState}
                transcriptsState={transcriptsState}
                keyMomentsData={keyMomentsData}
                videoDifference={videoDifference}
                onTimeUpdate={handleTimeUpdate}
                currentVideoTime={currentTime}
                videoRef={videoRef}
                therapistVideoRef={therapistVideoRef}
                updatedKeyMoments={updatedKeyMoments}
                setUpdatedKeymoments={setUpdatedKeymoments}
                sessionTranscripts={transcriptsState?.data?.transcript}
                framesData={framesState.data}
                therapistFramesData={therapistFramesState.data}
                recordingTimeFrame={recordingTimeFrame}
                sessionId={sessionId}
                setIsLoadingState={setIsLoadingState}
              />
            </div>
          )}
        </div>
      </Sentry.ErrorBoundary>
      <div className="mt-6 md_d:mt-auto">
        {step === 0 && (
          <div className="w-full">
            <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
              {!isFrequencyCalculated && (
                <Button
                  variant="plainSecondary"
                  text="Calculate frequency metrics"
                  onClick={onFrequencyCalculateClick}
                />
              )}
              {trimmedMxLabs && updatedKeyMoments && (
                <Plot
                  plotConfig={{ plotId: 1, plotSize: PlotSizeEnum.MEDIUM }}
                  signalsData={trimmedMxLabs}
                  timelineRange={timelineRange}
                  isHrFilter={false}
                  keyMoments={updatedKeyMoments}
                />
              )}
            </Sentry.ErrorBoundary>
            <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
              <SessionReviewTimeline
                updatedKeyMoments={updatedKeyMoments}
                recordingTimeFrame={recordingTimeFrame}
                videoDuration={videoDuration}
                sessionDuration={sessionDuration}
                currentTime={currentTime}
                recordingStartTime={recordingStartTime}
                recordingStopTime={recordingStopTime}
                handleTimelineClick={handleTimelineClick}
              />
            </Sentry.ErrorBoundary>
          </div>
        )}
      </div>
    </div>
  );
};

export default SessionReviewByTherapist;
