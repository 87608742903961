import { useEffect, useRef, useState } from 'react';

import Button from '@components/Button';
import { PauseIcon, PlayIcon, RefreshCcwIcon } from 'lucide-react';
import AiSimplifiedSettings from '@entities/shared/RealtimeDashboard/ui/SimplifiedView/ui/AiSimplifiedSettings';
import { dashboardActor } from '@pages/shared/realtime-dashboard/model/xstate/dashboardMachine';
import { shallowEqual, useSelector } from '@xstate/react';
import { Loader } from '@shared/ui/loader/Loader';

interface TopBarProps {
  onRestartSession: () => void;
  onFinishSession: () => void;
  onPauseAISession: ({ label, value }: { label: string; value: string }) => void;
  onOpenAdvancedMode: (x: boolean) => void;
  isWatingSessionEnd: boolean;
}

const END_TYPE_OPTIONS_LABEL_MAP = {
  Manual: 'Manual',
  VAD: 'VAD',
};

const END_TYPE_OPTIONS = {
  MANUAL: 'none',
  SERVER_VAD: 'server_vad',
};

const TopBar = ({
  onRestartSession,
  onFinishSession,
  onPauseAISession,
  onOpenAdvancedMode,
  isWatingSessionEnd,
}: TopBarProps) => {
  const currentStep = useSelector(
    dashboardActor,
    (state) => state.context.currentStepIndex,
    shallowEqual
  );
  const currentConversationTopic = useSelector(
    dashboardActor,
    (state) => state.context.currentAIConversationTopic,
    shallowEqual
  );
  const steps = useSelector(dashboardActor, (state) => state.context.steps, shallowEqual);

  const [isVisible, setIsVisible] = useState(false);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState({
    time: '',
    date: '',
  });

  const hideTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current);
      hideTimeoutRef.current = null;
    }
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    hideTimeoutRef.current = setTimeout(() => {
      if (!menuOpen) {
        setIsVisible(false);
      }
    }, 500);
  };

  const handleStopStart = () => {
    const nextPausedState = !isPaused;
    setIsPaused(nextPausedState);

    const label = nextPausedState
      ? END_TYPE_OPTIONS_LABEL_MAP.Manual
      : END_TYPE_OPTIONS_LABEL_MAP.VAD;
    const value = nextPausedState ? END_TYPE_OPTIONS.MANUAL : END_TYPE_OPTIONS.SERVER_VAD;

    onPauseAISession({ label, value });
  };

  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      const time = now.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      const date = now.toLocaleDateString('en-GB').replace(/\//g, '.');
      setCurrentDateTime({ time, date });
    };

    updateDateTime();
    const interval = setInterval(updateDateTime, 1000);

    return () => clearInterval(interval);
  }, []);

  const isSetupStep = steps[currentStep].label === 'Setup';
  const shouldHideTopBar = !menuOpen && !isVisible;

  return (
    <div
      className="relative w-full"
      style={{
        height: '180px',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`fixed z-10 top-0 left-0 w-full h-[150px] text-purple-600 flex items-center justify-between px-4 
        ${shouldHideTopBar ? 'animate-fadeOutUpTopBar' : 'animate-fadeInDownTopBar'}`}
        style={{
          willChange: 'transform, opacity',
          background:
            'linear-gradient(0deg, rgba(147, 52, 234, 0.00) 14.29%, rgba(147, 51, 234, 0.30) 100%)',
        }}
      >
        {currentConversationTopic ? (
          <div className="flex flex-col text-gray-800 font-['Inter'] leading-tight tracking-tight">
            <span className="text-sm font-normal">Current Topic:</span>
            <span className="text-gray-800 text-lg font-bold">{currentConversationTopic}</span>
          </div>
        ) : (
          <div className="flex flex-col text-gray-800 font-['Inter'] leading-tight tracking-tight">
            <span className="text-sm font-normal">Current Stage:</span>
            <span className="text-gray-800 text-lg font-bold">{steps[currentStep].label}</span>
          </div>
        )}
        <div className="flex gap-14">
          <button
            disabled={isSetupStep}
            onClick={handleStopStart}
            className={`${isSetupStep ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
          >
            {isPaused ? (
              <div className="flex gap-3 text-base font-medium">
                <PlayIcon />
                Play
              </div>
            ) : (
              <div className="flex gap-3">
                <PauseIcon />
                Pause
              </div>
            )}
          </button>
          <span className="text-lg font-bold">{currentDateTime.time}</span>
          <span className="text-lg font-medium">{currentDateTime.date}</span>
        </div>
        <div className="flex gap-2 items-center">
          <Button
            onClick={onRestartSession}
            text="Restart Session"
            variant="plain"
            icon={<RefreshCcwIcon />}
          />

          <Button
            variant="primary"
            icon={isWatingSessionEnd && <Loader spinnerClasses="!h-4 !ml-0" />}
            onClick={onFinishSession}
            text="Finish Session"
          />

          <AiSimplifiedSettings
            isSetupStep={isSetupStep}
            onOpenAdvancedMode={onOpenAdvancedMode}
            setMenuOpen={setMenuOpen}
            isTopBarVisible={isVisible}
          />
        </div>
      </div>
    </div>
  );
};

export default TopBar;
