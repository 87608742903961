export const TERMS = [
  {
    blockTitle: 'General',
    blockContent: [
      'Welcome to \'Betteryou Platform\', an AI Assisted Telehealth System ("the System") designed to assist the user ("the User") in remote communication and self-assessment of their mental state, operational at Betteryou.tech and any other or additional addresses as may be from time to time ("the Site", and together with the System: "the Services").',

      'These Terms of Use define your rights and obligations when using the System and the Site and constitute, along with the Site\'s Privacy Policy and any other policy document linked with these terms, a binding agreement between you and us – Better You Technologies Ltd. ("we"). The use of the Site and System indicates your consent to these terms. Therefore, please read these Terms of Use carefully. If, after reading the terms, you find that they do not align with your views or wishes, you should refrain from using the Site. We would regret this, but it is your full right.',

      'These terms apply to you even if you have not registered for the System, except for provisions in these terms that apply only to registered users. These terms of use do not apply for Alpha users.',
      "Changes may be made to these terms due to the dynamic nature of the Site, the Internet as a whole, and aspects of legislation and regulation that may apply to the Site's operations. We will publish advance notice of expected changes on the Site itself or send an email detailing the nature of the change and its effective date.",

      'Your consent to these Terms of Use also constitutes consent to amended terms; however, as required by law, we will ask for your consent to the new terms again. During the notice period and until the updated terms take effect, you will have the opportunity to express your disagreement with them. Accordingly, the permission granted to you to use the Site will expire if you do not agree to the new terms. Due to the limitations of the Hebrew language, in some cases, the wording is in masculine form only, but these terms refer to all genders.',
    ],
  },
  {
    blockTitle: 'Use of the Site and System',
    blockContent: [
      'The use of the Site is intended for obtaining information regarding the System. The use of the System is intended for the User\'s personal use, for testing the System, providing feedback, and general impressions. The Site and System (together: "the Services") should not be used for any other purpose without obtaining our prior written consent. The content of the Services belongs on the Site only, and it is not permitted to publish details and content from them on any other site, application, or media, in any form, nor should they be passed on to others. Additionally:',
    ],
    blockList: [
      'It is not permitted to copy the content of the Services, embed it in other sites, display it through framing, create mirror sites, sell, grant usage licenses, rent, loan, or distribute the content of the Services in any way.',
      'It is not allowed to modify, create derivative works, perform reverse engineering, or take any action aimed at exposing the source files of the Services.',
      'It is forbidden to link to the content of the Services in a way that presents it detached from the web page in which the content is located. When linking to web pages of the Services, it is not allowed to hide, block, disrupt, or delete any information from the web pages of the Services as they are originally presented.',
      'It is not permitted to access the Site or the contents included in it with the intention of developing or creating a similar or competing site, including through the collection of details and information from the Services.',
      'Without our prior written and explicit consent, it is not permitted to use the Site for purposes of examination, scanning, automatic information collection, hacking activities, breaching the security systems of the Services, interfering with the operation of the Services, bypassing the protection mechanisms of the Services, performing manipulations in the activity of the Services, systematic access to the computers of the Services using automatic software tools such as "bots" and "crawlers", and accessing a feature or functionality of the Services that is not intended to be accessible or is undocumented.',
      'The Site should not be used for any activity that constitutes or encourages the commission of criminal acts, civil torts, or otherwise violates relevant law, including laws relating to deception, fraud, forgery, privacy protection, identity theft, computer material intrusion, distribution of viruses and other malicious software, copyright, defamation, harassment, and obscene publications. This includes, but is not limited to, the use of the Services to trade or distribute products and services prohibited by law or contrary to specific regulatory rules, such as weapons, explosives, drugs and narcotics, wild animals, antiquities, tobacco products, alcohol, and dangerous toys.',
      'It is not permitted to link the Site to illegal sites, including those related to gambling, pornography, or hate. At any time, we are entitled to change the nature and scope of the information that will be exposed to you on the Site.',
    ],
  },
  {
    blockTitle: 'Registration for the System',
    blockContent: [
      'Use of part of the Site is also available without opening a user account. Registration for the System is possible through the Site.',
      'During the registration process, we will ask you to provide details and agree to additional terms related to your registration. The provision of these details and our use of them are subject to our Privacy Policy, as detailed below.',
      'We are entitled to cancel your registration for the System or not allow you to register in the first place if, in our discretion, you registered to perform an illegal act (for example: by impersonating another), or to harm others, the Services, us, or anyone on our behalf, if your activity on the Site violates a court order or a binding authority directive, or violates these terms, or if, in our opinion, there is another justified reason for it, without needing to explain our decision.',
      '<strong>Do not use the System or open an account if you are under 18 years of age!</strong>',
    ],
  },
  {
    blockTitle: 'Accessibility',
    blockContent: [
      'This site has not yet undergone accessibility work, as according to the Equal Rights for People with Disabilities (Accessibility Adjustments for Service) Regulations 5773-2013 ("Accessibility Regulations"), as of today, this site is exempt from the obligation to perform accessibility adjustments, by virtue of sections 35(b)(h)(2) and 35(b)(t) of the Accessibility Regulations.',
    ],
  },
  {
    blockTitle: 'Privacy',
    blockContent: [
      'When you access or use our System, we may collect, access, use, and share certain Personal Data (as defined in the  <a href="#" style="color: #9333ea; text-decoration: underline; text-underline-offset: 4px;">Privacy Policy</a>) about you.',
      'Please review our  <a href="#" style="color: #9333ea; text-decoration: underline; text-underline-offset: 4px;">Privacy Policy</a>, incorporated here by reference, for details on our data collection and use practices.',
    ],
  },
  {
    blockTitle: 'Information Security',
    blockContent: [
      'We implement rigorous systems and procedures for information security to protect our services and information against misuse and unauthorized use. These systems reduce security risks, but none provide full assurance, and our service systems, including the software used, are not immune to faults and attacks. Therefore, we cannot guarantee that our services or your personal information will be completely secure against unauthorized access or breach.',
    ],
  },
  {
    blockTitle: 'Liability',
    blockContent: [
      "As mentioned, the content within our services is provided 'as is' and may not suit every individual's needs. Users will not have any claims, demands, or lawsuits against us regarding the content's features, capabilities, limitations, or its suitability for the user's purpose. Your use of the services and exposure to information within them is solely at your own risk. We do not guarantee the completeness, accuracy, legality, or precision of third-party content, including content and services provided by third-party providers and users, nor that they will meet your expectations or requirements. We bear no responsibility for any consequences arising from them, their use, or reliance on them. If you decide to rely on content published in the services or to act based on them, you bear full and sole responsibility for your actions and their outcomes.",
      "Moreover, the system is designed to assist you, the user, in self-assessing your mental state. The system is not intended to provide, and should not be relied upon for, mental health counselling. The use of the system is for the purpose of assisting in drawing conclusions and making decisions by the user only and is the user's sole responsibility.",
      "Occasionally, malfunctions in the service may occur due to issues with the service's communication providers, software systems, changes, and maintenance we perform on the site, among other reasons. Such malfunctions can cause inconvenience and difficulty in using the site, and we will do our best to overcome them as quickly as possible. However, we are not responsible for any inconvenience or damage that may result from them.",
    ],
  },
  {
    blockTitle: 'Law, Jurisdiction, and Dispute Resolution',
    blockContent: [
      'This agreement shall be governed exclusively by the laws of the State of Israel. In any dispute arising between the parties, including disputes related to the use of the site and/or terms of use, the exclusive jurisdiction shall be granted to the competent court in Tel Aviv, Israel.',
    ],
  },
  {
    blockTitle: 'Links',
    blockContent: [
      'The services may contain links ("links") to various pages on the internet. These links allow you to find content published on the internet. Most of this content is not published by us or on our behalf, and we do not control or supervise it. Linking to such content does not imply our agreement with its content nor guarantees its reliability, timeliness, legality, privacy practices, or any other aspect related to its operation. You may find that these contents do not meet your needs, or you may object to their content, or find them offensive, irritating, inappropriate, illegal, or immoral. We are not responsible for the content linked to and are not liable for any consequences resulting from their use or reliance on them.',
    ],
  },
  {
    blockTitle: 'Confidentiality, Intellectual Property, and Non-Competition',
    blockContent: [
      'All copyright and intellectual property rights in the services and the content related to them belong exclusively to us or to a third party who has permitted us to use them. Copyright and intellectual property rights in content uploaded or shared on the services, including links, as far as content can be uploaded and shared on the services, belong solely to the person who uploaded them to the site or shared them, or to a third party who has permitted their use.',

      'You may not copy, distribute, publicly display, perform publicly, broadcast to the public, modify, process, create derivative works, sell, or lease any of the foregoing, whether by yourself or through or in conjunction with a third party, in any manner or means, whether electronic, mechanical, optical, photographic, recording, or any other means, outside the scope of these terms, or without our prior written consent or that of other rights holders, as applicable and subject to the consent given by the rights holders (if given).',
    ],
  },
  {
    blockTitle: 'Feedback',
    blockContent: [
      "You are allowed, but not obliged, to provide us with feedback about the service, comments, and suggestions for improvement ('feedback'). We have the right to use the feedback at our discretion and will own all rights in the feedback. You waive any moral rights you have or may have in the feedback.",
    ],
  },
  {
    blockTitle: 'Contact Us',
    blockContent: [
      'You can contact us at any time at <a href="mailto:team@betteryou.tech" style="color: #9333ea; text-decoration: underline; text-underline-offset: 4px;">team@betteryou.tech</a> with any request or inquiry regarding the terms of use.',
    ],
  },
];
