import React, { useMemo, useRef, useState } from 'react';
import Uplot from 'uplot';
import UplotReact from 'uplot-react';
import 'uplot/dist/uPlot.min.css';

type PSDPlotProps = {
  frequencies: number[];
  powerValues: number[];
};

const PSDPlot: React.FC<PSDPlotProps> = ({ frequencies, powerValues }) => {
  const chartContainerRef = useRef<HTMLDivElement | null>(null);

  // Define frequency bands
  const bands = [
    { x0: 0.003, x1: 0.04, color: 'rgba(255, 0, 0, 0.2)' }, // VLF (red)
    { x0: 0.04, x1: 0.15, color: 'rgba(0, 255, 0, 0.2)' }, // LF (green)
    { x0: 0.15, x1: 0.4, color: 'rgba(147, 51, 234, 0.2)' }, // HF  #9333EA(purple)
  ];

  // Custom plugin for shading frequency bands
  const bandShadingPlugin = () => {
    return {
      hooks: {
        draw: (u: Uplot) => {
          const ctx = u.ctx;
          const { top, height } = u.bbox;

          bands.forEach(({ x0, x1, color }) => {
            const x0Px = u.valToPos(x0, 'x', true);
            const x1Px = u.valToPos(x1, 'x', true);

            ctx.fillStyle = color;
            ctx.fillRect(x0Px, top, x1Px - x0Px, height);
          });
        },
      },
    };
  };
  const [opts] = useState<Uplot.Options>({
    width: 400,
    height: 200,
    scales: {
      x: { time: false, min: 0, max: 0.45, auto: false },
      y: { auto: true },
    },
    series: [
      { label: 'Frequency (Hz)' },
      { label: 'Power (µW)', stroke: '#9333EA', fill: 'rgba(147, 51, 234, 0.3)', width: 2 },
    ],
    plugins: [bandShadingPlugin()],

    legend: { show: true },
    hooks: {
      draw: [
        (u) => {
          const legendEl = u.root.querySelector('.u-legend') as HTMLElement;
          if (legendEl) {
            legendEl.style.position = 'absolute';
            legendEl.style.right = '0px';
            legendEl.style.top = '0px';
            legendEl.style.background = 'rgba(255, 255, 255, 0.5)';
            legendEl.style.padding = '2px';
            legendEl.style.borderRadius = '5px';
          }
        },
      ],
    },
  });

  const data = useMemo(() => {
    if (!frequencies.length || !powerValues.length) return [[0], [0]] as Uplot.AlignedData;
    const scaledPowerValues = powerValues.map((p) => p * 1e6); // Micro-Watts instead of Watts

    return [frequencies, scaledPowerValues] as Uplot.AlignedData;
  }, [frequencies, powerValues]);
  return (
    <div
      ref={chartContainerRef}
      className="relative"
      style={{ width: 400, height: 200 }}
    >
      {opts ? (
        <UplotReact
          options={opts}
          data={data}
        />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default PSDPlot;
