import { ValueOf } from '@utils/types';

const DateFormat = {
  HH_MM: 'hours',
  DD_MM: 'days',
  HH_MM_SS: 'HH:MM:SS',
  YYYY_MM_DD: 'full',
} as const;

type FormatTimestampOptions = ValueOf<typeof DateFormat>;

export const formatTimestamp = (timestamp: number, format: FormatTimestampOptions): string => {
  const date = new Date(timestamp);

  switch (format) {
    case DateFormat.HH_MM:
      return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
    case DateFormat.HH_MM_SS:
      return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
    case DateFormat.DD_MM:
      return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}`;

    case DateFormat.YYYY_MM_DD:
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

    default:
      throw new Error(`Unknown date format: ${format}`);
  }
};

export const formatSessionDate = (timestamp: number) => ({
  time: formatTimestamp(timestamp, 'hours'),
  date: formatTimestamp(timestamp, 'days'),
});

export const formatDeltaMsToTime = (deltaTimeStamp: number) => {
  let seconds = Math.floor(deltaTimeStamp / 1000);
  let hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  let minutes = Math.floor(seconds / 60);
  seconds %= 60;

  const _seconds = seconds < 10 ? `0${seconds}` : seconds;
  const _minutes = minutes < 10 ? `0${minutes}` : minutes;
  const _hours = hours < 10 ? `0${hours}` : hours;

  if (hours === 0 && minutes === 0) {
    return `${_seconds} s`;
  } else if (hours === 0) {
    return `${_minutes}:${_seconds}`;
  } else {
    return `${_hours}:${_minutes}:${_seconds}`;
  }
};
