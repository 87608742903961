import { ReactNode, createContext, useContext, useEffect, useState, FC } from 'react';
import { io, Socket } from 'socket.io-client';
import { BASE_WS_URL } from '@api/base';

interface WSTherapistMessage {
  therapistId: string;
  content: string;
}

interface WebSocketContextType {
  socket: Socket | null;
  messages: WSTherapistMessage[];
}

interface WebSocketProviderProps {
  children: ReactNode;
}

const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);

export const WebSocketProvider: FC<WebSocketProviderProps> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [messages, setMessages] = useState<WSTherapistMessage[]>([]);

  useEffect(() => {
    const socket = io(BASE_WS_URL, {
      path: '/server-rt',
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 2000,
      reconnectionDelayMax: 10000,
      withCredentials: true,
      transports: ['websocket', 'polling'],
    });

    socket.on('connect', () => {
      console.log('Connected to the WebSocket server');
    });

    socket.on('message', (message: WSTherapistMessage) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    socket.on('disconnect', (reason: Socket.DisconnectReason, description?: any) => {
      console.log(
        'Disconnected from the WebSocket server',
        JSON.stringify({ reason, description })
      );
    });

    setSocket(socket);

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <WebSocketContext.Provider value={{ socket, messages }}>{children}</WebSocketContext.Provider>
  );
};

export const useWebSocket = (): WebSocketContextType => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};
