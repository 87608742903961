import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';

import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
import { saveUpdatedKeyMoments } from '@api/userAPI';

import { useSelector } from '@xstate/react';
import TherapistWrapUpStepper from './TherapistWrapUpStepper';

import Plot from '@components/Plot';
import { useMXLabsLogs } from '@components/Plot/utils';
import { ITimelineRange, PlotSizeEnum } from '@components/Plot/types';
import { AppContext } from '../../../contextApp';
import { Loader } from '@shared/ui/loader/Loader';
import { fetchCurrentUserDetails } from '@api/user/me';
import { trimByVideoDuration } from '@utils/HRGraph/trimByVideoDuration';
import { ProcessedMXLabsData } from '@shared/types/mxlabs/logs';
import { USER_ROLES } from '@interfaces/user';
import { KeyMomentsData, KeyMomentWithRange } from '@components/SessionComponents/KeyMoments/types';
import { ShamefulAny } from '@interfaces/index';

import { PageTitle } from '@shared/ui/caption/PageTitle';
import { getSessionInfo } from '@api/session/sessionInfo';
import { getTherapistRecordingInstances, getPatientRecordingInstances } from '@utils/helpers';
import { RecordingInstance } from '@utils/types';

import * as Sentry from '@sentry/react';
import { SessionReviewTimeline } from '@components/SessionReview/ui/SessionReviewTimeline';

type SessionWrapupProps = {
  keyMomentData: KeyMomentsData | null;
  updatedKeyMoments: KeyMomentsData | null;

  loadingState: Record<
    string,
    { isLoading: boolean; isFailed: boolean | null; error: string | null; data: ShamefulAny }
  >;
  setUpdatedKeymoments:
    | React.Dispatch<React.SetStateAction<KeyMomentsData | undefined>>
    | ShamefulAny;
};

const SessionWrapup = ({
  keyMomentData,
  updatedKeyMoments,
  loadingState,
  setUpdatedKeymoments,
}: SessionWrapupProps) => {
  const stateContext = useSelector(refaelaTherapistActor, (state) => state.context);

  const patientId = stateContext.currentPatientId;
  const therapistId = stateContext.currentUser?.therapistId;
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const sessionId = stateContext?.roomUrl?.split('/').pop()!;

  const [videoDuration, setVideoDuration] = useState<number | null>(null);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [trimmedMxLabs, setTrimmedMxLabs] = useState<Record<string, ProcessedMXLabsData[]> | null>(
    null
  );

  const [sessionDuration, setSessionDuration] = useState<number | null>(null); // for case when there is no video only frames
  const [videoDifference, setVideoDiff] = useState<{
    recordingStartsFirst: 'therapistRecording' | 'patientRecording';
    differenceBetweenStarts: number;
  } | null>(null);

  const videoRef = useRef<HTMLVideoElement>(null);
  const therapistVideoRef = useRef<HTMLVideoElement>(null);

  const [recordingTimeFrame, setRecordingTimeFrame] = useState<{
    recordingStartTime: number | undefined;
    recordingStopTime: number | undefined;
  }>({
    recordingStartTime: undefined,
    recordingStopTime: undefined,
  });

  const [timelineRange, setTimelineRange] = useState<ITimelineRange>({
    startTimestamp: 0,
    endTimestamp: 0,
  });

  const { step, setStep } = useContext(AppContext);

  const {
    recording: recordingState,
    transcripts: transcriptsState,
    therapistRecording: therapistRecordingState,
    general: generalState,
    frames: framesState,
    therapistFrames: therapistFramesState,
  } = loadingState;

  useEffect(() => {
    const { recordingStartTime, recordingStopTime } = recordingTimeFrame;
    if (!recordingStopTime || !recordingStartTime) return;

    setSessionDuration(recordingStopTime - recordingStartTime);
  }, [recordingTimeFrame]);

  useEffect(() => {
    setStep(0);
  }, []);

  useEffect(() => {
    if (!updatedKeyMoments || !patientId || !therapistId || !sessionId) return;

    // Create a new copy to prevent mutation
    const newUpdatedKeyMoments = {
      ...updatedKeyMoments,
      keyMoments: updatedKeyMoments.keyMoments.map((moment: KeyMomentWithRange) => ({
        ...moment,
        keyMomentRange:
          !moment.transcripts || moment.transcripts.length === 0
            ? { startTime: null, endTime: null }
            : {
                startTime: moment.transcripts[0]?.timestamp ?? null,
                endTime: moment.transcripts.at(-1)?.timestamp ?? null,
              },
      })),
    };

    saveUpdatedKeyMoments(newUpdatedKeyMoments, patientId, therapistId, sessionId);
  }, [updatedKeyMoments, patientId, therapistId, sessionId]);

  const fetchSessionData = useCallback(async () => {
    try {
      const sessionResponse = await getSessionInfo(sessionId);

      if (sessionResponse.error) {
        throw new Error(sessionResponse.message);
      }

      const therapistInstances = getTherapistRecordingInstances(
        sessionResponse
      ) as RecordingInstance;
      const patientInstances = getPatientRecordingInstances(sessionResponse) as RecordingInstance;

      // Check if both start and stop times exist in both instances
      if (
        therapistInstances.recordingStartTime &&
        therapistInstances.recordingStopTime &&
        patientInstances.recordingStartTime &&
        patientInstances.recordingStopTime
      ) {
        const therapistStartTime = therapistInstances.recordingStartTime;
        const patientStartTime = patientInstances.recordingStartTime;

        const startTimeToUse = Math.max(therapistStartTime, patientStartTime);
        const stopTimeToUse = Math.min(
          therapistInstances.recordingStopTime,
          patientInstances.recordingStopTime
        );

        const differenceBetweenStarts = Math.abs(therapistStartTime - patientStartTime);
        const recordingStartsFirst =
          therapistStartTime < patientStartTime ? 'therapistRecording' : 'patientRecording';

        setRecordingTimeFrame({
          recordingStartTime: new Date(startTimeToUse).getTime(),
          recordingStopTime: new Date(stopTimeToUse).getTime(),
        });

        setVideoDiff({ recordingStartsFirst, differenceBetweenStarts });

        const videoDurationToUse = stopTimeToUse - startTimeToUse; // in ms

        setVideoDuration(videoDurationToUse);
      } else {
        console.warn('Missing recordingStartTime or recordingStopTime in instances');
      }
    } catch (error: ShamefulAny) {
      console.error('Error fetching session data:', JSON.stringify(error));
    }
  }, [sessionId]);

  useEffect(() => {
    fetchSessionData();
  }, [fetchSessionData]);

  const handleTimeUpdate = (currentTimeMillis: number) => {
    setCurrentTime(currentTimeMillis);
  };

  const handleUserUpdateOnFinishSessionReview = async () => {
    if (therapistId) {
      try {
        const userId = therapistId.includes('_T') ? therapistId.replace('_T', '') : therapistId;
        const newUserData = await fetchCurrentUserDetails(userId);
        refaelaTherapistActor.send({ type: 'therapistUser.update', currentUser: newUserData });
      } catch (e) {
        console.error('update error for', therapistId);
      }
    }
  };

  const handleTimelineClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const durationToUse = videoDuration || sessionDuration;

    if (durationToUse) {
      const timelineRect = e.currentTarget.getBoundingClientRect();
      const clickPositionX = e.clientX - timelineRect.left;
      const clickPositionPercent = clickPositionX / timelineRect.width;
      const newTimeInSeconds = clickPositionPercent * (durationToUse / 1000);

      if (videoDifference) {
        const { recordingStartsFirst, differenceBetweenStarts } = videoDifference;

        const safeDifference = isNaN(differenceBetweenStarts) ? 0 : differenceBetweenStarts / 1000;

        if (recordingStartsFirst === 'therapistRecording' && therapistVideoRef.current) {
          therapistVideoRef.current.currentTime = newTimeInSeconds + safeDifference;
        } else if (recordingStartsFirst === 'patientRecording' && videoRef.current) {
          videoRef.current.currentTime = newTimeInSeconds + safeDifference;
        }
      } else {
        if (videoRef.current) {
          videoRef.current.currentTime = newTimeInSeconds;
        }
        if (therapistVideoRef.current) {
          therapistVideoRef.current.currentTime = newTimeInSeconds;
        }
      }
      handleTimeUpdate(newTimeInSeconds * 1000);
    }
  };
  const { logs: mxlabs, loading: mxlabsloading } = useMXLabsLogs(
    therapistId!,
    patientId!,
    sessionId!
  );
  useEffect(() => {
    const areMxLabsReceived =
      mxlabs && Object.values(mxlabs).every((logs: ProcessedMXLabsData[]) => logs.length);

    if (
      areMxLabsReceived &&
      recordingTimeFrame.recordingStartTime &&
      recordingTimeFrame.recordingStopTime
    ) {
      const {
        startTimestamp,
        endTimestamp,
        [USER_ROLES.THERAPIST]: therapistTrimmedData,
        [USER_ROLES.PATIENT]: patientTrimmedData,
      } = Object.keys(mxlabs).reduce(
        (trimmedAcc: Record<string, ProcessedMXLabsData[] | number>, role: string) => {
          const { startTimestamp, endTimestamp, trimmedData } = trimByVideoDuration(
            mxlabs[role],
            recordingTimeFrame.recordingStartTime,
            recordingTimeFrame.recordingStopTime
          );
          if (!trimmedAcc.startTimestamp || !trimmedAcc.endTimestamp) {
            trimmedAcc = {
              startTimestamp,
              endTimestamp,
            };
          }
          trimmedAcc[role] = trimmedData;

          return trimmedAcc;
        },
        {}
      );

      setTrimmedMxLabs({
        [USER_ROLES.THERAPIST]: therapistTrimmedData as ProcessedMXLabsData[],
        [USER_ROLES.PATIENT]: patientTrimmedData as ProcessedMXLabsData[],
      });

      setTimelineRange({
        startTimestamp: startTimestamp as number,
        endTimestamp: endTimestamp as number,
      });
    }
  }, [mxlabs, sessionId, recordingTimeFrame]);

  if (mxlabsloading) {
    return generalState.isLoading || mxlabsloading ? (
      <div className="flex flex-col items-center justify-center w-full h-full">
        <div>
          <Loader
            label="Data processing..."
            className="ml-4"
          />
        </div>
      </div>
    ) : (
      <div className="flex items-center justify-center w-full h-full">Please try again later.</div>
    );
  }

  if (!sessionId) {
    return (
      <div className="flex w-full h-full justify-center items-center">
        No session id was found. Can't proceed data processing
      </div>
    );
  }

  const { recordingStartTime, recordingStopTime } = recordingTimeFrame;

  return (
    <div className="h-full w-full flex flex-col gap-4 md_d:gap-10 mt-[20px] relative">
      <div className="flex items-center justify-between">
        <PageTitle>Review, Reflect, and Assign</PageTitle>
      </div>
      <div className="flex h-full  justify-between">
        {keyMomentData && updatedKeyMoments && (
          <div className="w-full h-fit">
            <TherapistWrapUpStepper
              keyMomentsData={keyMomentData}
              videoDifference={videoDifference}
              onTimeUpdate={handleTimeUpdate}
              currentVideoTime={currentTime}
              videoRef={videoRef}
              updatedKeyMoments={updatedKeyMoments}
              setUpdatedKeymoments={setUpdatedKeymoments}
              sessionTranscripts={transcriptsState?.data?.transcript}
              recordingState={recordingState}
              therapistRecordingState={therapistRecordingState}
              transcriptsState={transcriptsState}
              framesState={framesState}
              onFinishSession={handleUserUpdateOnFinishSessionReview}
              framesData={framesState.data}
              recordingTimeFrame={recordingTimeFrame}
              therapistVideoRef={therapistVideoRef}
              therapistFramesData={therapistFramesState.data} // TODO:??? check if need both therapistFramesState and therapistFramesData
              therapistFramesState={therapistFramesState}
              sessionId={sessionId}
            />
          </div>
        )}
      </div>
      <div className="mt-6 md_d:mt-auto">
        {step === 0 && (
          <div className="w-full">
            <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
              {trimmedMxLabs && updatedKeyMoments && (
                <Plot
                  plotConfig={{ plotId: 1, plotSize: PlotSizeEnum.MEDIUM }}
                  signalsData={trimmedMxLabs}
                  timelineRange={timelineRange}
                  isHrFilter={false}
                  keyMoments={updatedKeyMoments}
                />
              )}{' '}
            </Sentry.ErrorBoundary>

            <SessionReviewTimeline
              updatedKeyMoments={updatedKeyMoments}
              recordingTimeFrame={recordingTimeFrame}
              videoDuration={videoDuration}
              sessionDuration={sessionDuration}
              currentTime={currentTime}
              recordingStartTime={recordingStartTime}
              recordingStopTime={recordingStopTime}
              handleTimelineClick={handleTimelineClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SessionWrapup;
