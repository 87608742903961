import { isUserExist } from './isUserExist';
import { createUserDb } from '@services/db/users/createUserDb';

export const insertCurrentUser = async (id: string) => {
  try {
    const isExist = await isUserExist(id);

    if (!isExist) {
      await createUserDb(id);
      return;
    }
  } catch (e) {
    (e as any).sentryHandled = true;
  }
};
