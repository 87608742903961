import { ShamefulAny } from '@interfaces/index';
import { apiClient } from '..';

export async function getLastSessionWithTherapist(
  patientId: string,
  therapistId: string
): Promise<ShamefulAny> {
  try {
    const { data: lastSessionData } = await apiClient.post(
      '/video_session/getLastSessionWithTherapist',
      {
        patientId,
        therapistId,
      }
    );

    const lastSession = lastSessionData.lastSession;

    return { lastSession };
  } catch (e: ShamefulAny) {
    e.sentryHandled = true;
    console.error('Error fetching recording:', e.message, e.details);

    return {
      error: true,
      message: `${e.message}: ${e.details || 'No additional details'}`,
    };
  }
}

export async function getSessionWithTherapistDataById(
  patientId: string,
  therapistId: string,
  sessionId: string
): Promise<ShamefulAny> {
  try {
    const { data } = await apiClient.post('/video_session/get_session_with_therapist_by_id', {
      patientId,
      therapistId,
      sessionId,
    });

    return (
      data.sessionWithTherapistBySessionId ?? {
        error: true,
        message: 'No session data found',
      }
    );
  } catch (e: ShamefulAny) {
    console.error('Error fetching session data:', e.message, e.details);
    return { error: true, message: `${e.message}: ${e.details || 'No additional details'}` };
  }
}
