import { Loader } from '@shared/ui/loader/Loader';
import { Pin, PinOff } from 'lucide-react';
import { memo } from 'react';

type AISimplifiedShenaiCanvas = {
  isSDKInitialized?: boolean;
  isPinned: boolean;
  isConnected: boolean;
  onPin: () => void;
};

const AISimplifiedShenaiCanvas = ({
  isSDKInitialized,
  onPin,
  isPinned,
  isConnected,
}: AISimplifiedShenaiCanvas) => {
  return (
    <div className="relative w-[9.1875rem] h-[13.8125rem]">
      {isConnected && (
        <div
          onClick={onPin}
          className={`${isSDKInitialized ? 'opacity-100' : 'opacity-0'} cursor-pointer shadow-md bg-white absolute top-[-0.625rem] left-[-0.625rem] rounded-lg p-2`}
        >
          {!isPinned && (
            <Pin
              key="pin"
              className="text-purple-600 w-5 h-5"
            />
          )}
          {isPinned && (
            <PinOff
              key="pinoff"
              className="text-purple-600 w-5 h-5"
            />
          )}
        </div>
      )}
      {!isSDKInitialized && <Loader className="absolute top-1/2 left-1/3" />}
      <div className="w-full h-full rounded-lg overflow-hidden">
        <canvas
          className={`${isSDKInitialized ? 'opacity-100' : 'opacity-0'} w-full h-full aspect-[2] object-cover`}
          id="mxcanvas-ai-simplified"
        />
      </div>
    </div>
  );
};

const MemoAISimplifiedShenaiCanvas = memo(AISimplifiedShenaiCanvas);
MemoAISimplifiedShenaiCanvas.displayName = 'AISimplifiedShenaiCanvas';

export default MemoAISimplifiedShenaiCanvas;
