import Button from '@components/Button';
import { dashboardActor } from '@pages/shared/realtime-dashboard/model/xstate/dashboardMachine';
import { shallowEqual, useSelector } from '@xstate/react';
import { useEffect, useState } from 'react';

type StartSessionButton = {
  onClick: () => void;
};

export const StartSessionButton = ({ onClick }: StartSessionButton) => {
  const shenAIdata = useSelector(dashboardActor, (state) => state.context.sdkData, shallowEqual);

  const [clickedOnDisabled, setClickedOnDisabled] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);

  const handleStartSessionClick = () => {
    if (!buttonEnabled) {
      setClickedOnDisabled(true);

      setTimeout(() => {
        setClickedOnDisabled(false);
      }, 3000);
    } else {
      onClick?.();
    }
  };

  useEffect(() => {
    if (shenAIdata?.hr10s && shenAIdata?.hrvSDNN && shenAIdata?.cardiacStressRT) {
      setButtonEnabled(true);
    } else {
      setButtonEnabled(false);
    }
  }, [shenAIdata?.hr10s, shenAIdata?.hrvSDNN, shenAIdata?.cardiacStressRT]);

  return (
    <div className="z-10">
      <Button
        variant="primary"
        text="Start Session"
        onClick={handleStartSessionClick}
        className={`${!buttonEnabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer opacity-100'} `}
      />

      {!buttonEnabled && clickedOnDisabled && (
        <div className="mt-2 text-sm text-red-600">
          You’ll be able to start the session once setup is complete
        </div>
      )}
    </div>
  );
};
