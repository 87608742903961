import ToggleableTabs from '@features/Tab/ToggleableTabs/ToggleableTabs';
import { TOGGLEABLE_SECTIONS } from '@entities/shared/RealtimeDashboard/model/constants/togglableSections';
import { useSelector } from '@xstate/react';
import { dashboardActor } from '@pages/shared/realtime-dashboard/model/xstate/dashboardMachine';
import { KeywordChart } from '@entities/shared/RealtimeDashboard/ui/AdvancedView/ui/barChart';
import { memo } from 'react';

type SelfReviewFrequentKeywordsDataProps = {
  frequentKeywords: FrequentKeywordsLibrary;
};

export type FrequentKeywordsLibrary = {
  [keyword: string]: number;
};

const SelfReviewFrequentKeywordsData = ({
  frequentKeywords,
}: SelfReviewFrequentKeywordsDataProps) => {
  const sections = useSelector(dashboardActor, (state) => state.context.sections);

  const handleSectionToggle = (key: string) => {
    dashboardActor.send({ type: 'TOGGLE_SECTION', key });
  };

  return (
    <div className="mb-4 space-y-4">
      <div className="flex flex-col gap-4 w-full">
        <ToggleableTabs
          isShown={sections[TOGGLEABLE_SECTIONS.FREQUENT_KEYWORDS]}
          onToggle={() => handleSectionToggle(TOGGLEABLE_SECTIONS.FREQUENT_KEYWORDS)}
          tabs={[
            {
              label: 'Frequent Keywords',
              content: (
                <div className="w-full">
                  <KeywordChart keywords={frequentKeywords} />
                </div>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default memo(SelfReviewFrequentKeywordsData);
