import { Patient } from '@interfaces/patient';
import { TSessionInstance } from '@interfaces/session';

type Session = { patient: Patient; session: TSessionInstance };

export const getPreviousSessionsData = (
  patients?: Patient[]
): { previousSessions: Session[]; assignedSessions: any[] } => {
  if (!patients || patients.length === 0) return { previousSessions: [], assignedSessions: [] };

  const previousSessions: Session[] = [];
  const assignedSessions: any[] = [];

  for (const patient of patients) {
    // Check if patient has assignedHomeworks and count > 0 for any session type
    if (patient && patient.assignedHomeworks && Array.isArray(patient.assignedHomeworks)) {
      // Iterate over each assigned homework type
      patient.assignedHomeworks.forEach((homework) => {
        if (homework.count > 0) {
          // Add this session type to assignedSessions
          assignedSessions.push({
            patientName: patient.name,
            patientSurname: patient.surname,
            image: patient.image,
            patientId: patient.patientId,
            type: homework.type,
            count: homework.count,
            assignedSessionsByType: homework.assignedSessionsByType,
          });
        }
      });
    }

    // Continue if the patient has no sessions
    if (patient.sessions.length === 0) continue;

    // Process each session to check if it's a previous session
    for (const session of patient.sessions) {
      const sessionTimestamp = new Date(session.timestamp).getTime();

      // If the session timestamp is earlier than now, add it to previousSessions
      if (sessionTimestamp < new Date().getTime()) {
        previousSessions.push({ patient, session });
      }
    }
  }

  // Sort the previous sessions based on the timestamp, descending
  previousSessions.sort(
    (a, b) => new Date(b.session.timestamp).getTime() - new Date(a.session.timestamp).getTime()
  );

  return { previousSessions, assignedSessions };
};
