import { memo, useState } from 'react';
import { useSelector } from '@xstate/react';
// xState
import { dashboardActor } from '@pages/shared/realtime-dashboard/model/xstate/dashboardMachine';
// Constants
import { TOGGLEABLE_SECTIONS } from '@entities/shared/RealtimeDashboard/model/constants/togglableSections';
// Components
import Button from '@components/Button';
import HeartRateGraph from './HeartRateGraph';
import HRVSDNNGraph from './HRVSDNNGraph';
import CardiacStressGraph from './CardiacStressGraph';
import TachogramGraph from './TachogramGraph';
import PSDGraph from './PSDGraph';

type RealtimeMeasurementGraphsProps = {
  onSectionToggleByKey: (key: string) => void;
};

const RealtimeMeasurementGraphs = ({ onSectionToggleByKey }: RealtimeMeasurementGraphsProps) => {
  const sections = useSelector(dashboardActor, (state) => state.context.sections);

  const [isCleared, setIsCleared] = useState<boolean>(false);

  const handleSectionToggle = (key: string) => {
    onSectionToggleByKey(key);
  };

  const handlePlotsCleared = () => {
    setIsCleared(true);
    setTimeout(() => setIsCleared(false), 1000);
  };

  return (
    <div className="mb-4 w-full">
      <div className="flex items-center mb-2 justify-between">
        <div className="w-56 h-6 text-black  text-[15px] font-bold font-['Inter'] leading-normal">
          Realtime measurement graphs
        </div>

        <Button
          onClick={handlePlotsCleared}
          variant="outlinedSecondary"
          text={isCleared ? 'Clearing..' : 'Clear all'}
        />
      </div>

      <div className="flex flex-col items-center gap-3 w-full">
        <PSDGraph />
        <HeartRateGraph
          isCleared={isCleared}
          isShown={sections[TOGGLEABLE_SECTIONS.REALTIME_MEASUREMENT_GRAPHS_HEARTRATE]}
          onToggle={() =>
            handleSectionToggle(TOGGLEABLE_SECTIONS.REALTIME_MEASUREMENT_GRAPHS_HEARTRATE)
          }
        />
        <HRVSDNNGraph
          isCleared={isCleared}
          isShown={sections[TOGGLEABLE_SECTIONS.REALTIME_MEASUREMENT_GRAPHS_HRVSDNN]}
          onToggle={() =>
            handleSectionToggle(TOGGLEABLE_SECTIONS.REALTIME_MEASUREMENT_GRAPHS_HRVSDNN)
          }
        />
        <CardiacStressGraph
          isShown={sections[TOGGLEABLE_SECTIONS.REALTIME_MEASUREMENT_GRAPHS_CARDIAC_STRESS]}
          onToggle={() =>
            handleSectionToggle(TOGGLEABLE_SECTIONS.REALTIME_MEASUREMENT_GRAPHS_CARDIAC_STRESS)
          }
          isCleared={isCleared}
        />
        <TachogramGraph
          isShown={sections[TOGGLEABLE_SECTIONS.REALTIME_MEASUREMENT_GRAPHS_TACHOGRAM]}
          onToggle={() =>
            handleSectionToggle(TOGGLEABLE_SECTIONS.REALTIME_MEASUREMENT_GRAPHS_TACHOGRAM)
          }
          isCleared={isCleared}
        />
      </div>
    </div>
  );
};

export default memo(RealtimeMeasurementGraphs);
