import ToggleableTabs from '@features/Tab/ToggleableTabs/ToggleableTabs';

import ValueTimePlot from '@features/Plot/ValueTimePlot';
import { useSelector } from '@xstate/react';
import { dashboardActor } from '@pages/shared/realtime-dashboard/model/xstate/dashboardMachine';

type HRVSDNNGraphProps = {
  isShown: boolean;
  onToggle: () => void;
  isCleared: boolean;
};

const HRVSDNNGraph = ({ isShown, onToggle, isCleared }: HRVSDNNGraphProps) => {
  const sdkData = useSelector(dashboardActor, (state) => state.context.sdkData);
  return (
    <ToggleableTabs
      isShown={isShown}
      onToggle={onToggle}
      withCardStyle
      titleClasses="max-w-[190px]"
      tabs={[
        {
          label: 'SDNN',
          content: (
            <ValueTimePlot
              key="hrv-plot"
              sdkData={sdkData}
              valueForPlot={sdkData?.hrvSDNN}
              isCleared={isCleared}
            />
          ),
        },
      ]}
      additionalData={sdkData?.hrvSDNN && `${sdkData?.hrvSDNN} ms`}
    />
  );
};

export default HRVSDNNGraph;
