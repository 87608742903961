import { apiClient } from '..';

export const fetchPatientList = async (userId: string) => {
  try {
    const { data } = await apiClient.get(`/patient/${userId}/patientList`);
    return data.patientList;
  } catch (error: any) {
    error.sentryHandled = true;
    console.error('Error getting patient list', JSON.stringify(error));
  }
};
