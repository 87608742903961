import { memo, useCallback, useState } from 'react';
import { useSelector } from '@xstate/react';
// xState
import { dashboardActor } from '@pages/shared/realtime-dashboard/model/xstate/dashboardMachine';
// Constants
import { TOGGLEABLE_SECTIONS } from '@entities/shared/RealtimeDashboard/model/constants/togglableSections';
// Types
import { ShamefulAny } from '@interfaces/index';
// Components
import AgentState from '@entities/shared/RealtimeDashboard/ui/AdvancedView/ui/AgentState.js';
import ConversationData from '@entities/shared/RealtimeDashboard/ui/AdvancedView/ui/ConversationData.js';
import ToggleableTabs from '@features/Tab/ToggleableTabs/ToggleableTabs';
import { SelectOption, SimpleSelect } from '@shared/ui/input/select/SimpleSelect';
import StateControls from '@entities/shared/RealtimeDashboard/ui/AdvancedView/ui/Stepper/StateControls';

import Button from '@components/Button';

import './ConsolePage.css';
import { SourcedRealtimeEvent } from '@pages/shared/realtime-dashboard/model/types';

const mock = [
  { label: 'Setup', value: 0 },
  { label: 'Start and chat', value: 1 },
  { label: 'Do exercise', value: 2 },
  { label: 'Reflect and finish', value: 3 },
];

interface RealtimeAIProps {
  apiKey?: string;
  hideUI?: boolean;
  agentState?: ShamefulAny;
  startTimeRef?: ShamefulAny;
  realtimeEvents?: SourcedRealtimeEvent[];
  voiceBotInstance: ShamefulAny;
  wavRecorder?: ShamefulAny;
  wavStreamPlayer?: ShamefulAny;
  customInstructions?: string;
  onInstructionsUpdate: ({ key, value }: { key: string; value: string }) => void;
  onSectionToggleByKey?: (key: string) => void;
}

const RealtimeAI = ({
  hideUI = false,
  agentState,
  startTimeRef,
  wavRecorder,
  wavStreamPlayer,
  voiceBotInstance,
  onInstructionsUpdate,
  onSectionToggleByKey,
}: RealtimeAIProps) => {
  const sections = useSelector(dashboardActor, (state) => state.context.sections);
  const items = useSelector(dashboardActor, (state) => state.context.items);
  const isConnected = useSelector(dashboardActor, (state) => state.context.isConnected);
  const realtimeEvents = useSelector(dashboardActor, (state) => state.context.realtimeEvents);
  const sessionPrompt = useSelector(dashboardActor, (state) => state.context.sessionPrompt);

  const [instructions, setInstructions] = useState(sessionPrompt);

  const handleSectionToggle = useCallback(
    (key: string) => {
      onSectionToggleByKey?.(key);
    },
    [onSectionToggleByKey]
  );

  const handleInstructionsChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newInstructions = event.target.value;
    setInstructions(newInstructions);
  }, []);

  const handleUpdateInstructionsConfirm = useCallback(() => {
    onInstructionsUpdate({ key: 'instructions', value: instructions });
  }, [instructions, onInstructionsUpdate]);

  if (hideUI) {
    return null;
  }

  return (
    <div data-component="ConsolePage">
      <div className="flex flex-col mt-5 space-y-4 w-full">
        <StateControls
          wavRecorder={wavRecorder}
          wavStreamPlayer={wavStreamPlayer}
        />
        <ToggleableTabs
          isShown={sections[TOGGLEABLE_SECTIONS.SESSION_SETTINGS]}
          onToggle={() => handleSectionToggle(TOGGLEABLE_SECTIONS.SESSION_SETTINGS)}
          withCardStyle
          tabs={[
            {
              label: 'Session settings',
              content: (
                <div className="flex flex-col">
                  <div className="flex px-1 space-x-4">
                    <div className="w-2/3 mb-4">
                      <span className="text-black text-[13px] font-bold font-['Inter'] leading-normal">
                        System Instructions
                      </span>
                      <textarea
                        className="w-full border border-gray-300 rounded p-2 resize-none"
                        rows={4}
                        value={instructions}
                        onChange={handleInstructionsChange}
                      />
                      <Button
                        text="Update instructions"
                        onClick={handleUpdateInstructionsConfirm}
                      />
                    </div>
                    <div className="flex flex-col w-1/3 mb-4">
                      <span className="text-black text-[13px] mb-1 font-bold font-['Inter'] leading-normal">
                        Start state
                      </span>
                      <SimpleSelect
                        disabled={false}
                        withSearch={false}
                        selectClass="w-[11rem] md_d:w-full"
                        selectDropdownClass="w-[11rem]"
                      >
                        {mock.map((setup, index) => {
                          return (
                            <SelectOption
                              key={index}
                              value={setup.value}
                            >
                              {setup.label}
                            </SelectOption>
                          );
                        })}
                      </SimpleSelect>
                      <div className="flex mt-4 justify-end">
                        <Button text="Start auto session" />
                      </div>
                    </div>
                  </div>
                  <AgentState agentState={agentState} />
                </div>
              ),
            },
          ]}
        />
        <ConversationData
          startTime={startTimeRef.current}
          items={items}
          realtimeEvents={realtimeEvents}
          isConnected={isConnected}
          voiceBotInstance={voiceBotInstance}
        />
      </div>
    </div>
  );
};

const MemoRealtimeAi = memo(RealtimeAI);
MemoRealtimeAi.displayName = 'RealtimeAI';
export { MemoRealtimeAi };
