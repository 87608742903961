import React from 'react';
// xState
import { useSelector } from '@xstate/react';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
// Types
import { HomeworkProps } from './types';
import { SESSION_TYPES } from '@interfaces/constants';
import { Patient } from '@interfaces/patient';
// Utils
import { findPatientById } from '@utils/patient';
import { getLastSession } from '@utils/sessions';
import { formatTimestamp } from '@modules/Therapist/Home/model/utils/formatters';

const Homework: React.FC<HomeworkProps> = ({ emptyLabelText }) => {
  const { currentPatientId, currentUser } = useSelector(
    refaelaTherapistActor,
    (state) => state.context
  );
  const patientList = useSelector(
    refaelaTherapistActor,
    (state) => state.context.patientSegment.patientList
  );

  const currentPatient =
    currentUser && currentPatientId && findPatientById(patientList, currentPatientId);

  const aiSessions = (currentPatient as Patient)?.sessions.filter(
    (el) => el.sessionType !== SESSION_TYPES.THERAPIST
  );

  const lastAiSession = getLastSession(aiSessions);

  const formattedHHmmTime = formatTimestamp(lastAiSession?.timestamp as number, 'hours');

  const formattedFull = formatTimestamp(lastAiSession?.timestamp as number, 'full');

  return (
    <div className="w-full h-full">
      <div className="flex flex-col gap-5">
        {lastAiSession && (
          <div className="flex flex-col gap-3 items-start ">
            <div className="flex gap-5">
              <div> {formattedFull}</div> <div>{formattedHHmmTime}</div>
            </div>

            <div className="flex gap-8">
              <span> Session type: </span>
              <div>{lastAiSession?.sessionType}</div>
            </div>
            <div className="flex gap-8">
              <span className="text-left text-nowrap">Ai session summary</span>
              <div className="text-left">{lastAiSession?.summary}</div>
            </div>
          </div>
        )}
        <div>{emptyLabelText || 'No content yet'}</div>
      </div>
    </div>
  );
};

export default Homework;
