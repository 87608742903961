import { ReactElement, ReactNode } from 'react';
import SessionItemTitle from './SessionItemTitle';

type SessionListItemProps = {
  img?: string;
  fullName?: string;
  time?: string | number;
  actionsSlot?: ReactNode;
  infoSlot?: ReactElement;
  subTitle?: string;
  itemClass?: string;
  aiSessionType?: string;
  onClick?: () => void;
};

const SessionListItem = ({
  infoSlot,
  actionsSlot,
  fullName = 'John Doe',
  itemClass,
  onClick,
  subTitle = '',
}: SessionListItemProps) => {
  return (
    <div
      onClick={onClick}
      className={`flex cursor-pointer space-x-2 py-1 pl-1 pr-2 sm_d:py-3 sm_d:pl-3 sm_d:pr-5 w-full min-h-10 sm_d:min-h-16 md_d:min-h-[4.5rem] rounded-xl bg-bg-gray ${itemClass}`}
    >
      <div className="flex gap-x-2 w-[80%]">
        <div className={`flex flex-col min-w-[36%] max-w-[36%]`}>
          <SessionItemTitle
            fullName={fullName}
            subTitle={subTitle}
          />
        </div>
        {infoSlot && <div className="flex gap-2 shrink-0 ml-3 space-x-1">{infoSlot}</div>}
      </div>
      {actionsSlot && (
        <div className="flex w-[20%] space-x-2 items-center justify-end">{actionsSlot}</div>
      )}
    </div>
  );
};

export default SessionListItem;
