import { Info, LinkIcon, RefreshCwIcon, TrashIcon } from 'lucide-react';
import { formatDeltaMsToTime } from '../formatters';
import { InfoSlot } from '@modules/Therapist/Home/ui/SessionListItem/InfoSlot';

import { useState } from 'react';
import { deleteAssignedSessionsByType } from '@api/ai/assignedSession';
import { generateSessionLink } from '@api/userAPI';
import { Loader } from '@shared/ui/loader/Loader';

export const createPastSessionInfoSlot = ({ ...props }) => {
  const duration =
    typeof props.duration === 'number' ? formatDeltaMsToTime(props.duration) : props.duration;
  return (
    <>
      <InfoSlot
        titleClass="!font-bold"
        valueClass="!font-bold"
        title="Duration"
        value={duration}
      />
      <InfoSlot
        title="Date"
        value={props.date}
      />
      <InfoSlot
        title="Start time"
        value={props.startTime}
      />
    </>
  );
};
interface AssignSessionInfoProps {
  count: number;
  date: string;
  sessionUrl?: string;
  userId: string;
  type: string;
  onSessionCleared: (clearedType: string, clearedPatientId: string) => void;
}

export const CreateAssignSessionInfoSlot = ({
  count,
  date,
  sessionUrl: initialSessionUrl,
  userId,
  type,
  onSessionCleared,
}: AssignSessionInfoProps) => {
  const [hoveredLink, setHoveredLink] = useState(false);
  const [hoveredTrash, setHoveredTrash] = useState(false);
  const [hoveredRegenerate, setHoveredRegenerate] = useState(false);
  const [copied, setCopied] = useState(false);
  const [sessionUrl, setSessionUrl] = useState(initialSessionUrl);
  const [isRegenerating, setIsRegenerating] = useState(false); // Add loading state

  const handleCopy = () => {
    if (sessionUrl) {
      navigator.clipboard.writeText(sessionUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  const handleClear = async () => {
    console.log('Deleting assigned session...');
    const res = await deleteAssignedSessionsByType(type, userId);

    if (res === 'Success') {
      console.log('Session deleted successfully');
      onSessionCleared(type, userId);
      setSessionUrl(undefined);
    } else {
      console.error('Failed to delete session:', res);
    }
  };

  const handleLinkRegenerate = async () => {
    setIsRegenerating(true); // Set loading state to true before regenerating
    const shortUrl = await generateSessionLink(userId, type, 1);
    setSessionUrl(shortUrl);
    setIsRegenerating(false); // Set loading state to false after regeneration
  };

  return (
    <div className="flex gap-5 items-center">
      <div className="flex gap-2 items-center">
        {' '}
        <InfoSlot
          title="Count"
          value={count}
          infoSlotClasses="items-center"
        />
        <InfoSlot
          valueClass="item"
          title="Date"
          value={date}
        />
      </div>

      {sessionUrl && (
        <div
          className={`relative flex items-center ${isRegenerating ? 'opacity-0' : 'opacity-100'}`}
        >
          <button
            onClick={handleCopy}
            onMouseEnter={() => setHoveredLink(true)}
            onMouseLeave={() => setHoveredLink(false)}
            className="relative flex items-center"
            disabled={isRegenerating}
          >
            <LinkIcon className="text-gray-500 hover:text-purple-600 transition-colors duration-200" />
          </button>

          {(hoveredLink || copied) && (
            <span className="absolute z-10 right-3 top-6 ml-2 px-2 py-1 text-gray-900 text-xs font-normal bg-white rounded text-nowrap shadow-md">
              {copied ? 'Copied!' : 'Copy session URL'}
            </span>
          )}
        </div>
      )}

      <div className="relative flex items-center ">
        <button
          onClick={handleLinkRegenerate}
          onMouseEnter={() => setHoveredRegenerate(true)}
          onMouseLeave={() => setHoveredRegenerate(false)}
          className="relative flex items-center"
          disabled={isRegenerating} // Disable button while regenerating
        >
          {isRegenerating ? (
            <Loader
              className="ml-0"
              spinnerClasses={'!ml-0'}
            />
          ) : (
            <RefreshCwIcon className="text-gray-500 hover:text-purple-600 transition-colors duration-200" />
          )}
        </button>

        {hoveredRegenerate && !isRegenerating && (
          <span className="absolute z-10 right-3 top-6 ml-2 px-2 py-1 text-gray-900 text-xs font-normal bg-white rounded text-nowrap shadow-md">
            {'Re-generate Link'}
          </span>
        )}
      </div>

      <div className="relative flex items-center ml-4">
        <button
          onClick={handleClear}
          onMouseEnter={() => setHoveredTrash(true)}
          onMouseLeave={() => setHoveredTrash(false)}
          className="relative flex items-center"
        >
          <TrashIcon className="text-gray-500 hover:text-rose-400 transition-colors duration-200" />
        </button>

        {hoveredTrash && (
          <span className="absolute z-10 right-3 top-6 ml-2 px-2 py-1 text-rose-400 text-xs font-normal bg-white rounded text-nowrap shadow-md">
            {'Clear assigned sessions'}
          </span>
        )}
      </div>
    </div>
  );
};

export const createLastSessionInfoSlot = ({ ...props }) => {
  const duration =
    typeof props.duration === 'number' ? formatDeltaMsToTime(props.duration) : props.duration;

  return (
    <>
      <InfoSlot
        title="Start time"
        value={new Date(props.timestamp).toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: '2-digit',
          hour12: true,
        })}
      />
      <InfoSlot
        title="Duration"
        value={duration}
      />
      <InfoSlot
        title="Key moments"
        value={props.keyMoments}
      />
      <InfoSlot
        title={
          <div
            title="Learn about SSI more"
            className="flex items-center space-x-2"
          >
            <p>SSI</p>
            <Info className="w-2 h-2 md_d:w-3 md_d:h-3 text-blue-500" />
          </div>
        }
        value={'-'}
      />
    </>
  );
};
