import { useSelector } from '@xstate/react';

import { refaelaPatientActor } from '@components/xState/machines/refaelaPatientMachine';

import InfoCard from '@shared/ui/card/InfoCard';
import HomePageLayout from '@shared/ui/layout/HomePageLayout';
import { useUserMetadata } from '@hooks/useUserMetadata';
import { PatientPrivateMetadata } from '@interfaces/patient';
import { useUser } from '@clerk/clerk-react';

const PatientHome: React.FC = () => {
  const currentUser = useSelector(refaelaPatientActor, (state) => state.context.currentUser);

  const { user } = useUser();
  const { id: userId } = user!;

  const { userMetadata } = useUserMetadata(userId);
  const patientMetadata = userMetadata as PatientPrivateMetadata;

  const handleJoinSession = () => {
    if (currentUser) {
      refaelaPatientActor.send({
        type: 'goToPatientPretalkWaitingRoom',
        currentUser,
      });
    }
  };
  // todo test
  const handlePrepareForSession = () => {
    const hasAssignedSessions =
      patientMetadata.assignedHomeworks &&
      Array.isArray(patientMetadata.assignedHomeworks) &&
      patientMetadata.assignedHomeworks.some((hw) => hw.count > 0);

    if (hasAssignedSessions && currentUser) {
      refaelaPatientActor.send({
        type: 'goToAIDashboard',
        currentUser: currentUser,
      });
    }
  };

  return (
    <HomePageLayout>
      <div className="flex flex-col items-start gap-y-2">
        <p className="text-4xl mb-4 font-semibold text-black-20">Hello, {currentUser?.name}!</p>
        <div className="flex gap-x-6 h-[22rem] md_d:h-[32rem]">
          <InfoCard
            title="Start immediate session with AI"
            caption="Buckle up for the next consultation"
            buttonLabel="Prepare for the session"
            cardClasses="bg-purple-600 text-white"
            captionClasses="text-white"
            onCardButtonClick={handlePrepareForSession}
          />
          <InfoCard
            title="Join session"
            buttonLabel="Go to waiting room"
            onCardButtonClick={handleJoinSession}
          />
        </div>
      </div>
    </HomePageLayout>
  );
};

export default PatientHome;
