import { ReactNode, useEffect, useState } from 'react';
import { ShamefulAny } from '@interfaces/index';

type HeaderProps = {
  navigation: ReactNode;
  breadcrumbs: ReactNode;
  notification: ReactNode;
  dynamic?: {
    afterNavigation: ReactNode;
    afterBreadcrumbs: ReactNode;
  };
  ignoreInputList?: string[];
  ignoreNotificationList?: string[];
  ignoreBreadcrumpsList?: string[];
  stateValue: ShamefulAny;
  disabledMic?: boolean;
};

const Header = ({
  navigation,
  breadcrumbs,
  notification,
  dynamic,
  // ignoreInputList,
  ignoreNotificationList,
  ignoreBreadcrumpsList,
  stateValue,
  // disabledMic,
}: HeaderProps) => {
  const [isNotificationBar, setIsNotificationBar] = useState<boolean>(false);

  useEffect(() => {
    setIsNotificationBar(!ignoreNotificationList?.includes(stateValue['TherapistSession']));
  }, [stateValue]);

  return (
    <div className='flex w-full gap-2'>
      <div className='-ml-14'>{navigation}</div>
      <div className="flex justify-between w-full">
        <div className="flex justtify-start">
          <input
            className="w-full ml-0.5 rounded-lg"
            type="text"
            onChange={() => {}}
            placeholder="type something..."
          />
        </div>
        <div>{dynamic?.afterNavigation}</div>
        <div className="flex justify-center mt-auto mb-auto">
          {!ignoreBreadcrumpsList?.includes(stateValue) && (
            <div className="w-full flex justify-center items-center max-w-[100%]">
              {breadcrumbs}
            </div>
          )}
        </div>

        <div>{dynamic?.afterBreadcrumbs}</div>
        <div>{isNotificationBar && <div className="flex justify-end">{notification}</div>}</div>
        <div></div>
      </div>
    </div>
  );
};

export default Header;
