import { EventLog } from '@shared/types/events/eventLogs';
import { apiClient } from '.';
import { postAIEventLogToDb, postEventLogToDb } from './db';
import { ShamefulAny } from '@interfaces/index';

export interface PostEventLogsPayload {
  therapistId: string;
  patientId: string;
  sessionId: string;
  userRole: string;
  logs: EventLog[];
  json_obj?: string;
}

export interface PostAIEventLogsPayload {
  userId: string;
  sessionId: string;
  logs: EventLog[];
  json_obj?: string;
}

export const postEventLogs = async (payload: PostEventLogsPayload): Promise<ShamefulAny> => {
  return Promise.all([postEventLogToDb(payload), apiClient.post('/logs', payload)]);
};

export const postAIEventLogs = async (payload: PostAIEventLogsPayload): Promise<ShamefulAny> => {
  return Promise.all([postAIEventLogToDb(payload), apiClient.post('/logs/ai', payload)]);
};
