import { useState, useEffect } from 'react';
import { SignOutButton } from '@clerk/clerk-react';
import { LogOut } from 'lucide-react';

import { NavigationButtonRootIcon, NavigationButtonItem } from '../types';

import { iconProps } from '../model/constants/iconSettings';
import Button from '@components/Button';

type NavigationButtonProps = {
  actions?: NavigationButtonItem[];
  rootIcon: NavigationButtonRootIcon;
};

const NavigationButton = ({ actions, rootIcon }: NavigationButtonProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [items, setItems] = useState<NavigationButtonItem[]>([]);

  useEffect(() => {
    if (actions) setItems(actions.filter((action) => action.isDisplayed));
  }, [actions]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleItemClick = (callback: () => void) => {
    callback();
  };

  const signOutButtonTransitionDelay = isHovered ? items.length * 200 : 0;

  const getTransitionStyles = (delay: number) => {
    return `all 0.3s, opacity 0.3s ${delay}ms, transform 0.3s ${delay}ms`;
  };

  return (
    <div className="w-full h-full">
      <div
        role="button"
        tabIndex={0}
        className="relative w-full h-full"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Button
          onClick={rootIcon.onClick}
          variant="primary"
          title={rootIcon.name}
          icon={rootIcon.icon}
          navigation
        />
      </div>
      {items && (
        <div
          className={`absolute z-20 pt-3 flex flex-col items-center gap-4 transition-all duration-300 ease-in-out ${
            isHovered ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'
          } overflow-hidden`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {items.map((item, index) => {
            const transitionDelay = isHovered ? index * 200 : 0;
            const notificationCount = item.name === 'Notification' ? 2 : undefined;

            return (
              <Button
                key={item.name}
                title={item.name}
                onClick={() => handleItemClick(item.onClick)}
                icon={item.icon}
                variant="outline"
                navigation
                className={`${isHovered ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}`}
                aria-label={item.name}
                notificationCount={notificationCount}
                style={{ transition: getTransitionStyles(transitionDelay) }}
              />
            );
          })}
          <SignOutButton>
            <Button
              key="signout"
              title="Sign out"
              icon={<LogOut {...iconProps} />}
              variant="outline"
              navigation
              className={`${isHovered ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}`}
              aria-label="signout"
              style={{ transition: getTransitionStyles(signOutButtonTransitionDelay) }}
            />
          </SignOutButton>
        </div>
      )}
    </div>
  );
};

export default NavigationButton;
