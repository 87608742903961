type SessionItemTitleProps = {
  fullName: string;
  subTitle: string;
};

const SessionItemTitle = ({ fullName, subTitle }: SessionItemTitleProps) => {
  return (
    <div className="flex flex-col gap-y-1 justify-center">
      <p
        title={fullName}
        className="text-left text-sm sm_d:text-base truncate"
      >
        {fullName}
      </p>

      <span className="text-left text-[0.625rem] sm_d:text-xs line-clamp-2 overflow-hidden text-ellipsis">
        {subTitle}
      </span>
    </div>
  );
};

export default SessionItemTitle;
