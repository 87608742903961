import { useCallback } from 'react';
// xState
import { dashboardActor } from '../xstate/dashboardMachine';
// Hooks
import useRealtimeBuffers from './useRealtimeBuffers';
import useVoiceBotEvents from './useVoicebotEvents';
// Constants
import { STEPS_MAP } from '@entities/shared/RealtimeDashboard/model/constants/stepsMap';
// Types
import { RealtimeEventState } from '@shared/types/events/realtimeEvents';

const useEventHandlers = (
  voiceBotInstance: any,
  wavStreamPlayerRef: any,
  userId: string,
  sessionId: string,
  socket: any
) => {
  const handleStateChanged = useCallback((state: RealtimeEventState) => {
    const { stepIndex, substepIndex } = STEPS_MAP[state.event.data.value as keyof typeof STEPS_MAP];
    const currentStepIndex = dashboardActor.getSnapshot().context.currentStepIndex;

    if (currentStepIndex < stepIndex) {
      dashboardActor.send({ type: 'NEXT_STEP' });
    }

    dashboardActor.send({
      type: 'MARK_ACTIVE_CHECK',
      stepIndex,
      checkIndex: substepIndex,
    });
  }, []);

  const {
    agentStateBuffer,
    realtimeEventsBuffer,
    throttleUpdateAgentState,
    throttleUpdateRealtimeEvents,
  } = useRealtimeBuffers();

  const { handleSetEventHandlers } = useVoiceBotEvents(
    voiceBotInstance,
    wavStreamPlayerRef.current,
    handleStateChanged,
    agentStateBuffer,
    throttleUpdateAgentState,
    realtimeEventsBuffer,
    throttleUpdateRealtimeEvents,
    userId,
    sessionId,
    socket
  );

  return { handleSetEventHandlers };
};

export default useEventHandlers;
