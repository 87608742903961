import ToggleableTabs from '@features/Tab/ToggleableTabs/ToggleableTabs';
import ValueTimePlot from '@features/Plot/ValueTimePlot';

import { useSelector } from '@xstate/react';
import { dashboardActor } from '@pages/shared/realtime-dashboard/model/xstate/dashboardMachine';

type HeartRateTabsProps = {
  isShown: boolean;
  onToggle: () => void;
  isCleared: boolean;
};

const HeartRateGraph = ({ isShown, onToggle, isCleared }: HeartRateTabsProps) => {
  const sdkData = useSelector(dashboardActor, (state) => state.context.sdkData);

  return (
    <ToggleableTabs
      isShown={isShown}
      onToggle={onToggle}
      withCardStyle
      tabs={[
        {
          label: 'HR4',
          content: (
            <ValueTimePlot
              key="hr4"
              sdkData={sdkData}
              valueForPlot={sdkData?.hr4s}
              isCleared={isCleared}
              label="hr4"
            />
          ),
        },
        {
          label: 'HR10',
          content: (
            <ValueTimePlot
              key="hr10"
              sdkData={sdkData}
              valueForPlot={sdkData?.hr10s}
              isCleared={isCleared}
              label="hr10"
            />
          ),
        },
      ]}
      titleClasses="max-w-[190px]"
      additionalData={sdkData?.hr10s && `${sdkData.hr10s} bpm`}
    />
  );
};

export default HeartRateGraph;
