import { useContext, useEffect } from 'react';
import { Socket } from 'socket.io-client';
import { AppContext } from '../contextApp';
// Types
import { EventLog } from '@shared/types/events/eventLogs';
// Utils
import { getEventSourceByType } from '@shared/utils/events/getEventSourceByType';
import { postAIEventLogToDb } from '../api/db';

const useSocketIOLogging = (
  sessionId: string,
  userId: string,
  socket: Socket | null,
  eventName: string
) => {
  const { isLogsSending, getEventLogs, clearEventLogs } = useContext(AppContext);

  useEffect(() => {
    if (!isLogsSending || !sessionId || !userId || !socket) return;

    const sendLogsInterval = setInterval(() => {
      const logs: EventLog[] = getEventLogs(); // copy logs
      if (isLogsSending) {
        const logsWithSource = logs.map((log) => ({
          ...log,
          source: getEventSourceByType(log.type),
        }));

        postAIEventLogToDb({
          userId,
          sessionId,
          logs: logsWithSource,
        });

        if (logs.length > 0 && socket?.connected) {
          socket.emit(eventName, {
            userId,
            sessionId,
            logs: logsWithSource,
            json_obj: JSON.stringify(logs),
          });
          clearEventLogs();
        }
      }
    }, 2000);

    return () => {
      clearInterval(sendLogsInterval);
    };
  }, [isLogsSending, sessionId, userId]);
};

export default useSocketIOLogging;
