import { useContext, useMemo } from 'react';
import { useSelector } from '@xstate/react';
import { refaelaTherapistActor } from '@components/xState/machines/refaelaTherapistMachine';
// Constants
import { THERAPIST_MACHINE_EVENTS } from '@machines/therapist/constants/states';
// Types
import { ShamefulAny } from '@interfaces/index';
import { AI_SESSION_TYPES, SESSION_TYPES } from '@interfaces/constants';
// Utils
import { getLastSessionData, getPreviousSessionsData } from './model/utils';
import { formatSessionDate } from './model/utils/formatters';
import { AppContext } from '../../../contextApp';
// Components
import { Chip } from '@shared/ui/chip/Chip';
import TabCard from '@features/TabCard';
import Button from '@components/Button';
import HomePageLayout from '@shared/ui/layout/HomePageLayout';
import InfoCard from '@shared/ui/card/InfoCard';
import SessionListItem from './ui/SessionListItem/SessionListItem';
import PastSessionsTab from './ui/PastSessionsTab';
import AssignedSessionsTab from './ui/AssignedSessionsTab';
import { Loader } from '@shared/ui/loader/Loader';
import { createLastSessionInfoSlot } from './model/utils/slots/infoSlot';
import { createActionSlot } from './model/utils/slots/actionSlot';

type HomeProps = {
  setIsAISessionPresetModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Home = ({ setIsAISessionPresetModalOpen }: HomeProps) => {
  const { patientToMeetId } = useContext(AppContext);
  const currentUser = useSelector(refaelaTherapistActor, (state) => state.context.currentUser);
  const patientList = useSelector(
    refaelaTherapistActor,
    (state) => state.context.patientSegment.patientList
  );
  const isPatientsDataLoading = useSelector(
    refaelaTherapistActor,
    (state) => state.context.patientSegment.isPatientListLoading
  );

  const { assignedSessions, previousSessions } = useMemo(() => {
    return getPreviousSessionsData(patientList);
  }, [patientList]);

  const sessionsWithTherapist = previousSessions.filter(
    (session: ShamefulAny) =>
      // === '' means session completed before definition of session type created
      // and backthen we had only session_with_therapist
      session.session.sessionType === SESSION_TYPES.THERAPIST || session.session.sessionType === ''
  );

  const aiSessions = previousSessions.filter((session: ShamefulAny) =>
    AI_SESSION_TYPES.includes(session.session.sessionType)
  );

  //#region handlers
  const handleGoToSessionReview = (patientId: string, sessionId: string) => {
    refaelaTherapistActor.send({
      type: THERAPIST_MACHINE_EVENTS.GO_TO_SESSION_REPLAY_BY_THERAPIST,
      patientId: patientId,
      sessionId: sessionId,
    });
  };

  const handleAISessionAssign = () => {
    refaelaTherapistActor.send({ type: THERAPIST_MACHINE_EVENTS.GO_TO_ASSIGN_AI_SESSION });
  };

  const handleGoToAISessionReview = (
    userId: string,
    sessionId: string,
    name: string,
    timestamp: number
  ) => {
    refaelaTherapistActor.send({
      type: THERAPIST_MACHINE_EVENTS.GO_TO_AI_SESSION_REVIEW,
      userId: userId,
      sessionId: sessionId,
      aiSessionOwnerName: name,
      aiSessionDateTime: timestamp,
    });
  };

  const handleStartInstantSession = () => {
    refaelaTherapistActor.send({
      type: THERAPIST_MACHINE_EVENTS.GO_TO_THERAPIST_PRETALK_WAITING_ROOM,
      patientId: patientToMeetId,
    });
  };

  const handleLastSessionClick = () => {
    const lastSessionData = getLastSessionData(patientList);
    if (lastSessionData) {
      const { patient, session } = lastSessionData;
      handleGoToSessionReview(patient.patientId, session.sessionId);
    }
  };

  const handlePatientListOpen = () => {
    refaelaTherapistActor.send({ type: THERAPIST_MACHINE_EVENTS.GO_TO_PATIENT_LIST });
  };

  //#endregion handlers

  const chips = [
    { label: 'Pull up last session', onClickFn: handleLastSessionClick },
    { label: 'Open patient list', onClickFn: handlePatientListOpen },
  ];

  const tabs = useMemo(() => {
    return [
      {
        label: 'Past AI sessions',
        content: (
          <PastSessionsTab
            aiSessions={aiSessions}
            onGoToAISessionReview={handleGoToAISessionReview}
            isPatientsDataLoading={isPatientsDataLoading}
          />
        ),
      },
      {
        label: 'Assigned AI sessions',
        content: (
          <AssignedSessionsTab
            assignedSessions={assignedSessions}
            isPatientsDataLoading={isPatientsDataLoading}
          />
        ),
      },
    ];
  }, [aiSessions, isPatientsDataLoading]);

  return (
    <HomePageLayout>
      <div className="flex flex-col items-start gap-y-2">
        <p className="text-4xl font-semibold text-black-20">Hello, {currentUser?.name}!</p>
        <div className="flex gap-2 mb-4 overflow-hidden overflow-x-auto">
          {chips.map((c, i) => {
            return (
              <Chip
                key={i}
                onClick={c.onClickFn}
                bgColor="#F3E8FF"
                textColor="#9333EA"
                label={c.label}
              />
            );
          })}
        </div>
        <div className="flex gap-x-6 h-auto">
          <InfoCard
            title="Last sessions"
            cardClasses="bg-white md_d:!h-full !pt-3.5 !pb-2.5 !w-[27rem] md_d:!w-[38rem]"
          >
            <div className="space-y-2 h-3/4">
              {sessionsWithTherapist.length > 0 ? (
                sessionsWithTherapist.map(({ patient, session }, i) => {
                  const { name, surname, image, patientId } = patient;
                  const { sessionId, timestamp, duration, shortSummary, keyMomentsData } = session;
                  const { date, time } = formatSessionDate(timestamp as number);

                  return (
                    <SessionListItem
                      key={`${patientId}-${name}-${i}-${surname}`}
                      img={image}
                      subTitle={date}
                      time={time}
                      infoSlot={createLastSessionInfoSlot({
                        date,
                        duration,
                        keyMoments: (keyMomentsData?.keyMoments || []).length,
                        timestamp: timestamp,
                      })}
                      actionsSlot={createActionSlot({ shortSummary })}
                      fullName={`${name} ${surname}`}
                      onClick={() => handleGoToSessionReview(patientId, sessionId)}
                    />
                  );
                })
              ) : isPatientsDataLoading ? (
                <Loader className="flex h-full w-full justify-center items-center" />
              ) : (
                <div className="flex h-full items-center justify-center text-center text-gray-500">
                  No assigned sessions available
                </div>
              )}
            </div>
          </InfoCard>

          <TabCard
            cardClasses="bg-white md_d:!h-full !w-[27em] !pb-2.5 !pt-2.5 md_d:!w-[38rem]"
            tabs={tabs}
            sharedBetweenTabsCustomButton={
              <Button
                className="truncate mt-2 h-10 sm_d:h-16 md_d:h-[72px]"
                variant="primary"
                text="Assign AI Session"
                onClick={handleAISessionAssign}
              />
            }
          />
          <div className="flex flex-col h-full justify-between">
            <InfoCard
              title="Start session"
              cardClasses="!w-[15em] bg-gradient-to-r from-purple-600 to-purple-500 !h-[48%] text-white"
              captionClasses="text-white"
              isClickableHoverClasses="hover:from-[#740dd2] hover:to-purple-600"
              buttonLabel="Go to session"
              onCardButtonClick={handleStartInstantSession}
              isClickable={true}
            />
            <InfoCard
              title="Start AI session"
              cardClasses="!w-[15em] bg-gradient-to-r from-blue to-blue-500 !h-[48%] text-white"
              captionClasses="text-white"
              isClickableHoverClasses="hover:from-[#1152e2] hover:to-blue-500"
              buttonLabel="Start session"
              onCardButtonClick={() => setIsAISessionPresetModalOpen(true)}
              isClickable={true}
            />
          </div>
        </div>
      </div>
    </HomePageLayout>
  );
};

export default Home;
