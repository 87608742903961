import { Heartbeat } from '@shared/types/mxlabs/logs';
import { calculateFrequencyDomainMetrics } from './frequencyDomainCalculation';

let rollingHeartbeatWindow: Heartbeat[] = [];
let lastEndLocationSec: number | null = null;

const reconstructHeartbeatWindow = (savedEntry: any) => {
  if (!savedEntry) {
    // for test
    // console.warn('Invalid savedEntry:', savedEntry);
    return;
  }

  const { realtimeHeartbeats } = savedEntry;

  if (!Array.isArray(realtimeHeartbeats) || realtimeHeartbeats.length === 0) {
    // for test
    // console.warn('No new heartbeats, keeping existing window.');
    return;
  }

  const firstNewHeartbeat = realtimeHeartbeats[0];
  if (
    lastEndLocationSec !== null &&
    firstNewHeartbeat.start_location_sec < lastEndLocationSec - 30
  ) {
    // for test
    // console.warn('Data reset detected, clearing rolling window.');
    rollingHeartbeatWindow.length = 0; // Clear window
  }

  // Append new heartbeats
  rollingHeartbeatWindow.push(...realtimeHeartbeats);
  lastEndLocationSec = rollingHeartbeatWindow.at(-1)?.end_location_sec ?? null;

  // Trim the window to keep only the last 30 seconds of data
  if (lastEndLocationSec !== null) {
    const windowStartTime = lastEndLocationSec - 31; // 31 because 30 is not enough TODO: think about it - main concern
    rollingHeartbeatWindow = rollingHeartbeatWindow.filter(
      (hb) => hb.end_location_sec >= windowStartTime
    );
  }
};

export const processSavedData = (savedLogs: any[]) => {
  return savedLogs.map((entry) => {
    reconstructHeartbeatWindow(entry);

    let frequencyMetrics = null;
    if (rollingHeartbeatWindow.length > 1) {
      const firstHeartbeatTime = rollingHeartbeatWindow[0]?.end_location_sec ?? 0;
      const lastHeartbeatTime = rollingHeartbeatWindow.at(-1)?.end_location_sec ?? 0;

      if (lastHeartbeatTime - firstHeartbeatTime >= 30) {
        frequencyMetrics = calculateFrequencyDomainMetrics(rollingHeartbeatWindow);
      }
      //else {
      // for test
      // console.warn('Not enough data accumulated yet.');
      // }
    }

    return {
      ...entry,
      processedData: {
        ...entry.data,
        frequencyDomainMetricsCustom: frequencyMetrics,
      },
    };
  });
};
