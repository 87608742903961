import { isValidElement, ReactNode } from 'react';

type InfoSlotProps = {
  title: string | ReactNode;
  value: string | ReactNode;
  titleClass?: string;
  valueClass?: string;
  infoSlotClasses?: string;
};

export const InfoSlot = ({
  title,
  value,
  titleClass,
  valueClass,
  infoSlotClasses,
}: InfoSlotProps) => {
  const isTitleCanBePlaced = typeof title === 'string' || typeof title === 'number';
  return (
    <div
      className={`flex flex-col w-10 max-w-10 md_d:w-16 md_d:max-w-16 justify-start truncate ${infoSlotClasses}`}
    >
      <div
        className={`text-wrap text-[0.538rem] leading-6 text-left md_d:text-sm font-normal truncate ${titleClass}`}
        title={isTitleCanBePlaced ? String(title) : undefined}
      >
        {title}
      </div>
      <span
        className={`text-[0.75rem] leading-6 text-left font-semibold md_d:text-sm ${isValidElement(value) ? '' : 'truncate'} ${valueClass}`}
        title={isTitleCanBePlaced ? String(value) : undefined}
      >
        {value}
      </span>
    </div>
  );
};
