import { cleanAuthToken } from '@api/userAPI';
import { getIdWithoutPrefix } from '@utils/prefixes';

export const clearAuthToken = async (userId?: string) => {
  try {
    if (!userId) return;

    await cleanAuthToken(getIdWithoutPrefix(userId));
  } catch (e) {
    (e as any).sentryHandled = true;
    console.error('Auth token clean issue', JSON.stringify(e));
  }
};
