import { CustomRadio } from '@shared/ui/input/radio/CustomRadio';

interface LanguageSelectorProps {
  selectedLanguage: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const options = [
  { label: 'English', value: 'English' },
  { label: 'Hebrew', value: 'Hebrew' },
];

export const LanguageSelector = ({ selectedLanguage, onChange }: LanguageSelectorProps) => {
  return (
    <div>
      <div className="font-bold text-gray-800 mb-2">Session Language</div>
      <CustomRadio
        options={options}
        selected={selectedLanguage}
        onChange={onChange}
      />
    </div>
  );
};
