import { useRef, useCallback, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { useSelector } from '@xstate/react';
import { dashboardActor } from '../xstate/dashboardMachine';
import { RealtimeClient } from '@openai/realtime-api-beta';
// API
import { BASE_WS_URL } from '@api/base';
// Types
import { ClientConfig, UseClientResult, VadConfig } from '../types';

interface UseClientInitialConfig extends ClientConfig {
  turnDetectionType: string;
}

const useRealtimeClient = (): UseClientResult => {
  const sessionSettings = useSelector(dashboardActor, (state) => state.context.sessionSettings);
  const sessionPrompt = useSelector(dashboardActor, (state) => state.context.sessionPrompt);
  const selectedEndType = useSelector(dashboardActor, (state) => state.context.selectedEndType);

  const voiceBotInstanceRef = useRef<RealtimeClient>(
    new RealtimeClient({ url: `${BASE_WS_URL}/azure-rt` })
  );

  const realtimeClientConfig = useMemo(() => {
    return {
      voice: sessionSettings?.voice,
      instructions: sessionPrompt,
      temperature: sessionSettings?.temperature,
      maxTokens: sessionSettings?.maxTokens,
      threshold: sessionSettings?.threshold,
      prefixPadding: sessionSettings?.prefixPadding,
      silenceDuration: sessionSettings?.silenceDuration,
      turnDetectionType: selectedEndType.value,
    };
  }, [sessionSettings, sessionPrompt, selectedEndType.value]);

  const sendUpdateConfig = (config: Partial<UseClientInitialConfig>) => {
    if (voiceBotInstanceRef.current.isConnected()) {
      voiceBotInstanceRef?.current?.realtime.send('session.update', {
        session: config,
      });
    }
  };

  const handleUpdateConfig = useCallback(
    (config: Partial<UseClientInitialConfig>) => {
      if (!voiceBotInstanceRef.current) return;
      //VAD config
      voiceBotInstanceRef.current.defaultServerVadConfig.threshold =
        config.threshold ?? voiceBotInstanceRef.current.defaultServerVadConfig.threshold;
      voiceBotInstanceRef.current.defaultServerVadConfig.prefix_padding_ms =
        config.prefixPadding ??
        voiceBotInstanceRef.current.defaultServerVadConfig.prefix_padding_ms;
      voiceBotInstanceRef.current.defaultServerVadConfig.silence_duration_ms =
        config.silenceDuration ??
        voiceBotInstanceRef.current.defaultServerVadConfig.silence_duration_ms;

      voiceBotInstanceRef.current.defaultServerVadConfig.type =
        config.turnDetectionType ?? voiceBotInstanceRef.current.defaultServerVadConfig.type;

      const newConfig = {
        ...voiceBotInstanceRef.current.defaultSessionConfig,
        voice: config.voice ?? voiceBotInstanceRef.current.defaultSessionConfig.voice,
        instructions:
          config.instructions ?? voiceBotInstanceRef.current.defaultSessionConfig.instructions,
        temperature:
          config.temperature ?? voiceBotInstanceRef.current.defaultSessionConfig.temperature,
        max_response_output_tokens:
          config.maxTokens ??
          voiceBotInstanceRef.current.defaultSessionConfig.max_response_output_tokens,
        input_audio_transcription: { model: 'whisper-1' },
        turn_detection: voiceBotInstanceRef.current.defaultServerVadConfig,
      };

      voiceBotInstanceRef.current.defaultSessionConfig = {
        ...voiceBotInstanceRef.current.defaultSessionConfig,
        ...newConfig,
      };

      voiceBotInstanceRef.current.sessionConfig = {
        ...voiceBotInstanceRef.current.sessionConfig,
        ...newConfig,
      };

      sendUpdateConfig(newConfig);
    },
    [voiceBotInstanceRef.current]
  );

  const handleUpdateVadConfig = useCallback(
    (config: Partial<VadConfig>) => {
      if (!voiceBotInstanceRef.current) return;

      const currentVadConfig = voiceBotInstanceRef.current.defaultServerVadConfig;

      const newVadConfig = {
        type: config.type ?? currentVadConfig.type,
        threshold: config.threshold ?? currentVadConfig.threshold ?? 0.5,
        prefix_padding_ms: config.prefixPadding ?? currentVadConfig.prefix_padding_ms ?? 300,
        silence_duration_ms: config.silenceDuration ?? currentVadConfig.silence_duration_ms ?? 500,
      };

      if ((voiceBotInstanceRef.current.sessionConfig as any).turn_detection === null) {
        voiceBotInstanceRef.current.defaultServerVadConfig = newVadConfig;
      } else {
        // Update turn detection on the server with debounce
        if (voiceBotInstanceRef.current.isConnected()) {
          debounce(() => {
            voiceBotInstanceRef?.current?.realtime.send('session.update', {
              session: {
                turn_detection: newVadConfig,
              },
            });
          }, 1000)();
        }
      }
    },
    [voiceBotInstanceRef.current]
  );

  const handleClientReady = useCallback(
    (client: RealtimeClient) => {
      voiceBotInstanceRef.current = client;
      handleUpdateConfig(realtimeClientConfig);
    },
    [realtimeClientConfig, handleUpdateConfig]
  );

  const handleConnectionChange = useCallback(
    (connected: boolean) => {
      if (connected && voiceBotInstanceRef.current) {
        handleUpdateConfig(realtimeClientConfig);
      }
    },
    [realtimeClientConfig, handleUpdateConfig]
  );

  return {
    voiceBotInstance: voiceBotInstanceRef.current,
    handleUpdateConfig,
    handleUpdateVadConfig,
    handleClientReady,
    handleConnectionChange,
  };
};

export default useRealtimeClient;
