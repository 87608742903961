import { memo } from 'react';
import { formatSessionDate } from '../model/utils/formatters';
import SessionListItem from './SessionListItem/SessionListItem';
import { createPastSessionInfoSlot } from '../model/utils/slots/infoSlot';
import { createActionSlot } from '../model/utils/slots/actionSlot';
import { Loader } from '@shared/ui/loader/Loader';

type onGoToAISessionReview = {
  aiSessions: any;
  onGoToAISessionReview: any;
  isPatientsDataLoading: boolean;
};

const PastSessionsTab = memo(
  ({ aiSessions, onGoToAISessionReview, isPatientsDataLoading }: onGoToAISessionReview) => {
    return (
      <div className="space-y-1 h-full">
        {aiSessions.length > 0 ? (
          aiSessions.map(({ patient, session }: any) => {
            const { name, surname, image, patientId } = patient;
            const { sessionId, timestamp, duration, shortSummary } = session;
            const { date, time } = formatSessionDate(timestamp);
            return (
              <SessionListItem
                key={`${patientId}-${sessionId}-${surname}`}
                img={image}
                itemClass="[&&]:bg-gray-90" // the way how to avoid !important by scaling selector points
                subTitle={date}
                time={time}
                fullName={`${name} ${surname}`}
                infoSlot={createPastSessionInfoSlot({ duration: duration, date, startTime: time })}
                actionsSlot={createActionSlot({ shortSummary })}
                onClick={() => onGoToAISessionReview(patientId, sessionId, name, timestamp)}
              />
            );
          })
        ) : isPatientsDataLoading ? (
          <Loader className="flex h-full w-full justify-center items-center" />
        ) : (
          <div className="flex h-full items-center justify-center text-center text-gray-500">
            No assigned sessions available
          </div>
        )}
      </div>
    );
  }
);
PastSessionsTab.displayName = 'PastSessionsTab';

export default PastSessionsTab;
