import { useEffect, useRef, useState } from 'react';
import RealtimeDashboardStepper from '@features/Stepper/RealtimeDashboardStepper';
import { dashboardActor } from '@pages/shared/realtime-dashboard/model/xstate/dashboardMachine';
import { useSelector } from '@xstate/react';
import { WavRenderer } from '@modules/Patient/AISession/model/utils/wav_renderer';
import { WavRecorder, WavStreamPlayer } from '@shared/lib/wavtools/index';

type RealtimeDashboardStates = {
  wavStreamPlayer: WavStreamPlayer;
  wavRecorder: WavRecorder;
};

const StateControls = ({ wavStreamPlayer, wavRecorder }: RealtimeDashboardStates) => {
  const sdkData = useSelector(dashboardActor, (state) => state.context.sdkData);
  const steps = useSelector(dashboardActor, (state) => state.context.steps);
  const isConnected = useSelector(dashboardActor, (state) => state.context.isConnected);
  const currentStep = useSelector(dashboardActor, (state) => state.context.currentStepIndex);

  const [isShenAICalibrated, setIsShenAICalibrated] = useState(false);
  const [isShenAIMeasurementStarted, setShenAIMeasurementStarted] = useState(false);

  // Refs
  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const serverCanvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (sdkData?.heartRate && !isShenAICalibrated) {
      dashboardActor.send({
        type: 'MARK_ACTIVE_CHECK',
        stepIndex: 0,
        checkIndex: 0,
      });
      setIsShenAICalibrated(true);
    }
    if (
      sdkData?.heartRate &&
      sdkData?.cardiacStressRT &&
      sdkData?.hrvSDNN &&
      !isShenAIMeasurementStarted
    ) {
      dashboardActor.send({
        type: 'MARK_ACTIVE_CHECK',
        stepIndex: 0,
        checkIndex: 1,
      });
      dashboardActor.send({
        type: 'NEXT_STEP',
      });
      setShenAIMeasurementStarted(true);
    }
  }, [sdkData?.heartRate, isShenAICalibrated, isShenAIMeasurementStarted]);

  /**
   * Set up render loops for the visualization canvas
   */

  useEffect(() => {
    let isLoaded = true;
    const clientCanvas = clientCanvasRef.current;
    const serverCanvas = serverCanvasRef.current;

    let clientCtx: CanvasRenderingContext2D | null = null;
    let serverCtx: CanvasRenderingContext2D | null = null;

    const render = () => {
      if (!isLoaded) return;

      if (clientCanvas) {
        if (!clientCanvas.width || !clientCanvas.height) {
          clientCanvas.width = clientCanvas.offsetWidth;
          clientCanvas.height = clientCanvas.offsetHeight;
        }
        clientCtx = clientCtx || clientCanvas.getContext('2d');
        if (clientCtx) {
          clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
          const result = wavRecorder?.recording
            ? wavRecorder.getFrequencies('voice')
            : { values: new Float32Array([0]) };
          WavRenderer.drawBars(clientCanvas, clientCtx, result.values, '#2563EB', 10, 0, 8);
        }
      }

      if (serverCanvas) {
        if (!serverCanvas.width || !serverCanvas.height) {
          serverCanvas.width = serverCanvas.offsetWidth;
          serverCanvas.height = serverCanvas.offsetHeight;
        }
        serverCtx = serverCtx || serverCanvas.getContext('2d');
        if (serverCtx) {
          serverCtx.clearRect(0, 0, serverCanvas.width, serverCanvas.height);
          const result = wavStreamPlayer?.analyser
            ? wavStreamPlayer.getFrequencies('voice')
            : { values: new Float32Array([0]) };
          WavRenderer.drawBars(serverCanvas, serverCtx, result.values, '#9333ea', 10, 0, 8);
        }
      }

      requestAnimationFrame(render);
    };

    render();

    return () => {
      isLoaded = false;
    };
  }, []);

  return (
    <RealtimeDashboardStepper
      steps={steps}
      isAutoMode={true}
      isConnected={isConnected}
      currentStepValue={currentStep}
    >
      <div className="flex flex-col mt-10 ml-7">
        <div className="flex items-center h-[40px] w-[100px] gap-1 client">
          <canvas
            className="w-full h-full text-current"
            ref={clientCanvasRef}
          />
        </div>
        <div className="flex items-center h-[40px] w-[100px] gap-1 server">
          <canvas
            className="w-full h-full text-current"
            ref={serverCanvasRef}
          />
        </div>
      </div>
    </RealtimeDashboardStepper>
  );
};

export default StateControls;
