import { Patient } from '@interfaces/patient';
import { TherapistUser } from '@interfaces/therapist';
import { isUserPatient, isUserTherapist } from './helpers';
import { TSessionInstance } from '@interfaces/session';

export const getSessionCookie = () => {
  const name = '__session=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null; // Return null if the cookie is not found
};

export const getSessionsCountByUser = (
  user?: Patient | TherapistUser,
  patientList?: Patient[]
): number | undefined => {
  if (!user) return;

  if (isUserPatient(user.role!)) {
    return (user as Patient).sessions.length;
  }

  if (isUserTherapist(user.role!)) {
    return (
      patientList
        ?.filter((patient) => patient.sessions?.length)
        .reduce((acc, p) => acc + p.sessions.length, 0) ?? 0
    );
  }
};

export const getLastSession = (sessions: TSessionInstance[]) => {
  if (!sessions || sessions.length === 0) {
    return undefined;
  }

  return sessions.reduce((lastSession, currentSession) => {
    return new Date(currentSession.timestamp) > new Date(lastSession.timestamp)
      ? currentSession
      : lastSession;
  });
};
