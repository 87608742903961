import { memo, useMemo } from 'react';
import { KeyMomentWithRange } from '@components/SessionComponents/KeyMoments/types';
import { KeyMoment, KeyMomentsData } from '@interfaces/keyMoments';
import { formatTime } from '@utils/keyMoment';

interface TimelineProps {
  updatedKeyMoments: KeyMomentsData | null;
  recordingTimeFrame: {
    recordingStartTime: number | undefined;
    recordingStopTime: number | undefined;
  };
  videoDuration: number | null;
  sessionDuration: number | null;
  currentTime: number;
  recordingStartTime?: number;
  recordingStopTime?: number;

  handleTimelineClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  videoDifference?: {
    recordingStartsFirst: 'therapistRecording' | 'patientRecording';
    differenceBetweenStarts: number;
  } | null;
}
export const SessionReviewTimeline = memo(
  ({
    updatedKeyMoments,

    videoDuration,
    sessionDuration,
    currentTime,
    recordingStartTime,
    recordingStopTime,
    handleTimelineClick,
  }: TimelineProps) => {
    const durationToUse = useMemo(
      () => videoDuration || sessionDuration,
      [videoDuration, sessionDuration]
    );

    const stableRecordingStartTime = useMemo(() => recordingStartTime ?? 0, [recordingStartTime]);
    const stableRecordingStopTime = useMemo(() => recordingStopTime ?? 0, [recordingStopTime]);

    const rangeBars = useMemo(() => {
      return updatedKeyMoments?.keyMoments.map((moment: KeyMomentWithRange, index: number) => {
        if (!moment.keyMomentRange?.startTime || !moment.keyMomentRange?.endTime) return null;

        const startTime = moment.keyMomentRange.startTime;
        const endTime = moment.keyMomentRange.endTime;
        const a = startTime - stableRecordingStartTime;
        const b = endTime - stableRecordingStartTime;

        const leftPercent = durationToUse ? `${(a / durationToUse) * 100}%` : '0%';
        const widthPercent = durationToUse ? `${((b - a) / durationToUse) * 100}%` : '0%';

        return (
          <div
            key={`range-${index}`}
            className="absolute flex items-center"
            style={{
              left: leftPercent,
              width: widthPercent,
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          >
            <div className="h-5 w-[1px] bg-purple-600 absolute left-0 top-[-50%]"></div>
            <div className="h-3 bg-purple-600 opacity-15 w-full"></div>
            <div className="h-5 w-[1px] bg-purple-600 absolute right-0 top-[-50%]"></div>
          </div>
        );
      });
    }, [updatedKeyMoments, stableRecordingStartTime, durationToUse]);

    const dots = useMemo(() => {
      return updatedKeyMoments?.keyMoments.map((moment: KeyMoment, index: number) => {
        const keyMomentTimeInMs = moment.timestamp - stableRecordingStartTime;
        const leftPercent = durationToUse
          ? `${Math.min((keyMomentTimeInMs / durationToUse) * 100, 100)}%`
          : '0%';

        return (
          <div
            key={`dot-${index}`}
            className={`absolute transform -translate-x-1/2 -translate-y-1/2 cursor-pointer ${
              moment.approvalStatus === 'approved'
                ? 'w-[8px] h-[8px] bg-purple-600 hover:shadow-[0_0_6px_2px_rgba(128,0,128,0.2)]'
                : 'w-2 h-2 bg-gray-600 hover:shadow-[0_0_6px_2px_rgba(75,75,75,0.2)]'
            } rounded-full`}
            style={{ left: leftPercent, top: '50%' }}
          />
        );
      });
    }, [updatedKeyMoments, stableRecordingStartTime, durationToUse]);

    return (
      <div
        className="ml-7 mr-[1.6rem] mt-8 relative"
        onClick={handleTimelineClick}
      >
        <div className="relative h-3 bg-[#ccced0] rounded-xl">
          {rangeBars}
          {dots}

          <div
            className="absolute flex flex-col   w-[2px]"
            style={{
              left: durationToUse ? `${(currentTime / (durationToUse || 1)) * 100}%` : '0%',
              bottom: '-32px',
            }}
          >
            <div className="h-[162px]  bg-purple-600 w-[2px]" />
            <div className="w-16 relative z-10 left-[-2rem] mt-2 bg-purple-600 text-white text-xs px-2 py-1 rounded-lg">
              {formatTime(currentTime + stableRecordingStartTime)}
            </div>
          </div>
        </div>

        <div className="flex w-full justify-between mt-3">
          {!stableRecordingStartTime && !stableRecordingStopTime ? (
            <div className="text-gray-800 text-sm font-normal leading-tight tracking-tight">
              No recording timeframe available
            </div>
          ) : (
            ''
          )}
          {stableRecordingStartTime && (
            <div className="text-gray-800 text-sm font-normal leading-tight tracking-tight">
              {formatTime(stableRecordingStartTime)}
            </div>
          )}
          {stableRecordingStopTime && (
            <div className="text-gray-800 text-sm font-normal leading-tight tracking-tight">
              {formatTime(stableRecordingStopTime)}
            </div>
          )}
        </div>
      </div>
    );
  }
);
