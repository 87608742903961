import { useState, useEffect, useCallback } from 'react';

type FetchState<T> = {
  data: T | null;
  isLoading: boolean;
  error: Error | null;
};

type UseFetchOptions<T, Args extends any[]> = {
  fetcher: (...args: Args) => Promise<T>;
  initialArgs?: Args;
  autoFetch?: boolean;
  enabled?: boolean;
};

export const useFetch = <T, Args extends any[]>({
  fetcher,
  initialArgs = [] as unknown as Args,
  autoFetch = true,
  enabled = true,
}: UseFetchOptions<T, Args>): FetchState<T> & {
  refresh: (...args: Args) => Promise<void>;
} => {
  const [state, setState] = useState<FetchState<T>>({
    data: null,
    isLoading: false,
    error: null,
  });

  const executeQuery = useCallback(
    async (...args: Args) => {
      if (!enabled) return;

      setState((prev) => ({ ...prev, isLoading: true, error: null }));

      try {
        const data = await fetcher(...args);

        setState((prev) => ({ ...prev, data }));
      } catch (error) {
        console.log('useFetch error', JSON.stringify(error));
        setState({
          data: null,
          isLoading: false,
          error: error instanceof Error ? error : new Error('Unknown error'),
        });
      } finally {
        setState((prev) => ({ ...prev, isLoading: false }));
      }
    },
    [fetcher, enabled]
  );

  const refresh = useCallback(
    async (...args: Args) => {
      await executeQuery(...args);
    },
    [executeQuery]
  );

  useEffect(() => {
    if (autoFetch && enabled) {
      executeQuery(...initialArgs);
    }
  }, [autoFetch, enabled, executeQuery]);

  return {
    data: state.data,
    isLoading: state.isLoading,
    error: state.error,
    refresh,
  };
};
