import { refaelaPatientActor } from '@components/xState/machines/refaelaPatientMachine';
import { PATIENT_MACHINE_EVENTS } from '@machines/patient/constants/events';

export const PrivacyDetailsModalContent = () => {
  const handlePrivacyPolicyDetails = () => {
    refaelaPatientActor.send({
      type: PATIENT_MACHINE_EVENTS.GO_TO_PATIENT_TERMS_OF_USE_AND_PRIVACY,
    });
  };

  return (
    <div
      className="overflow-y-auto pr-4 h-full flex flex-col gap-8 text-[13px] leading-[21px]
tracking-tight text-gray-800"
    >
      <p>
        This page provides a brief summary of the main personal data types we collect and how we use
        it.For more full and detailed information, please visit our{' '}
        <span
          onClick={handlePrivacyPolicyDetails}
          className="text-purple-600 cursor-pointer text-decoration-line: underline underline-offset-4"
        >
          Privacy Policy.
        </span>
      </p>
      <div className="flex flex-col">
        <h4 className="mb-5 text-sm font-bold">What Data We Collect</h4>
        <p>If you decide to join us, here is the personal data we would collect:</p>
        <ul className="list-disc  px-6">
          <li>Contact Details: Your name, email address, and phone number</li>
          <li>Session Recordings: Video and audio from your teletherapy sessions</li>
          <li>Biometric Data: Information like your heart rate and facial expressions</li>
          <li>Chat Logs: Your interactions with our AI platform</li>
          <li>
            Notes: Any notes or information you provide through the platform. This could include
            notes from your therapy sessions or notes of reviewed self-work sessions by patients.
          </li>
          <li>
            Technical Data: IP address, device identifier, or other similar identifiers, information
            on interaction with our platform, activity logs.
          </li>
        </ul>
      </div>
      <div className="flex flex-col">
        <h4 className="mb-5 text-sm font-bold">How We Would Use Your Information</h4>
        <p>We would use this personal data for two main purposes:</p>
        <ul className="my-4 list-decimal px-6">
          <li>
            {' '}
            <span className="text-sm font-bold">AI Model Training:</span> training and improving our
            AI models. This helps us enhance the platform's ability to provide meaningful insights
            and support.
          </li>
          <li>
            {' '}
            <span className="text-sm font-bold">System Improvement:</span> Our team would analyse
            this information to understand how well our system is working and make necessary
            improvements.
          </li>
        </ul>
        <p>
          Please note that we may also use your personal data for other purposes, such as improving
          our security measures, complying with legal obligations, and providing customer support.
          During this alpha stage, members of our team (composed of AI development specialists and
          mental health professionals) will personally review and analyse your personal data. We
          want to assure you that all team members who have access to your data are bound by strict
          confidentiality agreements. They are trained in handling sensitive information and are
          obligated to maintain your privacy. Access to your data is limited only to those team
          members who absolutely need it for research and development purposes.
        </p>
      </div>
      <div>
        <h4 className="mb-5 text-sm font-bold">Your Rights</h4>
        <p>
          While you've agreed to participate in our Alpha stage, you still retain important rights
          regarding your personal data. These include the right to access, correct, or delete your
          data, and to object to certain types of processing. We're committed to respecting these
          rights and making it easy for you to exercise them. However, please note that exercising
          some of these rights, particularly data deletion, may result in your inability to continue
          participating in the alpha stage.
        </p>
      </div>
      <div>
        <h4 className="mb-5 text-sm font-bold">Your Choice Matters</h4>
        <p>We want to emphasise a few important points:</p>
        <ul className="list-decimal px-6">
          <li>
            {' '}
            <span className="text-sm font-bold">This is entirely voluntary.</span> You have complete
            freedom to choose whether to participate or not.
          </li>
          <li>
            {' '}
            <span className="text-sm font-bold">You can change your mind at any time.</span> If you
            start and later decide it's not for you, you can stop participating without any negative
            consequences.
          </li>
          <li>
            {' '}
            <span className="text-sm font-bold">Your comfort is our priority.</span> Your comfort is
            our priority. We want this to be a positive experience for you.
          </li>
        </ul>
      </div>
      <div>
        <h4 className="mb-5 text-sm font-bold">Your Choice Matters</h4>
        <p>
          We understand that the information you provide us is deeply personal. Our commitments to
          you are:
        </p>
        <ul className="list-disc  px-6">
          <li>We will always respect your privacy.</li>
          <li>We will never sell your personal data.</li>
          <li>We will keep your personal data safe and secure.</li>
          <li>We will be transparent about how we are using your personal data.</li>
        </ul>
      </div>
      <div>
        <h4 className="mb-5 text-sm font-bold">We are Here for You</h4>
        <p>
          If you have any questions, concerns, or you just want to discuss this further, please
          reach out. You can email us at{' '}
          <a
            href="mailto:privacy@betteryou.tech"
            className="text-purple-600 hover:underline"
          >
            privacy@betteryou.tech
          </a>
          .
        </p>{' '}
        <p>
          Thank you for being a part of our alpha program. Your experiences and insights are
          incredibly valuable, and we are grateful for your trust.
        </p>
        <p className="mt-5 mb-4">In support of your journey,</p>
        <p>BetterYou Team</p>
      </div>
    </div>
  );
};
