import { ComponentPropsWithoutRef, ReactNode, SyntheticEvent } from 'react';

type ActionSlot = {
  onClick: () => void;
  className?: string;
  icon: ReactNode;
};

const ActionSlot = ({
  onClick,
  icon,
  className = '',
  ...props
}: ActionSlot & ComponentPropsWithoutRef<'div'>) => {
  const handleOnClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    onClick?.();
  };

  return (
    <div
      {...props}
      className={`cursor-pointer ${className}`}
      onClick={handleOnClick}
    >
      {icon}
    </div>
  );
};

export default ActionSlot;
