export const SESSION_TYPES = {
  THERAPIST: 'session_with_therapist',
  AI_BIO_FEEDBACK: 'bio_feedback_session',
  AI_MEMORY_INTERVIEW: 'memory_interview',
  AI_FOLLOW_UP_CONVERSATION: 'follow_up_conversation',
} as const;

export const AISessionTypes = {
  'Bio Feedback Session': SESSION_TYPES.AI_BIO_FEEDBACK,
  'Memory Interview': SESSION_TYPES.AI_MEMORY_INTERVIEW,
  'Follow Up Conversation': SESSION_TYPES.AI_FOLLOW_UP_CONVERSATION,
} as const;

export const NormalisedAISessionTypes = {
  '': '',
  [SESSION_TYPES.AI_BIO_FEEDBACK]: 'Bio Feedback Session',
  [SESSION_TYPES.AI_MEMORY_INTERVIEW]: 'Memory Interview',
  [SESSION_TYPES.AI_FOLLOW_UP_CONVERSATION]: 'Follow Up Conversation',
} as const;

export const AI_SESSION_PROMPT_BY_TYPE = {
  bio_feedback_session: 'ai-bio-feedback-1',
  memory_interview: 'ai-memory-interview-1',
  follow_up_conversation: 'ai-follow-up-conversation-1',
} as const;

export const SESSION_PROMPTS = {
  AI_BIO_FEEDBACK_1: 'ai-bio-feedback-1',
  AI_BIO_FEEDBACK_2: 'ai-bio-feedback-2',
  AI_BIO_FEEDBACK_3: 'ai-bio-feedback-3',
  AI_MEMORY_INTERVIEW_1: 'ai-memory-interview-1',
  AI_MEMORY_INTERVIEW_2: 'ai-memory-interview-2',
  AI_MEMORY_INTERVIEW_3: 'ai-memory-interview-3',
  AI_FOLLOW_UP_CONVERSATION_1: 'ai-follow-up-conversation-1',
  AI_FOLLOW_UP_CONVERSATION_2: 'ai-follow-up-conversation-2',
  AI_FOLLOW_UP_CONVERSATION_3: 'ai-follow-up-conversation-3',
} as const;

export const AI_SESSION_TYPES: string[] = [
  SESSION_TYPES.AI_BIO_FEEDBACK,
  SESSION_TYPES.AI_FOLLOW_UP_CONVERSATION,
  SESSION_TYPES.AI_MEMORY_INTERVIEW,
] as const;

export const AI_SESSION_PROMPT_BY_SESSION_TYPE = {
  'Bio Feedback': ['ai-bio-feedback-1', 'ai-bio-feedback-2', 'ai-bio-feedback-3'],
  'Memory Interview': ['ai-memory-interview-1', 'ai-memory-interview-2', 'ai-memory-interview-3'],
  'Follow Up Conversation': [
    'ai-follow-up-conversation-1',
    'ai-follow-up-conversation-2',
    'ai-follow-up-conversation-3',
  ],
} as const;

export const AI_SESSION_PROMPT_TEMPLATE_BY_SESSION_TYPE = {
  [SESSION_TYPES.AI_BIO_FEEDBACK]: ['ai-bio-feedback-1', 'ai-bio-feedback-2', 'ai-bio-feedback-3'],
  [SESSION_TYPES.AI_MEMORY_INTERVIEW]: ['ai-memory-interview-1', 'ai-memory-interview-2', 'ai-memory-interview-3'],
  [SESSION_TYPES.AI_FOLLOW_UP_CONVERSATION]: [
    'ai-follow-up-conversation-1',
    'ai-follow-up-conversation-2',
    'ai-follow-up-conversation-3',
  ],
} as const;

export const AI_SESSION_SUBTYPES_BY_TYPE = {
  [SESSION_TYPES.AI_BIO_FEEDBACK]: ['Bio Feedback Training', 'Meditation Practice'],
  [SESSION_TYPES.AI_MEMORY_INTERVIEW]: ['Explore Memories', 'Visualise Memory'],
  [SESSION_TYPES.AI_FOLLOW_UP_CONVERSATION]: [
    'Key Moments Reflection',
    'Song Reflection',
    'Open Ended Chat',
  ],
} as const;

export const FOLLOW_UP_SUBTYPES = {
  KEY_MOMENTS_REFLECTION: 'Key Moments Reflection',
  SONG_REFLECTION: 'Song Reflection',
  OPEN_ENDED_CHAT: 'Open Ended Chat',
} as const;

export const BIO_FEEDBACK_SUBTYPES = {
  BIO_FEEDBACK_TRAINING: 'Bio Feedback Training',
  MEDITATION_PRACTICE: 'Meditation Practice',
} as const;

export const MEMORY_INTERVIEW_SUBTYPES = {
  EXPLORE_MEMORIES: 'Explore Memories',
  VISUALISE_MEMORY: 'Visualise Memory',
} as const;

export const AI_SESSION_SUBTYPES_DESCRIPTION = {
  [FOLLOW_UP_SUBTYPES.KEY_MOMENTS_REFLECTION]: 'Opening questions and key moments reflection',
  [FOLLOW_UP_SUBTYPES.SONG_REFLECTION]: 'Opening questions, song reflection and discussion on lyrics',
  [FOLLOW_UP_SUBTYPES.OPEN_ENDED_CHAT]: 'Opening questions, explore mood symptoms and open chat',
  [BIO_FEEDBACK_SUBTYPES.BIO_FEEDBACK_TRAINING]: 'Training regulation skills with opening questions, breathing exercise and Reflection',
  [BIO_FEEDBACK_SUBTYPES.MEDITATION_PRACTICE]: 'Training Regulation Skills with Opening Questions, Meditation and Reflection',
  [MEMORY_INTERVIEW_SUBTYPES.EXPLORE_MEMORIES]: 'Interview, explore and visualize memories with Opening Questions and Memory interview',
  [MEMORY_INTERVIEW_SUBTYPES.VISUALISE_MEMORY]: 'Interview, explore and visualize memories with Opening Questions, Memory interview and generating significant images or movies',
} as const;

export const AI_SESSION_TYPES_NORMALISED: string[] = [
  'Bio Feedback',
  'Follow Up Conversation',
  'Memory Interview',
] as const;

export const SPEAKER_STYLES: string[] = [
  'Option 1',
  'Option 2',
  'Option 3',
] as const;

export const azureSupportedLanguages = [
  { name: 'Afrikaans (South Africa)', code: 'af-ZA' },
  { name: 'Arabic (Egypt)', code: 'ar-EG' },
  { name: 'Arabic (Saudi Arabia)', code: 'ar-SA' },
  { name: 'Bulgarian (Bulgaria)', code: 'bg-BG' },
  { name: 'Chinese (Simplified)', code: 'zh-CN' },
  { name: 'Chinese (Traditional)', code: 'zh-TW' },
  { name: 'Croatian (Croatia)', code: 'hr-HR' },
  { name: 'Czech (Czech Republic)', code: 'cs-CZ' },
  { name: 'Danish (Denmark)', code: 'da-DK' },
  { name: 'Dutch (Netherlands)', code: 'nl-NL' },
  { name: 'English (Australia)', code: 'en-AU' },
  { name: 'English (United Kingdom)', code: 'en-GB' },
  { name: 'English (United States)', code: 'en-US' },
  { name: 'Finnish (Finland)', code: 'fi-FI' },
  { name: 'French (France)', code: 'fr-FR' },
  { name: 'German (Germany)', code: 'de-DE' },
  { name: 'Greek (Greece)', code: 'el-GR' },
  { name: 'Hebrew (Israel)', code: 'he-IL' },
  { name: 'Hindi (India)', code: 'hi-IN' },
  { name: 'Hungarian (Hungary)', code: 'hu-HU' },
  { name: 'Indonesian (Indonesia)', code: 'id-ID' },
  { name: 'Italian (Italy)', code: 'it-IT' },
  { name: 'Japanese (Japan)', code: 'ja-JP' },
  { name: 'Korean (Korea)', code: 'ko-KR' },
  { name: 'Norwegian (Bokmål, Norway)', code: 'nb-NO' },
  { name: 'Polish (Poland)', code: 'pl-PL' },
  { name: 'Portuguese (Brazil)', code: 'pt-BR' },
  { name: 'Portuguese (Portugal)', code: 'pt-PT' },
  { name: 'Romanian (Romania)', code: 'ro-RO' },
  { name: 'Russian (Russia)', code: 'ru-RU' },
  { name: 'Slovak (Slovakia)', code: 'sk-SK' },
  { name: 'Spanish (Spain)', code: 'es-ES' },
  { name: 'Swedish (Sweden)', code: 'sv-SE' },
  { name: 'Turkish (Turkey)', code: 'tr-TR' },
  { name: 'Ukrainian (Ukraine)', code: 'uk-UA' },
];
