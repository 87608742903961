import { useEffect, useState, useContext, useRef, memo, useCallback } from 'react';

import { HeartbeatsPreview } from './HeartbeatsPreview';

import { initEventLogsPolling } from './utils';
import { AppContext } from '../../contextApp';
import { isUserPatient, isUserTherapist } from '../../utils/helpers';
import ShortBioInfoBlock from './ShortBioInfoBlock';
import { postCameraFramePatient, postCameraFrameTherapist } from '../../services/frame';

import {
  BioModuleCameraWrapper,
  BioModuleCanvas,
  BioModuleCanvasWrapper,
  BioModuleCard,
  BioModuleContainer,
  BioModuleShortInfoContainer,
} from './bioModule.styled';
import BioModeExtraData from './BioModuleExtraData';
import CameraSelector from '@features/Select/Camera/CameraSelector';
import MicrophoneSelector from '@features/Select/Microphone/MicrophoneSelector';
import { Loader } from '@shared/ui/loader/Loader';

import { useSelector } from '@xstate/react';
import { useWebSocket } from '../../websocket';

import { ShamefulAny } from '@interfaces/index';
import { useShenai } from '@hooks/shenAI/useShenai';
import { getIsFaceOk } from '@shared/utils/shenai/getFaceIsOk';
import { ShenaiSdkDataI } from '@shared/types/shenai/sdk';

interface CalibrationProps {
  onFinish?: () => void;
  sessionId?: string;
  isDailyMeeting: boolean;
  stateMachineActor: ShamefulAny;
  moduleContainerClass?: string;
  areSelectorsShown?: boolean;
  isHintShown?: boolean;
  isExtraDataShown?: boolean;
}
const Calibration = memo<CalibrationProps>(
  ({
    sessionId,
    isDailyMeeting,
    stateMachineActor,
    areSelectorsShown = true,
    isHintShown = true,
    isExtraDataShown = true,
    moduleContainerClass,
  }) => {
    const [isBioCalibrated, setIsBioCalibrated] = useState(false);

    const [isOneMinuteMeasure] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [sdkData, setShenAISDKData] = useState<ShenaiSdkDataI | null>(null);

    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    const stateMachineContext = useSelector(
      stateMachineActor,
      (state: ShamefulAny) => state.context
    );

    const currentUser = stateMachineContext.currentUser || {};

    const { socket } = useWebSocket();

    const {
      setIsBioCalibrated: setIsBioCalibratedCtx,
      getEventLogs,
      clearEventLogs,
      setFrameUrl,
      microphoneId,
      setMicrophoneId,
      cameraId,
      setCameraId,
      isLogsSending,
    } = useContext(AppContext);
    const { shenaiSDK, isSDKInitialized, subscribeToSdkData } = useShenai('calibration');

    const patientId = isUserTherapist(currentUser.role)
      ? stateMachineContext.currentPatientId
      : currentUser.patientId;

    const therapistId = currentUser.therapistId;

    const handleShenAIDataUpdate = useCallback((data: ShenaiSdkDataI) => {
      setShenAISDKData(data);
    }, []);

    useEffect(() => {
      const unsubscribe = subscribeToSdkData(handleShenAIDataUpdate);

      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 1000);

      return () => {
        unsubscribe();
        clearTimeout(timer);
      };
    }, []);

    useEffect(() => {
      let pollingInterval = setInterval(() => {});

      if (isLogsSending && sessionId) {
        pollingInterval = setInterval(
          () =>
            initEventLogsPolling(
              getEventLogs,
              clearEventLogs,
              currentUser.therapistId,
              patientId,
              sessionId,
              currentUser.role,
              isLogsSending
            ),
          2000
        );
      }

      return () => clearInterval(pollingInterval);
    }, [isLogsSending, stateMachineContext]);

    useEffect(() => {
      if (!isOneMinuteMeasure) {
        if (!window.byObj) {
          window.byObj = {};
        }

        if (
          sdkData?.faceState === shenaiSDK?.FaceState.OK &&
          sdkData?.measurementState === shenaiSDK?.MeasurementState.RUNNING_SIGNAL_GOOD &&
          sdkData?.realtimeHeartbeats &&
          sdkData?.hr4s &&
          !isBioCalibrated &&
          !window.byObj?.bioIsCalibrated
        ) {
          // we start to get measurements, we set the flags to true

          setIsBioCalibrated(true);
          setIsBioCalibratedCtx(true);
          window.byObj.bioIsCalibrated = true;
        }
      }
    }, [
      sdkData?.faceState,
      sdkData?.measurementState,
      sdkData?.realtimeHeartbeats,
      sdkData?.hr4s,
      sdkData?.measurementPreset,
    ]);

    useEffect(() => {
      if (!sessionId || !getIsFaceOk(sdkData, shenaiSDK) || !isLogsSending) {
        return;
      }

      const sendFrame = async () => {
        try {
          //TODO: refactor
          const response = isUserTherapist(currentUser.role)
            ? await postCameraFrameTherapist(therapistId, patientId, sessionId)
            : await postCameraFramePatient(patientId, sessionId);

          if (!response || !response[0].frame_url) return;
          const [res] = response;
          const { frame_url: frameUrl } = res;
          if (!frameUrl) return;
          setFrameUrl(frameUrl);
        } catch (error) {
          console.error('calibration send frame error', JSON.stringify(error));
        }
      };

      const intervalId = setInterval(() => {
        sendFrame();
      }, 500);

      return () => clearInterval(intervalId);
    }, [sdkData?.faceState, isLogsSending, sessionId]);

    useEffect(() => {
      if (isBioCalibrated && sessionId && isUserPatient(currentUser.role)) {
        socket?.emit('patient-calibration-finished', {
          patientId: currentUser.patientId,
          sessionId: sessionId,
          therapistId: currentUser.therapistId,

          hr4s: sdkData?.hr4s,
        });
      }
    }, [sdkData, sessionId, isBioCalibrated]);

    useEffect(() => {
      const handleBeforeUnload = () => {
        if (isUserPatient(currentUser.role)) {
          socket?.emit('patient-left-call', {
            patientId: currentUser.patientId,
            sessionId: sessionId,
          });
        } else if (isUserTherapist(currentUser.role)) {
          socket?.emit('patient-left-call', {
            patientId: patientId,
            sessionId: sessionId,
          });
        }
      };

      // Add event listener for tab/window close
      window.addEventListener('beforeunload', handleBeforeUnload);

      // Clean up on component unmount and remove event listener
      return () => {
        handleBeforeUnload();
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, []);

    if (!isDailyMeeting) {
      return (
        <div className="flex flex-col">
          <BioModuleContainer
            className={`w-full flex items-center max-w-[800px] ${moduleContainerClass}`}
            key="bio"
            style={{ display: isDailyMeeting ? 'hidden' : '', background: 'none' }}
          >
            <BioModuleCameraWrapper className="min-w-[220px] w-full h-full md_d:min-w-[320px]">
              <div className="relative w-full h-full">
                <BioModuleCanvasWrapper
                  className={`relative h-[17rem] mx-[11px] md_d:h-full w-full md_d:w-full ${!isLoading || isSDKInitialized ? 'visible' : 'hidden loading-canvas'}`}
                >
                  <BioModuleCanvas
                    className="camera-feed"
                    ref={canvasRef}
                    id="mxcanvas1"
                  />
                </BioModuleCanvasWrapper>
                {!isSDKInitialized && (
                  <Loader className="flex absolute top-1/2 left-[45%] self-center items-center justify-center" />
                )}
              </div>
            </BioModuleCameraWrapper>
            <BioModuleShortInfoContainer>
              <ShortBioInfoBlock
                sdkState={sdkData}
                shenaiSDK={shenaiSDK}
                isDailyMeeting={false}
              />
              {isExtraDataShown && (
                <BioModuleCard
                  is_one_minute_measure={isOneMinuteMeasure ? 'true' : 'false'}
                  className="bio-module-card heartbeat-preview-block overflow-hidden"
                >
                  <span>Hearbeat intervals tachogram</span>
                  <HeartbeatsPreview
                    realtimeBeats={sdkData?.realtimeHeartbeats}
                    finalBeats={sdkData?.results?.heartbeats}
                  />
                </BioModuleCard>
              )}
            </BioModuleShortInfoContainer>
            {isOneMinuteMeasure && <BioModeExtraData sdkState={sdkData} />}
          </BioModuleContainer>
          {areSelectorsShown && (
            <div className="flex mt-4 gap-x-4 items-start">
              <CameraSelector
                setCamera={setCameraId}
                camera={cameraId}
                updateShenai={shenaiSDK}
              />
              <MicrophoneSelector
                setMicrophone={setMicrophoneId}
                microphone={microphoneId}
              />
            </div>
          )}
          {isHintShown && (
            <div className="flex mt-4 text-start text-sm">
              Please wait. You can only start the session when your heartbeat is measuring.
            </div>
          )}
        </div>
      );
    }
  }
);

Calibration.displayName = 'Calibration';

export default Calibration;
