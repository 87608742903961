import { useState } from 'react';
// Components
import Button from '@components/Button';
import Checkbox from '@shared/ui/input/checkbox/Checkbox';
import { Loader } from '@shared/ui/loader/Loader';

import { PrivacyDetailsModalContent } from './ui/PrivacyDetailsModalContent';
import { refaelaPatientActor } from '@components/xState/machines/refaelaPatientMachine';
import { PATIENT_MACHINE_EVENTS } from '@machines/patient/constants/events';
import { getUserMetadata } from '@api/userAPI';
import { updateClerkUserDataById } from '@api/user/updateClerkUserDataById';
import { fetchCurrentUserDetails } from '@api/user/me';
import { ShamefulAny } from '@interfaces/index';
import { AI_SESSION_TYPES, SESSION_TYPES } from '@interfaces/constants';
import { useSelector } from '@xstate/react';

import { PrivacyPolicy } from '@pages/shared/policies/PrivacyPolicy';
import { TermsOfUse } from '@pages/shared/policies/TermsOfUse';
import { ReusableModal } from '@features/Modal/ui/ReusableModal';

export const TermsOfPrivacy = () => {
  const user = useSelector(refaelaPatientActor, (state) => state.context.currentUser);
  const { roomUrl, incomingSessionType, assignedHomeworks, patientId } = user || {};

  const [isAgreed, setIsAgree] = useState(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeModal, setActiveModal] = useState<'privacyPolicy' | 'termsOfUse' | 'details' | null>(
    null
  );
  const handleOnContinue = async () => {
    setIsLoading(true);
    await handleUserAgreement?.(isAgreed);
    setIsLoading(false);
  };

  const handleAgreeTerms = (value: boolean) => {
    setIsAgree(value);
  };
  // ToDo test this:
  const handleUserAgreement = async (isAgreed: boolean) => {
    try {
      if (!patientId || !user) return;

      const existingMetadata = await getUserMetadata(patientId);
      await updateClerkUserDataById(patientId, {
        metadata: {
          ...existingMetadata,
          areTermsOfPrivacyAccepted: isAgreed,
        },
      });

      const updatedUser = await fetchCurrentUserDetails(patientId);

      refaelaPatientActor.send({
        type: PATIENT_MACHINE_EVENTS.UPDATE_PATIENT_USER,
        currentUser: updatedUser,
      });

      // Check if any session type in assignedHomeworks has count > 0
      const hasAssignedSessions =
        assignedHomeworks &&
        Array.isArray(assignedHomeworks) &&
        assignedHomeworks.some((hw) => hw.count > 0);

      // Navigate after agreement
      if (roomUrl && incomingSessionType === SESSION_TYPES.THERAPIST) {
        refaelaPatientActor.send({
          type: PATIENT_MACHINE_EVENTS.GO_TO_PATIENT_PRETALK_WAITING_ROOM,
          currentUser: user,
        });
      } else if (
        hasAssignedSessions &&
        incomingSessionType &&
        AI_SESSION_TYPES.includes(incomingSessionType)
      ) {
        refaelaPatientActor.send({
          type: PATIENT_MACHINE_EVENTS.GO_TO_AI_DASHBOARD,
          currentUser: user,
        });
      }
    } catch (e: ShamefulAny) {
      console.error('Error updating patient agreement', e.message, e.details);
    }
  };

  return (
    <>
      <div className="flex w-1/2 flex-col self-center justify-center mt-8 space-y-4 items-start">
        <p className="font-bold w-2/3 text-2xl text-left leading-7">
          Thank you for joining us in shaping the future of AI assisted therapy!
        </p>
        <div className="w-5/6 flex flex-col justify-start text-left">
          <div
            className="text-left flex flex-col items-start gap-4 leading-[21px]
tracking-tight text-[13px] text-gray-800"
          >
            <p>
              We are happy to invite you to participate in the Alpha version of our platform aimed
              at making psychological treatment more effective, accessible, and personalised.
            </p>
            <p>
              By participating in this version, you consent to the collection of your personal data
              which may include: name, contact details, age, heart rate as well as video and audio
              recordings of therapy sessions.
            </p>
            <p>
              We use this data to train our AI model and to improve our services, enhancing support
              for you and all future patients and therapists.
              <br />
              We are committed to protecting your personal information.
            </p>
            <p>
              Your data will be kept confidential, stored securely, and shared only with our trusted
              service providers.
            </p>
            <p>
              There is no legal obligation for you to provide your personal information, and its
              provision depends on your consent.
            </p>
            <p>
              Your data will be kept confidential, stored securely, and shared only with our trusted
              service providers.
            </p>
            <p>
              There is no legal obligation for you to provide your personal information, and its
              provision depends on your consent.
            </p>

            <p>
              {' '}
              For brief review of our privacy Policy{' '}
              <span
                className="text-purple-600 cursor-pointer"
                onClick={() => setActiveModal('details')}
              >
                Click here
              </span>{' '}
              or see the full Privacy Policy.
            </p>
          </div>
          <div
            className="flex items-center gap-2 mt-5 leading-tight
tracking-tight text-sm"
          >
            <Checkbox
              className=" self-start"
              label=""
              checked={isAgreed}
              onChange={handleAgreeTerms}
            />
            <span>
              Yes, I agree to{' '}
              <span
                onClick={() => setActiveModal('privacyPolicy')}
                className="text-purple-600 cursor-pointer text-decoration-line: underline underline-offset-4"
              >
                Privacy policy
              </span>{' '}
              and{' '}
              <span
                onClick={() => setActiveModal('termsOfUse')}
                className="text-purple-600 cursor-pointer text-decoration-line: underline underline-offset-4"
              >
                Terms of Use
              </span>{' '}
            </span>
          </div>
        </div>
        <Button
          className="self-end"
          text="Continue"
          disabled={!isAgreed}
          onClick={handleOnContinue}
          icon={isLoading && <Loader spinnerClasses="!h-4 !ml-0" />}
        />
      </div>
      {activeModal && (
        <ReusableModal
          isOpen={!!activeModal}
          title="BetterYou Alpha: Your Privacy"
          onClose={() => setActiveModal(null)}
          content={
            activeModal === 'privacyPolicy' ? (
              <div className="overflow-y-auto h-full">
                <PrivacyPolicy />
              </div>
            ) : activeModal === 'termsOfUse' ? (
              <div className="overflow-y-auto h-full">
                <TermsOfUse />
              </div>
            ) : (
              <PrivacyDetailsModalContent />
            )
          }
        />
      )}
    </>
  );
};
