import { Tool } from '@entities/shared/RealtimeDashboard/model/types/tools';
import { dashboardActor } from '@pages/shared/realtime-dashboard/model/xstate/dashboardMachine';
import Switch from '@shared/ui/switch/Switch';
import { shallowEqual, useSelector } from '@xstate/react';
import { SettingsIcon } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';

type AiSimplifiedSettingsProps = {
  onOpenAdvancedMode: (x: boolean) => void;
  setMenuOpen: (isOpen: boolean) => void;
  isSetupStep: boolean;
  isTopBarVisible: boolean;
};

const AiSimplifiedSettings = ({
  onOpenAdvancedMode,
  setMenuOpen,
  isSetupStep,
  isTopBarVisible,
}: AiSimplifiedSettingsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const tools: Tool[] = useSelector(dashboardActor, (state) => state.context.tools, shallowEqual);
  const showTranscription = tools.find((tool) => tool.label === 'Captions')?.flag.isInUse || false;
  const showGraphs = tools.find((tool) => tool.label === 'Graphs')?.flag.isInUse || false;
  const [isClosing, setIsClosing] = useState(false);
  const settingsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isOpen) return;
    !isTopBarVisible && closeMenu();
  }, [isTopBarVisible, isOpen]);

  const toggleMenu = () => {
    isOpen ? closeMenu() : openMenu();
  };

  const openMenu = () => {
    setIsOpen(true);
    setIsClosing(false);
  };

  const closeMenu = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsOpen(false);
      setMenuOpen(false);
      setIsClosing(false);
    }, 300); // Matches CSS duration
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (settingsRef.current && !settingsRef.current.contains(event.target as Node)) {
      isOpen && closeMenu();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    const handleFullScreenChange = () => {
      const fullScreenElement =
        document.fullscreenElement ||
        (document as any).webkitFullscreenElement ||
        (document as any).mozFullScreenElement ||
        (document as any).msFullscreenElement;

      setIsFullScreen(!!fullScreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
    document.addEventListener('mozfullscreenchange', handleFullScreenChange);
    document.addEventListener('msfullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
      document.removeEventListener('msfullscreenchange', handleFullScreenChange);
    };
  }, []);

  const handleToggleTranscription = () => {
    dashboardActor.send({
      type: 'AI_TOOLS_OPEN',
      toolLabel: 'Captions',
    });
  };

  const handleAdvancedModeClick = () => {
    onOpenAdvancedMode(false);
  };

  const handleToggleGraphs = () => {
    dashboardActor.send({
      type: 'AI_TOOLS_OPEN',
      toolLabel: 'Graphs',
    });
  };

  const handleFullScreenToggle = () => {
    if (!isFullScreen) {
      document.documentElement.requestFullscreen().catch((err) => {
        console.error(`Error attempting to enable full-screen mode: ${err.message}`);
      });
    } else {
      document.exitFullscreen().catch((err) => {
        console.error(`Error attempting to exit full-screen mode: ${err.message}`);
      });
    }
    setIsFullScreen(!isFullScreen);
  };

  const menuItems = [
    {
      key: 'advancedView',
      className: 'px-4 py-2 hover:bg-gray-100 cursor-pointer w-full text-start',
      content: (
        <span className="text-gray-800 text-sm font-normal font-['Inter'] leading-tight tracking-tight">
          Advanced view
        </span>
      ),
      onClick: handleAdvancedModeClick,
    },
    {
      key: 'chatCaptions',
      isDisabled: isSetupStep,
      className: 'px-4 py-2 hover:bg-gray-100 w-full text-start',
      content: (
        <Switch
          disabled={isSetupStep}
          label="Chat captions"
          checked={showTranscription}
          containerClasses="justify-between"
          labelClasses="text-gray-800 text-sm font-normal font-['Inter'] leading-tight tracking-tight"
        />
      ),
      onClick: !isSetupStep ? handleToggleTranscription : undefined,
    },
    {
      key: 'graphs',
      isDisabled: isSetupStep,
      className: 'px-4 py-2 hover:bg-gray-100 w-full text-start',
      content: (
        <Switch
          disabled={isSetupStep}
          label="Graphs"
          checked={showGraphs}
          containerClasses="justify-between"
          labelClasses="text-gray-800 text-sm font-normal font-['Inter'] leading-tight tracking-tight"
        />
      ),
      onClick: !isSetupStep ? handleToggleGraphs : undefined,
    },
    {
      key: 'fullScreen',
      isDisabled: isSetupStep,
      className: 'px-4 py-2 hover:bg-gray-100 cursor-pointer w-full text-start',
      content: (
        <button
          className={'cursor-pointer'}
          disabled={isSetupStep}
        >
          <span className="text-gray-800 text-sm font-normal font-['Inter'] leading-tight tracking-tight">
            {isFullScreen ? 'Exit Full Screen' : 'Full Screen'}
          </span>
        </button>
      ),
      onClick: !isSetupStep ? handleFullScreenToggle : undefined,
    },
  ];

  return (
    <div
      className="relative inline-block"
      ref={settingsRef}
    >
      <button
        onClick={toggleMenu}
        className=" p-2 flex items-center justify-center rounded-md hover:bg-purple-50 h-7 "
      >
        <SettingsIcon stroke="#9333ea" />
      </button>

      {(isOpen || isClosing) && (
        <div
          className={`w-[266px] h-auto bg-white rounded shadow-[0px_0px_11.5px_0px_rgba(156,163,175,0.18)] absolute right-0 z-20 transition-all ${
            isClosing ? 'animate-fadeOutUp' : 'animate-fadeInDown'
          }  `}
        >
          <ul className="flex flex-col items-start w-full">
            {menuItems.map(({ key, className, content, onClick, isDisabled }) => (
              <li
                key={key}
                className={`${isDisabled ? 'cursor-not-allowed' : ''} ${className}`}
                onClick={onClick}
              >
                {content}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AiSimplifiedSettings;
