import React from 'react';

interface PowerColumnProps {
  label: string;
  percentage: number;
  color: string;
}

const PowerColumn: React.FC<PowerColumnProps> = ({ label, percentage, color }) => {
  return (
    <div className="relative w-3 bg-gray-200 flex flex-col justify-end items-center h-[90px] rounded-md">
      <div
        className={`w-full transition-all duration-500`}
        style={{ height: `${percentage}%`, backgroundColor: color, opacity: '0.6' }}
      ></div>
      <span className="absolute top-[-40px]  text-gray-800 font-bold text-xs">
        {label} {percentage.toFixed(1)}%
      </span>
    </div>
  );
};

export default PowerColumn;
