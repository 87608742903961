import { Users } from 'lucide-react';

type SelectPatientOptionProps = {
  withIcon?: boolean;
  label: string;
  placeholder?: string;
};

const SelectedPatientOption = ({ withIcon = false, label, placeholder }: SelectPatientOptionProps) => {
  return (
    <div className="flex overflow-hidden items-center">
      {withIcon && (
        <div className="mr-2 w-5">
          <Users
            size={16}
            strokeWidth={1.5}
          />
        </div>
      )}
      <div
        title={label}
        className="text-ellipsis truncate"
      >
        {label || placeholder}
      </div>
    </div>
  );
};

export default SelectedPatientOption;
