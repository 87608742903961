export const PRIVACY_POLICY_DATA_USES = [
  {
    purpose: 'To facilitate, operate, enhance, and provide usage of our Services.',
    legalBasis: ['Consent', 'Performance of a Contract', 'Legitimate Interests'],
  },
  {
    purpose:
      'To train our AI models, including via human review of input and output made by patients in the Alpha stage of the platform.',
    legalBasis: ['Consent'],
  },
  {
    purpose:
      'To authenticate the identity of our Patients and Therapists, and to allow them to access and use our Services.',
    legalBasis: ['Legitimate Interests', 'Performance of a Contract'],
  },
  {
    purpose: 'To provide our Patients and Therapists with assistance and support.',
    legalBasis: ['Legitimate Interests', 'Performance of a Contract'],
  },
  {
    purpose:
      'To develop, customize and improve the Services and our Patients and Therapists’ experience, based on common or personal preferences, experiences, difficulties and feedback.',
    legalBasis: ['Performance of a Contract', 'Consent', 'Legitimate Interests'],
  },
  {
    purpose:
      'To contact our Patients and Therapists with general or personalized service-related messages (such as password-retrieval or billing); or with promotional messages (such as newsletters, special offers, new features etc.); and to facilitate, sponsor and offer certain events and promotions.',
    legalBasis: ['Performance of a Contract', 'Legitimate Interests', 'Consent'],
  },
  {
    purpose:
      'To support and enhance our data security measures, including for the purposes of preventing and mitigating the risks of fraud, error or any illegal or prohibited activity.',
    legalBasis: ['Performance of a Contract', 'Legitimate Interests', 'Legal Obligations'],
  },
  {
    purpose:
      'To create aggregated statistical data, inferred non-Personal Data or anonymized or pseudonymized data (rendered non-personal), which we or our business partners may use to provide and improve our respective services or for any other business purpose.',
    legalBasis: ['Legitimate Interests'],
  },
  {
    purpose:
      'To comply with our contractual and legal obligations and requirements, and maintain our compliance with applicable laws, regulations and standards.',
    legalBasis: ['Performance of a Contract', 'Legitimate Interests', 'Legal Obligations'],
  },
];

type SectionLink = {
  id: string;
  text: string;
};

export const SECTION_LINKS: SectionLink[] = [
  { id: '#section-1', text: 'Data Collection' },
  { id: '#section-2', text: 'Data Uses & Business Purposes' },
  { id: '#section-3', text: 'Data Location' },
  { id: '#section-4', text: 'Data Retention' },
  { id: '#section-5', text: 'Data Disclosure' },
  { id: '#section-6', text: 'Cookies and Tracking Technologies' },
  { id: '#section-7', text: 'Communications' },
  { id: '#section-8', text: 'Data Security' },
  { id: '#section-9', text: 'Data Subject Rights' },
  { id: '#section-10', text: 'Additional Information and Contact Details' },
];
