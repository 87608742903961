import { useEffect, useRef, useState } from 'react';
import MicTestVisualizer from '@components/Visualizer/MicTestVisualizer';
import { dashboardActor } from '@pages/shared/realtime-dashboard/model/xstate/dashboardMachine';
import { useSelector } from '@xstate/react';
import { WavRecorder, WavStreamPlayer } from '@shared/lib/wavtools/index';

export const AUDIO_SOURCE = {
  SERVER: 'server',
  CLIENT: 'client',
  MICROPHONE: 'microphone',
};

type MicTestVisualizerRef = {
  getFrequencies: (type: 'client' | 'server') => { values: number[] };
  updateVisualizer: (frequencies: Float32Array, source: string) => void;
};

type AudioVisualizerProps = {
  wavRecorder: WavRecorder;
  wavStreamPlayer: WavStreamPlayer;
  isShowWarning: boolean;
};

const AudioVisualizer = ({ wavRecorder, wavStreamPlayer, isShowWarning }: AudioVisualizerProps) => {
  const isConnected = useSelector(dashboardActor, (state) => state.context.isConnected);

  const [isServerSpeaking, setServerSpeaking] = useState(false);
  const [isMicTestRunning, setMicTestIsRunning] = useState<boolean>(true);

  const clientVisualizerRef = useRef<MicTestVisualizerRef | null>(null);
  const serverVisualizerRef = useRef<MicTestVisualizerRef | null>(null);
  const micTestVisualizerRef = useRef<MicTestVisualizerRef | null>(null);

  useEffect(() => {
    if (!isMicTestRunning) return;
    let isLoaded = true;
    let audioContext: AudioContext | null = null;
    let stream: MediaStream | null = null;

    const startMicTest = async () => {
      try {
        stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const source = audioContext.createMediaStreamSource(stream);
        source.connect(analyser);

        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Float32Array(bufferLength);
        const lastDataArray = new Float32Array(bufferLength);
        const threshold = 10;
        const updateInterval = 100;
        let lastUpdateTime = performance.now();

        const updateData = () => {
          if (!isLoaded) return;
          analyser.getFloatFrequencyData(dataArray); //TODO think about moving it inside the throttling condition now - lastUpdateTime >= updateInterval

          const now = performance.now();

          if (now - lastUpdateTime >= updateInterval) {
            const hasSignificantChange = dataArray.some(
              (value, index) => Math.abs(value - lastDataArray[index]) > threshold
            );

            if (hasSignificantChange) {
              lastDataArray.set(dataArray);

              if (micTestVisualizerRef.current) {
                micTestVisualizerRef.current.updateVisualizer(dataArray, AUDIO_SOURCE.MICROPHONE);
              }
            }

            lastUpdateTime = now;
          }

          requestAnimationFrame(updateData);
        };

        updateData();
      } catch (err) {
        console.error('Microphone access denied or error:', err);
      }
    };

    startMicTest();

    return () => {
      isLoaded = false;
      if (audioContext) {
        audioContext.close();
      }
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [isMicTestRunning]);

  useEffect(() => {
    let isLoaded = true;
    const frameInterval = 1000 / 30; // 30 FPS = ~33.33ms per frame
    let lastRender = performance.now();

    const isWavRecorderValid = wavRecorder && typeof wavRecorder.getFrequencies === 'function';
    const isWavStreamPlayerValid =
      wavStreamPlayer && typeof wavStreamPlayer.getFrequencies === 'function';

    if (!isWavRecorderValid && !isWavStreamPlayerValid) {
      console.warn('Both WavRecorder and WavStreamPlayer are not properly initialized.');
      return;
    }

    const render = (time: number) => {
      if (!isLoaded) return;

      if (time - lastRender >= frameInterval) {
        // Handle client frequencies
        if (isWavRecorderValid && wavRecorder.recording) {
          const result = wavRecorder.getFrequencies('voice');

          // // Update visualizer dynamically
          if (clientVisualizerRef.current) {
            clientVisualizerRef.current.updateVisualizer(result.values, AUDIO_SOURCE.CLIENT);
          }
        }

        // Handle server frequencies
        if (isWavStreamPlayerValid && wavStreamPlayer.analyser) {
          const result = wavStreamPlayer.getFrequencies('voice');
          const isActive = result.values.some((value: number) => value > 0.1);
          setServerSpeaking(isActive);

          // // Update visualizer dynamically
          if (serverVisualizerRef.current) {
            serverVisualizerRef.current.updateVisualizer(result.values, AUDIO_SOURCE.SERVER);
          }
        } else {
          setServerSpeaking(false);
        }

        lastRender = time;
      }

      window.requestAnimationFrame(render);
    };

    window.requestAnimationFrame(render);

    return () => {
      isLoaded = false;
    };
  }, [wavRecorder, wavStreamPlayer]);

  useEffect(() => {
    isConnected == false ? setMicTestIsRunning(true) : setMicTestIsRunning(false);
  }, [isConnected]);

  const visualizerRef = isMicTestRunning
    ? micTestVisualizerRef
    : isServerSpeaking
      ? serverVisualizerRef
      : clientVisualizerRef;

  return (
    <MicTestVisualizer
      key="ai-visualizer"
      ref={visualizerRef}
      wrapperClassName={isConnected ? 'justify-start' : ''}
      width={105}
      height={105}
      isWarning={isShowWarning}
    />
  );
};

export default AudioVisualizer;
