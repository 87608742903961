import { setup, assign, createActor } from 'xstate';

import { TOGGLEABLE_SECTIONS } from '@entities/shared/RealtimeDashboard/model/constants/togglableSections';
import { generateToggleState } from '@entities/shared/RealtimeDashboard/model/utils/generateToggleSectionsState';
import { ShenaiSdkDataI } from '@shared/types/shenai/sdk';

import { Check, Step } from '@entities/shared/RealtimeDashboard/model/types/steps';
import { ItemType } from '@openai/realtime-api-beta/dist/lib/client';
import { Tool } from '@entities/shared/RealtimeDashboard/model/types/tools';

import { DashboardState, Events } from './events';
import { SessionSettings } from '@shared/types/dashboard/sessionSettings';
import { SourcedRealtimeEvent } from '../types';
import { defaultSessionSettings } from '@shared/constants/sessionSettings';

interface Context {
  sdkData: ShenaiSdkDataI | null;
  items: ItemType[];
  realtimeEvents: SourcedRealtimeEvent[];
  state: DashboardState | null;
  simplifiedSegment: {
    isShowCanvas: boolean;
    isShowWarning: boolean;
  };
  sections: Record<string, boolean>;
  steps: Step[];
  tools: Tool[];
  sessionType: string;
  sessionPrompt: string;
  currentStepIndex: number;
  currentCheckIndex: number;
  isPaused: boolean;
  currentAIConversationTopic: string;
  isConnected: boolean;
  sessionSettings: SessionSettings | null;
  selectedEndType: { label: string; value: string };
}

const dashboardMachine = setup({
  schemas: {
    context: {} as Context,
    events: {} as Events,
  },
}).createMachine({
  id: 'PATIENT_DASHBOARD_MACHINE',
  initial: 'idle',

  context: {
    sdkData: null,
    simplifiedSegment: {
      isShowCanvas: false,
      isShowWarning: false,
    },
    items: [],
    realtimeEvents: [],
    state: null,
    sections: generateToggleState(TOGGLEABLE_SECTIONS),
    sessionType: '',
    sessionPrompt: '',
    currentAIConversationTopic: '',
    steps: [
      {
        label: 'Setup',
        isStepBlocked: false,
        checks: [
          { label: 'lighting and setup', isCheckBlocked: false, isCompleted: false },
          { label: 'heartrate measure', isCheckBlocked: false, isCompleted: false },
        ],
      },
      {
        label: 'Start and chat',
        isStepBlocked: true,
        checks: [
          { label: 'short discussion', isCheckBlocked: true, isCompleted: false },
          { label: 'ready to do excersize', isCheckBlocked: true, isCompleted: false },
        ],
      },
      {
        label: 'Do exercise',
        isStepBlocked: true,
        checks: [
          { label: 'instructions were given', isCheckBlocked: true, isCompleted: false },
          { label: 'show the timer visual', isCheckBlocked: true, isCompleted: false },
        ],
      },
      {
        label: 'Reflect and finish',
        isStepBlocked: true,
        checks: [
          { label: 'short discussion', isCheckBlocked: true, isCompleted: false },
          { label: 'concent to finish', isCheckBlocked: true, isCompleted: false },
        ],
      },
    ],
    tools: [
      {
        label: 'Captions',
        isToolBlocked: false,
        flag: { isBlocked: false, isInUse: true },
      },
      {
        label: 'Graphs',
        isToolBlocked: false,
        flag: { isBlocked: false, isInUse: false },
      },
      {
        label: 'AudioPlayback',
        isToolBlocked: false,
        flag: { isBlocked: false, isInUse: false },
      },
      {
        label: 'VideoPlayback',
        isToolBlocked: false,
        flag: { isBlocked: false, isInUse: false },
      },
    ],
    currentStepIndex: 0,
    currentCheckIndex: 0,
    isPaused: false,
    isConnected: false,
    sessionSettings: defaultSessionSettings,
    selectedEndType: { label: 'select value', value: 'server_vad' },
  },
  states: {
    idle: {
      on: {
        // updates
        SDK_DATA_UPDATE: {
          actions: assign({
            sdkData: ({ event }) => event.data,
          }),
        },
        START_REALTIME_CHECK: {
          actions: [
            assign({
              simplifiedSegment: ({ context }) => {
                const { sdkData } = context;
                const x = sdkData?.bbox?.x;
                let isShowCanvas;
                const isShowWarning =
                  (sdkData?.hr10s || 0) > 100 || (sdkData?.cardiacStressRT || 0) > 4;

                if (!context.simplifiedSegment.isShowCanvas) {
                  isShowCanvas = x === undefined || x > 0.34 || x < 0;
                } else {
                  isShowCanvas = context.simplifiedSegment.isShowCanvas;
                }

                return {
                  ...context.simplifiedSegment,
                  isShowWarning,
                  isShowCanvas,
                };
              },
            }),
          ],
        },
        RESET_SHOW_CANVAS: {
          actions: assign({
            simplifiedSegment: ({ context }) => {
              const { sdkData } = context;
              const x = sdkData?.bbox?.x;

              return {
                ...context.simplifiedSegment,

                isShowCanvas: x === undefined || x > 0.34 || x < 0,
              };
            },
          }),
        },
        REALTIME_EVENTS_UPDATE: {
          actions: assign({
            realtimeEvents: ({ context, event }) => {
              const prev = context.realtimeEvents;
              const realtimeEventData = event.data;

              return [...prev, ...realtimeEventData];
            },
          }),
        },
        REALTIME_CONVERSATION_ITEMS_UPDATE: {
          actions: assign({
            items: ({ event }) => {
              return event.data;
            },
          }),
        },
        CURRENT_CONVERSATION_TOPIC_UPDATE: {
          actions: assign({
            currentAIConversationTopic: ({ event }) => event.currentAIConversationTopic,
          }),
        },
        DASHBOARD_STATE_UPDATE: {
          actions: assign({
            state: ({ event }) => event.state,
          }),
        },
        SESSION_PROMPT_UPDATE: {
          actions: assign({
            sessionPrompt: ({ event }) => event.sessionPrompt,
          }),
        },
        SESSION_SETTINGS_UPDATE: {
          actions: assign({
            sessionSettings: ({ context, event }) => {
              const { key, value } = event.data;
              return {
                ...context.sessionSettings,
                [key]: value,
              };
            },
          }),
        },
        END_TYPE_UPDATE: {
          actions: assign({
            selectedEndType: ({ event }) => {
              return event.option;
            },
          }),
        },
        // toggle
        AI_TOOLS_OPEN: {
          actions: assign({
            tools: ({ context, event }) => {
              return context.tools.map((tool: Tool) =>
                tool.label === event.toolLabel
                  ? { ...tool, flag: { ...tool.flag, isInUse: !tool.flag.isInUse } }
                  : tool
              );
            },
          }),
        },
        TOGGLE_SECTION: {
          actions: assign({
            sections: ({ context, event }) => {
              const key = event.key;
              return {
                ...context.sections,
                [key]: !context.sections[key],
              };
            },
          }),
        },
        SET_IS_CONNECTED: {
          actions: assign({
            isConnected: ({ event }) => event.isConnected,
          }),
        },
        // steps
        NEXT_STEP: {
          actions: assign({
            currentStepIndex: ({ context }) => {
              const nextIndex = context.currentStepIndex + 1;
              return nextIndex < context.steps.length ? nextIndex : context.currentStepIndex;
            },
            steps: ({ context }) => {
              const nextIndex = context.currentStepIndex + 1;
              return context.steps.map((step: Step, index: number) => ({
                ...step,
                isStepBlocked: index > nextIndex,
              }));
            },
          }),
        },
        PREVIOUS_STEP: {
          actions: assign({
            currentStepIndex: ({ context }) => {
              const prevIndex = context.currentStepIndex - 1;
              return prevIndex >= 0 ? prevIndex : context.currentStepIndex;
            },
            steps: ({ context }) => {
              const prevIndex = context.currentStepIndex - 1;
              return context.steps.map((step: Step, index: number) => ({
                ...step,
                isStepBlocked: index > prevIndex,
              }));
            },
          }),
        },
        MARK_ACTIVE_CHECK: {
          actions: assign({
            steps: ({ context, event }) => {
              const { stepIndex, checkIndex } = event;
              return context.steps.map((step: Step, index: number) =>
                index === stepIndex
                  ? {
                      ...step,
                      checks: step.checks.map((check: Check, i: number) =>
                        i === checkIndex ? { ...check, isCompleted: true } : check
                      ),
                    }
                  : step
              );
            },
          }),
        },
        // controls
        PAUSE: {
          actions: assign({
            isPaused: true,
          }),
        },
        RESUME: {
          actions: assign({
            isPaused: false,
          }),
        },
      },
    },
  },
});

export const dashboardActor = createActor(dashboardMachine, {});
