import { useSelector } from '@xstate/react';

import ToggleableTabs from '@features/Tab/ToggleableTabs/ToggleableTabs';
import { dashboardActor } from '../../../../../../pages/shared/realtime-dashboard/model/xstate/dashboardMachine';
import ConversationModelEvents from '@modules/Patient/AISession/ui/ConversationModelEvents';
import RealtimeEvents from '@modules/Patient/AISession/ui/RealtimeEvents';
import { TOGGLEABLE_SECTIONS } from '../../../model/constants/togglableSections';

type ConversationDataProps = {
  items?: any;
  realtimeEvents?: any;
  isConnected?: boolean;
  voiceBotInstance?: any;
  startTime: string;
};

const ConversationData = ({
  realtimeEvents,
  startTime,
  voiceBotInstance,
}: ConversationDataProps) => {
  const sections = useSelector(dashboardActor, (state) => state.context.sections);

  const handleSectionToggle = (key: string) => {
    dashboardActor.send({ type: 'TOGGLE_SECTION', key });
  };

  return (
    <div className="mb-4 space-y-4">
      <ToggleableTabs
        isShown={sections[TOGGLEABLE_SECTIONS.CONVERSATION_MODEL_EVENTS]}
        onToggle={() => handleSectionToggle(TOGGLEABLE_SECTIONS.CONVERSATION_MODEL_EVENTS)}
        withCardStyle
        tabs={[
          {
            label: 'Conversation model events',
            content: <ConversationModelEvents voiceBotInstance={voiceBotInstance} />,
          },
        ]}
        key="rtTranscript"
      />
      <ToggleableTabs
        isShown={sections[TOGGLEABLE_SECTIONS.REALTIME_TRANSCRIPTS]}
        onToggle={() => handleSectionToggle(TOGGLEABLE_SECTIONS.REALTIME_TRANSCRIPTS)}
        withCardStyle
        tabs={[
          {
            label: 'Realtime transcript',
            content: (
              <RealtimeEvents
                startTime={startTime}
                realtimeEvents={realtimeEvents}
              />
            ),
          },
        ]}
        key="conversationalModelEvents"
      />
    </div>
  );
};

export default ConversationData;
